import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { downloadPDF } from '@api/docs';
import { useMutation } from '@tanstack/react-query';

export const useDownloadDoc = () => {
  const downloadMutation = useMutation({
    mutationFn: ({ id }: { id: string; name: string }) => downloadPDF({ id }),
    onMutate: () => {
      startedSnack({
        label: 'Downloading doc...',
        close: true,
      });
    },
    onSuccess: (data, variables) => {
      // Create a URL for the Blob and trigger the download
      const url = window.URL.createObjectURL(
        new Blob([data], { type: 'application/pdf' }),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${variables.name ? variables.name : 'Untitled'}.pdf`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      startedSnack({
        label: 'Downloaded doc',
      });
    },
    onError: (error, variables) => {
      console.log(error);
      startedSnack({
        label: "Couldn't download doc",
        action: {
          label: 'Retry',
          action: () => {
            downloadMutation.mutate(variables);
          },
          close: true,
        },
      });
    },
  });

  return {
    downloadMutation,
  };
};
