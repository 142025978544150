import React from 'react';

import { Helmet } from 'react-helmet-async';

interface Props {
  name: string;
}

export const DocumentHead = ({ name }: Props) => {
  return (
    <Helmet>
      <title>
        {`${name === null ? 'Untitled' : name}`}
        &nbsp;| Visualist
      </title>
    </Helmet>
  );
};
