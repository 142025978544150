import React from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import type { Stage } from 'konva/lib/Stage';
import {
  Group,
  Image as KonvaImage,
  Layer,
  Stage as KonvaStage,
  Transformer,
} from 'react-konva';
import { Html } from 'react-konva-utils';
import useLoadedImage from 'use-image';

import {
  AssistChip,
  Check,
  SegmentedButton,
  ThinArrowIcon,
  Tooltip,
  TypographyPoppins,
  VaiStarIcon,
  VaiVerticalIcon,
} from '@visualist/design-system/src/components/v2';
import { useKeyPress } from '@visualist/hooks';

import { PageLoader } from '@components/PageLoader';
import { Spinner } from '@components/Spinner';
import { ImageBlock } from '@pages/StudioPage/api';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useLayer } from '@pages/StudioPage/hooks/useLayer';
import {
  $showShuffler,
  closedShuffler,
  closedVaiTidyupModal,
} from '@pages/StudioPage/model';
import { VaiModal } from '@src/entities/vai/ui/modal';
import { WOLFE_LIGHT } from '@src/shared/constants/colours';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { useShuffler } from './useShuffler';

import styles from './styles.module.css';

type Props = {
  stageRef: React.MutableRefObject<Stage | null>;
  designId: string;
  debouncedGenerateThumbnail: () => void;
};

export const Shuffler = ({
  stageRef,
  designId,
  debouncedGenerateThumbnail,
}: Props) => {
  const [showShuffler] = useUnit([$showShuffler]);

  const {
    selectHero,
    centerPoint,
    currentHeroImage,
    currentMapKey,
    setCurrentMapKey,
    shuffledMap,
    handleShuffle,
    hasLoadedTransparentImages,
    imageUpdateMutation,
  } = useShuffler({ designId });

  const { mutateLayers } = useLayer();

  const applyLayoutMutation = useMutation({
    mutationFn: async () => {
      if (!currentMapKey) return console.error('No layout selected');

      const currentLayoutData = shuffledMap.get(currentMapKey);

      if (!currentLayoutData) return console.error('No layout data found');

      // TODO: Send images update and new layer info
      const imageUpdates = currentLayoutData.images.map((image) => {
        return imageUpdateMutation.mutateAsync({
          imageId: image.id,
          positionX: image.studio.position_x,
          positionY: image.studio.position_y,
          positionHeight: image.studio.position_height ?? image.height,
          positionWidth: image.studio.position_width ?? image.width,
          positionOmega: image.studio.position_omega,
          positionLock: false,
        });
      });

      await Promise.allSettled(imageUpdates);

      await mutateLayers.mutateAsync({
        designId,
        imageBlocks: currentLayoutData.images,
      });

      if (!stageRef.current) return;

      closedShuffler();

      // Wait for the modal to close
      await new Promise((resolve) => setTimeout(resolve, 400));

      // Animate all the images to new positions
      stageRef.current.children.forEach((layer) => {
        if (layer.name() !== 'Images') return;

        layer.children.forEach((g) => {
          // @ts-ignore
          g.children.forEach((c: TImage) => {
            if (c.attrs.name === 'Image') {
              const imageBlock = currentLayoutData.images.find(
                (block) => block.id === c.id(),
              );

              if (!imageBlock) return;

              c.to({
                x: imageBlock.studio.position_x,
                y: imageBlock.studio.position_y,
                rotation: imageBlock.studio.position_omega,
                width: imageBlock.studio.position_width ?? imageBlock.width,
                height: imageBlock.studio.position_height ?? imageBlock.height,
                duration: 0.5,
              });
            }
          });
        });
      });

      debouncedGenerateThumbnail();
    },
  });

  useKeyPress({
    key: 'Enter',
    onKeyDown: () => {
      if (!showShuffler) return;
      applyLayoutMutation.mutate();
    },
  });

  return (
    <VaiModal
      id="vai-tidy-up"
      showModal={showShuffler}
      closeModal={() => closedShuffler()}
      footer={
        <LayoutControls
          numberOfKeys={shuffledMap.size}
          currentMapKey={currentMapKey}
          setCurrentMapKey={setCurrentMapKey}
          applyLayoutMutation={applyLayoutMutation}
        />
      }
    >
      <div className={styles.toolbarContainer}>
        <Toolbar handleShuffle={handleShuffle} />
      </div>
      <Canvas
        designId={designId}
        selectHero={selectHero}
        centerPoint={centerPoint}
        currentHeroImage={currentHeroImage}
        currentMapKey={currentMapKey}
        handleShuffle={handleShuffle}
        shuffledMap={shuffledMap}
        hasLoadedTransparentImages={hasLoadedTransparentImages}
      />
    </VaiModal>
  );
};

const Toolbar = ({ handleShuffle }: { handleShuffle: () => void }) => {
  return (
    <>
      <TypographyPoppins type="body" bodySize="M" className={styles.footerText}>
        Tidy up your designs with Vai. To see more layouts, press Spacebar or
      </TypographyPoppins>
      <AssistChip
        leadingIcon
        icon={<VaiVerticalIcon color="black" />}
        onClick={handleShuffle}
        style="elevated"
        className={styles.tidyButton}
      >
        <div className={styles.sparkles}>
          <VaiStarIcon className={styles.sparkle} />
        </div>
        Tidy up
      </AssistChip>
    </>
  );
};

const LayoutControls = (props: {
  currentMapKey: number | undefined;
  setCurrentMapKey: React.Dispatch<React.SetStateAction<number | undefined>>;
  numberOfKeys: number;
  applyLayoutMutation: UseMutationResult<void, Error, void, unknown>;
}) => {
  const { currentMapKey, setCurrentMapKey, numberOfKeys, applyLayoutMutation } =
    props;

  const incrementMapKey = () => {
    if (currentMapKey === undefined) return setCurrentMapKey(0);
    if (currentMapKey >= numberOfKeys) return;
    setCurrentMapKey((k = 0) => k + 1);
  };

  const decrementMapKey = () => {
    if (currentMapKey === undefined) return;

    if (currentMapKey <= 0) return;

    setCurrentMapKey(currentMapKey - 1);
  };

  useKeyPress({
    key: 'ArrowRight',
    onKeyDown: incrementMapKey,
  });

  useKeyPress({
    key: 'ArrowLeft',
    onKeyDown: decrementMapKey,
  });

  return (
    <div className={styles.toolbar}>
      <Tooltip
        style={{
          minWidth: 'max-content',
        }}
        parameter={{
          type: 'plain',
          description: 'Previous layout',
          position: 'top',
          hasVisualBoundary: true,
        }}
      >
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<ThinArrowIcon direction="left" height={24} width={24} />}
          isDisabled={currentMapKey === 0}
          onClick={decrementMapKey}
        />
      </Tooltip>
      <Tooltip
        style={{
          minWidth: 'max-content',
        }}
        parameter={{
          type: 'plain',
          description: 'Next layout',
          position: 'top',
          hasVisualBoundary: true,
        }}
      >
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<ThinArrowIcon direction="right" height={24} width={24} />}
          isDisabled={
            currentMapKey === undefined || currentMapKey >= numberOfKeys
          }
          onClick={incrementMapKey}
        />
      </Tooltip>
      <div className={styles.divider} />
      <SegmentedButton
        buttonStyle={styles.button}
        icon={
          applyLayoutMutation.isPending ? (
            <Spinner colour={WOLFE_LIGHT} />
          ) : (
            <Check height={14} width={14} />
          )
        }
        label="Apply this layout"
        isDisabled={numberOfKeys === 0}
        onClick={async () => {
          if (!applyLayoutMutation.isPending) {
            await applyLayoutMutation.mutateAsync();
            closedVaiTidyupModal();
          }
        }}
      />
    </div>
  );
};

const STAGE_SCALE = 0.07;

const Canvas = (props: {
  designId: string;
  handleShuffle: () => void;
  hasLoadedTransparentImages: boolean;
  centerPoint: { x: number; y: number };
  selectHero: (id: string) => void;
  currentHeroImage: string;
  currentMapKey: number | undefined;
  shuffledMap: Map<number, { images: ImageBlock[] }>;
}) => {
  const {
    designId,
    handleShuffle,
    hasLoadedTransparentImages,
    centerPoint,
    selectHero,
    currentHeroImage,
    currentMapKey,
    shuffledMap,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const { imageQuery } = useImages({ designId });
  const stageRef = React.useRef<Stage | null>(null);
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (!ref.current) return;
    const { width, height } = ref.current.getBoundingClientRect();
    setDimensions({
      width: Math.min(width ?? 0, 1200),
      height: Math.min(height ?? 0, 1200),
    });

    window.addEventListener('resize', () => {
      setDimensions({
        width: Math.min(ref.current?.clientWidth ?? 0, 1200),
        height: Math.min(ref.current?.clientHeight ?? 0, 1200),
      });
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [hasLoadedTransparentImages]);

  useKeyPress({
    key: ' ',
    onKeyDown: handleShuffle,
  });

  if (!hasLoadedTransparentImages)
    return (
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <PageLoader isVai />
      </div>
    );

  return (
    <div className={styles.canvas} ref={ref}>
      <KonvaStage
        ref={stageRef}
        scale={{
          x: STAGE_SCALE,
          y: STAGE_SCALE,
        }}
        x={-centerPoint.x * STAGE_SCALE + dimensions?.width / 2}
        y={-centerPoint.y * STAGE_SCALE + dimensions?.height / 2}
        height={dimensions.height}
        width={dimensions.width}
      >
        <Layer>
          {imageQuery.data ? (
            <Images
              selectHero={selectHero}
              heroImage={currentHeroImage}
              images={
                currentMapKey
                  ? shuffledMap.get(currentMapKey)?.images ??
                    imageQuery.data.data.results
                  : imageQuery.data.data.results
              }
            />
          ) : null}
        </Layer>
      </KonvaStage>
    </div>
  );
};

const Images = ({
  images,
  heroImage,
  selectHero,
}: {
  images: ImageBlock[];
  heroImage: string;
  selectHero: (id: string) => void;
}) => {
  return (
    <>
      {images.map((image) => (
        <Image
          selectHero={selectHero}
          key={image.id}
          id={image.id}
          x={image.studio.position_x ?? 0}
          y={image.studio.position_y ?? 0}
          height={image.studio.position_height ?? 0}
          width={image.studio.position_width ?? 0}
          rotation={image.studio.position_omega}
          url={image.file.full_size}
          isHero={heroImage === image.id}
        />
      ))}
    </>
  );
};

const Image = ({
  id,
  height,
  width,
  rotation,
  url,
  x,
  y,
  isHero,
  selectHero,
}: {
  id: string;
  x: number;
  y: number;
  height: number;
  width: number;
  rotation: number;
  url: string;
  isHero: boolean;
  selectHero: (id: string) => void;
}) => {
  const [image] = useLoadedImage(url, 'anonymous');

  const [isHovered, setIsHovered] = React.useState(false);
  const hoveredTrRef = React.useRef<Konva.Transformer>(null);
  const imageRef = React.useRef<Konva.Image>(null);

  const removeHoveredImageTransformer = () => {
    // @ts-ignore
    hoveredTrRef.current.nodes([]);
    // @ts-ignore
    hoveredTrRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if (isHovered && hoveredTrRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      hoveredTrRef.current.nodes([imageRef.current]);
      // @ts-ignore
      hoveredTrRef.current.getLayer().batchDraw();
    } else if (!isHovered && hoveredTrRef.current)
      removeHoveredImageTransformer();

    return () => {
      if (hoveredTrRef.current) removeHoveredImageTransformer();
    };
  }, [isHovered]);

  return (
    <>
      <KonvaImage
        ref={imageRef}
        id={id}
        x={x}
        y={y}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        stroke={isHero ? WOLFE_LIGHT : undefined}
        strokeWidth={isHero ? 20 : undefined}
        height={height}
        width={width}
        rotation={rotation}
        image={image}
        onClick={() => selectHero(id)}
      />
      {isHovered ? (
        <Transformer
          rotateEnabled={false}
          // @ts-ignore TODO fix type issue
          ref={hoveredTrRef}
          keepRatio={false}
          enabledAnchors={[]}
          resizeEnabled={false}
          borderStroke={WOLFE_LIGHT}
          anchorStroke={WOLFE_LIGHT}
        />
      ) : null}
      <Group x={x} y={y} width={width}>
        <Html
          groupProps={{
            x: width / 2,
            y: -320,
          }} // additional properties to the group wrapper, useful for some position offset
        >
          {/* isHovered && !isHero */}
          {isHovered && !isHero ? (
            // @ts-ignore Some query client global issue
            <div className={styles.tooltip}>
              <TypographyPoppins type="body" bodySize="M">
                Select this as hero
              </TypographyPoppins>
            </div>
          ) : null}
        </Html>
      </Group>
    </>
  );
};
