import { useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Button,
  Divider,
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { ResponseHubActions } from '@api/actions';
import { HubResponse } from '@api/hubs';
import {
  updatedFilterByHub,
  updatedHubFilter,
} from '@pages/action-items/modal';
import { ActionItem } from '@pages/action-items/ui/action-items-list';
import { ActionItemDetails } from '@src/entities/sheet/action-item-details';

import styles from './styles.module.css';

type Props = {
  items?: ResponseHubActions;
  hub: HubResponse;
};

export const HubActionItems = ({ items, hub }: Props) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actionId = queryParams.get('actionId');
  const [showActionItemDetails, setShowActionItemDetails] = useState<boolean>(
    typeof actionId === 'string',
  );
  const openActionItems = () => {
    updatedHubFilter({
      id: hub.id,
      name: hub.name,
    });
    updatedFilterByHub('hub');
    history.push('/action-items/');
  };
  const createNewActionItem = () => {
    updatedHubFilter({
      id: hub.id,
      name: hub.name,
    });
    updatedFilterByHub('hub');
    setShowActionItemDetails(true);
    history.push(`/h/${hub.id}?actionId=new`);
  };

  return (
    <>
      <li className={styles.item} id="onboarding-hub-todos">
        <div className={cn(styles.card, styles.actionItemCard)}>
          <div className={styles.header}>
            <TypographyPoppins
              className={styles.color}
              type="title"
              titleSize="M"
            >
              Action items ({items?.count ? items?.count : 0})
            </TypographyPoppins>
            {items && items.count > 0 && (
              <div className={styles.actions}>
                <IconButton
                  type="unfilled"
                  icon={<Icon className={styles.expand} name="sprite/plus" />}
                  onClick={createNewActionItem}
                />
              </div>
            )}
          </div>
          <div className={styles.horizontal}>
            <Divider className={styles.divider} type="long-line" />
          </div>
          <div className={styles.list}>
            <ul className={styles.actionItems}>
              {!items || items.count === 0 ? (
                <div className={styles.emptyActionItemsContainer}>
                  <div className={styles.emptyContent}>
                    <Icon name="sprite/todo-list-soft-colored" size={80} />
                    <TypographyPoppins
                      type="body"
                      bodySize="S"
                      style={{ color: 'var(--color-secondary-40)' }}
                    >
                      Keep track of your to-dos
                      <br />
                      effortlessly
                    </TypographyPoppins>
                  </div>
                  <Button
                    label="Add new action item"
                    onClick={createNewActionItem}
                    type="outlined"
                  />
                </div>
              ) : (
                items?.results.map((item, index) => (
                  <ActionItem
                    key={item.id}
                    action={item}
                    onClick={() => {
                      setShowActionItemDetails(true);
                      history.push(`/h/${hub.id}?actionId=${item.id}`);
                    }}
                    displayDescription={false}
                    displayDivider={index === 2 ? false : true}
                    dividerAlignAvatar={true}
                  />
                ))
              )}
            </ul>
          </div>
          {items && items.count > 0 && (
            <Button
              type="text"
              label="See all"
              style={{ color: 'var(--color-secondary-40)' }}
              labelSize="S"
              onClick={openActionItems}
            />
          )}
        </div>
      </li>
      <ActionItemDetails
        isOpen={showActionItemDetails}
        close={() => {
          history.push(`/h/${hub.id}`);
          setShowActionItemDetails(false);
        }}
      />
    </>
  );
};
