import React from 'react';

import { useUnit } from 'effector-react';
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';

import {
  AssistChip,
  TypographyPoppins,
  VaiVerticalIcon,
} from '@visualist/design-system/src/components/v2';

import {
  $showVaiTidyupModal,
  closedVaiTidyupModal,
  openedShuffler,
} from '@pages/StudioPage/model';
import { VaiPopup } from '@src/entities/vai/ui/popup';

import styles from './styles.module.css';

export const VaiTidyPopupModal = () => {
  const [showVaiTidyupModal] = useUnit([$showVaiTidyupModal]);
  const text = `You’ve got a nice selection of\nimages in Studio. Let Vai help you\ntidy them up?`;

  const textLength = useMotionValue(0);

  React.useEffect(() => {
    let controls: AnimationPlaybackControls;
    if (showVaiTidyupModal) {
      const timeout = setTimeout(() => {
        controls = animate(textLength, text.length, {
          duration: 4,
        });
      }, 500);
      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
  }, [showVaiTidyupModal]);

  const roundedTextLength = useTransform(textLength, (latest) =>
    Math.round(latest),
  );
  const displayedText = useTransform(roundedTextLength, (latest) =>
    text.slice(0, latest),
  );

  return (
    <VaiPopup
      closePopup={() => closedVaiTidyupModal()}
      showPopup={showVaiTidyupModal}
      width={300}
    >
      <div className={styles.main}>
        <TypographyPoppins type="body" bodySize="M" className={styles.text}>
          <motion.span>{displayedText}</motion.span>
        </TypographyPoppins>
        <AssistChip
          leadingIcon
          icon={<VaiVerticalIcon color="black" />}
          onClick={() => openedShuffler()}
          style="elevated"
        >
          Tidy up now
        </AssistChip>
      </div>
    </VaiPopup>
  );
};
