import { createEvent, createStore, sample } from 'effector';
import { Action, createBrowserHistory, Location } from 'history';

type HistoryBack = {
  location: Location<unknown>;
  action: Action;
};

const history = createBrowserHistory();

export const navrailmenuShown = createEvent();
export const navrailmenuHidden = createEvent();
export const historyBack = createEvent<HistoryBack>();

export const $isShowNavrailmenu = createStore(true);

export const scrollingStarted = createEvent();
export const scrollingEnded = createEvent();

export const $isScrolling = createStore(false);

$isShowNavrailmenu
  .on(navrailmenuShown, () => true)
  .on(navrailmenuHidden, () => false);

$isScrolling.on(scrollingStarted, () => true).on(scrollingEnded, () => false);

sample({
  clock: historyBack,
  target: navrailmenuShown,
});

history.listen(() => {
  historyBack({ location: history.location, action: history.action });
});
