import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { ErrorBoundary } from '@sentry/react';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';

import { EmailViewer } from './ui/email-view/email-viewer';
import { EmailsSidebar } from './ui/side-bar/emails-sidebar';

import styles from './styles.module.css';

export const MessagesPage = () => {
  const history = useHistory();
  const params = useParams<{ hubId?: string; threadId?: string }>();

  return (
    <div className={styles.container}>
      <DocumentHead name="Message Center" />
      <SearchDialog hideMobileSearch />
      <div
        className={cn(styles.messagesContainer)}
        // Only applies on mobile sizes
        style={
          {
            '--grid-template-areas': params.threadId
              ? '"email-viewer"'
              : '"emails-sidebar"',
          } as React.CSSProperties
        }
      >
        <ErrorBoundary
          fallback={
            <div className={styles.error}>
              <TypographyPoppins type="body" bodySize="M">
                Oops, something went wrong.
              </TypographyPoppins>
              <Button
                type="filled"
                label="Go to home"
                onClick={() => {
                  history.push('/home');
                }}
              />
            </div>
          }
        >
          <EmailsSidebar hubId={params.hubId} />
          <EmailViewer hubId={params.hubId} threadId={params.threadId} />
        </ErrorBoundary>
      </div>
    </div>
  );
};
