import { useHistory } from 'react-router';

import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { MakeRootBoard, turnIntoRootBoard } from '../../../../../api/boards';
import {
  ALL_BOARDS_QUERY,
  BOARD_QUERY,
  BOARDS_TREE_QUERY,
} from '../../../../../shared/constants/query-names';

export const useConvertBoardIntoTopLevel = (
  options?: MutateOptions<MakeRootBoard, any, MakeRootBoard>,
) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate: convertBoardIntoTopLevel } = useMutation({
    mutationFn: ({ boardId, parentBoardId }: MakeRootBoard) =>
      turnIntoRootBoard({ boardId, parentBoardId }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: variables.boardId }],
      });
      if (variables.parentBoardId) {
        history.push(`/board/${variables.parentBoardId}`);
      }

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
  });

  return { convertBoardIntoTopLevel };
};
