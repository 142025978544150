import cn from 'classnames';
import { Icon } from 'icons';
import { isDesktop } from 'react-device-detect';
//@ts-ignore
import { NavLink, useLocation } from 'react-router-dom';

import { Board } from '@visualist/design-system';
import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

interface SubBoardsProps {
  name?: string;
  boardIdLevel2?: string;
  onListItemCLick?: () => void;
  boardsLevel2: Array<Board | null>;
  openHub?: boolean;
  openBoard: boolean;
}

export const SubBoards = ({
  name,
  boardIdLevel2,
  onListItemCLick,
  boardsLevel2,
  openBoard,
}: SubBoardsProps) => {
  const location = useLocation();

  const activeItemId = location.pathname.split('/')[2];

  const boardsLevel3 = boardsLevel2
    .filter((board) => board?.children !== null && board?.id === boardIdLevel2)
    .map((board) => board?.children)
    .flat();

  return (
    <>
      <div
        className={cn(styles.drawerTab, styles.level2, {
          [styles.level2ForDesktop]: isDesktop,
          [styles.drawerTabActive]: openBoard,
        })}
      >
        <NavLink
          to={`/board/${boardIdLevel2}`}
          className={styles.projectLink}
          onClick={onListItemCLick}
        >
          <Icon name="sprite/board" size={16} className={styles.boardIcon} />
          <TypographyPoppins
            type="label"
            labelSize="L"
            className={cn(styles.boardName, {
              [styles.activeName]: activeItemId === boardIdLevel2,
            })}
          >
            {name}
          </TypographyPoppins>
        </NavLink>
      </div>
      <ul
        className={cn(styles.drawerList, {
          [styles.drawerListOpen]: openBoard,
        })}
      >
        {boardsLevel3 &&
          boardsLevel3.length > 0 &&
          boardsLevel3.map((board) => (
            <li
              className={cn(styles.drawerListItem, styles.level3)}
              key={board?.id}
              onClick={onListItemCLick}
            >
              <NavLink to={`/board/${board?.id}`}>
                <Icon
                  name="sprite/board"
                  size={16}
                  className={styles.boardIcon}
                />
                <TypographyPoppins
                  type="label"
                  labelSize="L"
                  className={cn(styles.boardName, {
                    [styles.activeName]: activeItemId === board?.id,
                  })}
                >
                  {board?.name}
                </TypographyPoppins>
              </NavLink>
            </li>
          ))}
      </ul>
    </>
  );
};
