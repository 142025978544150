import cn from 'classnames';
import { Icon } from 'icons';

import styles from './styles.module.css';

type LevelOfBoard = 0 | 1 | 2;

interface BreadCrumbsProps {
  hub: { id: string; name: string } | null;
  hubName?: string;
  level: LevelOfBoard;
  parentBoardName?: string;
  topBoardName: string;
}

export const BreadCrumbs = ({
  hub,
  hubName,
  level,
  parentBoardName,
  topBoardName,
}: BreadCrumbsProps) => (
  <div style={{ display: 'flex' }} className={styles.breadcrumbs}>
    {hub && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name="sprite/hub" className={styles.hubIcon} size={14} />
        <span className={styles.name}>{hubName}</span>
      </div>
    )}
    {level === 1 && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {hub && <Icon name="sprite/3-dot-menu" size={16} />}
        <Icon
          name="sprite/board"
          className={cn(styles.topBoardIcon, {
            [styles.boardInHub]: hub,
          })}
          size={14}
        />
        <span className={styles.name}>{parentBoardName}</span>
      </div>
    )}
    {level === 2 && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {hub && <Icon name="sprite/3-dot-menu" size={16} />}
        <Icon
          name="sprite/board"
          className={cn(styles.topBoardIcon, {
            [styles.boardInHub]: hub,
          })}
          size={14}
        />
        <span className={styles.name}>{topBoardName}</span>
        <Icon name="sprite/3-dot-menu" size={16} />
        <Icon
          name="sprite/board"
          className={styles.nestedBoardIcon}
          size={14}
        />
        <span className={styles.name}>{parentBoardName}</span>
      </div>
    )}
  </div>
);
