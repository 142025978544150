import React from 'react';

import { NavLink } from 'react-router-dom';

import { Header } from '../../components/header';
import { getRoute } from '../../routes';

export const EmailSentPage = () => (
  <section className="auth-page forgot-password-page">
    <Header hideMobile />

    <section className="small-container email-info">
      <img src="/assets/img/email-sent.svg" alt="email-sent" />
      <h1>It's on its way!</h1>
      <p>
        We've sent password reset instructions to{' '}
        <b>{localStorage.getItem('emailRecovery')}</b>
      </p>
      <hr />
      <p>
        Didn’t receive the email? Try checking spam - we sometimes end up there
        even if we don't like it!
      </p>

      <NavLink to={getRoute('forgotPassword')} className="btn btn-black-1">
        Try again
      </NavLink>
      <br />
      <a href="https://www.visualistapp.com/help" className="btn btn-link">
        Help, I’m still stuck
      </a>
    </section>
  </section>
);
