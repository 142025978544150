import { forwardRef, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

import TypographyPoppins, {
  BodySize,
  HeadlineSize,
  LabelSize,
  Size,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export interface FilterChipProps {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  trailingAction?: MouseEventHandler<HTMLButtonElement>;
  isSelected?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
  className?: string;
  classNameLabel?: string;

  type: Variant;
  size?: Size;
  labelSize?: LabelSize;
  headlineSize?: HeadlineSize;
  titleSize?: Size;
  bodySize?: BodySize;
}

export const FilterChip = forwardRef<HTMLButtonElement, FilterChipProps>(
  (
    {
      leadingIcon,
      trailingIcon,
      onClick,
      trailingAction,
      isSelected,
      isDisabled,
      children,
      className,
      classNameLabel,

      type,
      size,
      labelSize,
      headlineSize,
      titleSize,
      bodySize,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <button
        className={cn(styles.chip, className, {
          [styles.selected]: isSelected,
          [styles.disabled]: isDisabled && !isSelected,
          [styles.disabledSelected]: isDisabled && isSelected,
          [styles.withLeadingIcon]: leadingIcon,
          [styles.withTrailingIcon]: trailingIcon,
        })}
        onClick={onClick}
        disabled={isDisabled}
        {...props}
        ref={forwardedRef}
      >
        {leadingIcon && (
          <span
            className={cn(styles.leadingIcon, {
              [styles.disabledLeadingIcon]: isDisabled,
            })}
          >
            {leadingIcon}
          </span>
        )}
        <TypographyPoppins
          className={cn(styles.label, classNameLabel, {
            [styles.disabledLabel]: isDisabled,
            [styles.disabledSelectedLabel]: isDisabled && isSelected,
          })}
          type={type}
          labelSize={labelSize}
          bodySize={bodySize}
          headlineSize={headlineSize}
          size={size}
          titleSize={titleSize}
        >
          <span
            className={cn({
              [styles.disabledLabel]: isDisabled,
              [styles.disabledSelectedLabel]: isDisabled && isSelected,
            })}
          >
            {children}
          </span>
        </TypographyPoppins>
        {trailingIcon && (
          <button
            className={cn(styles.trailingIcon, {
              [styles.disabledTrailingIcon]: isDisabled,
              [styles.disabledSelectedTrailingIcon]: isDisabled && isSelected,
            })}
            onClick={(e) => {
              if (trailingAction) {
                e.stopPropagation();
                trailingAction(e);
              }
            }}
            disabled={isDisabled}
          >
            {trailingIcon}
          </button>
        )}
      </button>
    );
  },
);
