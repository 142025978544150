import { KeyboardEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useGate, useUnit } from 'effector-react';
import { Icon } from 'icons';
import useOnclickOutsideMultiple from 'react-cool-onclickoutside';
import { isMobile } from 'react-device-detect';

import {
  Button,
  MenuItem,
  Modal,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { useGoogleAutocomplete } from '@src/shared/hooks/use-google-autocomplete';

import { DialogContainer } from '../../ui/dialog-container';
import { Title } from '../../ui/title';
import {
  $hubDescription,
  $hubId,
  $hubName,
  $isAutocompleteOpen,
  $isShowHubRenamingDialog,
  $location,
  $selectedLocation,
  autocompleteOpened,
  Gate,
  hubDescriptionCleared,
  hubDescriptionEntered,
  hubNameCleared,
  hubNameEntered,
  hubRenamingDialogClosed,
  locationCleared,
  locationEntered,
  locationSelected,
} from '../model';

import styles from './styles.module.css';

const MAX_LENGTH = 100;

export const HubRenamingDialog = () => {
  const [previousName, setPreviousName] = useState('');
  const [previousDescription, setPreviousDescription] = useState('');
  const [previousLocation, setPreviousLocation] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const isShowHubRenamingDialog = useUnit($isShowHubRenamingDialog);
  const hubId = useUnit($hubId);
  const hubName = useUnit($hubName);
  const hubDescription = useUnit($hubDescription);
  const location = useUnit($location);
  const selectedLocation = useUnit($selectedLocation);
  const isAutocompleteOpen = useUnit($isAutocompleteOpen);

  const textField = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setPreviousName(hubName);
    setPreviousDescription(hubDescription);
    setPreviousLocation(location);
  }, [isShowHubRenamingDialog]);

  const { getPlacePredictions, placePredictions } = useGoogleAutocomplete({
    input: location,
  });

  useGate(Gate, { placePredictions });

  const itemsList = useOnclickOutsideMultiple(() => {
    getPlacePredictions({ input: '' });
  });

  const { editHub } = useHubs();

  useEffect(() => {
    // Focus password element after animation is complete otherwise is janks
    let timeout: NodeJS.Timeout | null = null;

    if (isShowHubRenamingDialog) {
      timeout = setTimeout(() => {
        textField.current?.focus();
      }, 300);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isShowHubRenamingDialog]);

  useEffect(() => {
    if (!isAutocompleteOpen) {
      locationCleared();
      getPlacePredictions({ input: '' });
    }
    autocompleteOpened();
  }, [isAutocompleteOpen]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (placePredictions.length > 0) {
      if (e.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) =>
          prevIndex < placePredictions.length - 1 ? prevIndex + 1 : 0,
        );
        e.preventDefault();
      } else if (e.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : placePredictions.length - 1,
        );
        e.preventDefault();
      } else if (e.key === 'Enter' && selectedIndex >= 0) {
        locationEntered(placePredictions[selectedIndex].description);
        locationSelected(placePredictions[selectedIndex].description);
        getPlacePredictions({ input: '' });
        setSelectedIndex(-1);
        e.preventDefault();
      }
    }

    if (
      e.key === 'Enter' &&
      location &&
      location === selectedLocation &&
      hubName.trim().length > 0 &&
      hubName.trim().length <= MAX_LENGTH
    ) {
      updateHub();
    }
  };

  const updateHub = () => {
    if (
      hubId &&
      hubName.trim().length > 0 &&
      hubName.trim().length <= MAX_LENGTH &&
      (hubName.trim() !== previousName ||
        hubDescription.trim() !== previousDescription ||
        (location.trim() !== previousLocation && location === selectedLocation))
    ) {
      editHub({
        hubId,
        name: hubName,
        description: hubDescription,
        location: location === selectedLocation ? selectedLocation : undefined,
      });
    }
    hubRenamingDialogClosed();
  };

  return (
    <Modal
      showModal={isShowHubRenamingDialog}
      handleClose={hubRenamingDialogClosed}
    >
      <DialogContainer className={styles.hub}>
        <Title title="Update hub" className={styles.title} />
        <div className={styles.info}>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.label}
          >
            Hub name
          </TypographyPoppins>
          <TextField
            ref={textField}
            value={hubName}
            onChange={(e) => hubNameEntered(e.target.value)}
            onKeyDown={(e) => {
              if (
                e.key === 'Enter' &&
                hubName.trim().length > 0 &&
                hubName.trim().length <= MAX_LENGTH
              ) {
                updateHub();
              }
            }}
            clear={() => hubNameCleared()}
            tabIndex={1}
          />
        </div>
        <div className={styles.info}>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.label}
          >
            Description (optional)
          </TypographyPoppins>
          <div className={styles.textarea}>
            <textarea
              className={styles.description}
              value={hubDescription}
              onChange={(e) => hubDescriptionEntered(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
                  e.preventDefault();
                  if (
                    hubName.trim().length > 0 &&
                    hubName.trim().length <= MAX_LENGTH
                  ) {
                    updateHub();
                  }
                }
              }}
              tabIndex={2}
            />
            {hubDescription.trim() && (
              <button
                className={styles.clear}
                onClick={() => hubDescriptionCleared()}
                type="button"
              >
                <Icon name="sprite/x" size={16} />
              </button>
            )}
          </div>
        </div>
        <div className={styles.info}>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.label}
          >
            Project location (optional)
          </TypographyPoppins>
          <TextField
            ref={itemsList}
            placeholder="Enter the city or town"
            value={location}
            onChange={(e) => {
              locationEntered(e.target.value);
              getPlacePredictions({ input: e.target.value });
            }}
            clear={() => {
              locationCleared();
              getPlacePredictions({ input: '' });
            }}
            tabIndex={3}
            onKeyDown={onKeyDown}
          />
          <ul
            ref={itemsList}
            className={cn(styles.itemsList, {
              [styles.mobile]: isMobile,
            })}
          >
            {placePredictions.map(({ description, place_id }, index) => {
              return (
                <MenuItem
                  key={place_id}
                  type="body"
                  bodySize="L"
                  className={cn(styles.item, {
                    [styles.selected]: index === selectedIndex,
                  })}
                  classNameContent={styles.content}
                  leadingIcon={
                    <Icon
                      name="sprite/location-pin-filled"
                      className={styles.icon}
                    />
                  }
                  onClickContent={() => {
                    locationEntered(description);
                    locationSelected(description);
                    getPlacePredictions({ input: '' });
                  }}
                  onMouseEnter={() => setSelectedIndex(-1)}
                  density="-2"
                >
                  {description}
                </MenuItem>
              );
            })}
          </ul>
        </div>
        <div className={styles.actions}>
          <Button
            type="outlined"
            label="Cancel"
            onClick={() => hubRenamingDialogClosed()}
            tabIndex={5}
          />
          <Button
            type="filled"
            label="Done"
            onClick={updateHub}
            isDisabled={
              hubName.trim().length === 0 || hubName.trim().length > MAX_LENGTH
            }
            tabIndex={4}
          />
        </div>
      </DialogContainer>
    </Modal>
  );
};
