import { useContext, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useParams } from 'react-router-dom';

import { Board } from '@visualist/design-system';

import { $hubDeleteModalState } from '@src/entities/delete-modals/modal';
import { useBilling } from '@src/shared/queries/useBilling';
import { uploadFiles } from '@src/shared/utils';

import { Context } from '../../App';
import { Plan } from '../../pages/AccountPage/components/types';
import { useAllBoards } from '../../pages/BoardPage/model/queries/useAllBoards';
import { ActionButton } from '..';

import styles from './LibraryActions.module.css';

export type Page = 'docPage';

interface Props {
  mobile?: boolean;
  showFAB?: boolean;
  handleOpen?: any;
  handleClose?: any;
  currentBoard?: Board;
  page?: Page;
  plan?: Plan;
  isHubLocked?: boolean;
}

export const LibraryActions = ({
  mobile = false,
  showFAB,
  handleOpen,
  handleClose,
  currentBoard,
  page,
  isHubLocked,
}: Props) => {
  const hubDeleteModalState = useUnit($hubDeleteModalState);

  const {
    query: { isSuccess, data },
    // syncMutation: { mutateAsync },
  } = useBilling();

  useEffect(() => {
    if (isSuccess && data) {
      setPlan(data.membershipTierInfo.plan);
    }
  }, [data]);

  const [plan, setPlan] = useState<Plan>('free');
  const { data: allBoards } = useAllBoards();
  const { board_id } = useParams<any>();
  const uploadButtonRef = useRef<HTMLInputElement>(null);
  //@ts-ignore
  const { setEmailTipShow } = useContext(Context);

  const handleUpload = () => {
    //@ts-ignore
    const { files = [] } = uploadButtonRef.current;
    const board = allBoards.find((board: any) => board.id === board_id);
    uploadFiles(files, board);
    setEmailTipShow(true);
  };

  const uploadAction = () => {
    uploadButtonRef.current?.click();
  };

  if (isHubLocked) return null;

  return (
    <>
      <ActionButton
        className={cn(
          {
            [styles.desktop]: !mobile,
            [styles.mobile]: mobile,
            [styles.confirmationDialogOpened]: !!hubDeleteModalState,
          },
          styles.button,
        )}
        mobile={mobile}
        uploadAction={uploadAction}
        showFAB={showFAB}
        handleOpen={handleOpen}
        handleClose={handleClose}
        currentBoard={currentBoard}
        page={page}
        plan={plan}
      />
      {/*
       * HACK: this input is needed here because the DropDownList is being unMounted
       * once a user clicks on it and values become unavailable.
       */}
      <input
        type="file"
        multiple
        ref={uploadButtonRef}
        className={styles.uploadInput}
        accept="image/png, image/jpeg"
        onChange={handleUpload}
      />
    </>
  );
};
