import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const Files = () => {
  const history = useHistory();
  const location = useLocation();

  const goToLibrary = () => {
    history.push('/library');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={styles.files}
        type="unfilled"
        icon={
          location.pathname === '/library' ? (
            <Icon name="sprite/library-filled" />
          ) : (
            <Icon name="sprite/library" />
          )
        }
        isSelected={location.pathname === '/library'}
        onClick={goToLibrary}
      />
      <TypographyPoppins className={styles.text} type="label" labelSize="M">
        Files
      </TypographyPoppins>
    </div>
  );
};
