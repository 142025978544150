import { Icon } from 'icons';

import {
  IconButton,
  Tooltip,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useWindowSize } from '@visualist/hooks';

import { SMALL } from '@src/shared/constants/breakpoints';
import { copyFile } from '@src/shared/utils';

import styles from './styles.module.css';

export const Copy = ({ block, getFileType }: any) => {
  const { width } = useWindowSize();

  const handleCopy = () => {
    if (block.file.full_size) {
      copyFile(block.file.full_size);

      startedSnack({
        label: `Copied ${getFileType()}`,
        close: true,
      });
    } else {
      startedSnack({
        label: `Couldn't copy ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            handleCopy();
          },
        },
        close: true,
      });
    }
  };

  return (
    <Tooltip
      style={{
        minWidth: 'max-content',
        marginTop: width <= SMALL ? '5px' : '40px',
      }}
      parameter={{
        type: 'plain',
        description: 'Copy to clipboard',
        position: width <= SMALL ? 'bottom' : 'right',
      }}
    >
      <div className={styles.copy}>
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/copy" className={styles.icon} />}
          onClick={handleCopy}
        />
      </div>
    </Tooltip>
  );
};
