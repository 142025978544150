import { useLayoutEffect, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import { Button } from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { HubResponse } from '@api/hubs';
import { Plan } from '@pages/AccountPage/components/types';
import { MEDIUM, SMALL } from '@src/shared/constants/breakpoints';

import { Hub } from '../Hub';
import { ShowPlaceholder } from '../ShowPlaceholder';

import styles from './styles.module.css';

interface HubsProps {
  hubs: Array<HubResponse>;
  plan: Plan;
}

export const Hubs = ({ hubs, plan }: HubsProps) => {
  const [isCollapse, setIsCollapse] = useState(false);
  const [hubsRow, setHubsRow] = useState<number | undefined>(undefined);

  const { width } = useWindowSize();

  const isShowButton = hubsRow && hubs && hubs.length > hubsRow;

  useLayoutEffect(() => {
    if (width > SMALL && width <= MEDIUM) {
      setHubsRow(2);
    } else {
      setHubsRow(3);
    }
  }, [width]);

  if (!hubs) return null;

  const visibleHubs = isCollapse ? hubs : hubs.slice(0, hubsRow);

  return (
    <>
      <div
        className={cn(styles.seeAll, {
          [styles.hide]: !isShowButton,
        })}
      >
        <Button
          style={{ marginLeft: '8px' }}
          type="text"
          label={isCollapse ? 'Collapse' : 'See all'}
          icon={
            <Icon
              name="sprite/chevron-down"
              className={cn({ [styles.caretDownIcon]: isCollapse })}
            />
          }
          onClick={() => setIsCollapse(!isCollapse)}
        />
      </div>
      <ul
        className={cn(styles.hubsList, {
          [styles.singleHub]: !isShowButton,
        })}
      >
        {hubs.length === 0 ? (
          <ShowPlaceholder plan={plan} />
        ) : (
          visibleHubs.map(
            (
              {
                id,
                name,
                thumbnail: { full_size },
                thumbnail_hex,
                is_locked,
                description,
                project_details,
              },
              idx,
            ) => {
              return (
                <Hub
                  onboardingId={idx === 0 ? 'onboarding-hub-demo' : undefined}
                  index={idx}
                  key={id}
                  id={id}
                  name={name}
                  thumbnail={full_size}
                  thumbnail_hex={thumbnail_hex}
                  isLocked={is_locked}
                  description={description}
                  location={project_details?.location}
                  isShowButton={isShowButton}
                  plan={plan}
                />
              );
            },
          )
        )}
      </ul>
    </>
  );
};
