import React, { MouseEventHandler } from 'react';

import cx from 'classnames';

import styles from './ButtonPill.module.css';

export interface ButtonPillProps {
  label?: string;
  icon?: string;
  disabled?: boolean;
  design?: 'secondary' | 'tertiary';
  small?: boolean;
  backgroundColor?: string;
  width?: string;
  type?: 'submit' | 'reset';
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
  className?: string;
  leftIcon?: React.ReactNode;
}

const ButtonPill = ({
  icon,
  label,
  small,
  disabled,
  design,
  backgroundColor,
  width,
  type,
  onClick,
  children,
  className,
  leftIcon,
}: ButtonPillProps) => {
  return (
    <button
      className={cx(styles.buttonPill, className, {
        [styles.pillDisabled]: disabled,
        [styles.pillSecondary]: design === 'secondary',
        [styles.pillTertiary]: design === 'tertiary',
        [styles.pillSmall]: small,
        [styles.pillIcon]: icon,
      })}
      disabled={disabled}
      style={{ background: backgroundColor, width }}
      type={type}
      onClick={onClick}
    >
      {label}
      {icon && <img src={icon} alt="icon" />}
      {leftIcon && leftIcon}
      {children}
    </button>
  );
};

export default ButtonPill;
