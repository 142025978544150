import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  Action,
  createAction,
  deleteAction,
  getAction,
  InfiniteActionResponse,
  patchAction,
  SimpleAction,
} from '@api/actions';
import { User } from '@src/AppContext';
import { actions } from '@src/shared/constants';
import { mutateActionKey } from '@src/shared/constants/mutation-keys';
import { actionAssignees } from '@src/shared/constants/query-keys';
import {
  InfiniteData,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { $actionFilters } from '../modal';

export const useAction = (actionId?: string) => {
  const actionItemQuery = useQuery({
    queryKey: actions.action(actionId ?? ''),
    queryFn: () => getAction(actionId ?? ''),
    enabled: !!actionId,
  });
  return actionItemQuery;
};

export const useMutateAction = (actionId: string) => {
  const [filters] = useUnit([$actionFilters]);
  const queryClient = useQueryClient();

  const mutateAction = useMutation({
    mutationKey: mutateActionKey.action(actionId),
    mutationFn: (
      actionData: Partial<
        Omit<SimpleAction, 'assigned_to'> & {
          assigned_to?: Array<number>;
        }
      >,
    ) => patchAction(actionId, actionData),
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: actions.action(actionId),
      });
      queryClient.cancelQueries({
        queryKey: actions.filtered(filters),
      });

      const originalActionData = queryClient.getQueryData<Action>(
        actions.action(actionId),
      );

      if (!originalActionData) {
        return;
      }

      const optimisticActionData = { ...originalActionData, ...variables };

      // Special case as when assigned_to is patched we send an array of ids vs when we fetch we get a list of the users
      if (
        optimisticActionData.assigned_to &&
        optimisticActionData.assigned_to[0] &&
        typeof optimisticActionData.assigned_to[0] === 'number'
      ) {
        // Swap out numbers with assigned_to user object from assignee endpoint cache data as that is the place where we get the original id sent here
        const originalAssigneeData = queryClient.getQueryData<Array<User>>(
          actionAssignees.action(actionId),
        );

        if (!originalAssigneeData) {
          // Cancel the optimistic update
          return;
        }

        optimisticActionData.assigned_to = optimisticActionData.assigned_to.map(
          (assignee) => {
            if (typeof assignee === 'number') {
              const matchedUser = originalAssigneeData.find(
                (user) => user.id === assignee,
              );
              return (
                matchedUser ||
                // Make an empty fake user so a blank avatar is rendered if nothing matches for any reason.
                ({
                  first_name: '',
                  last_name: '',
                  email: '',
                  id: 99999999,
                  is_google_linked: false,
                  last_login: new Date().toISOString(),
                  valid_password: true,
                } satisfies User)
              );
            }

            return assignee;
          },
        );
      }

      queryClient.setQueryData(actions.action(actionId), optimisticActionData);

      // Also set this single action item in the rendered list of action items
      const originalActionsData = queryClient.getQueryData<
        InfiniteData<InfiniteActionResponse, unknown>
      >(actions.filtered(filters));

      if (originalActionsData) {
        const optimisticActionsData = structuredClone(originalActionsData);

        const pageIdx = originalActionsData.pages.findIndex((page) =>
          page.results.some((action) => action.id === optimisticActionData.id),
        );

        if (pageIdx !== -1) {
          const actionIdx = optimisticActionsData.pages[
            pageIdx
          ].results.findIndex(
            (action) => action.id === optimisticActionData.id,
          );

          if (actionIdx !== -1) {
            optimisticActionsData.pages[pageIdx].results[actionIdx] = {
              ...optimisticActionData,
            } as SimpleAction;
          }
        }

        queryClient.setQueryData(
          actions.filtered(filters),
          optimisticActionsData,
        );
      }

      return { originalActionData, originalActionsData };
    },
    onError: (_err, _variables, ctx) => {
      console.log(_err);
      queryClient.setQueryData(
        actions.action(actionId),
        ctx?.originalActionData,
      );
      queryClient.setQueryData(
        actions.filtered(filters),
        ctx?.originalActionsData,
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
  });

  return { mutateAction };
};

export const useCreateAction = (
  onSuccess?: (createdAction: SimpleAction) => void,
) => {
  const queryClient = useQueryClient();
  const createActionItem = useMutation({
    mutationKey: ['create-action'],
    mutationFn: (action: Partial<SimpleAction> & { hub?: string }) =>
      createAction({
        name: action.name,
        status: action.status,
        priority: action.priority,
        due_date: action.due_date,
        description: action.description,
        hub: action.hub,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      onSuccess && onSuccess(data);
    },
  });
  return createActionItem;
};

export const useDeleteAction = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const { mutate: removeAction } = useMutation({
    mutationFn: (actionId: string) => deleteAction(actionId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      onSuccess();
    },
    onError: (err, variables) => {
      startedSnack({
        label: "Couldn't delete action item",
        action: {
          label: 'Try again',
          action: () => {
            removeAction(variables);
          },
        },
        close: true,
      });
    },
  });
  return { removeAction };
};
