import React from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
  Variants,
} from 'framer-motion';
import { flushSync } from 'react-dom';

import {
  AssistChip,
  Button,
  CurvedArrowIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { PageLoader } from '@components/PageLoader';
import { VaiModal } from '@src/entities/vai/ui/modal';

import { BlockTags } from './api';
import { $showVaiTagModal, closedVaiTagModal } from './model';
import { useTags } from './useTags';

import styles from './styles.module.css';

type DragState = {
  direction: 'left' | 'right' | null;
  buttonScale: number;
  distance: number;
};

export const VaiTagModal = () => {
  const [showVaiTagModal] = useUnit([$showVaiTagModal]);

  const { query, mutation } = useTags({
    enabled: showVaiTagModal,
  });

  const [answerIndex, setAnswerIndex] = React.useState(0);
  const [continueAnswering, setContinueAnswering] = React.useState(true);
  const [cardDragState, setCardDragState] = React.useState<DragState>({
    direction: null,
    buttonScale: 1,
    distance: 0,
  });

  const getNextCard = () => {
    if (!query.data || (query.data && query.data.length === answerIndex))
      return;

    const nextAnswerIndex = answerIndex + 1;
    setAnswerIndex(nextAnswerIndex);
    if (nextAnswerIndex % 5 === 0 || nextAnswerIndex === query.data.length) {
      setContinueAnswering(false);
    } else {
      setContinueAnswering(true);
    }

    if (nextAnswerIndex === query.data.length) {
      query.refetch();
      setAnswerIndex(0);
    }
  };

  const answerTag = (answer: boolean) => {
    if (!query.data || (query.data && query.data.length === answerIndex))
      return;

    flushSync(() =>
      setCardDragState((prev) => ({
        ...prev,
        direction: answer ? 'right' : 'left',
      })),
    );

    const tagBlock = query.data[query.data.length - 1 - answerIndex];
    mutation.mutate({
      blockId: query.data[query.data.length - 1 - answerIndex].id,
      tag: tagBlock.tag,
      isTag: answer,
    });

    getNextCard();
  };

  // On mount close the modal incase closed from elsewhere
  React.useEffect(() => {
    return () => closedVaiTagModal();
  }, []);

  React.useEffect(() => {
    setCardDragState((prev) => ({
      ...prev,
      direction: null,
      buttonScale: 1,
      distance: 0,
    }));
  }, [answerIndex]);

  return (
    <VaiModal
      showModal={showVaiTagModal}
      closeModal={() => closedVaiTagModal()}
      footer={
        continueAnswering ? (
          <Footer
            tag={
              query.data && query.data.length !== 0
                ? query.data[query.data.length - 1 - answerIndex].tag
                : ''
            }
            cardDragState={cardDragState}
            answerTag={answerTag}
          />
        ) : (
          <EmptyFooter
            closeModal={() => closedVaiTagModal()}
            requestNewCards={() => {
              if (!query.data) return;
              // Continue with current unless we have answered all available questions
              if (answerIndex >= query.data.length) {
                setAnswerIndex(0);
                setContinueAnswering(true);
                query.refetch();
              } else {
                // Continue with current question
                setContinueAnswering(true);
              }
            }}
          />
        )
      }
      footerHeight={116}
    >
      <div className={styles.container}>
        <TypographyPoppins type="body" bodySize="M" className={styles.text}>
          Answer these quickfire questions to teach Vai your style vocabulary
        </TypographyPoppins>
        <div
          className={cn(styles.imageContainer, {
            [styles.locked]: !continueAnswering,
          })}
        >
          {query.isFetching ? (
            // Query is still loading
            <PageLoader />
          ) : query.data && query.data.length !== 0 ? (
            <ImageSwiper
              blockTags={query.data.slice(0, query.data.length - answerIndex)}
              cardDragState={cardDragState}
              setCardDragState={setCardDragState}
              answerTag={answerTag}
            />
          ) : (
            // No more questions
            <div className={styles.emptyQuestions}>No more images</div>
          )}
        </div>
      </div>
    </VaiModal>
  );
};

const ImageSwiper = ({
  blockTags,
  cardDragState,
  setCardDragState,
  answerTag,
}: {
  blockTags: BlockTags;
  cardDragState: DragState;
  setCardDragState: React.Dispatch<React.SetStateAction<DragState>>;
  answerTag: (b: boolean) => void;
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  return (
    <div ref={containerRef} className={styles.imageSwiper}>
      <motion.div
        style={{
          opacity:
            cardDragState.direction === 'left'
              ? 1 - Math.abs(cardDragState.distance)
              : 1,
        }}
        className={styles.sideText}
      >
        <CurvedArrowIcon
          height={40}
          width={40}
          direction="left"
          color="#8B39A8"
        />
        <TypographyPoppins type="title" titleSize="M">
          No
        </TypographyPoppins>
      </motion.div>
      <div className={styles.centeredImage}>
        <AnimatePresence>
          {blockTags.map((bt, i, arr) => (
            <Card
              arr={arr}
              i={i}
              cardDragState={cardDragState}
              containerRef={containerRef}
              blockTag={bt}
              setCardDragState={setCardDragState}
              answerTag={answerTag}
              key={bt.id}
            />
          ))}
        </AnimatePresence>
      </div>
      <motion.div
        className={styles.sideText}
        style={{
          opacity:
            cardDragState.direction === 'right'
              ? 1 - cardDragState.distance
              : 1,
        }}
      >
        <CurvedArrowIcon
          height={40}
          width={40}
          direction="right"
          color="#8B39A8"
        />
        <TypographyPoppins type="title" titleSize="M">
          Yes
        </TypographyPoppins>
      </motion.div>
    </div>
  );
};

type Level = 'front' | 'middle' | 'back' | 'remaining';

/**
 *
 * @param idx
 * @param length
 * @returns
 */
const whichLevel = (idx: number, length: number): Level => {
  switch (idx) {
    case length - 1:
      return 'front';
    case length - 2:
      return 'middle';
    case length - 3:
      return 'back';
    default:
      return 'remaining';
  }
};

const Card = ({
  blockTag,
  i,
  arr,
  cardDragState,
  setCardDragState,
  containerRef,
  answerTag,
}: {
  blockTag: BlockTags[number];
  i: number;
  arr: BlockTags;
  cardDragState: DragState;
  setCardDragState: React.Dispatch<React.SetStateAction<DragState>>;
  containerRef: React.RefObject<HTMLDivElement>;
  answerTag: (b: boolean) => void;
}) => {
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const [isDragging, setIsDragging] = React.useState(false);

  const nx = useTransform(x, [-200, 200], [-1, 1]);
  const ny = useTransform(y, [-100, 100], [1, -1]);

  const level = whichLevel(i, arr.length);

  // Adjust ranges
  const buttonScale = useTransform(nx, [-1, 0, 1], [1.2, 1, 1.2]);

  const rotateY = useTransform(nx, [-1, 1], ['-15deg', '15deg']);
  const rotateX = useTransform(ny, [-1, 1], ['-15deg', '15deg']);

  useMotionValueEvent(x, 'change', (latest) => {
    setCardDragState({
      ...cardDragState,
      direction: latest > 0 ? 'right' : latest < 0 ? 'left' : null,
      buttonScale: buttonScale.get(),
      distance: nx.get(),
    });
  });

  const aspectRatio = blockTag.width / blockTag.height;

  const variants = {
    initial: {
      rotateZ: 0,
      opacity: 0,
      aspectRatio,
      scale: 0.4,
    },
    front: {
      rotateZ: 0,
      opacity: 1,
      aspectRatio,
      maxHeight: aspectRatio < 1 ? '70%' : '70%',
      maxWidth: aspectRatio < 1 ? '60%' : '60%',
      scale: 1,
      transition: {
        type: 'spring',
        bounce: 0.1,
        duration: 0.4,
      },
    },
    middle: {
      rotateZ: 5,
      opacity: 1,
      aspectRatio: 0.8,
      scale: 1,
      transition: {
        type: 'spring',
        bounce: 0.1,
        duration: 0.5,
      },
    },
    back: {
      rotateZ: 10,
      opacity: 1,
      aspectRatio: 0.8,
      scale: 1,
      transition: {
        type: 'spring',
        bounce: 0.1,
        duration: 0.6,
      },
    },
    remaining: {
      opacity: 0,
    },
    exit:
      level === 'front'
        ? {
            scale: 0,
            opacity: 0,
            translateX:
              cardDragState.direction === 'left'
                ? isDragging
                  ? -300
                  : -500
                : cardDragState.direction === 'right'
                ? isDragging
                  ? 300
                  : 500
                : 0,
            scaleX: isDragging ? 1 : 2,
          }
        : undefined,
  } as Variants;

  return (
    <div className={styles.imagePositioner}>
      <motion.div
        drag={level === 'front'}
        dragSnapToOrigin
        dragConstraints={containerRef}
        dragElastic={0.4}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={(_, info) => {
          const offset = Math.abs(info.offset.x);
          setIsDragging(false);
          setCardDragState({
            buttonScale: 1,
            direction: null,
            distance: 0,
          });

          if (offset > 150) {
            // Draggeed more than 150px so success
            x.stop();
            y.stop();

            info.offset.x > 0 ? answerTag(true) : answerTag(false);
          }
        }}
        variants={variants}
        initial="initial"
        animate={level}
        exit="exit"
        style={{
          rotateX,
          rotateY,
          x,
          y,
        }}
        className={cn(styles.imageDiv, {
          [styles.shadow]: !isDragging && level === 'front',
        })}
        key={blockTag.id}
      >
        <motion.img
          animate={{
            opacity:
              level === 'front'
                ? 1
                : level === 'middle'
                ? Math.abs(cardDragState.distance)
                : 0,
          }}
          transition={{ duration: 0 }}
          src={blockTag.file.thumbnail_640}
          className={styles.image}
        />
      </motion.div>
    </div>
  );
};

const Footer = ({
  cardDragState,
  answerTag,
  tag,
}: {
  cardDragState: DragState;
  answerTag: (b: boolean) => void;
  tag: string;
}) => {
  return (
    <motion.div
      className={styles.footer}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
    >
      <TypographyPoppins type="body" bodySize="M" className={styles.footerText}>
        Is this <Tag label={tag} /> ?
      </TypographyPoppins>
      <div className={styles.buttons}>
        <motion.span
          style={{
            scale:
              cardDragState.direction === 'left'
                ? cardDragState.buttonScale
                : 1,
          }}
        >
          <AssistChip
            onClick={() => {
              answerTag(false);
            }}
            style="elevated"
          >
            No
          </AssistChip>
        </motion.span>
        <motion.span
          style={{
            scale:
              cardDragState.direction === 'right'
                ? cardDragState.buttonScale
                : 1,
          }}
        >
          <AssistChip
            onClick={() => {
              answerTag(true);
            }}
            style="elevated"
          >
            Yes
          </AssistChip>
        </motion.span>
      </div>
    </motion.div>
  );
};

const Tag = ({ label }: { label: string }) => {
  return <span className={styles.tag}>{label}</span>;
};

const EmptyFooter = ({
  requestNewCards,
  closeModal,
}: {
  requestNewCards: () => void;
  closeModal: () => void;
}) => {
  return (
    <motion.div
      className={styles.footer}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -20, opacity: 0 }}
      transition={{
        duration: 0.3,
      }}
    >
      <TypographyPoppins type="body" bodySize="M" className={styles.footerText}>
        Nice work! Every question you answer helps Vai to make better
        suggestions. Continue?
      </TypographyPoppins>
      <div className={styles.buttons}>
        <Button
          onClick={() => closeModal()}
          type="outlined"
          label="No thanks"
        />
        <Button
          onClick={() => requestNewCards()}
          type="filled"
          label="Keep going"
        />
      </div>
    </motion.div>
  );
};
