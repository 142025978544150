import { combine, createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

export const navrailMenuBackdropShown = createEvent();
export const navrailMenuBackdropHidden = createEvent();
export const libraryBackdropShown = createEvent();
export const libraryBackdropHidden = createEvent();
export const docIdPassed = createEvent<string>();
export const docsDotMenuNavrailClosed = createEvent();
export const deleteDocModalNavrailOpened = createEvent();
export const deleteDocModalNavrailClosed = createEvent();
export const enabledLoadingDemoMode = createEvent();
export const disabledLoadingDemoMode = createEvent();

export const $isShowNavrailMenuBackdrop = createStore(false);
export const $isShowLibraryBackdrop = createStore(false);
export const $docsDotMenuNavrail = createStore('');
export const $isOpenDocDeleteModalNavrail = createStore(false);
export const $isTogglingDemoMode = createStore(false);

$isTogglingDemoMode
  .on(enabledLoadingDemoMode, () => true)
  .reset(disabledLoadingDemoMode);

$isShowNavrailMenuBackdrop
  .on(navrailMenuBackdropShown, () => true)
  .reset(navrailMenuBackdropHidden);

$isShowLibraryBackdrop
  .on(libraryBackdropShown, () => true)
  .reset(libraryBackdropHidden);

$docsDotMenuNavrail
  .on(docIdPassed, (_, docId) => docId)
  .reset(docsDotMenuNavrailClosed);

const $isOpenDocsDotMenu = $docsDotMenuNavrail.map((docId) => docId.length > 0);
const $isCloseDocsDotMenu = $docsDotMenuNavrail.map(
  (docId) => docId.length === 0,
);

$isOpenDocDeleteModalNavrail
  .on(deleteDocModalNavrailOpened, () => true)
  .reset(deleteDocModalNavrailClosed);

const $allMenusClose = combine(
  $isShowNavrailMenuBackdrop,
  $isCloseDocsDotMenu,
  $isOpenDocDeleteModalNavrail,
  (
    isShowNavrailMenuBackdrop,
    isCloseDocsDotMenu,
    isOpenDocDeleteModalNavrail,
  ) =>
    isShowNavrailMenuBackdrop &&
    isCloseDocsDotMenu &&
    !isOpenDocDeleteModalNavrail,
);

sample({
  clock: navrailMenuBackdropHidden,
  source: $isShowNavrailMenuBackdrop,
  filter: (isNavrailMenuOpen) => !isNavrailMenuOpen,
  target: docsDotMenuNavrailClosed,
});

hotkey({
  key: 'Escape',
  filter: $allMenusClose,
  target: navrailMenuBackdropHidden,
});

hotkey({
  key: 'Escape',
  filter: $isOpenDocsDotMenu,
  target: docsDotMenuNavrailClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenDocDeleteModalNavrail,
  target: deleteDocModalNavrailClosed,
});
