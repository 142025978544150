import { ChangeEvent, Dispatch, KeyboardEvent, useEffect, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';
import { isMobileOnly } from 'react-device-detect';

import { ClipItem, Input } from '@visualist/design-system';

import { getBoards } from '@api/boards';
import { $parentBoard } from '@pages/BoardPage/model';
import { $isActivateBoardsSearch } from '@pages/DocPage/model';
import { BOARDS_SUGGESTIONS_QUERY } from '@src/shared/constants/query-names';
import { useQuery } from '@tanstack/react-query';

import { $boardSearchActive } from './model';

import styles from './styles.module.css';

interface Props {
  selectRow: number | null;
  onHandleChangeHubs: (e: ChangeEvent<HTMLInputElement>) => void;
  onRemoveFocus: (e: KeyboardEvent<HTMLInputElement>) => void;
  addingBoardToHubDialog: boolean;
  selectHub: (hubName: string, id: string) => void;
  onBlur: () => void;
  setSelectRow: Dispatch<number | null>;
}

export const BoardsSuggestions = ({
  selectRow,
  onHandleChangeHubs,
  onRemoveFocus,
  addingBoardToHubDialog,
  selectHub,
  onBlur,
  setSelectRow,
}: Props) => {
  const [isActivateBoardsSearch, parentBoard, boardSearchActive] = useUnit([
    $isActivateBoardsSearch,
    $parentBoard,
    $boardSearchActive,
  ]);

  const searchRef = useRef<HTMLInputElement>(null);

  const { data: boardsSuggestions = [] } = useQuery({
    queryKey: [BOARDS_SUGGESTIONS_QUERY, parentBoard],
    queryFn: () =>
      getBoards({
        name: parentBoard,
      }),
    enabled: parentBoard.length > 0 && isActivateBoardsSearch,
  });

  useEffect(() => {
    if (addingBoardToHubDialog) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 70);
    }
  }, [addingBoardToHubDialog]);

  return (
    <div>
      <Input
        ref={searchRef}
        className={cn(styles.hubsSearchField, {
          [styles.focused]: boardSearchActive,
          [styles.hubsField]: addingBoardToHubDialog,
        })}
        customStyleRightIcon={cn({
          [styles.сhevronIconActiveSearch]: boardSearchActive,
        })}
        type="text"
        name="name"
        placeholder="Add it to..."
        customLabel="Add to"
        rightIcon={<Icon name="sprite/caret-down" />}
        value={parentBoard}
        onChange={onHandleChangeHubs}
        onKeyUp={onRemoveFocus}
        onBlur={onBlur}
      />
      {boardSearchActive && parentBoard && (
        <div
          className={cn(styles.dropdown, {
            [styles.dropdownMobile]: isMobileOnly,
            [styles.dropdownHubs]: parentBoard,
          })}
        >
          <ul
            style={{
              maxHeight: isMobileOnly ? '139px' : '239px',
            }}
            className={styles.hubsList}
          >
            {boardsSuggestions && boardsSuggestions.length > 0 ? (
              boardsSuggestions.map((item, index) => (
                <li
                  key={item.id}
                  className={cn(styles.li, {
                    [styles.highlight]: selectRow === index,
                  })}
                  onMouseEnter={() => setSelectRow(index)}
                  onClick={() => selectHub(item.name, item.id)}
                >
                  <span style={{ display: 'flex' }}>
                    <Icon
                      name="sprite/board"
                      size={16}
                      className={styles.hubIcon}
                    />
                    <ClipItem type="hub" name={item.name} query={parentBoard} />
                  </span>
                </li>
              ))
            ) : (
              <li className={styles.noMatches}>No matches</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
