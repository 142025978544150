import { ChangeEvent, FormEvent, KeyboardEvent, Ref } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { FormikErrors } from 'formik';

import { Board } from '@visualist/design-system';

import { $isActivateBoardsSearch } from '@pages/DocPage/model';

import { HubResponse } from '../../api/hubs';
import { $isShowHubDialog } from '../../pages/HubPage/model';
import { Buttons, Cols } from '.';
import { BoardDialog } from './BoardDialog';
import { BoardsSearch } from './BoardsSearch';
import { BoardsSuggestions } from './BoardsSuggestions';
import { BookmarkDialog } from './BookmarkDialog';
import { DocDialog } from './DocDialog';
import { HubDialog } from './HubDialog';
import { HubsSearch } from './HubsSearch';
import {
  $boardCreationDialog,
  $isAddingAndCreatingBoardDialogOpen,
  $isShowBoardDialog,
} from './model';

import styles from './styles.module.css';

interface CreateDialogProps {
  isMobileOnly: boolean;
  inputRef: Ref<HTMLInputElement>;
  name: string;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  onHandleChangeParentBoard: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: any) => void;
  onRemoveFocus: (e: KeyboardEvent<HTMLInputElement>) => void;
  boardsSuggestions: Board[];
  selectRow: number | null;
  selectBoard: (board: string, boardId?: string) => void;
  lastSuggestion: number;
  closeDropdown: () => void;
  handleCancel: () => void;
  handleSubmit: (e?: FormEvent<HTMLFormElement> | undefined) => void;
  renamingBoard: string;
  creatingHub: boolean;
  renamingHub: string;
  creatingBookmark: string;
  errors: FormikErrors<{
    name: string;
  }>;
  addingBoardToHubDialog: boolean;
  onHandleChangeHubs: (e: ChangeEvent<HTMLInputElement>) => void;
  hubName: string;
  hubsSuggestions?: HubResponse[];
  onKeyDownHubs: (e: any) => void;
  selectHub: (hubName: string, id: string) => void;
  editBookmark: string;
  parentBoardId?: string;
  currentHubNamePage?: string;
  currentHubId: string;
  onBlur: () => void;
  setSelectRow: (row: number | null) => void;
  renamingDoc: string;
}

export const CreateDialog = ({
  isMobileOnly,
  inputRef,
  name,
  onHandleChange,
  handleOnKeyPress,
  onHandleChangeParentBoard,
  onKeyDown,
  onRemoveFocus,
  boardsSuggestions,
  selectRow,
  selectBoard,
  lastSuggestion,
  closeDropdown,
  handleCancel,
  handleSubmit,
  renamingBoard,
  creatingHub,
  renamingHub,
  creatingBookmark,
  editBookmark,
  errors,
  addingBoardToHubDialog,
  onHandleChangeHubs,
  hubName,
  hubsSuggestions,
  onKeyDownHubs,
  selectHub,
  parentBoardId,
  currentHubNamePage,
  currentHubId,
  onBlur,
  setSelectRow,
  renamingDoc,
}: CreateDialogProps): JSX.Element => {
  const isShowBoardDialog = useUnit($isShowBoardDialog);
  const isShowHubDialog = useUnit($isShowHubDialog);
  const boardCreationDialog = useUnit($boardCreationDialog);
  const isAddingAndCreatingBoardDialogOpen = useUnit(
    $isAddingAndCreatingBoardDialogOpen,
  );
  const isBoardNotPartOfHub = boardsSuggestions.some((board) => {
    if (board && parentBoardId) {
      return board.id === parentBoardId && board.hub === null;
    } else return false;
  });

  const hubId = boardsSuggestions.filter((board) => {
    if (board && parentBoardId) {
      return board.id === parentBoardId && board.hub !== null;
    } else return '';
  })[0]?.hub?.id;

  const autoFillHubName = hubsSuggestions?.filter((hub) => {
    if (hubId) {
      return hub.id === hubId;
    }
  })[0]?.name;

  const isLevelOneBoard = boardsSuggestions
    ?.filter((board: Board | null) => board && board.parent === null)
    .some((board: Board | null) => board && board.id === parentBoardId);

  const [isActivateBoardsSearch] = useUnit([$isActivateBoardsSearch]);

  return (
    <>
      <div
        className={cn(styles.firstRowCreating, {
          [styles.firstRowRenaming]:
            renamingBoard || renamingHub || renamingDoc,
          [styles.firstRowBookmark]:
            (creatingBookmark || editBookmark) && errors.name,
          [styles.firstRowAddingBoardToHub]:
            addingBoardToHubDialog && !isAddingAndCreatingBoardDialogOpen,
          [styles.firstRowMobile]:
            (renamingBoard ||
              creatingHub ||
              creatingBookmark ||
              editBookmark ||
              renamingHub ||
              renamingDoc) &&
            isMobileOnly,
        })}
      >
        {isShowHubDialog && (
          <HubDialog
            handleOnKeyPress={handleOnKeyPress}
            inputRef={inputRef}
            isMobileOnly={isMobileOnly}
            onHandleChange={onHandleChange}
            name={name}
            renamingHub={renamingHub}
          />
        )}
        {isShowBoardDialog && (
          <BoardDialog
            handleOnKeyPress={handleOnKeyPress}
            inputRef={inputRef}
            isMobileOnly={isMobileOnly}
            onHandleChange={onHandleChange}
            renamingBoard={renamingBoard}
            name={name}
            selectRow={selectRow}
            onHandleChangeHubs={onHandleChangeHubs}
            onKeyDownHubs={onKeyDownHubs}
            onRemoveFocus={onRemoveFocus}
            addingBoardToHubDialog={addingBoardToHubDialog}
            hubName={hubName}
            hubsSuggestions={hubsSuggestions}
            selectHub={selectHub}
            isBoardNotPartOfHub={isBoardNotPartOfHub}
            autoFillHubName={autoFillHubName || currentHubNamePage}
            hubId={hubId}
            parentBoardId={parentBoardId}
            currentHubId={currentHubId}
            isLevelOneBoard={isLevelOneBoard}
            onBlur={onBlur}
            setSelectRow={setSelectRow}
          />
        )}
        {renamingDoc && (
          <DocDialog
            handleOnKeyPress={handleOnKeyPress}
            inputRef={inputRef}
            isMobileOnly={isMobileOnly}
            onHandleChange={onHandleChange}
            name={name}
            renamingDoc={renamingDoc}
          />
        )}
        {(creatingBookmark || editBookmark) && (
          <BookmarkDialog
            handleOnKeyPress={handleOnKeyPress}
            inputRef={inputRef}
            isMobileOnly={isMobileOnly}
            onHandleChange={onHandleChange}
            name={name}
            errors={errors}
          />
        )}
      </div>
      <Cols addingBoardToHubDialog={addingBoardToHubDialog}>
        {(!renamingBoard &&
          !addingBoardToHubDialog &&
          !boardCreationDialog &&
          !renamingHub &&
          !renamingDoc) ||
          (boardCreationDialog && (
            <BoardsSearch
              isMobileOnly={isMobileOnly}
              boardsSuggestions={boardsSuggestions}
              selectRow={selectRow}
              selectBoard={selectBoard}
              lastSuggestion={lastSuggestion}
              closeDropdown={closeDropdown}
              onHandleChangeParentBoard={onHandleChangeParentBoard}
              onKeyDown={onKeyDown}
              onRemoveFocus={onRemoveFocus}
              creatingHub={creatingHub}
              creatingBookmark={creatingBookmark}
              editBookmark={editBookmark}
              currentHubId={currentHubId}
              onBlur={onBlur}
              setSelectRow={setSelectRow}
            />
          ))}

        {addingBoardToHubDialog &&
          !isAddingAndCreatingBoardDialogOpen &&
          !isActivateBoardsSearch && (
            <HubsSearch
              selectRow={selectRow}
              onHandleChangeHubs={onHandleChangeHubs}
              onKeyDownHubs={onKeyDownHubs}
              onRemoveFocus={onRemoveFocus}
              addingBoardToHubDialog={addingBoardToHubDialog}
              hubName={hubName}
              hubsSuggestions={hubsSuggestions}
              selectHub={selectHub}
              onBlur={onBlur}
              setSelectRow={setSelectRow}
            />
          )}

        {isActivateBoardsSearch && (
          <BoardsSuggestions
            selectRow={selectRow}
            addingBoardToHubDialog={addingBoardToHubDialog}
            onRemoveFocus={onRemoveFocus}
            onBlur={onBlur}
            setSelectRow={setSelectRow}
            selectHub={selectBoard}
            onHandleChangeHubs={onHandleChangeParentBoard}
          />
        )}
        <div>
          <Buttons
            closeDialog={handleCancel}
            onSubmit={handleSubmit}
            renamingBoard={renamingBoard}
            renamingHub={renamingHub}
            name={name}
            creatingBookmark={creatingBookmark}
            editBookmark={editBookmark}
            hubName={hubName}
            addingBoardToHubDialog={addingBoardToHubDialog}
            hubsSuggestions={hubsSuggestions}
            renamingDoc={renamingDoc}
          />
        </div>
      </Cols>
    </>
  );
};
