import React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { Icon } from 'icons';

import {
  TypographyPoppins,
  VaiColouredPathIcon,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const VaiPopup = ({
  showPopup,
  closePopup,
  children,
  title = 'Vai, your AI assistant',
  top,
  right,
  width,
}: {
  title?: string;
  showPopup: boolean;
  closePopup: () => void;
  children?: React.ReactNode;
  top?: number;
  right?: number;
  width?: number;
}) => {
  return (
    <AnimatePresence>
      {showPopup ? (
        <motion.div
          className={styles.container}
          initial={{
            opacity: 0,
            scale: 0,
            translateX: 150,
            translateY: -100,
          }}
          animate={{
            opacity: 1,
            scale: 1,
            scaleX: 1,
            translateX: 0,
            translateY: 0,
          }}
          exit={{
            opacity: 0,
            scale: 0,
            translateX: 150,
            translateY: -100,
          }}
          transition={{
            type: 'spring',
            // stiffness: 50,
            // mass: 0.2,
            damping: 15,
          }}
          style={
            {
              '--top': top ? `${top}px` : undefined,
              '--right': right ? `${right}px` : undefined,
              '--width': `${width}px`,
            } as React.CSSProperties
          }
        >
          <div className={styles.header}>
            <div className={styles.headerLeft}>
              <VaiColouredPathIcon height={20} width={20} />
              <TypographyPoppins
                type="title"
                titleSize="M"
                className={styles.title}
              >
                {title}
              </TypographyPoppins>
            </div>
            <button
              tabIndex={0}
              className={styles.closeButton}
              onClick={closePopup}
            >
              <Icon
                name="sprite/plus"
                style={{
                  transform: 'rotate(45deg)',
                }}
              />
            </button>
          </div>
          <div className={styles.main}>{children}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
