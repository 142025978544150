import React from 'react';

import { NavLink } from 'react-router-dom';

import { Header } from '../../components/header';
import { getRoute } from '../../routes';

export const SuccessPage = () => (
  <section className="auth-page success-page">
    <Header hideMobile />

    <div className="small-container">
      <img src="/assets/img/success.svg" alt="success" />
      <h1>Success!</h1>
      <p>Your account is now in tip-top shape.</p>
      <NavLink className="btn btn-black" to={getRoute('login')}>
        Login to Visualist
      </NavLink>
    </div>
  </section>
);
