import { useEffect, useRef } from 'react';

import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import { $hubName } from '../../move-board/model';
import { $location, locationCleared } from './model';
import { $width, widthSetted } from './model';

export const useSearch = () => {
  const location = useUnit($location);
  const hubName = useUnit($hubName);
  const width = useUnit($width);

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        widthSetted(entry.contentRect.width);
      });
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [location, hubName]);

  const getLeadingIcon = () => {
    if (location && location.type === 'hub') {
      return <Icon name="sprite/hub" />;
    } else if (location && location.type === 'board') {
      return <Icon name="sprite/board" />;
    } else {
      return null;
    }
  };

  const getTrailingIcon = () => {
    if (location) {
      return <Icon name="sprite/x" color="var(--color-neutral-variant-30)" />;
    } else {
      return <Icon name="sprite/caret-down" />;
    }
  };

  const clear = () => {
    if (location) {
      locationCleared();
    }
  };

  const isShowTooltip = () => {
    if (!location) {
      return false;
    } else if (location && location.type === 'hub') {
      return false;
    } else if (
      location &&
      location.type === 'board' &&
      location.level === 0 &&
      !location.parent
    ) {
      return false;
    } else {
      return true;
    }
  };

  return {
    ref,
    location,
    width,
    getLeadingIcon,
    getTrailingIcon,
    clear,
    isShowTooltip,
  };
};
