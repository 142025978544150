import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { flushSync } from 'react-dom';

import {
  IconButton,
  TypographyPoppins,
  VaiColouredPathIcon,
} from '@visualist/design-system/src/components/v2';

import { openedVaiTagModal } from '@src/widgets/VaiTagModal/model';

import styles from './styles.module.css';

export const VaiButton = ({ isMobile = false }: { isMobile?: boolean }) => {
  const [mouseState, setMouseState] = useState<'none' | 'hover'>('none');
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const loopAnimation = () => {
    return setTimeout(() => {
      // Check if still hovered
      if (mouseState === 'hover') {
        loopAnimation();
      } else {
        setShouldAnimate(false);
      }
    }, 1500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div
        className={cn(styles.hoverCheck, {
          [styles.animated]: shouldAnimate,
        })}
        onMouseEnter={() => {
          flushSync(() => {
            setMouseState('hover');
            setShouldAnimate(true);
          });
          timeoutRef.current = loopAnimation();
        }}
        onMouseLeave={() => {
          setMouseState('none');
        }}
        aria-hidden="true"
      >
        <IconButton
          className={styles.studio}
          type="unfilled"
          icon={<VaiColouredPathIcon isPurple height={22} width={22} />}
          isSelected={false}
          onClick={() => openedVaiTagModal()}
          aria-hidden="false"
        />
      </div>
      {isMobile ? null : (
        <TypographyPoppins className={styles.text} type="label" labelSize="M">
          Vai
        </TypographyPoppins>
      )}
    </div>
  );
};
