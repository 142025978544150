import { MouseEvent, useRef, useState } from 'react';

import { Icon } from 'icons';

import {
  Dropdown,
  IconButton,
  Item,
  TeamIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { boardIdPassed } from '@components/CreateDialogsBox/model';
import { shareSideSheetOpened } from '@pages/BoardPage/model';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';

import styles from './styles.module.css';

export interface IBoard {
  id: string;
  name: string;
  description?: string;
}

interface BoardProps extends IBoard {
  onClick: (id: string) => void;
  parent: string;
}

export const Board = ({
  id,
  name,
  onClick,
  parent,
  description,
}: BoardProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const boardRef = useRef<HTMLLIElement>(null);

  const onItemClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (e.ctrlKey || e.metaKey) {
      window.open(`${import.meta.env.VITE_BASE_URL}/board/${id}`, '_blank');
    } else {
      onClick(id);
    }
  };

  const handleDotMenuRightClick = (e: MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setOpenDropdown(true);
  };

  const openShareModal = () => shareSideSheetOpened();

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/board/${id}`,
      );

      startedSnack({
        label: 'Copied link to board',
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(name);
    boardIdSelected(id);

    if (description) {
      boardDescriptionEntered(description);
    }
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: id,
      parentBoardId: parent,
    });
    boardIdPassed(id);
  };

  const menuItems: Item[] = [
    {
      leadingIcon: <TeamIcon />,
      content: 'Invite',
      onClick: () => openShareModal(),
    },
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      isDivider: true,
      onClick: rename,
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: () => deleteBoard(),
      classNameContent: styles.delete,
    },
  ];

  return (
    <li
      className={styles.boardCard}
      ref={boardRef}
      onContextMenu={handleDotMenuRightClick}
      onClick={onItemClick}
    >
      <header className={styles.header}>
        <div className={styles.fileContent}>
          <Icon
            name="sprite/board"
            size={16}
            className={styles.boardCardIcon}
          />
          <TypographyPoppins labelSize="M" type="label" className={styles.text}>
            {name}
          </TypographyPoppins>
        </div>

        <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
          <Dropdown.Menu
            trigger={
              <IconButton
                type="unfilled"
                icon={<Icon name="sprite/3-dot-menu" />}
                onClick={() => {}}
                stopPropagation
                isSelected={isOpenDropdown}
              />
            }
            side="top"
            density="-2"
          >
            {menuItems.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </header>
    </li>
  );
};
