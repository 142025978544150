import { Icon } from 'icons';

import {
  Divider,
  MenuItem,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { actionItemsSheetOpened } from './model';

import styles from './styles.module.css';

type Props = {
  actions?: number;
};

export const Overview = ({ actions }: Props) => {
  const description =
    actions === 1 ? ' open action item' : ' open action items';
  return (
    <>
      <li className={styles.item}>
        <div className={styles.card}>
          <div className={styles.header}>
            <TypographyPoppins
              className={styles.color}
              type="title"
              titleSize="M"
            >
              Overview
            </TypographyPoppins>
          </div>
          <div className={styles.horizontal}>
            <Divider className={styles.divider} type="long-line" />
          </div>
          {actions && actions > 0 ? (
            <ul className={styles.actionItems}>
              <MenuItem
                className={styles.actionItem}
                classNameLeadingIcon={styles.icon}
                leadingIcon={<Icon name="sprite/checkbox" />}
                onClickContent={() => actionItemsSheetOpened()}
              >
                <TypographyPoppins
                  className={styles.color}
                  type="body"
                  bodySize="M"
                >
                  <span className={styles.number}>{actions}</span>
                  {description}
                </TypographyPoppins>
              </MenuItem>
            </ul>
          ) : (
            <div className={styles.emptyState}>
              <Icon name="sprite/sticky-note-soft-colored" size={80} />
              <TypographyPoppins
                className={styles.content}
                type="body"
                bodySize="S"
              >
                You’re all set—nothing to see here for now
              </TypographyPoppins>
            </div>
          )}
        </div>
      </li>
    </>
  );
};
