import { useState } from 'react';

import { Icon } from 'icons';

import {
  ChatSpeechRight,
  Dropdown,
  IconButton,
  Item,
} from '@visualist/design-system/src/components/v2';

import { useUserOnboarding } from '@src/widgets/onboarding/useUserOnboarding';

import styles from './styles.module.css';

type HelpProps = {
  isSmallScreenSize?: boolean;
};

export const Help = ({ isSmallScreenSize }: HelpProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const { startDemoMode, isDemoModeOn } = useUserOnboarding();

  const gotoDemo = () => startDemoMode({});

  const helpCenter = () => {
    window.open('https://visualistapp.com/help-center/all', '_blank');
  };

  const contactUs = () => {
    window.open('https://visualistapp.com/contact', '_blank');
  };

  const menuItems: Item[] = [
    ...(isDemoModeOn
      ? []
      : [
          {
            leadingIcon: <Icon name="sprite/demo" />,
            content: 'Go to demo',
            onClick: gotoDemo,
          },
        ]),
    {
      leadingIcon: <Icon name="sprite/FAQ" />,
      content: 'Help center',
      onClick: helpCenter,
    },
    {
      leadingIcon: <ChatSpeechRight fill="none" />,
      content: 'Contact us',
      onClick: contactUs,
    },
  ];

  return (
    <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
      <Dropdown.Menu
        trigger={
          <IconButton
            iconStyles={styles.icon}
            type="unfilled"
            icon={<Icon name="sprite/question" size={16} />}
            isSelected={isOpenDropdown}
            onClick={() => {}}
          />
        }
        side={isSmallScreenSize ? 'bottom' : 'right'}
        sideOffset={isSmallScreenSize ? 5 : 15}
        density="-2"
      >
        {menuItems.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
