import { useEffect, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { isDesktop } from 'react-device-detect';
//@ts-ignore
import { NavLink, useLocation } from 'react-router-dom';

import { Board } from '@visualist/design-system';
import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { ReactComponent as IconChevronRight } from './chevron-right.svg';
import { SubBoards } from './SubBoards';

import styles from './styles.module.css';

interface ParentBoardsSectionProps {
  name: string;
  boardIdLevel1: string;
  boardsLevel1: Array<Board>;
  drawerShow?: boolean;
  listItemClickHandler?: () => void;
  openHub?: boolean;
  closeButtonClickHandler?: () => void;
  isExpandBoardsSection: boolean;
  otherItems?: boolean;
}

export const ParentBoardsSection = ({
  name,
  boardIdLevel1,
  drawerShow,
  listItemClickHandler,
  boardsLevel1,
  openHub,
  isExpandBoardsSection,
  otherItems = false,
}: ParentBoardsSectionProps) => {
  const [openBoard, setOpenBoard] = useState(false);

  const location = useLocation();

  const activeItemId = location.pathname.split('/')[2];

  const boardsLevel2 = boardsLevel1
    .filter((board) => board.children !== null && board.id === boardIdLevel1)
    .map((board) => board.children)
    .flat();

  useEffect(() => {
    !drawerShow && setOpenBoard(false);
  }, [drawerShow]);

  useEffect(() => {
    if (openHub) {
      setOpenBoard(true);
    } else {
      setOpenBoard(false);
    }
  }, [openHub]);

  const openButtonClickHandler = () => {
    setOpenBoard(!openBoard);
  };

  const isChevron =
    boardsLevel1.filter(
      (board) =>
        board.children !== null &&
        board.children?.length > 0 &&
        board.id === boardIdLevel1,
    ).length > 0;

  return (
    <div
      className={cn({
        [styles.otherItems]: otherItems,
      })}
    >
      <div
        className={cn(styles.drawerItem, {
          [styles.drawerItemForDesktop]: isDesktop,
          [styles.collapsed]: !isExpandBoardsSection,
        })}
      >
        <div className={styles.drawerTabWrapper}>
          <div
            className={cn(styles.drawerTab, styles.trailingIcon, {
              [styles.drawerTabActive]: openBoard,
              [styles.noChevron]: !isChevron,
            })}
          >
            <NavLink
              to={`/board/${boardIdLevel1}`}
              className={styles.projectLink}
              onClick={listItemClickHandler}
            >
              <Icon
                name="sprite/board"
                size={16}
                className={styles.boardIcon}
              />
              <TypographyPoppins
                type="label"
                labelSize="L"
                className={cn(styles.boardName, {
                  [styles.activeName]: activeItemId === boardIdLevel1,
                })}
              >
                {name}
              </TypographyPoppins>
            </NavLink>
            {isChevron && (
              <button
                className={styles.openTabButton}
                onClick={openButtonClickHandler}
              >
                <IconChevronRight className={styles.iconChevron} />
              </button>
            )}
          </div>
        </div>
        <ul
          className={cn(styles.drawerList, {
            [styles.drawerListOpen]: openBoard,
          })}
        >
          {boardsLevel2.length > 0 &&
            boardsLevel2.map((board) => (
              <SubBoards
                key={board?.id}
                boardIdLevel2={board?.id}
                name={board?.name}
                onListItemCLick={listItemClickHandler}
                boardsLevel2={boardsLevel2}
                openHub={openHub}
                openBoard={openBoard}
              />
            ))}
        </ul>
      </div>
    </div>
  );
};
