import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import {
  PermissionsHub,
  ResponseInvitee,
  UpdatePermissionHub,
} from '@api/hubs';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type InvitedUserProps = {
  user: ResponseInvitee;
  permissions: PermissionsHub[];
  changePermissionHub: UseMutateFunction<
    UpdatePermissionHub,
    any,
    UpdatePermissionHub,
    unknown
  >;
  handleRemoveInviteeFromHub: (inviteeId: string) => void;
};

export const InvitedUser = ({
  handleRemoveInviteeFromHub,
  user,
  changePermissionHub,
  permissions,
}: InvitedUserProps) => {
  const [on, setOn] = useState(true);
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getPermissions = (user: ResponseInvitee) => {
    if (user.permissions.includes('edit')) {
      return 'Editor';
    } else if (user.permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  const changePermissions = (permissions: PermissionsHub[]) => {
    changePermissionHub({
      hubId: user.hub,
      member: user.id,
      permissions,
    });
  };

  const remove = () => {
    if (on) {
      handleRemoveInviteeFromHub(user);
    }
    setOn(false);
  };

  return (
    <User
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      on={on}
      permissionsRef={permissionsRef}
      permissions={permissions}
      getPermissions={getPermissions}
      changePermissions={changePermissions}
      remove={remove}
    />
  );
};
