import { useEffect } from 'react';

export const useEscape = (handler: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') handler(event);
    };

    document.addEventListener('keyup', handleEsc);

    return () => {
      document.removeEventListener('keyup', handleEsc);
    };
  }, [handler]);
};
