import React from 'react';

import { Icon } from 'icons';

import {
  RadioButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { OptionKey } from '@src/AppContext';

import { AnyIconName } from '../../../../../../packages/icons/src/Icon';

import styles from './styles.module.css';

interface DemoOptionsProps {
  selectedKey: OptionKey;
  onRadioClick: (key: OptionKey) => void;
  onRadioHover: (key: OptionKey | null) => void;
}

export const DemoOptions = ({
  selectedKey,
  onRadioClick,
  onRadioHover,
}: DemoOptionsProps) => {
  const options: { text: string; key: OptionKey; icon: AnyIconName }[] = [
    {
      text: 'Create hubs for client collaboration',
      key: 'createHubs',
      icon: 'sprite/team-soft-colored',
    },
    {
      text: 'Work creatively and make moodboards',
      key: 'workCreatively',
      icon: 'sprite/moodboard-soft-colored',
    },
    {
      text: 'Manage business administration',
      key: 'manageBusinessAdmin',
      icon: 'sprite/invoice-soft-colored',
    },
  ];

  return (
    <div className={styles.demoOptionsContainer}>
      {options.map((option) => (
        <div
          key={option.key}
          className={styles.cardOptionCard}
          onClick={() => onRadioClick(option.key)}
          onMouseEnter={() => onRadioHover(option.key)}
          onMouseLeave={() => onRadioHover(null)}
        >
          <div className={styles.cardOption}>
            <Icon
              name={option.icon}
              size={80}
              className={styles.cardOptionIcon}
            />
            <TypographyPoppins
              type="label"
              labelSize="S"
              className={styles.cardOptionLabel}
            >
              {option.text}
            </TypographyPoppins>
          </div>
          <RadioButton
            state="enabled"
            selected={selectedKey === option.key}
            key={option.key}
          />
        </div>
      ))}
    </div>
  );
};
