import { ChangeEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import {
  Divider,
  Dropdown,
  IconButton,
  Item,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { deleteHubModalOpened } from '@src/entities/delete-modals/modal';
import {
  hubDescriptionEntered,
  hubIdSelected,
  hubNameEntered,
  hubRenamingDialogOpened,
  locationEntered,
} from '@src/entities/dialogs/hub/model';
import { Autocomplete } from '@src/widgets/Autocomplete';

import { selectedHubID } from '../Title/model';

import styles from './styles.module.css';

type Props = {
  name: string;
  description: string;
  id: string;
  location?: string;
};

export const Details = ({ name, description, id, location }: Props) => {
  const [isEditBody, setEditBody] = useState(false);
  const [localDescription, setLocalDescription] = useState('');
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (description) {
      setLocalDescription(description);
    }
  }, [description]);

  const onDoubleClick = () => {
    setEditBody(true);

    setTimeout(() => {
      if (descriptionRef.current) {
        descriptionRef.current.focus();

        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(descriptionRef.current);
        range.collapse(false);
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    }, 0);
  };

  const { editHub } = useHubs({});

  const onInput = (e: ChangeEvent<HTMLDivElement>) => {
    setLocalDescription(e.target.innerText);
  };

  const editDescription = () => {
    if (localDescription !== description) {
      editHub({ description: localDescription, hubId: id });
    }
    setEditBody(false);
  };

  const onBlur = () => {
    editDescription();
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/h/${id}`,
      );
      startedSnack({
        label: 'Copied link',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    hubRenamingDialogOpened();
    hubNameEntered(name);
    hubIdSelected(id);

    if (description) {
      hubDescriptionEntered(description);
    }

    if (location) {
      locationEntered(location);
    }
  };

  const deleteHub = () => {
    selectedHubID(id);
    deleteHubModalOpened({
      hubId: id,
      name,
    });
  };

  const menuItems: Item[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: copyLink,
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      isDivider: true,
      onClick: rename,
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      classNameContent: styles.delete,
      onClick: deleteHub,
    },
  ];

  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <TypographyPoppins className={styles.name} type="title" titleSize="M">
            {name}
          </TypographyPoppins>
          <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
            <Dropdown.Menu
              trigger={
                <IconButton
                  type="unfilled"
                  icon={<Icon name="sprite/3-dot-menu" />}
                  onClick={() => {}}
                  isSelected={isOpenDropdown}
                />
              }
              side="top"
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>

        <div className={styles.details}>
          {/* <div className={styles.content}>
            <div className={styles.people}>
              <div className={styles.team}>
                <Team className={styles.icon} fill="none" />
              </div>
              <div className={styles.ownerAndClients}>
                {invitees?.members.map((member) => (
                  <Avatar
                    key={member.id}
                    image={member.user?.photo.full_size}
                    initials={
                      member.user
                        ? `${member.user.first_name} ${member.user.last_name}`
                        : member.email
                    }
                    size={20}
                  />
                ))}
              </div>
            </div>
            <AssistChip
              className={styles.invite}
              style="outlined"
              onClick={() => shareSideSheetOpened()}
            >
              Invite
            </AssistChip>
          </div> */}
          <div className={styles.description}>
            <Icon className={styles.icon} name="sprite/information" />
            <div
              ref={descriptionRef}
              className={cn(styles.text, {
                [styles.editMode]: isEditBody,
              })}
              contentEditable={isEditBody}
              onDoubleClick={onDoubleClick}
              onInput={onInput}
              onBlur={onBlur}
              placeholder={
                description ? '' : 'write a short description of this project'
              }
            >
              {description}
            </div>
          </div>
          <Autocomplete hubId={id} location={location} />
        </div>
      </div>
    </li>
  );
};
