import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';
import { snapshot } from 'patronum/snapshot';

import { docIdPassed } from '@src/model';

//toolbar
export const editModeToolbarActivated = createEvent();
export const editModeToolbarDeactivated = createEvent();
export const titleToolbarEdited = createEvent<string>();
export const titleToolbarCleaned = createEvent();
export const prevTitleToolbarSaved = createEvent();
export const titleDropdownOpened = createEvent();
export const titleDropdownClosed = createEvent();

export const $isEditModeToolbar = createStore(false);
export const $titleToolbar = createStore('');
export const $isOpenTitleDropdown = createStore(false);

//doc page
export const editModeTitleDocPageActivated = createEvent();
export const editModeTitleDocPageDeactivated = createEvent();
export const docTitlePageEdited = createEvent<string>();
export const docTitlePageCleaned = createEvent();
export const prevDocTitlePageSaved = createEvent();
export const docRenamingDialogOpened = createEvent<string>();
export const docRenamingDialogClosed = createEvent();
export const deleteDocModalHubOpened = createEvent();
export const deleteDocModalClosed = createEvent();
export const deleteDocModalOpened = createEvent();
export const docNamePassed = createEvent<string>();
export const docPageIdPassed = createEvent<string>();
export const docPageIdCleared = createEvent();
export const boardsSearchActivated = createEvent();
export const boardsSearchDeactivated = createEvent();

export const $isEditModeDocTitlePage = createStore(false);
export const $docTitlePage = createStore('');
export const $docRenamingDialog = createStore('');
export const $docId = createStore('');
export const $docName = createStore('');
export const $isOpenDocDeleteModalHub = createStore(false);
export const $isOpenDocDeleteModal = createStore(false);
export const $currentDocPageId = createStore('');
export const $isActivateBoardsSearch = createStore(false);

$isEditModeToolbar
  .on(editModeToolbarActivated, () => true)
  .reset(editModeToolbarDeactivated);

$titleToolbar.on(titleToolbarEdited, (_, title) => title);

const $prevTitleToolbar = snapshot({
  source: $titleToolbar,
  clock: prevTitleToolbarSaved,
});

sample({
  clock: titleToolbarCleaned,
  source: $prevTitleToolbar,
  target: $titleToolbar,
});

$isOpenTitleDropdown
  .on(titleDropdownOpened, () => true)
  .reset(titleDropdownClosed);

$isEditModeDocTitlePage
  .on(editModeTitleDocPageActivated, () => true)
  .reset(editModeTitleDocPageDeactivated);

$docTitlePage.on(docTitlePageEdited, (_, title) => title);

export const $prevDocTitlePage = snapshot({
  source: $docTitlePage,
  clock: prevDocTitlePageSaved,
});

sample({
  clock: docTitlePageCleaned,
  source: $prevDocTitlePage,
  target: $docTitlePage,
});

$docRenamingDialog
  .on(docRenamingDialogOpened, (_, docName) => docName)
  .reset(docRenamingDialogClosed);

const $isDocRenamedDialogOpen = $docRenamingDialog.map(
  (docName) => docName.length > 0,
);

$docId.on(docIdPassed, (_, docId) => docId).reset(docRenamingDialogClosed);

$isOpenDocDeleteModalHub
  .on(deleteDocModalHubOpened, () => true)
  .reset(deleteDocModalClosed);

$isOpenDocDeleteModal
  .on(deleteDocModalOpened, () => true)
  .reset(deleteDocModalClosed);

$docName.on(docNamePassed, (_, name) => name).reset(deleteDocModalClosed);
$currentDocPageId.on(docPageIdPassed, (_, id) => id).reset(docPageIdCleared);

$isActivateBoardsSearch
  .on(boardsSearchActivated, () => true)
  .reset(boardsSearchDeactivated);

hotkey({
  key: 'Escape',
  filter: $isEditModeToolbar,
  target: [editModeToolbarDeactivated, titleToolbarCleaned],
});

hotkey({
  key: 'Escape',
  filter: $isOpenTitleDropdown,
  target: [titleDropdownClosed],
});

hotkey({
  key: 'Escape',
  filter: $isEditModeDocTitlePage,
  target: [editModeTitleDocPageDeactivated, docTitlePageCleaned],
});

hotkey({
  key: 'Escape',
  filter: $isDocRenamedDialogOpen,
  target: docRenamingDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenDocDeleteModalHub,
  target: deleteDocModalClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenDocDeleteModal,
  target: deleteDocModalClosed,
});
