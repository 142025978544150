import { Icon, IconProps } from './Icon';

export const VailFilledColored = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M12.8403 7.9407L8.56248 9L12.8403 10.1926L14.0217 14.5L15.2847 10.1926L19.5625 9L15.2847 7.9407L14.0625 3.5L12.8403 7.9407Z"
        fill="#E2C9EB"
        stroke="url(#paint0_linear_15968_482)"
        strokeLinecap="square"
      />
      <path
        d="M14.0234 19.518C13.1279 19.3766 12.4254 18.7587 11.6224 18.3987C11.1607 18.1917 10.7894 17.8594 10.3484 17.6348C10.103 17.5098 9.76238 17.3584 9.56665 17.1627C9.42164 17.0177 9.24979 16.7924 9.08951 16.6855"
        stroke="url(#paint1_linear_15968_482)"
        strokeLinecap="round"
      />
      <path
        d="M4.48856 13.1755C4.21356 13.0576 3.92724 12.7014 3.7487 12.4699C3.53005 12.1865 3.31969 11.9045 3.08723 11.6321C2.5746 11.0313 2.2733 10.2892 2.05338 9.53986C1.94969 9.18653 1.93089 8.8576 1.93089 8.49131C1.93089 8.17478 1.89181 7.83893 2.01908 7.54076C2.15701 7.21761 2.35818 6.91939 2.65605 6.7274C3.1384 6.4165 3.6536 6.19004 4.22397 6.10513C4.68432 6.03659 5.15127 6.0567 5.61305 6.00958C6.20161 5.94953 6.77566 5.99351 7.35492 6.09778C7.86327 6.18928 8.43416 6.28048 8.92039 6.45301C9.28 6.58061 9.63004 6.8312 10.0008 6.91359C10.2089 6.95983 10.3686 7.1268 10.5741 7.17818"
        stroke="url(#paint2_linear_15968_482)"
        strokeLinecap="round"
      />
      <path
        d="M16.6644 11.3086C17.274 11.3376 17.7908 11.9195 18.2405 12.2781C18.571 12.5416 18.8883 12.8194 19.2204 13.0817C19.5511 13.3429 19.8365 13.6494 20.1173 13.963C20.5644 14.4625 21.0676 14.9774 21.3926 15.5702C21.7195 16.1662 21.8746 16.9011 21.9266 17.5714C21.9803 18.2635 21.9401 18.9653 21.4471 19.4948C20.9724 20.0047 20.2797 20.4073 19.5574 20.4073C19.1941 20.4073 18.7359 20.4757 18.3909 20.3606"
        stroke="url(#paint3_linear_15968_482)"
        strokeLinecap="round"
      />
      <rect
        x="14.0625"
        y="18"
        width="4"
        height="4"
        fill="#E2C9EB"
        stroke="url(#paint4_linear_15968_482)"
      />
      <path
        d="M6.06248 19C4.40563 19 3.06248 17.6569 3.06248 16C3.06248 14.3431 4.40563 13 6.06248 13C7.71934 13 9.06248 14.3431 9.06248 16C9.06248 17.6569 7.71934 19 6.06248 19Z"
        fill="#E2C9EB"
        stroke="url(#paint5_linear_15968_482)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15968_482"
          x1="15.4252"
          y1="1"
          x2="14.4252"
          y2="13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="1" stopColor="#6B1888" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15968_482"
          x1="11.5564"
          y1="16.6855"
          x2="11.5564"
          y2="19.518"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="1" stopColor="#6B1888" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15968_482"
          x1="7.42523"
          y1="5.99988"
          x2="-2.57477"
          y2="19.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="0.915" stopColor="#360A46" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_15968_482"
          x1="19.3046"
          y1="11.3086"
          x2="19.4252"
          y2="30.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="1" stopColor="#6B1888" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_15968_482"
          x1="15.9252"
          y1="16"
          x2="16.0625"
          y2="22.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="1" stopColor="#6B1888" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_15968_482"
          x1="9.92522"
          y1="13"
          x2="2.92522"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E2C9EB" />
          <stop offset="0.479167" stopColor="#9E4BBB" />
          <stop offset="1" stopColor="#6B1888" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
