import React, {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import { BoardResponse } from '@api/boards';
import { useBoards } from '@pages/BoardPage/model/queries/useBoards';

import styles from './styles.module.css';

type Props = {
  board: BoardResponse;
};

export const Description = ({ board }: Props) => {
  const [description, setDescription] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  const { editBoard } = useBoards({});

  const editDescription = () => {
    if (description.trim() !== board.description) {
      editBoard({ boardId: board.id, description });
    }
  };

  useEffect(() => {
    if (board.description) {
      setDescription(board.description);
    }
  }, [board]);

  const onInput = (e: ChangeEvent<HTMLDivElement>) => {
    setDescription(e.target.innerText);
  };

  const onBlur = () => {
    if (ref.current) {
      setDescription(ref.current.innerText);
      editDescription();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey && ref.current) {
      setDescription(ref.current.innerText);
      editDescription();
      ref.current.blur();
    }
  };

  return (
    <div
      className={styles.description}
      ref={ref}
      contentEditable={true}
      suppressContentEditableWarning={true}
      onInput={onInput}
      onBlur={onBlur}
      onKeyDown={handleKeyDown}
    >
      {board.description}
    </div>
  );
};
