import { useRef, useState } from 'react';

import { Icon } from 'icons';

import {
  FilterChip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';

import { PermissionsHub } from '@api/hubs';

import { Permissions } from '../permissions';

import styles from './styles.module.css';

type GuestSeatProps = {
  permissions: PermissionsHub[];
  setPermissions: React.Dispatch<PermissionsHub[]>;
};

export const GuestSeat = ({ permissions, setPermissions }: GuestSeatProps) => {
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getPermissions = (permissions: PermissionsHub[]) => {
    if (permissions.includes('edit')) {
      return 'Editor';
    } else if (permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  return (
    <div className={styles.guestSeat}>
      <TypographyPoppins
        className={styles.description}
        type="body"
        bodySize="S"
      >
        Send invitation to join as
      </TypographyPoppins>
      <div ref={permissionsRef} className={styles.permissions}>
        <FilterChip
          className={styles.permission}
          leadingIcon={
            isShowMenu && (
              <Icon
                name="sprite/tick"
                size={16}
                color="var(--color-neutral-variant-30)"
              />
            )
          }
          trailingIcon={
            isShowMenu ? (
              <Icon name="sprite/caret-down" size={16} />
            ) : (
              <Icon name="sprite/caret-up" size={16} />
            )
          }
          type="label"
          labelSize="S"
          isSelected={isShowMenu}
          onClick={() => setShowMenu(!isShowMenu)}
        >
          {getPermissions(permissions)}
        </FilterChip>
        <Permissions
          isShowMenu={isShowMenu}
          setShowMenu={setShowMenu}
          permissions={permissions}
          changePermissions={setPermissions}
          position="bottom"
        />
      </div>
    </div>
  );
};
