import { createEvent, createStore } from 'effector';

export const actionItemsSheetOpened = createEvent();
export const actionItemsSheetClosed = createEvent();

export const $isActionItemsSheetOpen = createStore(false);

$isActionItemsSheetOpen
  .on(actionItemsSheetOpened, (open) => !open)
  .reset(actionItemsSheetClosed);
