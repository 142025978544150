import { useRef, useState } from 'react';

import { FormikErrors, useFormik } from 'formik';
import { Icon } from 'icons';
import { NavLink } from 'react-router-dom';

import { ButtonPill, Input, LogoForm } from '@visualist/design-system';

import styles from './styles.module.css';

export type ResetPasswordFormProps = {
  onSubmitForm: (values: ResetPasswordFormSchema) => void;
  greeting: string;
};

export type ResetPasswordFormSchema = {
  password1: string;
  password2: string;
};

export const ResetPasswordForm = ({
  onSubmitForm,
  greeting,
}: ResetPasswordFormProps): JSX.Element => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const passwordValidation = (password: string) => {
    return /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,}$/.test(password);
  };

  const codeInputRef = useRef<HTMLInputElement | null>(null);

  const { errors, values, handleSubmit, handleChange } = useFormik({
    initialValues: {
      password1: '',
      password2: '',
    },
    validate: ({ password1, password2 }) => {
      const errors: FormikErrors<{
        password1: string;
        password2: string;
      }> = {};

      if (password1.length === 0 && password2.length === 0 && !inputFocus) {
        errors.password1 =
          'Use at least 8 characters, incl. a number or symbol';
        errors.password2 = 'Confirm your password';
      }

      if (password1 !== values.password2 && password2.length !== 0) {
        errors.password2 = `Passwords don't match`;
      }

      if (password1.length !== 0) {
        if (!passwordValidation(password1)) {
          errors.password1 =
            'Use at least 8 characters, incl. a number or symbol';
        }
      }

      return errors;
    },
    onSubmit: (values) => {
      const { password1, password2 } = values;
      if (password1 === password2 && password1.length > 7) {
        onSubmitForm(values);
      }
    },
    validateOnChange: true,
    validateOnBlur: false,
  });

  return (
    <>
      <div className={styles.formContainer}>
        <div className={styles.logoReset}>
          <LogoForm />
        </div>
        <NavLink className={styles.btnBack} to="/login">
          <div className={styles.arrowAndText}>
            <div className={styles.backArrow}>
              <Icon name="sprite/chevron-left" />
            </div>
            <div className={styles.textBack}>
              <p>Back</p>
            </div>
          </div>
        </NavLink>
        <div className={styles.greeting}>
          <p>{greeting}</p>
        </div>
        <form className={styles.inputsWrap} onSubmit={handleSubmit}>
          <div className={styles.inputsReset}>
            <div className={styles.inputOne}>
              <Input
                ref={codeInputRef}
                type={showPassword ? 'text' : 'password'}
                placeholder="Your password"
                name="password1"
                onChange={handleChange}
                onBlur={(e) => {
                  e.preventDefault();
                  if (e) {
                    setInputFocus(false);
                  }
                }}
                onFocus={(e) => {
                  e.preventDefault();
                  if (e) {
                    setInputFocus(true);
                  }
                }}
                value={values.password1}
                errorMessage={errors.password1}
                rightIcon={
                  showPassword ? (
                    <Icon
                      name="sprite/eye-opened"
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <Icon
                      name="sprite/eye-closed"
                      onClick={() => setShowPassword(true)}
                    />
                  )
                }
                className={styles.inputPassword}
              />
              {!errors.password1 && values.password1.length === 0 && (
                <span className={styles.underText}>
                  Use at least 8 characters, incl. a number or symbol
                </span>
              )}
            </div>
            <div>
              <Input
                ref={codeInputRef}
                type={showConfirmPassword ? 'text' : 'password'}
                name="password2"
                placeholder="Confirm password"
                errorMessage={errors.password2}
                value={values.password2}
                onChange={handleChange}
                onBlur={(e) => {
                  e.preventDefault();
                  if (e) {
                    setInputFocus(false);
                  }
                }}
                onFocus={(e) => {
                  e.preventDefault();
                  if (e) {
                    setInputFocus(true);
                  }
                }}
                rightIcon={
                  showConfirmPassword ? (
                    <Icon
                      name="sprite/eye-opened"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  ) : (
                    <Icon
                      name="sprite/eye-closed"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  )
                }
                className={styles.inputPassword}
              />
            </div>
          </div>
          <div className={styles.buttonWrap}>
            <ButtonPill type="submit" label={'Reset'} />
          </div>
        </form>
      </div>
    </>
  );
};
