import chroma from 'chroma-js';
import { createEvent, createStore, sample } from 'effector';

import { ColorSwatch } from '@api/services';

// Left edge of leftmost (first) swatch
export const leftEdgeHovered = createEvent<boolean>();
export const $isLeftEdgeOfFirstItem = createStore(false);

sample({
  clock: leftEdgeHovered,
  target: $isLeftEdgeOfFirstItem,
});

// Current state (from server)
export const paletteCleared = createEvent();

export const $currentState = createStore<{
  theme: string;
  swatches: ColorSwatch[];
}>({ theme: '', swatches: [] });

$currentState.reset(paletteCleared);
// Local state
export const paletteSelected = createEvent<{
  theme: string;
  swatches: ColorSwatch[];
}>();

export const paletteReseted = createEvent();

export const $palette = createStore<{
  theme: string;
  swatches: ColorSwatch[];
}>({ theme: '', swatches: [] });

sample({
  clock: paletteSelected,
  target: [$currentState, $palette],
});

sample({
  clock: [paletteReseted, paletteCleared],
  source: $currentState,
  target: $palette,
});

// Removing a color swatch
export const swatchRemoved = createEvent<number>();

sample({
  clock: swatchRemoved,
  source: $palette,
  filter: (palette) => palette.swatches.length > 2,
  fn: (palette, orderToRemove) => ({
    ...palette,
    swatches: palette.swatches
      .filter((swatch) => swatch.order !== orderToRemove)
      .map((swatch, index) => ({ ...swatch, order: index + 1 })),
  }),
  target: $palette,
});

// Calculating a new color for the swatch based on its position
export const swatchCreated = createEvent<{ index: number }>();

const calculateNewSwatchColor = (
  palette: { swatches: ColorSwatch[] },
  index: number,
  isLeftEdgeOfFirstItem: boolean,
) => {
  let newHexColor: string;

  // Adding to the beginning
  if (index === 0 && isLeftEdgeOfFirstItem) {
    const firstColor = palette.swatches[0].hex_color;
    newHexColor = chroma(firstColor).darken(0.1).hex();
  }
  // Adding to the end
  else if (index === palette.swatches.length - 1) {
    const lastColor = palette.swatches[palette.swatches.length - 1].hex_color;
    newHexColor = chroma(lastColor).brighten(0.1).hex();
  }
  // Adding between two swatches
  else {
    const prevColor = palette.swatches[index].hex_color;
    const nextColor = palette.swatches[index + 1].hex_color;
    newHexColor = chroma.mix(prevColor, nextColor, 0.5, 'rgb').hex();
  }

  // Calculating HSL and HSV
  const newRgbColor = chroma(newHexColor)
    .rgb()
    .join(', ')
    .replace(/,\s+/g, ',');
  const hsl = chroma(newHexColor).hsl();
  const hsv = chroma(newHexColor).hsv();

  const hslColor = `${Math.round(hsl[0])}, ${Math.round(
    hsl[1] * 100,
  )}%, ${Math.round(hsl[2] * 100)}%`;

  const hsvColor = `${Math.round(hsv[0])}, ${Math.round(
    hsv[1] * 100,
  )}%, ${Math.round(hsv[2] * 100)}%`;

  return {
    hex_color: newHexColor,
    rgb_color: newRgbColor,
    hsl_color: hslColor,
    hsv_color: hsvColor,
  };
};

// Adding a color swatch
export const swatchAdded = createEvent<{
  index: number;
  newSwatch: Omit<ColorSwatch, 'order'>;
}>();

sample({
  clock: swatchCreated,
  source: { palette: $palette, isLeftEdgeOfFirstItem: $isLeftEdgeOfFirstItem },
  fn: ({ palette, isLeftEdgeOfFirstItem }, { index }) => {
    // Calculating a new color
    const newSwatchColor = calculateNewSwatchColor(
      palette,
      index,
      isLeftEdgeOfFirstItem,
    );

    // Passing the calculated color to swatchAdded
    return { index, newSwatch: newSwatchColor };
  },
  target: swatchAdded,
});

sample({
  clock: swatchAdded,
  source: { palette: $palette, isLeftEdgeOfFirstItem: $isLeftEdgeOfFirstItem },
  filter: ({ palette }) => palette.swatches.length < 5,
  fn: ({ palette, isLeftEdgeOfFirstItem }, { index, newSwatch }) => {
    if (index === 0 && isLeftEdgeOfFirstItem) {
      // Add to the beginning and recalculate the order
      const updatedSwatches = [
        { ...newSwatch, order: 1 },
        ...palette.swatches.map((swatch, i) => ({ ...swatch, order: i + 2 })),
      ];
      return { ...palette, swatches: updatedSwatches };
    }

    // Insert the swatch after the specified index and recalculate the order of all elements
    const updatedSwatches = [
      ...palette.swatches.slice(0, index + 1),
      { ...newSwatch, order: index + 2 },
      ...palette.swatches.slice(index + 1).map((swatch) => ({
        ...swatch,
        order: swatch.order + 1,
      })),
    ];

    return { ...palette, swatches: updatedSwatches };
  },
  target: $palette,
});

// Updating color of an individual swatch
export const swatchUpdated = createEvent<{
  order: number;
  hex_color: string;
  rgb_color: string;
}>();

sample({
  clock: swatchUpdated,
  source: $palette,
  fn: (palette, { order, hex_color, rgb_color }) => {
    let hslColor = '0, 0%, 0%';
    let hsvColor = '0, 0%, 0%';

    try {
      // Checking if hex_color is a valid color
      const color = chroma(hex_color);

      if (color) {
        const hsl = color.hsl();
        const hsv = color.hsv();

        // Checking that the HSL and HSV values do not contain NaN
        const hueHSL = isNaN(hsl[0]) ? 0 : Math.round(hsl[0]);
        const saturationHSL = isNaN(hsl[1]) ? 0 : Math.round(hsl[1] * 100);
        const lightnessHSL = isNaN(hsl[2]) ? 0 : Math.round(hsl[2] * 100);

        const hueHSV = isNaN(hsv[0]) ? 0 : Math.round(hsv[0]);
        const saturationHSV = isNaN(hsv[1]) ? 0 : Math.round(hsv[1] * 100);
        const lightnessHSV = isNaN(hsv[2]) ? 0 : Math.round(hsv[2] * 100);

        hslColor = `${hueHSL},${saturationHSL}%,${lightnessHSL}%`;
        hsvColor = `${hueHSV},${saturationHSV}%,${lightnessHSV}%`;
      }
    } catch (error) {
      console.error('Invalid color format:', hex_color, error);
      // Default values
      hslColor = '0, 0%, 0%';
      hsvColor = '0, 0%, 0%';
    }

    return {
      ...palette,
      swatches: palette.swatches.map((swatch) =>
        swatch.order === order
          ? {
              ...swatch,
              hex_color,
              rgb_color,
              hsl_color: hslColor,
              hsv_color: hsvColor,
            }
          : swatch,
      ),
    };
  },
  target: $palette,
});

// DnD swatches
export const dragStarted = createEvent<number>();

export const dragEnded = createEvent<{
  startIndex: number;
  endIndex: number;
}>();

sample({
  clock: dragEnded,
  source: $palette,
  filter: (palette, { startIndex, endIndex }) =>
    startIndex !== endIndex &&
    endIndex >= 0 &&
    endIndex < palette.swatches.length,
  fn: (palette, { startIndex, endIndex }) => ({
    ...palette,
    swatches: reorder(palette.swatches, startIndex, endIndex),
  }),
  target: $palette,
});

const reorder = (
  items: ColorSwatch[],
  startIndex: number,
  endIndex: number,
): ColorSwatch[] => {
  const swatches = Array.from(items);
  const [removed] = swatches.splice(startIndex, 1);
  swatches.splice(endIndex, 0, removed);

  return swatches.map((swatch, index) => ({ ...swatch, order: index + 1 }));
};
