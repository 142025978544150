import React from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage as TStage } from 'konva/lib/Stage';
import { Layer, Rect, Stage, Transformer } from 'react-konva';
import { Prompt } from 'react-router';

import { useKeyPress } from '@visualist/hooks';

import { PageLoader } from '@components/PageLoader';
import { File as ImageFile } from '@pages/StudioPage/api';
import {
  DRAG_SELECTION,
  IMAGES_LAYER,
  STICKY_LAYER,
  TEXT_LAYER,
} from '@pages/StudioPage/constants';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { useTextBoxes } from '@pages/StudioPage/hooks/useTextBoxes';
import {
  $currentTool,
  $dragSelection,
  $isDraggingImageFromLibrary,
  $isEditingText,
  $selectedImageBlocks,
  $selectedTextBlocks,
  $stageState,
  changedEditingState,
  selectedImageBlockIdsAction,
  selectedTextBlockIdsAction,
  startedDragSelection,
  stoppedDragSelection,
  updatedDragSelection,
  updatedStage,
} from '@pages/StudioPage/model';
import {
  calculateTextAndImageBounds,
  centeredWidthHeightForImage,
  centerStage,
  generateFormDataForUpload,
  getSelectedNodes,
  loadImageFromFile,
  selectBlocksInSelection,
} from '@pages/StudioPage/utils';
import { useAppData } from '@src/AppContext';
import { $hoveredStickyId, editedSticky } from '@src/entities/Stickies/model';
import {
  checkIfAttached,
  chooseColour,
  computeStickyPositionToRatio,
} from '@src/entities/Stickies/utils';
import { WOLFE_LIGHT } from '@src/shared/constants/colours';
import { IMAGE_PREVIEW_MUTATION } from '@src/shared/constants/query-names';
import useStickies from '@src/shared/queries/useStickies';
import { useIsMutating } from '@tanstack/react-query';

import { Images } from '../Images';
import { $draggableImages, SelectedImage } from '../Library/model';
import { OptimisticImages } from '../OptimisticImages';
import { Stickies } from '../Stickies';
import { TextBoxes } from '../Textboxes';
import { VaiTidyPopupModal } from '../TidyupPopup';

import styles from './styles.module.css';

type Props = {
  designId: string;
  stageRef: React.MutableRefObject<TStage | null>;
  debouncedGenerateThumbnail: () => void;
};

export const Canvas = React.forwardRef<TStage | null, Props>(
  ({ designId, debouncedGenerateThumbnail, stageRef }, ref) => {
    const [
      stage,
      currentTool,
      selectedImageBlocks,
      selectedTextBlocks,
      dragSelection,
      isEditingText,
      isDraggingImageFromLibrary,
      hoveredStickyId,
      draggedImages,
    ] = useUnit([
      $stageState,
      $currentTool,
      $selectedImageBlocks,
      $selectedTextBlocks,
      $dragSelection,
      $isEditingText,
      $isDraggingImageFromLibrary,
      $hoveredStickyId,
      $draggableImages,
    ]);

    const containerRef = React.useRef<HTMLDivElement>(null);
    const selectionTransformerRef = React.useRef<Transformer>(null);
    const [dimensions, setDimensions] = React.useState<
      { height: number; width: number } | undefined
    >(undefined);
    const [hasCentered, setHasCentered] = React.useState(false);
    const {
      imageQuery,
      imageUploadMutation,
      imageDeleteMutation,
      existingImageUploadMutation,
    } = useImages({
      designId,
    });
    const { textBoxQuery, deleteTextboxMutation } = useTextBoxes({ designId });
    const { stickiesQuery, addNewStickyOptimistic } = useStickies(
      designId,
      null,
    );
    const [showHandCursor, setShowHandCursor] = React.useState(false);
    const { user } = useAppData();

    const selectedBlocks = [...selectedImageBlocks, ...selectedTextBlocks];

    const { minY, maxY, minX, maxX } = React.useMemo(() => {
      return calculateTextAndImageBounds({
        images: imageQuery.data?.data.results,
        textboxes: textBoxQuery.data,
      });
    }, [imageQuery.data, textBoxQuery.data]);

    const isUploadingPreview = useIsMutating({
      mutationKey: [IMAGE_PREVIEW_MUTATION],
    });

    React.useEffect(() => {
      const confirmUnload = (event: BeforeUnloadEvent) => {
        if (
          imageUploadMutation.isPending ||
          imageDeleteMutation.isPending ||
          isUploadingPreview
        ) {
          event.preventDefault();
          return 'We’re saving your design. If you go to another page, you may lose some changes.';
        }
      };

      window.addEventListener('beforeunload', confirmUnload);

      // Make sure to clean up the event listener on component unmount
      return () => {
        window.removeEventListener('beforeunload', confirmUnload);
      };
    }, [
      imageUploadMutation.isPending,
      imageDeleteMutation.isPending,
      isUploadingPreview,
    ]);

    React.useEffect(() => {
      if (imageQuery.data && textBoxQuery.data) {
        // Center the stage
        centerStage({
          stageRef,
          imageData: imageQuery.data.data.results,
          textData: textBoxQuery.data,
          setHasCentered,
        });
      }
    }, [imageQuery.isLoading, textBoxQuery.isLoading, dimensions]);

    React.useEffect(() => {
      // Synchronise the current tool with the cursor
      if (currentTool === 'move') {
        setShowHandCursor(true);
      } else {
        setShowHandCursor(false);
      }
    }, [currentTool]);

    React.useEffect(() => {
      const updateDimensions = () => {
        if (containerRef.current) {
          setDimensions({
            height: containerRef.current.clientHeight,
            width: containerRef.current.clientWidth,
          });
        }
      };

      // Update dimensions initially
      updateDimensions();

      // Update dimensions when window resizes
      window.addEventListener('resize', updateDimensions);

      // Clean up event listener on unmount
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);

    React.useEffect(() => {
      if (
        selectedBlocks.length > 1 &&
        stageRef.current &&
        selectionTransformerRef.current
      ) {
        const nodes = getSelectedNodes(stageRef.current, selectedBlocks);

        // @ts-ignore
        selectionTransformerRef.current.nodes(nodes);
        // @ts-ignore
        selectionTransformerRef.current.getLayer()?.batchDraw();
      }
    }, [selectedBlocks]);

    // Keyboard Shortcuts
    const deleteSelectedBlocks = () => {
      if (selectedImageBlocks.size) {
        imageDeleteMutation.mutate(Array.from(selectedImageBlocks.values()));
      }

      if (selectedTextBlocks.size && !isEditingText) {
        deleteTextboxMutation.mutate({
          ids: Array.from(selectedTextBlocks.values()),
        });
      }
    };
    useKeyPress({
      key: 'Delete',
      onKeyDown: deleteSelectedBlocks,
    });

    useKeyPress({
      key: 'Backspace',
      onKeyDown: deleteSelectedBlocks,
    });

    // Control the Stage
    const onStageZoom = (e: KonvaEventObject<WheelEvent>) => {
      e.evt.preventDefault();

      const scaleBy = 1.02;
      const stage = e.target.getStage();
      if (!stage || !stage.getPointerPosition()) return;

      const oldScale = stage.scaleX();
      const pointerPosition = stage.getPointerPosition();

      if (!pointerPosition) return;
      const mousePointTo = {
        x: pointerPosition.x / oldScale - stage.x() / oldScale,
        y: pointerPosition.y / oldScale - stage.y() / oldScale,
      };

      const newScale =
        e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

      updatedStage({
        scale: newScale,
        x: (pointerPosition.x / newScale - mousePointTo.x) * newScale,
        y: (pointerPosition.y / newScale - mousePointTo.y) * newScale,
      });
    };

    const onDraggingStageEnded = (e: KonvaEventObject<DragEvent>) => {
      updatedStage({
        scale: stage.scale,
        x: e.currentTarget.position().x,
        y: e.currentTarget.position().y,
      });
    };

    const currentStickyMutationsRunning = useIsMutating({
      mutationKey: ['ADD_STICKY_MUTATION', { imageId: designId }],
    });

    const addPlaceholderSticky = (
      e: KonvaEventObject<MouseEvent> | KonvaEventObject<TouchEvent>,
    ) => {
      // If there are mutations running do nothing
      if (currentStickyMutationsRunning) return;

      const stage = e.target.getStage();
      if (!stage) return;

      const pointer = stage.getRelativePointerPosition();

      if (!pointer) return;

      // If not clicking on the container do nothing
      // Allow stickies on the staage, images, or text
      if (
        !(
          e.target.name() === 'Image' ||
          e.target.name() === 'Text' ||
          e.target.name() === 'Stage'
        )
      )
        return;

      // Turn off any sticky being edited
      editedSticky(null);

      const { ratioX, ratioY } = computeStickyPositionToRatio({
        x: pointer.x,
        y: pointer.y,
        minX,
        minY,
        maxX,
        maxY,
      });

      const attachment = checkIfAttached({
        stageRef,
        point: pointer,
      });

      addNewStickyOptimistic({
        id: 'new-sticky',
        created_by: user,
        is_private: true,
        left_pixel: ratioX,
        top_pixel: ratioY,
        text: '',
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        background_hex: chooseColour(
          stickiesQuery.data ? stickiesQuery.data.stickies.length : 0,
        ),
        attached_to_id: attachment?.id,
        attached_to_type: attachment?.type,
        action_sticky: null,
      });
    };

    const clickedOnCanvas = (e: KonvaEventObject<MouseEvent>) => {
      // TODO use better way to dismiss selected
      if (currentTool === 'sticky') addPlaceholderSticky(e);

      if (
        e.currentTarget.name() === 'Stage' &&
        e.target.name() !== 'Image' &&
        e.target.name() !== 'Text'
      ) {
        if (isEditingText) {
          // If text was being edited, when click off occurs, changed editing mode to false but select the text
          changedEditingState(false);
          return;
        }
        selectedImageBlockIdsAction(new Set());
        selectedTextBlockIdsAction(new Set());
      }
    };

    const startDragSelection = (e: KonvaEventObject<MouseEvent>) => {
      // If cursor over anything do not start selection
      if (e.target.name() !== 'Stage') return;
      // If select tool start drawing box
      if (currentTool === 'select') {
        startedDragSelection({
          x: (e.evt.offsetX - stage.x) / stage.scale,
          y: (e.evt.offsetY - stage.y) / stage.scale,
        });
      }
    };

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
      if (dragSelection) {
        // If a drag is in action continue drawing box
        updatedDragSelection({
          x: (e.evt.offsetX - stage.x) / stage.scale,
          y: (e.evt.offsetY - stage.y) / stage.scale,
        });
      }
    };

    const endDragSelection = () => {
      if (dragSelection) {
        if (stageRef && stageRef.current) {
          selectBlocksInSelection({
            stage: stageRef.current,
            x1: dragSelection.x1,
            y1: dragSelection.y1,
            x2: dragSelection.x2,
            y2: dragSelection.y2,
          });
        }
        stoppedDragSelection();
      }
    };

    const droppedImageFileFromLibrary = async ({
      e,
      images,
    }: {
      e: React.DragEvent<HTMLDivElement>;
      images: SelectedImage[];
    }) => {
      if (!stageRef || !stageRef.current) return;

      const files: ImageFile[] = [];

      const konvaStage = stageRef.current;

      const imageX =
        (e.nativeEvent.offsetX - konvaStage.x()) / konvaStage.scaleX();
      const imageY =
        (e.nativeEvent.offsetY - konvaStage.y()) / konvaStage.scaleY();

      if (images) {
        for (const image of images) {
          const newWidth = 900;
          const newHeight =
            (newWidth * image.ref.naturalHeight) / image.ref.naturalWidth;

          const { x, y } = centeredWidthHeightForImage({
            x: imageX,
            y: imageY,
            width: newWidth,
            height: newHeight,
          });

          files.push({
            file: image.id,
            position: {
              position_x: Math.trunc(x),
              position_y: Math.trunc(y),
              position_width: newWidth,
              position_height: newHeight,
              position_omega: 0,
              position_lock: false,
            },
          });
        }
      }

      if (files.length > 0) {
        await existingImageUploadMutation.mutateAsync({ id: designId, files });
        debouncedGenerateThumbnail();
      }
    };

    const droppedImageFile = async (e: React.DragEvent<HTMLDivElement>) => {
      // TODO ADD IMAGE CANVAS
      e.preventDefault();

      if (!stageRef || !stageRef.current) return;

      const konvaStage = stageRef.current;

      const forms: FormData[] = [];
      const imageX =
        (e.nativeEvent.offsetX - konvaStage.x()) / konvaStage.scaleX();
      const imageY =
        (e.nativeEvent.offsetY - konvaStage.y()) / konvaStage.scaleY();
      let containsNonImage = false;

      let file: File | null = null;

      let shouldHideFromLibrary = true;

      let height = undefined;
      let width = undefined;

      // File being uploaded
      file = e.dataTransfer.files[0];

      if (!file) return;

      const image = await loadImageFromFile(file);
      height = image.height;
      width = image.width;

      shouldHideFromLibrary = false;

      if (file && file.type.includes('image')) {
        const newWidth = 900;
        const newHeight = (newWidth * height) / width;

        const { x, y } = centeredWidthHeightForImage({
          x: imageX,
          y: imageY,
          width: newWidth,
          height: newHeight,
        });

        const form = generateFormDataForUpload({
          file,
          width: newWidth || width,
          height: newHeight || height,
          positionX: x,
          positionY: y,
          id: `${width}-${height}-${x}-${y}`,
          set: designId,
          isHiddenFromLibrary: shouldHideFromLibrary,
        });

        forms.push(form);
      } else {
        containsNonImage = true;
      }

      if (containsNonImage) {
        // Add Error Notification
      }

      if (forms.length > 0) {
        await imageUploadMutation.mutateAsync({ forms });
        debouncedGenerateThumbnail();
      }
    };

    const moveStageTouch = (e: KonvaEventObject<TouchEvent>) => {
      e.evt.preventDefault();

      // Example code to implement: https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html

      // const touch1 = e.evt.touches[0];
      // const touch2 = e.evt.touches[1];
    };

    return (
      <div
        ref={containerRef}
        className={styles.container}
        onDrop={(e) => {
          if (isDraggingImageFromLibrary && draggedImages) {
            droppedImageFileFromLibrary({ e, images: draggedImages });
          } else {
            droppedImageFile(e);
          }
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <VaiTidyPopupModal />
        <Prompt
          when={
            imageUploadMutation.isPending ||
            imageDeleteMutation.isPending ||
            !!isUploadingPreview
          }
          message={() =>
            `We’re saving your design. If you go to another page, you may lose some changes.`
          }
        />
        {dimensions ? (
          <Stage
            ref={ref as React.MutableRefObject<TStage | null>}
            x={stage.x}
            y={stage.y}
            scaleX={stage.scale}
            scaleY={stage.scale}
            height={dimensions.height}
            width={dimensions.width}
            draggable={currentTool === 'move'}
            onTouchMove={moveStageTouch}
            onMouseUp={endDragSelection}
            onMouseDown={startDragSelection}
            onMouseMove={onMouseMove}
            onMouseLeave={endDragSelection}
            onDragEnd={onDraggingStageEnded}
            onWheel={onStageZoom}
            onClick={clickedOnCanvas}
            onTap={clickedOnCanvas}
            name="Stage"
            className={cn({
              [styles.handToolCursor]:
                showHandCursor || (currentTool === 'sticky' && hoveredStickyId),
              [styles.addTextCursor]:
                !showHandCursor && currentTool === 'add-text',
              [styles.stickyCursor]:
                currentTool === 'sticky' &&
                !hoveredStickyId &&
                !currentStickyMutationsRunning,
              [styles.loadingCursor]:
                currentTool === 'sticky' && currentStickyMutationsRunning,
            })}
          >
            <Layer name={IMAGES_LAYER}>
              {designId && hasCentered ? (
                <>
                  <Images
                    stageRef={stageRef}
                    updateThumbnail={debouncedGenerateThumbnail}
                    designId={designId}
                  />
                  <OptimisticImages />
                </>
              ) : null}
            </Layer>
            <Layer name={TEXT_LAYER}>
              {designId && hasCentered ? (
                <TextBoxes
                  stageRef={stageRef}
                  designId={designId}
                  updateThumbnail={debouncedGenerateThumbnail}
                />
              ) : null}
            </Layer>
            <Layer name={STICKY_LAYER}>
              {designId && hasCentered ? (
                <Stickies
                  stageRef={stageRef}
                  designId={designId}
                  dimensions={dimensions}
                />
              ) : null}
            </Layer>
            <Layer name={DRAG_SELECTION}>
              {dragSelection ? (
                <Rect
                  x={dragSelection.x1}
                  y={dragSelection.y1}
                  width={dragSelection.x2 - dragSelection.x1}
                  height={dragSelection.y2 - dragSelection.y1}
                  fill="#8B39A833"
                  opacity={0.2}
                  stroke={WOLFE_LIGHT}
                  strokeWidth={10}
                  strokeEnabled
                />
              ) : null}
              {selectedImageBlocks.size + selectedTextBlocks.size > 1 ? (
                <Transformer
                  // @ts-ignore TODO fix type issue
                  ref={selectionTransformerRef}
                  borderStroke={WOLFE_LIGHT}
                  anchorStroke={WOLFE_LIGHT}
                />
              ) : null}
            </Layer>
          </Stage>
        ) : null}
        {!hasCentered ? (
          <div className={styles.loaderContainer}>
            <PageLoader />
          </div>
        ) : null}
      </div>
    );
  },
);
