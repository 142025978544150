import React from 'react';

import Icon, { IconProps } from './Icon';

const CrossIcon: React.FC<IconProps> = ({ size }: IconProps) => {
  return (
    <Icon size={size} viewBox="0 0 12 12">
      <path
        d="M0.000488281 6.00049C0.000488281 2.68678 2.68678 0.000488281 6.00049 0.000488281V0.000488281C9.3142 0.000488281 12.0005 2.68678 12.0005 6.00049V6.00049C12.0005 9.3142 9.3142 12.0005 6.00049 12.0005V12.0005C2.68678 12.0005 0.000488281 9.3142 0.000488281 6.00049V6.00049Z"
        fill="currentColor"
      />
      <path
        d="M3.50049 8.50049L8.50049 3.50049M8.50049 8.50049L3.50049 3.50049"
        stroke="white"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};

export default CrossIcon;
