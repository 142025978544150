import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './Header.module.css';

type LinkGroupProps = {
  className?: string;
  children: ReactNode;
};

const LinkGroup = ({ className, children }: LinkGroupProps) => (
  <nav className={cn(styles.linkGroup, className)}>{children}</nav>
);

export default LinkGroup;
