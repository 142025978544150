import { createEvent, createStore } from 'effector';

export const dropdownOpened = createEvent();
export const dropdownClosed = createEvent();
export const parentBoardSetted = createEvent<string>();
export const parentBoardUnSetted = createEvent();
export const shareSideSheetOpened = createEvent();
export const shareSideSheetClosed = createEvent();

export const currentBoardPageIdSetted = createEvent<string>();
export const currentBoardPageIdCleared = createEvent();

export const $isOpenDropdown = createStore(false);
export const $parentBoard = createStore('');
export const $isShareSheetOpen = createStore(false);
export const $currentBoardPageId = createStore('');

$currentBoardPageId
  .on(currentBoardPageIdSetted, (_, id) => id)
  .reset(currentBoardPageIdCleared);

$isShareSheetOpen
  .on(shareSideSheetOpened, (state) => !state)
  .reset(shareSideSheetClosed);

$isOpenDropdown.on(dropdownOpened, () => true).reset(dropdownClosed);

$parentBoard
  .on(parentBoardSetted, (_, name) => name)
  .reset(parentBoardUnSetted);
