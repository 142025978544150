import { useUnit } from 'effector-react';
import { Location } from 'history';
import { Icon } from 'icons';
import { matchPath, useLocation } from 'react-router';

import {
  AssistChip,
  Button,
  Modal,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Palette } from '../../../pages/FileCard/palette';
import { $palette } from '../../../pages/FileCard/palette/model';
import { DialogContainer } from '../ui/dialog-container';
import { Title } from '../ui/title';
import {
  $fileName,
  $isShowPaletteDialog,
  fileNameCleared,
  fileNameEntered,
  paletteDialogClosed,
  randomPaletteGenerated,
} from './model';
import { useCreatePalette } from './model/queries/useCreatePalette';

import styles from './styles.module.css';

export const PaletteCreationDialog = () => {
  const isShowPaletteDialog = useUnit($isShowPaletteDialog);
  const palette = useUnit($palette);
  const name = useUnit($fileName);

  const location = useLocation();

  const getBoardId = (location: Location<unknown>) => {
    const match = matchPath(location.pathname, {
      path: '/board/:id',
      exact: true,
      strict: false,
    });

    return match?.isExact ? match.params.id : undefined;
  };

  const { createPalette } = useCreatePalette();

  const savePalette = () => {
    createPalette({
      board: getBoardId(location),
      swatches: palette.swatches,
      name: name ? name : undefined,
    });
    paletteDialogClosed();
  };

  return (
    <Modal showModal={isShowPaletteDialog} handleClose={paletteDialogClosed}>
      <DialogContainer>
        <Title
          title="Create a palette file"
          description="Select 2 to 5 swatches to create a palette file and use in your projects."
        />
        <Palette isShowColorCodes={false} />
        <AssistChip
          style="outlined"
          leadingIcon
          icon={<Icon name="sprite/vai" className={styles.icon} />}
          onClick={() => randomPaletteGenerated()}
        >
          Generate random palette
        </AssistChip>
        <div className={styles.name}>
          <TypographyPoppins
            type="label"
            labelSize="M"
            className={styles.label}
          >
            File name (optional)
          </TypographyPoppins>
          <TextField
            className={styles.textField}
            value={name}
            onChange={(e) => fileNameEntered(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                savePalette();
              }
            }}
            clear={() => fileNameCleared()}
          />
        </div>
        <div className={styles.actions}>
          <Button
            type="outlined"
            label="Cancel"
            onClick={() => paletteDialogClosed()}
          />
          <Button autoFocus type="filled" label="Save" onClick={savePalette} />
        </div>
      </DialogContainer>
    </Modal>
  );
};
