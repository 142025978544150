import { Dispatch, useState } from 'react';

import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import { Board } from '@visualist/design-system';
import {
  Divider,
  SideSheet,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import { ResponseInvitee, UserInvitation } from '@api/boards';
import { PermissionsHub } from '@api/hubs';
import { createBoardShare, deleteShare } from '@api/share';
import { getInviteList } from '@api/users';
import {
  $isShareSheetOpen,
  shareSideSheetClosed,
} from '@pages/BoardPage/model';
import { useInviteToBoard } from '@pages/BoardPage/model/queries/useInviteToBoard';
import { useAppData } from '@src/AppContext';
import { MEMBERS_SUGGESTIONS_QUERY } from '@src/shared/constants/query-names';
import { showingDemoWarning } from '@src/widgets/onboarding/model';
import { useQuery } from '@tanstack/react-query';

import { Description } from './Description';
import { ExtraSeats } from './ExtraSeats';
import { GuestSeat } from './GuestSeat';
import { Header } from './Header';
import { Invite } from './Invite';
import { Invitees } from './Invitees';
import { InviteField } from './InviteField';
import { Links } from './Links';
import { ExistingUser } from './User/ExistingUser';

import styles from './styles.module.css';

type Props = {
  setBoardShare: Dispatch<any>;
  boardId: string;
  boardShare: any;
  subBoards: Board[];
  users: UserInvitation[];
  permissions: PermissionsHub[];
  setPermissions: Dispatch<PermissionsHub[]>;
  invitation: UserInvitation;
  invitees: ResponseInvitee;
  setInvitation: Dispatch<UserInvitation>;
  setUsers: Dispatch<UserInvitation[]>;
  boardName: string;
  nestedBoards: string[];
  isBoardInHub: boolean;
};

export const BoardShareSheet = ({
  setBoardShare,
  boardId,
  boardShare,
  subBoards,
  users,
  permissions,
  setPermissions,
  invitation,
  invitees,
  setInvitation,
  setUsers,
  boardName,
  nestedBoards,
  isBoardInHub,
}: Props) => {
  const [isInvite, setInvite] = useState(true);

  const isShareSheetOpen = useUnit($isShareSheetOpen);

  const closeSheet = () => shareSideSheetClosed();

  const { data: suggestions = [] } = useQuery({
    queryKey: [MEMBERS_SUGGESTIONS_QUERY, boardId, invitation.email],
    queryFn: () => getInviteList({ email: invitation.email, boardId }),
    enabled: invitation.email.length > 0 && users.length === 0,
  });

  const maxRemainingNumberSeats = invitees?.member_seats_available;

  const seats = maxRemainingNumberSeats === 1 ? 'seat' : 'seats';
  const guests = users.length === 1 ? 'guest' : 'guests';

  const numberRemainingSeats = maxRemainingNumberSeats - users.length;
  const remainingSeats = numberRemainingSeats === 1 ? 'seat' : 'seats';

  const isNotEnoughSeats = maxRemainingNumberSeats < users.length;

  const onSuccess = () => {
    if (users.length > 0) {
      clear();
    }

    setInvite(true);
  };

  const onError = () => {
    setInvite(true);
  };

  const { sendInvitation } = useInviteToBoard({
    onSuccess,
    onError,
  });

  const { user } = useAppData();

  const handlePublishChange = async (
    published: boolean,
    allow_comments = true,
  ) => {
    if (user.meta?.onboarding?.demoMode) {
      showingDemoWarning();
      return;
    }
    if (published) {
      let nestedBoards: string[] = [];
      nestedBoards = subBoards.map((nestedBoard) => nestedBoard.id);
      const share = await createBoardShare(
        boardId,
        allow_comments,
        nestedBoards,
      );
      setBoardShare(share);
    } else {
      if (boardShare) {
        await deleteShare(boardShare.id);
        setBoardShare(null);
      }
    }
    // setIncludeAllSubBoards(published);
  };

  const clear = () => {
    if (users.length > 0) {
      setUsers([]);
    }

    setInvitation({
      email: '',
    });
  };

  const invite = () => {
    if (isInvite) {
      sendInvitation({
        boardId,
        users,
        boardName,
        nested_board: nestedBoards,
        permissions,
      });
      setInvite(false);
    }
  };

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      type="large"
      showSheet={isShareSheetOpen}
      handleBackdropClick={closeSheet}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={closeSheet}
      >
        Share this board
      </SideSheet.Heading>
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer>
          <Links
            boardShare={boardShare}
            handlePublishChange={handlePublishChange}
            isBoardInHub={isBoardInHub}
          />
          <div className={styles.inviteByEmail}>
            <Header
              title="Invite others"
              description="Invite clients and others as collaborators"
            />
            {users.length > 0 && (
              <GuestSeat
                permissions={permissions}
                setPermissions={setPermissions}
              />
            )}
            <InviteField
              isNotEnoughSeats={isNotEnoughSeats}
              suggestions={suggestions}
              invitation={invitation}
              setInvitation={setInvitation}
              users={users}
              setUsers={setUsers}
              clear={clear}
              isBoardInHub={isBoardInHub}
            />
            {suggestions.length > 0 && users.length === 0 && (
              <ul className={styles.suggestions}>
                {suggestions.map((user) => (
                  <ExistingUser
                    key={user.id}
                    user={user}
                    boardId={boardId}
                    boardName={boardName}
                    sendInvitation={sendInvitation}
                    permissions={permissions}
                    nestedBoards={nestedBoards}
                  />
                ))}
              </ul>
            )}
            {users.length > 0 && (
              <Invite isNotEnoughSeats={isNotEnoughSeats} invite={invite} />
            )}
            <Description
              numberGuests={users.length}
              maxRemainingNumberSeats={maxRemainingNumberSeats}
              seats={seats}
              guests={guests}
              numberRemainingSeats={numberRemainingSeats}
              remainingSeats={remainingSeats}
              isNotEnoughSeats={isNotEnoughSeats}
            />
            <ExtraSeats />
            <Divider className={styles.divider} type="short-line" />
            <Header
              title={isBoardInHub ? 'Already invited' : 'Only you have access'}
              description={
                isBoardInHub
                  ? 'Those invited will have permissions to all items nested in this space'
                  : ''
              }
            />
            <Invitees invitees={invitees} boardName={boardName} />
          </div>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <Tooltip
        style={{
          minWidth: 'max-content',
        }}
        parameter={{
          type: 'plain',
          description: 'Coming soon: Make selected boards private',
          position: 'top',
        }}
      >
        <SideSheet.BottomControls
          className={styles.private}
          mainType="outlined"
          hideCancel
          primaryLabel="Make private"
          icon={<Icon name="sprite/lock-closed" />}
          closeSideSheet={() => {}}
          mainClick={() => {}}
          isDisabled
        />
      </Tooltip>
    </SideSheet>
  );
};
