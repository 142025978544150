import { Dispatch } from 'react';

import { useLocation } from 'react-router-dom';

import { Link, LinkGroup, Logo, Navbar } from '@visualist/design-system';

import { NavRailMenu } from '@src/entities/navbar/ui';

import { useAppData } from '../../AppContext';

interface HeaderProps {
  authorized?: boolean;
  tipShow?: boolean;
  showDrawer?: boolean;
  setShowDrawer?: Dispatch<boolean>;
}

const Header = ({ showDrawer, setShowDrawer }: HeaderProps) => {
  const location = useLocation();
  const { user } = useAppData();

  const authorized = user && user.id;

  // Account is a full height width page so hide nav bar
  if (location.pathname.includes('/account/')) return null;

  if (authorized) {
    return (
      <NavRailMenu showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
    );
  }

  return (
    <div style={{ display: 'none' }}>
      <Navbar>
        <Link to="/home">
          <Logo />
        </Link>

        <LinkGroup>
          <p>Don’t have an account?</p>
          <a
            className="btn btn-gray"
            style={{ height: 40, marginLeft: 10 }}
            href="https://visualist.typeform.com/to/u5EXRbTk"
          >
            Try it free
          </a>
        </LinkGroup>
      </Navbar>
    </div>
  );
};

export default Header;
