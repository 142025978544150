import { Icon } from 'icons';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const EmptyEmailState = () => {
  return (
    <div className={styles.emailViewer}>
      <div className={styles.placeholderContainer}>
        <div className={styles.placeholderInnerContainer}>
          <Icon name="sprite/team-soft-colored" size={80} />
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.placeholderText}
          >
            Let Vai help you streamline client
            <br />
            communications with automated summaries
            <br />
            and action items.
          </TypographyPoppins>
        </div>
      </div>
    </div>
  );
};
