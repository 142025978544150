import { Icon } from 'icons';

import {
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const VaiModal = ({
  children,
  footer,
  closeModal,
  showModal,
  footerHeight,
  ...rest
}: React.PropsWithChildren<
  {
    showModal: boolean;
    closeModal: () => void;
    footer?: React.ReactNode;
    footerHeight?: number;
  } & React.ComponentProps<'div'>
>) => {
  return (
    <Modal
      showModal={showModal}
      handleClose={closeModal}
      className={styles.modal}
      isVai
    >
      <div className={styles.shufflerContainer} id={rest.id} {...rest}>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <Icon name="sprite/vai" />
            <TypographyPoppins
              type="title"
              titleSize="M"
              className={styles.headerText}
            >
              Vai, your AI assistant
            </TypographyPoppins>
          </div>
          {/* TODO use actual X icon */}
          <button
            tabIndex={0}
            className={styles.closeButton}
            onClick={closeModal}
          >
            <Icon
              name="sprite/plus"
              style={{
                transform: 'rotate(45deg)',
              }}
            />
          </button>
        </div>
        <div className={styles.swipeContainer}>{children}</div>
        <div
          className={styles.footer}
          style={
            {
              '--height': `${footerHeight}px`,
            } as React.CSSProperties
          }
        >
          {footer}
        </div>
      </div>
    </Modal>
  );
};
