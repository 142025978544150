import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useState,
} from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';
import { Tooltip } from '../Tooltip';

import styles from './styles.module.css';

type TextFieldProps = ComponentPropsWithoutRef<'input'> & {
  placeholder?: string;
  label?: string;
  value: string;
  supportingText?: string;
  error?: string | boolean;
  errorAndSupportingText?: string | boolean;
  infoText?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  clear?: (e: MouseEvent<HTMLButtonElement>) => void;
  leadingIcon?: ReactNode;
  inputChips?: ReactNode[] | null;
  isDisabled?: boolean;
  hideClear?: boolean;
  isFocused?: boolean;
  inputStyle?: string;
};

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      placeholder,
      supportingText,
      error,
      errorAndSupportingText,
      onChange,
      value,
      onKeyDown,
      clear,
      leadingIcon,
      inputChips = [],
      isDisabled,
      hideClear = false,
      isFocused = false,
      type,
      infoText,
      inputStyle,
      ...props
    },
    ref,
  ) => {
    const [inputType, setInputType] = useState(type);

    return (
      <>
        <div
          className={cn(styles.textField, props.className, {
            [styles.textFieldError]: error || errorAndSupportingText,
            [styles.disabled]: isDisabled,
          })}
        >
          {leadingIcon && (
            <div className={styles.leadingElement}>{leadingIcon}</div>
          )}
          <div
            className={cn(styles.content, {
              [styles.label]: label,
            })}
          >
            {label && (
              <TypographyPoppins
                type="body"
                bodySize="S"
                className={styles.labelText}
              >
                {label}
              </TypographyPoppins>
            )}

            <ul className={styles.inputChipsList}>
              {inputChips && inputChips}
              <input
                {...props}
                ref={ref}
                className={cn(styles.input, inputStyle)}
                placeholder={
                  inputChips && inputChips.length > 0 ? '' : placeholder
                }
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                disabled={isDisabled}
                autoFocus={isFocused}
                type={inputType}
              />
            </ul>
          </div>
          {(value.trim() || (inputChips && inputChips.length > 0)) &&
            !hideClear &&
            !error &&
            type !== 'password' &&
            !errorAndSupportingText &&
            clear && (
              <button
                className={styles.trailingElement}
                onClick={clear}
                type="button"
              >
                <Icon name="sprite/x" size={16} />
              </button>
            )}
          {type === 'password' && (
            <button
              className={styles.trailingElement}
              onClick={() => {
                if (inputType === 'password') {
                  setInputType('text');
                } else if (inputType === 'text') {
                  setInputType('password');
                }
              }}
              type="button"
            >
              {inputType === 'password' ? (
                <Icon name="sprite/eye-closed" size={16} />
              ) : (
                <Icon name="sprite/eye-opened" size={16} />
              )}
            </button>
          )}
          {(error || errorAndSupportingText) && type !== 'password' ? (
            <button className={styles.trailingElement} type="button">
              <Icon
                name="sprite/information"
                size={16}
                className={styles.warning}
              />
            </button>
          ) : infoText ? (
            <button className={styles.trailingElement} type="button">
              <Tooltip
                parameter={{
                  type: 'plain',
                  description: infoText,
                  position: 'left',
                }}
              >
                <Icon
                  name="sprite/information"
                  size={16}
                  className={styles.info}
                />
              </Tooltip>
            </button>
          ) : null}
        </div>
        {(supportingText || errorAndSupportingText) && (
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={cn(styles.supportingText, {
              [styles.supportingTextError]: errorAndSupportingText,
            })}
          >
            {errorAndSupportingText ? errorAndSupportingText : supportingText}
          </TypographyPoppins>
        )}
      </>
    );
  },
);
