import React, { useEffect } from 'react';

/**
 *
 * Hook to use local storage that returns a value and a setter function
 *
 * @param key
 * @param initialValue
 * @returns [value, setValue]
 */

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      window.dispatchEvent(new Event('localStorageChange'));
    } catch (error) {
      console.error(error);
    }
  };

  const reset = () => {
    setValue(initialValue);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        setStoredValue(JSON.parse(window.localStorage.getItem(key) || '{}'));
      } catch (e) {
        console.error(e);
        setStoredValue(initialValue);
      }
    };

    window.addEventListener('localStorageChange', handleStorageChange);

    return () => {
      window.removeEventListener('localStorageChange', handleStorageChange);
    };
  }, [setStoredValue, key]);

  return [storedValue, setValue, reset] as const;
};
