import { Spinner } from '@visualist/design-system/src/components/v2';

import { useMessage } from '@pages/messages/hooks/useMessage';

import { EmailContent } from '../content';
import { EmptyEmailState } from '../empty-view';

import styles from './styles.module.css';

export const EmailViewer = ({
  hubId,
  threadId,
}: {
  hubId?: string;
  threadId?: string;
}) => {
  if (!hubId) {
    return (
      <div
        className={styles.emailViewer}
        style={{
          padding: '16px',
        }}
      >
        No hub id...
      </div>
    );
  }

  if (!threadId) {
    return <EmptyEmailState />;
  }

  return <EmailContentLoader hubId={hubId} threadId={threadId} />;
};

const EmailContentLoader = ({
  hubId,
  threadId,
}: {
  hubId: string;
  threadId: string;
}) => {
  const { messageQuery } = useMessage({ hubId, threadId });
  const { threads, threadQuery } = useMessage({ hubId, threadId });

  if (messageQuery.isLoading || threadQuery.isLoading) {
    return (
      <div className={styles.emailViewer}>
        <div className={styles.emailViewerSpinner}>
          <Spinner />
        </div>
      </div>
    );
  }

  if (messageQuery.isError) {
    return (
      <div className={styles.emailViewer}>
        Error message: {messageQuery.error.message}
      </div>
    );
  }

  if (threadQuery.isError) {
    return (
      <div className={styles.emailViewer}>
        Error thread: {threadQuery.error.message}
      </div>
    );
  }

  if (!messageQuery.data || !threads) {
    return <div className={styles.emailViewer}>No message data</div>;
  }

  return (
    <div className={styles.emailViewer}>
      <EmailContent
        hubId={hubId}
        thread={messageQuery.data}
        threads={threads}
      />
    </div>
  );
};
