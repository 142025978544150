import { useEffect, useState } from 'react';

import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';
//@ts-ignore
import { NavLink, useLocation } from 'react-router-dom';

import { Board } from '@visualist/design-system';
import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { ReactComponent as IconChevronRight } from './chevron-right.svg';
import { ResponseDocs } from './DocsSection';
import { ParentBoardsSection } from './ParentBoardsSection';

import styles from './styles.module.css';

export interface Hub {
  block_count: number;
  created_by: number;
  description: string;
  id: string;
  is_removed: boolean;
  logo: string;
  name: string;
}

interface HubsSectionProps {
  name: string;
  id: string;
  boardsLevel1: Array<Board>;
  drawerShow?: boolean;
  listItemClickHandler?: () => void;
  closeButtonClickHandler: () => void;
  docs?: ResponseDocs;
  docsDotMenuNavrail: string;
  docIdPassed: (id: string) => void;
  docsDotMenuNavrailClosed: () => void;
  docRenamingDialogOpened: (docName: string) => void;
  deleteDocModalNavrailOpened: () => void;
  docNamePassed: (docName: string) => void;
  isExpandHubsSection: boolean;
}

export const HubsSection = ({
  name,
  id,
  drawerShow,
  listItemClickHandler,
  boardsLevel1,
  closeButtonClickHandler,
  // docs,
  // docsDotMenuNavrail,
  // docIdPassed,
  // docsDotMenuNavrailClosed,
  // docRenamingDialogOpened,
  // deleteDocModalNavrailOpened,
  // docNamePassed,
  isExpandHubsSection,
}: HubsSectionProps) => {
  const [openHub, setOpenHub] = useState(false);
  const [isExpandBoardsSection, setExpandBoardsSection] = useState(false);
  // const [isExpandDocsSection] = useState(true);

  const location = useLocation();

  const activeItemId = location.pathname.split('/')[2];

  useEffect(() => {
    !drawerShow && setOpenHub(false);
  }, [drawerShow]);

  const openButtonClickHandler = () => {
    setOpenHub(!openHub);
    setExpandBoardsSection(true);
  };

  return (
    <div
      className={cn(styles.drawerItemHub, {
        [styles.collapsed]: !isExpandHubsSection,
      })}
    >
      <div className={styles.drawerTabWrapper}>
        <div
          className={cn(styles.drawerTab, styles.trailingIcon, {
            [styles.drawerTabActive]: openHub,
          })}
        >
          <NavLink
            to={`/h/${id}`}
            className={styles.linkHub}
            onClick={listItemClickHandler}
          >
            <TypographyPoppins
              type="label"
              labelSize="L"
              className={cn(styles.hubName, {
                [styles.activeName]: activeItemId === id,
              })}
            >
              {name}
            </TypographyPoppins>
          </NavLink>
          <button
            className={styles.openTabButton}
            onClick={openButtonClickHandler}
          >
            <IconChevronRight className={styles.iconChevronHub} />
          </button>
        </div>
      </div>
      <ul
        className={cn(styles.drawerList, {
          [styles.drawerListOpen]: openHub,
        })}
      >
        {boardsLevel1.length > 0 &&
          boardsLevel1
            .filter((board) => board.hub !== null && board.hub.id === id)
            .map((board) => (
              <ParentBoardsSection
                key={board.id}
                boardIdLevel1={board.id}
                name={board.name}
                drawerShow={drawerShow}
                listItemClickHandler={() =>
                  isMobileOnly && closeButtonClickHandler()
                }
                boardsLevel1={boardsLevel1}
                openHub={openHub}
                isExpandBoardsSection={isExpandBoardsSection}
              />
            ))}
        {/* {docs &&
          docs.results.length > 0 &&
          docs.results
            .filter((doc) => doc.hub !== null && doc.hub.id === id)
            .map(({ id, title }) => (
              <DocsSection
                key={id}
                title={title}
                docId={id}
                listItemClickHandler={() =>
                  isMobileOnly && closeButtonClickHandler()
                }
                docsDotMenuNavrail={docsDotMenuNavrail}
                docIdPassed={docIdPassed}
                docsDotMenuNavrailClosed={docsDotMenuNavrailClosed}
                docRenamingDialogOpened={docRenamingDialogOpened}
                deleteDocModalNavrailOpened={deleteDocModalNavrailOpened}
                docNamePassed={docNamePassed}
                isExpandDocsSection={isExpandDocsSection}
              />
            ))} */}
      </ul>
    </div>
  );
};
