import { useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { useFloating } from '@floating-ui/react';

import styles from './styles.module.css';

interface BoardProps {
  name: string;
  id: string;
  boardIdAddedCopy: string;
  blockId: string;
}

export const Board = ({ name, boardIdAddedCopy, id, blockId }: BoardProps) => {
  const [isShowTooltip, setShowTooltip] = useState(false);

  const history = useHistory();

  const { refs, floatingStyles } = useFloating({
    open: isShowTooltip,
    onOpenChange: setShowTooltip,
    placement: 'left',
  });

  return (
    <li
      className={cn(styles.board, {
        [styles.boardMobile]: isMobileOnly,
      })}
    >
      <IconButton
        ref={refs.setReference}
        type="unfilled"
        className={styles.iconButton}
        icon={<Icon name="sprite/board" className={styles.icon} />}
        isSelected={boardIdAddedCopy === id}
        onClick={() => history.replace(`/board/${id}/#/block/${blockId}`)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      />
      <TypographyPoppins type="label" labelSize="XS" className={styles.name}>
        {name}
      </TypographyPoppins>

      {isShowTooltip && boardIdAddedCopy === id && (
        <div ref={refs.setFloating} style={floatingStyles}>
          <span className={styles.tooltip}>
            <TypographyPoppins type="body" size="S">
              You're viewing the copy that's in this board
            </TypographyPoppins>
          </span>
        </div>
      )}

      {isShowTooltip && boardIdAddedCopy !== id && (
        <div ref={refs.setFloating} style={floatingStyles}>
          <span className={styles.tooltip}>
            <TypographyPoppins type="body" size="S">
              Switch to see copy in this board
            </TypographyPoppins>
          </span>
        </div>
      )}
    </li>
  );
};

export const BoardSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      {/* <div className={styles.skeletonGleam}></div> */}
    </div>
  );
};
