import React from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import {
  IconButton,
  PaperPlaneIcon,
  Tooltip,
} from '@visualist/design-system/src/components/v2';

import useStickies from '@src/shared/queries/useStickies';

import { StickyType } from '../types';

import styles from './styles.module.css';

export const ReplyInput = (props: { blockId: string; sticky: StickyType }) => {
  const [inputValue, setInputValue] = React.useState('');
  const [makeActionable, setMakeActionable] = React.useState(false);

  const { mutateReply } = useStickies(props.blockId, props.sticky.id);

  return (
    <div className={styles.threadContainer}>
      <form
        className={styles.threadInputContainer}
        onSubmit={async (e) => {
          e.preventDefault();
          mutateReply.mutate({
            text: inputValue,
            stickyId: props.sticky.id,
            makeAction: makeActionable,
          });
          setMakeActionable(false);
          setInputValue('');
        }}
      >
        <label hidden>Add a comment</label>
        <Textarea
          placeholder="Add a reply"
          type="text"
          name="comment"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              if (inputValue) {
                e.currentTarget.form?.dispatchEvent(
                  new Event('submit', { bubbles: true }),
                );
              }
            }
          }}
        />
        <InputOptions
          sticky={props.sticky}
          blockId={props.blockId}
          isButtonActive={!!inputValue}
          isAddingComment={mutateReply.isPending}
          makeActionable={makeActionable}
          setMakeActionable={setMakeActionable}
        />
      </form>
    </div>
  );
};

type InputProps = React.InputHTMLAttributes<HTMLTextAreaElement>;

const Textarea = React.forwardRef<HTMLTextAreaElement, InputProps>(
  ({ className, ...props }, ref) => {
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useImperativeHandle(
      ref,
      () => {
        return textAreaRef.current as HTMLTextAreaElement;
      },
      [],
    );

    React.useLayoutEffect(() => {
      if (textAreaRef.current) {
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        textAreaRef.current.style.height = '0px';
        const scrollHeight = textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = scrollHeight + 'px';
      }
    }, [props.value]);

    return (
      <textarea
        rows={8}
        ref={textAreaRef}
        className={cn(styles.textarea, className)}
        {...props}
      />
    );
  },
);

type InputOptionsProps = {
  isButtonActive: boolean;
  isAddingComment: boolean;
  sticky: StickyType;
  blockId: string;
  makeActionable: boolean;
  setMakeActionable: React.Dispatch<React.SetStateAction<boolean>>;
};

const InputOptions = (props: InputOptionsProps) => {
  const { isButtonActive, isAddingComment } = props;
  const [animateButton, setAnimateButton] = React.useState(false);
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  if (isAddingComment && !animateButton) {
    // Set this state on render to animate the button atleast once when submitting a comment
    setAnimateButton(true);
    timeoutRef.current = setTimeout(() => {
      setAnimateButton(false);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    }, 1000);
  }

  // TODO: When isActionable is true make created comment an actionable comment.

  return (
    <div className={styles.optionsContainer}>
      <div className={styles.optionContainer}>
        {/* Hidden buttons until we add them. */}
        {/* <button className={styles.optionButton}>
          <TypographyPoppins
            type="body"
            bodySize="M"
            className={styles.richTextLabel}
          >
            Aa
          </TypographyPoppins>
        </button>
        <button type="button" className={styles.optionButton}>
          <EmojiIcon height={20} width={20} />
        </button>
        <button type="button" className={styles.optionButton}>
          <AtIcon height={20} width={20} fill="transparent" />
        </button> */}
        <Tooltip
          parameter={{
            type: 'plain',
            description: props.makeActionable
              ? "Don't mark for action"
              : 'Mark for action',
            position: 'right',
            centerText: true,
          }}
        >
          <IconButton
            icon={<Icon name="sprite/checkbox" />}
            onClick={(e) => {
              e.preventDefault();
              props.setMakeActionable((p) => !p);
            }}
            type={props.makeActionable ? 'primary' : 'unfilled'}
          />
        </Tooltip>
      </div>
      <div className={styles.sendButtonContainer}>
        <Tooltip
          parameter={{
            type: 'plain',
            description: 'Send now',
            position: 'top',
          }}
          className={styles.tooltip}
        >
          <button
            type="submit"
            aria-disabled={!isButtonActive}
            className={cn(styles.sendButton, {
              [styles.sendButtonActive]: isButtonActive || animateButton,
              [styles.disabledButton]: !isButtonActive,
            })}
            disabled={!isButtonActive}
          >
            <PaperPlaneIcon
              data-animate={animateButton ? 'animate' : 'idle'}
              height={20}
              width={20}
              style={{
                transform: 'translateY(2px)',
              }}
              color="white"
              fill="transparent"
            />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};
