import { Icon } from 'icons';

import {
  Divider,
  TypographyItalic,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  name: string;
};

export const Details = ({ name }: Props) => {
  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <TypographyPoppins className={styles.name} type="title" titleSize="M">
            {name}
          </TypographyPoppins>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>

        <div className={styles.details}>
          {/* <div className={styles.content}>
            <div className={styles.people}>
              <div className={styles.team}>
                <Team className={styles.icon} fill="none" />
              </div>
              <div className={styles.ownerAndClients}>
                {invitees?.members.map((member) => (
                  <Avatar
                    key={member.id}
                    image={member.user?.photo.full_size}
                    initials={
                      member.user
                        ? `${member.user.first_name} ${member.user.last_name}`
                        : member.email
                    }
                    size={20}
                  />
                ))}
              </div>
            </div>
            <AssistChip
              className={styles.invite}
              style="outlined"
              onClick={() => shareSideSheetOpened()}
            >
              Invite
            </AssistChip>
          </div> */}
          <div className={styles.description}>
            <Icon className={styles.icon} name="sprite/information" />
            <TypographyItalic
              type="body"
              formattingBody="medium light italic"
              customStyle={styles.text}
            >
              write a short description of this project
            </TypographyItalic>
          </div>
          <div className={styles.location}>
            <Icon name="sprite/location-pin" className={styles.icon} />
            <TypographyItalic
              type="body"
              formattingBody="medium light italic"
              customStyle={styles.text}
            >
              add location
            </TypographyItalic>
          </div>
        </div>
      </div>
    </li>
  );
};
