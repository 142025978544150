import { Icon } from 'icons';

import {
  Button,
  Divider,
  TypographyPoppins,
  VailFilledColored,
} from '@visualist/design-system/src/components/v2';

import { openedVaiTagModal } from '@src/widgets/VaiTagModal/model';

import styles from './styles.module.css';

export const VaiCard = () => {
  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <VailFilledColored fill="none" />
          <TypographyPoppins
            className={styles.color}
            type="title"
            titleSize="M"
          >
            Vai
          </TypographyPoppins>
        </div>
        <div className={styles.horizontal}>
          <Divider className={styles.divider} type="long-line" />
        </div>
        <div className={styles.emptyState}>
          <Icon name="sprite/palette-soft-colored" size={80} />
          <TypographyPoppins
            className={styles.content}
            type="body"
            bodySize="S"
          >
            {`Vai is your copilot—make use 
              of automations and reminders 
              to take back your time`}
          </TypographyPoppins>
          <Button
            className={styles.actionButton}
            type="outlined"
            label="Teach Vai your style"
            onClick={() => openedVaiTagModal()}
          />
        </div>
      </div>
    </li>
  );
};
