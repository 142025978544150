import { Icon, IconProps } from './Icon';

export const DocFilled = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9002 2C15.2224 2 15.5249 2.15529 15.7127 2.41714L18.8126 6.73871C18.9345 6.90862 19 7.11246 19 7.32156V21V22H18H6H5V21V3V2H6H14.9002Z"
        fill="black"
      />
      <path d="M7 11L12 11" stroke="white" />
      <path d="M7 13L14 13" stroke="white" />
      <path d="M7 15H17" stroke="white" />
      <mask
        id="mask0_15893_5841"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="14"
        y="2"
        width="6"
        height="6"
      >
        <path d="M15 2.2L16 3H18L19.5 4V8H14V2L15 2.2Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15893_5841)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5 2V7V7.5H15H18.5V6.5H15.5V2H14.5Z"
          fill="white"
        />
      </g>
    </Icon>
  );
};
