import React, { ReactNode, useCallback, useMemo } from 'react';

import { Icon } from 'icons';

import { EditorState } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { BubbleMenu as BaseBubbleMenu } from '@tiptap/react';
import { Editor } from '@tiptap/react';

import { OptionsMenu } from './options';
import { isColumnGripSelected } from './utils';

export const TableColumnMenu = React.memo(
  ({ editor, appendTo }: { editor: Editor; appendTo: any }): JSX.Element => {
    const shouldShow = useCallback(
      ({
        view,
        state,
        from,
      }: {
        view: EditorView;
        state?: EditorState;
        from?: number;
      }) => {
        if (!state) {
          return false;
        }

        return isColumnGripSelected({ editor, view, state, from: from || 0 });
      },
      [editor],
    );

    const onAddColumnBefore = useCallback(() => {
      editor.chain().focus().addColumnBefore().run();
    }, [editor]);

    const onAddColumnAfter = useCallback(() => {
      editor.chain().focus().addColumnAfter().run();
    }, [editor]);

    const onDeleteColumn = useCallback(() => {
      editor.chain().focus().deleteColumn().run();
    }, [editor]);

    const menuItems = useMemo(
      () => [
        {
          icon: (
            <Icon
              name="sprite/doc-table-add"
              style={{
                transform: 'rotate(270deg)',
              }}
            />
          ) as ReactNode,
          tooltipText: 'Add column to left',
          onClick: onAddColumnBefore,
        },
        {
          icon: (
            <Icon
              name="sprite/doc-table-add"
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          ) as ReactNode,
          tooltipText: 'Add column to right',
          onClick: onAddColumnAfter,
        },
        {
          icon: (
            <Icon name="sprite/bin" color="var(--color-error-40)" />
          ) as ReactNode,
          tooltipText: 'Delete column',
          onClick: onDeleteColumn,
        },
      ],
      [editor],
    );

    return (
      <BaseBubbleMenu
        editor={editor}
        pluginKey="tableColumnMenu"
        updateDelay={0}
        tippyOptions={{
          appendTo: () => {
            return appendTo?.current;
          },
          offset: [0, 15],
          popperOptions: {
            modifiers: [{ name: 'flip', enabled: false }],
          },
        }}
        shouldShow={shouldShow}
      >
        <OptionsMenu options={menuItems} />
      </BaseBubbleMenu>
    );
  },
);

TableColumnMenu.displayName = 'TableColumnMenu';
