import { ChangeEvent, Dispatch, KeyboardEvent, Ref, useEffect } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import { Input } from '@visualist/design-system';

import { HubResponse } from '../../api/hubs';
import { AddToHub } from '../../pages/BoardPage/components/AddToHub';
import { HubsSearch } from './HubsSearch';
import {
  $isAddingAndCreatingBoardDialogOpen,
  addingBoardToHubDialogClosed,
} from './model';

import styles from './styles.module.css';

interface BoardDialogProps {
  isMobileOnly: boolean;
  renamingBoard: string;
  inputRef: Ref<HTMLInputElement>;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  name: string;
  selectRow: number | null;
  onHandleChangeHubs: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHubs: (e: any) => void;
  onRemoveFocus: (e: KeyboardEvent<HTMLInputElement>) => void;
  addingBoardToHubDialog: boolean;
  hubName: string;
  hubsSuggestions?: HubResponse[];
  selectHub: (hubName: string, id: string) => void;
  isBoardNotPartOfHub: boolean;
  autoFillHubName?: string;
  hubId?: string;
  parentBoardId?: string;
  currentHubId: string;
  isLevelOneBoard: boolean;
  onBlur: () => void;
  setSelectRow: Dispatch<number | null>;
}

export const BoardDialog = ({
  handleOnKeyPress,
  inputRef,
  isMobileOnly,
  onHandleChange,
  renamingBoard,
  name,
  selectRow,
  onHandleChangeHubs,
  onKeyDownHubs,
  onRemoveFocus,
  addingBoardToHubDialog,
  hubName,
  hubsSuggestions,
  selectHub,
  isBoardNotPartOfHub,
  autoFillHubName,
  hubId,
  parentBoardId,
  currentHubId,
  isLevelOneBoard,
  onBlur,
  setSelectRow,
}: BoardDialogProps) => {
  const isAddingAndCreatingBoardDialogOpen = useUnit(
    $isAddingAndCreatingBoardDialogOpen,
  );

  useEffect(() => {
    if ((isBoardNotPartOfHub || autoFillHubName) && !currentHubId) {
      addingBoardToHubDialogClosed();
    }
  }, [isBoardNotPartOfHub, autoFillHubName, currentHubId]);

  return (
    <>
      {!renamingBoard && !isAddingAndCreatingBoardDialogOpen && (
        <div className={styles.alignment}>
          <AddToHub
            hubName={autoFillHubName}
            hubId={hubId}
            boardId={parentBoardId}
            isBoardNotPartOfHub={isBoardNotPartOfHub}
            isLevelOneBoard={isLevelOneBoard}
          />
        </div>
      )}

      {isAddingAndCreatingBoardDialogOpen && (
        <HubsSearch
          selectRow={selectRow}
          onHandleChangeHubs={onHandleChangeHubs}
          onKeyDownHubs={onKeyDownHubs}
          onRemoveFocus={onRemoveFocus}
          addingBoardToHubDialog={addingBoardToHubDialog}
          hubName={hubName}
          hubsSuggestions={hubsSuggestions}
          selectHub={selectHub}
          onBlur={onBlur}
          setSelectRow={setSelectRow}
        />
      )}
      <div
        className={cn(styles.input, {
          [styles.inputMobile]: isMobileOnly,
        })}
      >
        {!renamingBoard && <Icon name="sprite/board" />}
        <Input
          className={cn(styles.input, {
            [styles.inputRenaming]: renamingBoard,
          })}
          type="text"
          ref={inputRef}
          name="name"
          placeholder={renamingBoard ? '' : 'Name your board...'}
          customLabel="Board name"
          value={name}
          onChange={onHandleChange}
          onKeyPress={handleOnKeyPress}
        />
      </div>
    </>
  );
};
