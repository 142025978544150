import { useRef, useState } from 'react';

import { FormikProps, useFormik } from 'formik';
import { Icon } from 'icons';

import { UserPersonalisationType, UserType } from '@visualist/design-system';
import {
  Dropdown,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { useGoogleAutocomplete } from '@src/shared/hooks/use-google-autocomplete';

import {
  ACQUISITION_CHANNEL_OPTIONS,
  AVERAGE_FEE_OPTIONS,
  CREATIVE_FIELD_OPTIONS,
  TEAM_SIZE_OPTIONS,
  USE_CASE_OPTIONS,
} from './constants';

import styles from './styles.module.css';

type FormValues = {
  active_since: string;
  location: string;
  creative_field: string;
  creative_field_other: string;
  team_size: string;
  average_fee: string;
  project_number: string;
  use_case: string;
  acquisition_channel: string;
  acquisition_channel_other: string;
};

export const PersonalizationFields = ({
  user,
  updatePersonalizeFields,
}: {
  user: UserType;
  updatePersonalizeFields: (user: UserPersonalisationType) => void;
}) => {
  const [creativeField, setCreativeField] = useState(user.creative_field ?? '');
  const [teamSize, setTeamSize] = useState(user.team_size ?? '');
  const [averageFee, setAverageFee] = useState(user.average_fee ?? '');
  const [useCase, setUseCase] = useState(user.use_case ?? '');
  const [acquisitionChannel, setAcquisitionChannel] = useState(
    user.acquisition_channel ?? '',
  );
  const formik = useFormik({
    initialValues: {
      active_since: user.active_since
        ? new Date(user.active_since).getFullYear().toString()
        : '',
      location: user.location ?? '',
      creative_field: user.creative_field ?? '',
      creative_field_other: user.creative_field_other ?? '',
      team_size: user.team_size ?? '',
      average_fee: user.average_fee ?? '',
      project_number: user.project_number ?? '',
      use_case: user.use_case ?? '',
      acquisition_channel: user.acquisition_channel ?? '',
      acquisition_channel_other: user.acquisition_channel_other ?? '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      const yearRegex = /^(19|20)\d{2}$/; // Accepts years from 1900 to 2099
      let activeSince = values.active_since;
      if (values.active_since && !yearRegex.test(values.active_since)) {
        activeSince = '';
        formik.setErrors({ active_since: 'Type a valid year' });
      }
      const formattedDate = activeSince
        ? `${activeSince}-01-01T00:00:00.000000Z`
        : null;
      updatePersonalizeFields({
        active_since: formattedDate,
        location: values.location.trim() || null,
        creative_field: creativeField || null,
        creative_field_other: values.creative_field_other || null,
        team_size: teamSize || null,
        average_fee: averageFee || null,
        project_number: values.project_number || null,
        use_case: useCase || null,
        acquisition_channel: acquisitionChannel || null,
        acquisition_channel_other: values.acquisition_channel_other || null,
      });
    },
  });

  const includedFields: Array<keyof UserPersonalisationType> = [
    'active_since',
    'location',
    'creative_field',
    'team_size',
    'average_fee',
    'project_number',
    'use_case',
    'acquisition_channel',
  ];
  const totalFields = includedFields.length;
  const filledFields = includedFields.filter((key) => {
    return Boolean(user[key]);
  }).length;
  const progressPercentage = Math.round((filledFields / totalFields) * 100);

  return (
    <div className={styles.personalizationFieldsContainer}>
      <form
        className={styles.personalizationForm}
        onSubmit={formik.handleSubmit}
      >
        <ProgressBar progressPercentage={progressPercentage} />
        <TypographyPoppins
          type="title"
          titleSize="M"
          className={styles.supportingText}
        >
          Fill in your details to help Vai personalize suggestions
        </TypographyPoppins>
        <ActiveSinceSection user={user} formik={formik} />
        <LocationSection user={user} formik={formik} />
        <CreativeFieldSection
          user={user}
          formik={formik}
          creativeField={creativeField}
          setCreativeField={setCreativeField}
        />
        <TeamSizeSection
          user={user}
          formik={formik}
          teamSize={teamSize}
          setTeamSize={setTeamSize}
        />
        <ProjectFeeSection
          user={user}
          formik={formik}
          averageFee={averageFee}
          setAverageFee={setAverageFee}
        />
        <ProjectCountSection user={user} formik={formik} />
        <UseCaseSection
          user={user}
          formik={formik}
          useCase={useCase}
          setUseCase={setUseCase}
        />
        <AcquisitionSection
          user={user}
          formik={formik}
          acquisitionChannel={acquisitionChannel}
          setAcquisitionChannel={setAcquisitionChannel}
        />
      </form>
    </div>
  );
};

const ProgressBar = ({
  progressPercentage,
}: {
  progressPercentage: number;
}) => {
  return (
    <div className={styles.personalizationProgressWrapper}>
      <div className={styles.progressBarWrapper}>
        <div
          className={styles.progressBar}
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <TypographyPoppins
        type="body"
        bodySize="M"
        className={styles.progressText}
      >
        Your profile is {progressPercentage}% complete
      </TypographyPoppins>
    </div>
  );
};

const ActiveSinceSection = ({
  user,
  formik,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
}) => {
  const submitActiveSince = () => {
    const activeSince = user.active_since
      ? new Date(user.active_since).getFullYear().toString()
      : '';
    if (activeSince !== formik.values.active_since) {
      formik.submitForm();
    }
  };
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="active_since">Active since</label>
        </TypographyPoppins>
        <TextField
          formNoValidate
          id="active_since"
          name="active_since"
          type="number"
          placeholder="Enter a year"
          value={formik.values.active_since + ''}
          onChange={formik.handleChange}
          onBlur={submitActiveSince}
          onKeyDown={(e) => {
            if (['e', 'E', '+', '-', '.'].includes(e.key)) {
              e.preventDefault();
            }
          }}
          errorAndSupportingText={formik.errors.active_since}
          clear={() => {
            formik.setFieldValue('active_since', '');
            formik.submitForm();
          }}
          inputStyle={styles.inputField}
        />
      </div>
    </div>
  );
};

const LocationSection = ({
  user,
  formik,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
}) => {
  const [location, setLocation] = useState(user.location ?? '');
  const [showLocationList, setShowLocationList] = useState(false);
  const { getPlacePredictions, placePredictions } = useGoogleAutocomplete({
    input: location,
  });
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="location">Location</label>
        </TypographyPoppins>
        <TextField
          formNoValidate
          id="location"
          name="location"
          type="text"
          placeholder="Enter a city or town"
          value={formik.values.location}
          onChange={formik.handleChange}
          onInput={(e) => {
            const target = e.target as HTMLInputElement;
            setShowLocationList(true);
            getPlacePredictions({ input: target.value });
          }}
          onBlur={() => {
            if (
              !showLocationList &&
              user.location !== (formik.values.location.trim() || null)
            ) {
              formik.submitForm();
            } else if (!showLocationList && !formik.values.location.trim()) {
              formik.setFieldValue('location', '');
            }
          }}
          errorAndSupportingText={formik.errors.location}
          clear={() => {
            formik.setFieldValue('location', '');
            formik.submitForm();
          }}
          inputStyle={styles.inputField}
        />
        <Dropdown open={showLocationList} modal={false}>
          <Dropdown.Menu
            side="bottom"
            sideOffset={0}
            alignOffset={0}
            align="start"
            density="-2"
          >
            {placePredictions.map(({ description, place_id }) => (
              <Dropdown.MenuItem
                key={place_id}
                item={{
                  content: description,
                  onClick: () => {
                    setLocation(description);
                    setShowLocationList(false);
                    if (user.location !== description) {
                      formik.setFieldValue('location', description || '');
                      formik.submitForm();
                    }
                  },
                  leadingIcon: (
                    <Icon
                      name="sprite/location-pin-filled"
                      color="var(--color-secondary-40)"
                    />
                  ),
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const CreativeFieldSection = ({
  user,
  formik,
  creativeField,
  setCreativeField,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
  creativeField: string;
  setCreativeField: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const creativeFieldOtherRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="creative_field">Creative field</label>
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            asChild
            trigger={
              <TextField
                formNoValidate
                id="creative_field"
                name="creative_field"
                type="text"
                placeholder="Interior design, wedding planning"
                value={
                  CREATIVE_FIELD_OPTIONS[
                    creativeField as keyof typeof CREATIVE_FIELD_OPTIONS
                  ] || ''
                }
                onChange={formik.handleChange}
                errorAndSupportingText={formik.errors.creative_field}
                clear={() => {
                  formik.setFieldValue('creative_field', '');
                  setCreativeField('');
                  formik.submitForm();
                }}
                inputStyle={styles.inputField}
              />
            }
            side="bottom"
            align="start"
            sideOffset={10}
            alignOffset={-12}
            density="-2"
          >
            {Object.entries(CREATIVE_FIELD_OPTIONS).map(([value, label]) => (
              <Dropdown.MenuItem
                key={value}
                item={{
                  content: label,
                  onClick: () => {
                    setCreativeField(value);
                    if (user.creative_field !== value) {
                      formik.setFieldValue('creative_field', label || '');
                      if (value !== 'other') {
                        formik.setFieldValue('creative_field_other', '');
                        formik.submitForm();
                      } else {
                        setTimeout(() => {
                          creativeFieldOtherRef.current?.focus();
                        }, 1);
                      }
                    }
                  },
                  trailingIcon: creativeField === value && (
                    <Icon name="sprite/tick" />
                  ),
                  isDivider: value === 'wedding_event_planning',
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {creativeField === 'other' && (
          <TextField
            required
            formNoValidate
            id="creative_field_other"
            ref={creativeFieldOtherRef}
            name="creative_field_other"
            type="text"
            placeholder="Please specify"
            value={formik.values.creative_field_other}
            onChange={formik.handleChange}
            onBlur={() => {
              if (!formik.values.creative_field_other.trim()) {
                formik.setErrors({
                  creative_field_other: 'Please specify creative field',
                });
              } else if (
                user.creative_field_other !==
                (formik.values.creative_field_other || null)
              ) {
                formik.submitForm();
              }
            }}
            errorAndSupportingText={formik.errors.creative_field_other}
            clear={() => {
              formik.setFieldValue('creative_field_other', '');
              creativeFieldOtherRef.current?.focus();
            }}
            className={styles.otherField}
            inputStyle={styles.inputField}
          />
        )}
      </div>
    </div>
  );
};

const TeamSizeSection = ({
  user,
  formik,
  teamSize,
  setTeamSize,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
  teamSize: string;
  setTeamSize: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="team_size">Team size</label>
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            asChild
            trigger={
              <TextField
                formNoValidate
                id="team_size"
                name="team_size"
                type="text"
                placeholder="Choose from options"
                value={
                  TEAM_SIZE_OPTIONS[
                    teamSize as keyof typeof TEAM_SIZE_OPTIONS
                  ] || ''
                }
                onChange={formik.handleChange}
                errorAndSupportingText={formik.errors.team_size}
                clear={() => {
                  formik.setFieldValue('team_size', '');
                  setTeamSize('');
                  formik.submitForm();
                }}
                inputStyle={styles.inputField}
              />
            }
            side="bottom"
            align="start"
            sideOffset={10}
            alignOffset={-12}
            density="-2"
          >
            {Object.entries(TEAM_SIZE_OPTIONS).map(([value, label]) => (
              <Dropdown.MenuItem
                key={value}
                item={{
                  content: label,
                  onClick: () => {
                    setTeamSize(value);
                    if (user.team_size !== value) {
                      formik.setFieldValue('team_size', label || '');
                      formik.submitForm();
                    }
                  },
                  trailingIcon: teamSize === value && (
                    <Icon name="sprite/tick" />
                  ),
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const ProjectFeeSection = ({
  user,
  formik,
  averageFee,
  setAverageFee,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
  averageFee: string;
  setAverageFee: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="average_fee">Typical project fee</label>
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            asChild
            trigger={
              <TextField
                formNoValidate
                id="average_fee"
                name="average_fee"
                type="text"
                placeholder="What you usually charge"
                value={
                  AVERAGE_FEE_OPTIONS[
                    averageFee as keyof typeof AVERAGE_FEE_OPTIONS
                  ] || ''
                }
                onChange={formik.handleChange}
                errorAndSupportingText={formik.errors.average_fee}
                clear={() => {
                  formik.setFieldValue('average_fee', '');
                  setAverageFee('');
                  formik.submitForm();
                }}
                inputStyle={styles.inputField}
              />
            }
            side="bottom"
            sideOffset={10}
            alignOffset={-12}
            align="start"
            density="-2"
          >
            {Object.entries(AVERAGE_FEE_OPTIONS).map(([value, label]) => (
              <Dropdown.MenuItem
                key={value}
                item={{
                  content: label,
                  onClick: () => {
                    setAverageFee(value);
                    if (user.average_fee !== value) {
                      formik.setFieldValue('average_fee', label || '');
                      formik.submitForm();
                    }
                  },
                  trailingIcon: averageFee === value && (
                    <Icon name="sprite/tick" />
                  ),
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const ProjectCountSection = ({
  user,
  formik,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="project_number">Number of live projects</label>
        </TypographyPoppins>
        <TextField
          formNoValidate
          id="project_number"
          name="project_number"
          type="number"
          placeholder="How many do you usually have?"
          value={formik.values.project_number + ''}
          onChange={formik.handleChange}
          onKeyDown={(e) => {
            if (['e', 'E', '+', '-', '.'].includes(e.key)) {
              e.preventDefault();
            }
          }}
          onBlur={() => {
            if (
              user.project_number !== (formik.values.project_number || null)
            ) {
              formik.submitForm();
            }
          }}
          errorAndSupportingText={formik.errors.project_number}
          clear={() => {
            formik.setFieldValue('project_number', '');
            formik.submitForm();
          }}
          inputStyle={styles.inputField}
        />
      </div>
    </div>
  );
};

const UseCaseSection = ({
  user,
  formik,
  useCase,
  setUseCase,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
  useCase: string;
  setUseCase: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="use_case">You’re using Visualist for</label>
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            asChild
            trigger={
              <TextField
                formNoValidate
                id="use_case"
                name="use_case"
                type="text"
                placeholder="Choose from options"
                value={
                  USE_CASE_OPTIONS[useCase as keyof typeof USE_CASE_OPTIONS] ||
                  ''
                }
                onChange={formik.handleChange}
                errorAndSupportingText={formik.errors.use_case}
                clear={() => {
                  formik.setFieldValue('use_case', '');
                  setUseCase('');
                  formik.submitForm();
                }}
                inputStyle={styles.inputField}
              />
            }
            side="bottom"
            sideOffset={10}
            alignOffset={-12}
            align="start"
            density="-2"
          >
            {Object.entries(USE_CASE_OPTIONS).map(([value, label]) => (
              <Dropdown.MenuItem
                key={value}
                item={{
                  content: label,
                  onClick: () => {
                    setUseCase(value);
                    if (user.use_case !== value) {
                      formik.setFieldValue('use_case', label || '');
                      formik.submitForm();
                    }
                  },
                  trailingIcon: useCase === value && (
                    <Icon name="sprite/tick" />
                  ),
                }}
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

const AcquisitionSection = ({
  user,
  formik,
  acquisitionChannel,
  setAcquisitionChannel,
}: {
  user: UserType;
  formik: FormikProps<FormValues>;
  acquisitionChannel: string;
  setAcquisitionChannel: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const acquisitionChannelOtherRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={styles.row}>
      <div className={styles.formInput}>
        <TypographyPoppins type="label" labelSize="M" className={styles.label}>
          <label htmlFor="acquisition_channel">
            How you heard about Visualist
          </label>
        </TypographyPoppins>
        <Dropdown>
          <Dropdown.Menu
            asChild
            trigger={
              <TextField
                formNoValidate
                id="acquisition_channel"
                name="acquisition_channel"
                type="text"
                placeholder="Let us know"
                value={
                  ACQUISITION_CHANNEL_OPTIONS[
                    acquisitionChannel as keyof typeof ACQUISITION_CHANNEL_OPTIONS
                  ] || ''
                }
                onChange={formik.handleChange}
                errorAndSupportingText={formik.errors.acquisition_channel}
                clear={() => {
                  formik.setFieldValue('acquisition_channel', '');
                  setAcquisitionChannel('');
                  formik.submitForm();
                }}
                inputStyle={styles.inputField}
              />
            }
            side="bottom"
            align="start"
            sideOffset={15}
            alignOffset={-12}
            density="-2"
          >
            {Object.entries(ACQUISITION_CHANNEL_OPTIONS).map(
              ([value, label]) => (
                <Dropdown.MenuItem
                  key={value}
                  item={{
                    content: label,
                    onClick: () => {
                      setAcquisitionChannel(value);
                      if (user.acquisition_channel !== value) {
                        formik.setFieldValue(
                          'acquisition_channel',
                          label || '',
                        );
                        if (value !== 'other') {
                          formik.setFieldValue('acquisition_channel_other', '');
                          formik.submitForm();
                        } else {
                          setTimeout(() => {
                            acquisitionChannelOtherRef.current?.focus();
                          }, 1);
                        }
                      }
                    },
                    trailingIcon: acquisitionChannel === value && (
                      <Icon name="sprite/tick" />
                    ),
                    isDivider: value === 'ad',
                  }}
                />
              ),
            )}
          </Dropdown.Menu>
        </Dropdown>
        {acquisitionChannel === 'other' && (
          <TextField
            required
            formNoValidate
            id="acquisition_channel_other"
            ref={acquisitionChannelOtherRef}
            name="acquisition_channel_other"
            type="text"
            placeholder="Please specify"
            value={formik.values.acquisition_channel_other}
            onChange={formik.handleChange}
            onBlur={() => {
              if (!formik.values.acquisition_channel_other.trim()) {
                formik.setErrors({
                  acquisition_channel_other:
                    'Please specify how you heard about Visualist',
                });
              } else if (
                user.acquisition_channel_other !==
                (formik.values.acquisition_channel_other.trim() || null)
              ) {
                formik.submitForm();
              }
            }}
            errorAndSupportingText={formik.errors.acquisition_channel_other}
            clear={() => {
              formik.setFieldValue('acquisition_channel_other', '');
              acquisitionChannelOtherRef.current?.focus();
            }}
            className={styles.otherField}
            inputStyle={styles.inputField}
          />
        )}
      </div>
    </div>
  );
};
