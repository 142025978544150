import { Icon } from 'icons';

import { IconButton } from '@visualist/design-system/src/components/v2';
import { useKeyboardShortcut } from '@visualist/hooks';

import styles from './styles.module.css';

interface ArrowLeftProps {
  goPrevFile: () => void;
}

export const ArrowLeft = ({ goPrevFile }: ArrowLeftProps) => {
  useKeyboardShortcut(['ArrowLeft'], goPrevFile);

  return (
    <nav className={styles.navigation}>
      <IconButton
        type="on secondary container"
        className={styles.arrowLeft}
        icon={<Icon name="sprite/chevron-left" className={styles.icon} />}
        onClick={goPrevFile}
      />
    </nav>
  );
};
