import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  BookmarkResponse,
  EditBookmark,
  editBookmark,
} from '../../../../../api/hubs';
import { BOOKMARKS_OF_HUB_QUERY } from '../../../../../shared/constants/query-names';

export const useEditBookmark = (
  options?: MutateOptions<BookmarkResponse, any, EditBookmark>,
) => {
  const queryClient = useQueryClient();

  const { mutate: updateBookmark } = useMutation({
    mutationFn: ({ id, url }: EditBookmark) => editBookmark({ id, url }),
    ...(options ?? {}),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: [BOOKMARKS_OF_HUB_QUERY],
      });

      if (options?.onSuccess) options?.onSuccess(...args);
    },
  });

  return { updateBookmark };
};
