import { ReactNode } from 'react';
import React from 'react';

import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './Header.module.css';

type NavLinkProps = {
  to: string;
  className?: string;
  children: ReactNode;
};

const Link = ({ to, className, children }: NavLinkProps) => (
  <NavLink
    className={cn(styles.link, className)}
    to={to}
    activeClassName={styles.active}
  >
    {children}
  </NavLink>
);

export default Link;
