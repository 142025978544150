import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import {
  $threadToDeleteId,
  selectedThreadToDelete,
} from '@src/entities/Stickies/model';
import useStickies from '@src/shared/queries/useStickies';

type Props = {
  blockId: string;
  stickyId: string;
};

export const DeleteReplyModal = ({ stickyId, blockId }: Props) => {
  const [threadToDeleteId] = useUnit([$threadToDeleteId]);

  const { mutateCommentDelete } = useStickies(blockId, stickyId);

  const onDelete = () => {
    if (!threadToDeleteId) return;

    mutateCommentDelete.mutate(
      {
        stickyId,
        commentId: threadToDeleteId,
      },
      {
        onSettled: () => {
          selectedThreadToDelete(null);
        },
      },
    );
  };

  return (
    <Modal
      showModal={!!threadToDeleteId}
      handleClose={() => selectedThreadToDelete(null)}
    >
      <Dialog
        headline="Delete sticky reply?"
        description="You're about to delete this reply."
        action={onDelete}
        actionLabel="Confirm"
        close={() => selectedThreadToDelete(null)}
      />
    </Modal>
  );
};
