import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { ResponseInvitee } from '@api/hubs';

import { User } from '..';

type OwnerProps = {
  user: ResponseInvitee;
};

export const Owner = ({ user }: OwnerProps) => {
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getPermissions = (user: ResponseInvitee) => {
    if (user.permissions.includes('edit')) {
      return 'Editor';
    } else if (user.permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  return (
    <User
      user={user}
      permissionsRef={permissionsRef}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      getPermissions={getPermissions}
      permissions={user.permissions}
    />
  );
};
