import { cloneElement, forwardRef, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import {
  IconButton,
  Modal,
  Spinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type WithName = {
  name: string;
};

type Props<T extends WithName> = {
  placeholder?: string;
  isLoading?: boolean;
  children:
    | React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>
    | Array<React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>>;
  searchTerm: string;
  updateSearchTerm: (searchTerm: string) => void;
  results: Array<T>;
  renderItem: RenderRow<T>;
  onClose?: () => void;
  icon?: React.ReactNode;
  iconDescription?: string;
  noResultsPlaceholder?: string;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

type RenderRow<T> = (item: T, close?: () => void) => React.ReactNode;

/**
 * SearchModal is a modal that opens when a child is clicked.
 * Important: Child must accept onClick prop
 * It is used to search for a value.
 *
 * @param placeholder - The placeholder text for the search input.
 * @param isLoading - Whether the search results are loading.
 * @param children - The child element that will trigger the modal.
 * @param searchTerm - The current search term.
 * @param updateSearchTerm - A function to update the search term.
 * @param results - The results of the search.
 * @param noResultsPlaceholder - Text to show when no results
 * @param icon - Icon for the empty state.
 * @param iconDescription - Description for the empty state.
 * @param isOpen - Optional external control of modal open state
 * @param setIsOpen - Optional external setter for modal open state
 * @returns A modal that opens when a child is clicked.
 */

export const SearchModal = <T extends WithName>({
  placeholder = 'Search',
  isLoading = false,
  children,
  searchTerm,
  updateSearchTerm,
  results,
  renderItem,
  onClose,
  noResultsPlaceholder = 'No matches... Try something else',
  icon,
  iconDescription,
  isOpen: externalIsOpen,
  setIsOpen: externalSetIsOpen,
}: Props<T>) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isOpen = externalIsOpen ?? internalIsOpen;
  const setIsOpen = externalSetIsOpen ?? setInternalIsOpen;

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  const clonedChild = Array.isArray(children)
    ? children.map((child) =>
        cloneElement(child, {
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
            // child.props.onClick?.(e);
            setIsOpen(true);
          },
        }),
      )
    : cloneElement(children, {
        onClick: (e: React.MouseEvent) => {
          e.stopPropagation();
          // children.props.onClick?.(e);
          setIsOpen(true);
        },
      });

  return (
    <>
      {clonedChild}
      <Modal
        className={styles.container}
        showModal={isOpen}
        handleClose={handleClose}
        isInitialFocused={false}
      >
        <Input
          value={searchTerm}
          onChange={(e) => updateSearchTerm(e.target.value)}
          placeholder={placeholder}
          onClose={handleClose}
          clear={() => updateSearchTerm('')}
        />
        <Results
          close={handleClose}
          searchTerm={searchTerm}
          renderItem={renderItem}
          results={results}
          isLoading={isLoading}
          noResultsPlaceholder={noResultsPlaceholder}
          icon={icon}
          iconDescription={iconDescription}
        />
      </Modal>
    </>
  );
};

const Input = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> & {
    onClose: () => void;
    clear: () => void;
  }
>((props, ref) => {
  const { onClose, clear, ...otherProps } = props;

  const showClear = !!otherProps.value;

  return (
    <div className={styles.inputContainer}>
      <IconButton
        type="unfilled"
        icon={<Icon name="sprite/chevron-left" />}
        onClick={onClose}
      />
      <input ref={ref} autoFocus className={styles.input} {...otherProps} />
      {showClear ? (
        <div className={styles.clearButton}>
          <button onClick={clear}>
            <Icon name="sprite/x" />
          </button>
        </div>
      ) : null}
    </div>
  );
});

Input.displayName = 'Input';

type ResultProps<T extends WithName> = {
  searchTerm: string;
  results: Array<T>;
  isLoading: boolean;
  close: () => void;
  renderItem: RenderRow<T>;
  noResultsPlaceholder: string;
  icon?: React.ReactNode;
  iconDescription?: string;
};

const Results = <T extends WithName>({
  searchTerm,
  results,
  isLoading,
  renderItem,
  noResultsPlaceholder,
  icon,
  iconDescription,
}: ResultProps<T>) => {
  if (isLoading) {
    return (
      <div className={styles.resultContainer}>
        <div className={styles.loading}>
          <Spinner />
        </div>
      </div>
    );
  }

  const filteredResults = results.filter((v) =>
    v.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (!searchTerm && filteredResults.length === 0) {
    return (
      <ul className={styles.resultContainer}>
        <div className={styles.noResults}>
          {icon ? icon : <Icon size={80} name="sprite/team-soft-colored" />}
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.emptyState}
          >
            {iconDescription}
          </TypographyPoppins>
        </div>
      </ul>
    );
  }

  if (
    filteredResults.length == 0 ||
    (filteredResults.length == 0 && !isLoading)
  ) {
    return (
      <ul className={styles.resultContainer}>
        <div className={styles.noResults}>
          {icon ? icon : <Icon size={80} name="sprite/team-soft-colored" />}
          <TypographyPoppins
            type="body"
            bodySize="S"
            className={styles.emptyState}
          >
            {noResultsPlaceholder}
          </TypographyPoppins>
        </div>
      </ul>
    );
  }

  return (
    <ul className={styles.resultContainer}>
      {filteredResults.map((r) => {
        return renderItem(r);
      })}
    </ul>
  );
};

SearchModal.Row = ({
  data,
  className,
  displaySelection = false,
  onClose,
}: {
  data: {
    id: string;
    onClick: () => void;
    name: string;
    isSelected?: boolean;
  };
  className?: string;
  displaySelection?: boolean;
  onClose?: () => void;
}) => {
  const { id, onClick, name, isSelected } = data;
  return (
    <li className={styles.result} key={id}>
      <button
        className={styles.resultButton}
        onClick={() => {
          onClick();
          onClose?.();
        }}
      >
        <TypographyPoppins type="body" bodySize="L" className={cn(className)}>
          <span className={styles.resultText}>{name}</span>
          {displaySelection && isSelected && (
            <Icon
              name="sprite/tick"
              size={24}
              style={{ padding: '8px', marginLeft: 'auto' }}
            />
          )}
        </TypographyPoppins>
      </button>
    </li>
  );
};

SearchModal.EmptyRow = ({
  onClick,
  children,
  className,
}: {
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <li className={cn(styles.result, className)} onClick={onClick}>
      {children}
    </li>
  );
};
