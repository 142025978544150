import { createEvent, createStore, sample } from 'effector';

import { closedVaiTagPopup } from '../VaiTagPopup/model';

export const openedVaiTagModal = createEvent();
export const closedVaiTagModal = createEvent();

export const $showVaiTagModal = createStore<boolean>(false)
  .on(openedVaiTagModal, () => true)
  .on(closedVaiTagModal, () => false);

sample({
  clock: openedVaiTagModal,
  target: closedVaiTagPopup,
});
