import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { createNewSet } from '@api/blocks';
import { useOpenedDesigns } from '@pages/StudioPage/hooks/useOpenedDesigns';
import { useMutation } from '@tanstack/react-query';

import styles from './styles.module.css';

export const Studio = () => {
  const history = useHistory();
  const location = useLocation();
  const { loadOpenedDesigns } = useOpenedDesigns();

  const { mutate: createNewSetMutation } = useMutation({
    mutationFn: () => createNewSet({ name: 'Untitled' }),
    onSuccess: async ({ data }) => {
      history.push(`/studio/${data.id}`);
    },
  });

  const goToStudio = async () => {
    if (location.pathname.includes('studio')) return;

    try {
      const designs = await loadOpenedDesigns();

      if (designs.size > 0) {
        const design = designs.values().next().value;

        if (!design) {
          createNewSetMutation();
        } else {
          history.push(`/studio/${design.id}`);
        }
      } else {
        createNewSetMutation();
      }
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={styles.studio}
        type="unfilled"
        icon={
          location.pathname.includes('studio') ? (
            <Icon name="sprite/studio-filled" />
          ) : (
            <Icon name="sprite/studio" />
          )
        }
        isSelected={location.pathname.includes('studio')}
        onClick={goToStudio}
      />
      <TypographyPoppins className={styles.text} type="label" labelSize="M">
        Studio
      </TypographyPoppins>
    </div>
  );
};
