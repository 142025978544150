import { MouseEvent, useRef, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { useHistory } from 'react-router';

import {
  Dropdown,
  IconButton,
  Item,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { boardIdPassed } from '@components/CreateDialogsBox/model';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';

import styles from './styles.module.css';

interface BoardProps {
  id: string;
  name: string;
  thumbnail: string | null;
  onboardingId?: string;
  hubId?: string;
  parentBoardId?: string;
  description?: string;
}

export const BoardOfHub = ({
  id,
  name,
  thumbnail,
  onboardingId,
  hubId,
  parentBoardId,
  description,
}: BoardProps) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const history = useHistory();

  const boardRef = useRef<HTMLDivElement>(null);

  const handleDotMenuRightClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOpenDropdown(true);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/board/${id}`,
      );

      startedSnack({
        label: 'Copied link to board',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(name);
    boardIdSelected(id);

    if (description) {
      boardDescriptionEntered(description);
    }
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: id,
      parentBoardId,
      hubId: hubId,
    });
    boardIdPassed(id);
  };

  const menuItems: Item[] = [
    {
      leadingIcon: (
        <Icon name="sprite/link" size={21} style={{ paddingLeft: '2px' }} />
      ),
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    {
      leadingIcon: <Icon name="sprite/pen" />,
      content: 'Rename',
      isDivider: true,
      onClick: rename,
    },
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: () => deleteBoard(),
      classNameContent: styles.delete,
    },
  ];

  return (
    <div
      id={onboardingId}
      className={cn(styles.board, {
        [styles.activeBoard]: isOpenDropdown,
      })}
      ref={boardRef}
      onClick={(e) => {
        e.preventDefault();

        if (e.ctrlKey || e.metaKey) {
          window.open(`${import.meta.env.VITE_BASE_URL}/board/${id}`, '_blank');
        } else {
          history.push(`/board/${id}`);
        }
      }}
      onContextMenu={handleDotMenuRightClick}
    >
      {thumbnail ? (
        <img className={styles.media} src={thumbnail} />
      ) : (
        <div className={styles.noMedia} />
      )}
      <header className={styles.content}>
        <div className={styles.name}>
          <Icon name="sprite/board" />
          <TypographyPoppins className={styles.text} type="label" labelSize="M">
            {name}
          </TypographyPoppins>
        </div>
        <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
          <Dropdown.Menu
            trigger={
              <IconButton
                type="unfilled"
                icon={<Icon name="sprite/3-dot-menu" />}
                onClick={() => {}}
                stopPropagation
                isSelected={isOpenDropdown}
              />
            }
            side="top"
            density="-2"
          >
            {menuItems.map((item, index) => (
              <Dropdown.MenuItem key={index} item={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </header>
    </div>
  );
};
