import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const Home = () => {
  const history = useHistory();
  const location = useLocation();

  const goToHomepage = () => {
    history.push('/home');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={styles.home}
        type="unfilled"
        icon={
          location.pathname === '/home' ? (
            <Icon name="sprite/home-filled" />
          ) : (
            <Icon name="sprite/home" />
          )
        }
        isSelected={location.pathname === '/home'}
        onClick={goToHomepage}
      />
      <TypographyPoppins className={styles.text} type="label" labelSize="M">
        Home
      </TypographyPoppins>
    </div>
  );
};
