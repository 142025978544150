import { useState } from 'react';

import { Icon } from 'icons';

import {
  Dropdown,
  IconButton,
  Item,
  Switch,
  TeamIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { BoardResponse } from '@api/boards';
import { boardIdPassed } from '@components/CreateDialogsBox/model';
import { BoardShare } from '@pages/BoardPage';
import { shareSideSheetOpened } from '@pages/BoardPage/model';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/modal';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/model';
import {
  boardIdForHubSelected,
  boardWarningEntered,
  hubIdForAddingSelected,
  hubIdForDeletingSelected,
  hubNameEntered,
  moveBoardDialogOpened,
} from '@src/entities/dialogs/board/move-board/model';

import styles from './styles.module.css';

type Props = {
  board: BoardResponse;
  shareToWeb: (published: boolean, allow_comments?: boolean) => Promise<void>;
  boardShare: BoardShare | null;
};

export const Title = ({ board, shareToWeb, boardShare }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/board/${board.id}`,
      );

      startedSnack({
        label: 'Copied link to board',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(board.name);
    boardIdSelected(board.id);

    if (board.description) {
      boardDescriptionEntered(board.description);
    }
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: board.id,
      parentBoardId: board.parent?.id,
      hubId: board.hub?.id,
    });
    boardIdPassed(board.id);
  };

  const menuItems: Item[] = board.hub
    ? [
        {
          leadingIcon: <Icon name="sprite/link" size={21} />,
          content: 'Copy link',
          onClick: () => copyLink(),
        },
        {
          leadingIcon: <Icon name="sprite/pen" />,
          content: 'Rename',
          onClick: rename,
        },
        board.level === 0 && {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Move to another hub',
          onClick: () => {
            moveBoardDialogOpened();

            if (board.hub) {
              hubIdForAddingSelected(board.hub.id);
              hubIdForDeletingSelected(board.hub.id);
              hubNameEntered(board.hub.name);
              boardIdForHubSelected(board.id);
              boardWarningEntered(board.name);
            }
          },
        },
        {
          leadingIcon: <TeamIcon />,
          content: 'Invite',
          onClick: () => shareSideSheetOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/public" />,
          content: 'Share to web',
          trailingIcon: (
            <Switch
              onValueChange={(value) => {
                shareToWeb(value);
                setOpenDropdown(false);
              }}
              value={boardShare?.link ? true : false}
            />
          ),
          isDivider: true,
        },
        {
          leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
          content: 'Delete',
          onClick: () => deleteBoard(),
          classNameContent: styles.delete,
        },
      ].filter(Boolean)
    : [
        {
          leadingIcon: <Icon name="sprite/link" size={21} />,
          content: 'Copy link',
          onClick: () => copyLink(),
        },
        {
          leadingIcon: <Icon name="sprite/pen" />,
          content: 'Rename',
          onClick: rename,
        },
        board.level === 0 && {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Move into hub',
          onClick: () => {
            moveBoardDialogOpened();
            boardIdForHubSelected(board.id);
          },
        },
        {
          leadingIcon: <Icon name="sprite/public" />,
          content: 'Share to web',
          trailingIcon: (
            <Switch
              onValueChange={(value) => {
                shareToWeb(value);
                setOpenDropdown(false);
              }}
              value={boardShare?.link ? true : false}
            />
          ),
          isDivider: true,
        },
        {
          leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
          content: 'Delete',
          onClick: () => deleteBoard(),
          classNameContent: styles.delete,
        },
      ].filter(Boolean);

  return (
    <div className={styles.title}>
      <TypographyPoppins type="display" size="M" className={styles.name}>
        {board.name}
      </TypographyPoppins>
      <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
        <Dropdown.Menu
          trigger={
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/caret-down" />}
              isSelected={isOpenDropdown}
              onClick={() => {}}
            />
          }
          side="bottom"
          align="end"
          density="-2"
        >
          {menuItems.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
