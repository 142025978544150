import { Icon } from 'icons';
import { useHistory } from 'react-router';

import { UserIcon } from '@visualist/design-system';
import {
  ChatSpeechRight,
  Dropdown,
  Item,
  User,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { useAppData } from '@src/AppContext';
import { useUserOnboarding } from '@src/widgets/onboarding/useUserOnboarding';

export const Avatar = ({ isSmallScreenSize }: any) => {
  const history = useHistory();

  const { isMobile } = useWindowSize();
  const { startDemoMode, isDemoModeOn } = useUserOnboarding();

  const gotoDemo = () => startDemoMode({});

  const helpCenter = () => {
    window.open('https://visualistapp.com/help-center/all', '_blank');
  };

  const contactUs = () => {
    window.open('https://visualistapp.com/contact', '_blank');
  };

  const { user, signOut } = useAppData();

  const logout = () => {
    signOut && signOut();
  };

  const goToAccount = () => {
    history.push('/account/profile', {
      from: history.location.pathname,
    });
  };

  const menuItems: Item[] = [
    {
      leadingIcon: <User fill="none" />,
      content: 'Account settings',
      onClick: () => goToAccount(),
    },
    ...(isMobile
      ? [
          ...(isDemoModeOn
            ? []
            : [
                {
                  leadingIcon: <Icon name="sprite/demo" />,
                  content: 'Go to demo',
                  onClick: gotoDemo,
                },
              ]),
          {
            leadingIcon: <Icon name="sprite/FAQ" />,
            content: 'Help center',
            onClick: helpCenter,
          },
          {
            leadingIcon: <ChatSpeechRight fill="none" />,
            content: 'Contact us',
            onClick: contactUs,
          },
        ]
      : []),
    {
      leadingIcon: <Icon name="sprite/logout" />,
      content: 'Log out',
      onClick: () => logout(),
    },
  ];

  return (
    <Dropdown>
      <Dropdown.Menu
        trigger={<UserIcon size={isSmallScreenSize ? 36 : 48} user={user} />}
        side={isSmallScreenSize ? 'bottom' : 'right'}
        sideOffset={isSmallScreenSize ? 10 : 15}
        density="-2"
      >
        {menuItems.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
