import React from 'react';

import { useUnit } from 'effector-react';
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import { Icon } from 'icons';

import {
  AssistChip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { doNotAskAgainTaggingModal } from '@api/vai';
import { VaiPopup } from '@src/entities/vai/ui/popup';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useMutation } from '@tanstack/react-query';

import { openedVaiTagModal } from '../VaiTagModal/model';
import { $showVaiTagPopup, closedVaiTagPopup } from './model';

import styles from './styles.module.css';

export const VaiTagPopup = () => {
  const [showVaiTagPopup] = useUnit([$showVaiTagPopup]);
  const [, setDoNotShowVaiTagPopup] = useLocalStorage(
    'do_not_show_vai_tag_popup',
    false,
  );

  const text = `What’s “elegant” and what’s “minimalist”?\nHelp Vai to learn your style.`;
  const textLength = useMotionValue(0);

  React.useEffect(() => {
    let controls: AnimationPlaybackControls;
    if (showVaiTagPopup) {
      const timeout = setTimeout(() => {
        controls = animate(textLength, text.length, {
          duration: 4,
        });
      }, 500);
      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
  }, [showVaiTagPopup]);

  const roundedTextLength = useTransform(textLength, (latest) =>
    Math.round(latest),
  );
  const displayedText = useTransform(roundedTextLength, (latest) =>
    text.slice(0, latest),
  );

  const { mutateAsync: doNotAskAgainMutation } = useMutation({
    mutationFn: doNotAskAgainTaggingModal,
    onSuccess: () => closedVaiTagPopup(),
  });

  return (
    <VaiPopup
      closePopup={() => {
        // setShowVaiSessionCheck((prev) => prev + 1);
        closedVaiTagPopup();
      }}
      showPopup={showVaiTagPopup}
      top={76}
      right={20}
    >
      <div className={styles.main}>
        <TypographyPoppins type="body" bodySize="M" className={styles.text}>
          <motion.span>{displayedText}</motion.span>
        </TypographyPoppins>
        <div className={styles.buttons}>
          <AssistChip
            leadingIcon
            icon={<Icon name="sprite/tidy-up" className={styles.tidyUp} />}
            onClick={() => openedVaiTagModal()}
            style="elevated"
          >
            Go to Vai
          </AssistChip>
          <AssistChip
            onClick={async () => {
              setDoNotShowVaiTagPopup(true);
              await doNotAskAgainMutation('tagging_prompt');
            }}
            style="outlined"
          >
            Don't ask me again
          </AssistChip>
        </div>
      </div>
    </VaiPopup>
  );
};
