import React, { useState } from 'react';

import { PhotoType } from '@visualist/design-system';

import { REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER } from '@src/shared/constants/variables-local-storage';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchLogin,
  fetchLogout,
  getUser,
  saveProfile,
  SaveProfilePayload,
} from './api';
import { deleteCookie, setCookie } from './shared/utils';

export type AppDataType = {
  user: User;
  signIn?: (payload: any) => void;
  signOut?: () => void;
  signInError: string;
  userMutation: UseMutationResult<any, Error, SaveProfilePayload, unknown>;
};

// TODO fix this non null assertion
const AppData = React.createContext<AppDataType>(undefined!);

export function AppDataProvider({ children }: { children: React.ReactNode }) {
  const queryClient = useQueryClient();
  const [signInError, setSignInError] = useState('');
  const { data: user } = useQuery({
    queryKey: ['my-user'],
    queryFn: getUser,
    initialData: () => {
      const data = localStorage.getItem('v_user');
      if (!data) return;
      return JSON.parse(data).user as User;
    },
  });

  const userMutation = useMutation({
    mutationFn: saveProfile,
    onSuccess: (data) => {
      queryClient.setQueryData(['my-user'], data);
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });
    },
  });

  const signIn = async (payload: { email: string; password: string }) => {
    setSignInError('');
    try {
      const response = await fetchLogin(payload);

      setCookie('access_token', response.access_token);
      setCookie('refresh_token', response.refresh_token);

      localStorage.setItem('v_user', JSON.stringify(response));

      const redirect = localStorage.getItem(
        REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER,
      );

      if (redirect) {
        window.location.href = redirect;
        localStorage.removeItem(REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER);
      } else {
        window.location.href = '/home';
      }
    } catch (e) {
      const typedError = e as any;
      setSignInError(typedError?.response?.data?.non_field_errors?.join(''));
    }
  };

  const signOut = async () => {
    queryClient.clear();
    // Await 1100ms to clear cache. Cache clear has throttle time of 1000ms
    await sleep(1100);
    await fetchLogout();
    deleteCookie('access_token');
    deleteCookie('refresh_token');
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <AppData.Provider
      value={{ user, userMutation, signIn, signOut, signInError }}
    >
      {children}
    </AppData.Provider>
  );
}

export const useAppData = () => React.useContext(AppData);

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// TYPES
export type UserOnboarding = {
  demoMode: boolean;
  selectedPath: OptionKey;
  completedPaths: Record<OptionKey, boolean>;
  createHubs: OnboardingSteps[number];
  workCreatively: OnboardingSteps[number];
  manageBusinessAdmin: OnboardingSteps[number];
};
export type User = {
  id: number;
  first_name: string | null;
  last_name: string | null;
  photo?: PhotoType;
  referral_code?: string;
  meta?: Record<string, unknown> & {
    hasSavedVaiTagPopup?: number;
    onboarding?: UserOnboarding;
  };
  is_google_linked: boolean;
  last_login: string;
  email: string;
  valid_password: boolean;
};

export type OptionKey = 'createHubs' | 'manageBusinessAdmin' | 'workCreatively';

export type OnboardingContent = Array<
  | {
      type: 'text';
      content: string;
      // Hide this text if on the last step
      hideOnEnd?: boolean;
    }
  | {
      type: 'image';
      href: string;
    }
  | {
      type: 'video';
      href: string;
    }
>;

export type OnboardingAction =
  | 'first-hub'
  | 'open-studio'
  | 'open-tidy-up'
  | 'doc-file-library';

export type Step = {
  content: OnboardingContent;
  title: string;
  nextActionTitle: string;
  onAction?: OnboardingAction;
  urlFilter: string;
} & (
  | {
      type: 'target';
      position: {
        anchor: 'left' | 'right' | 'top' | 'bottom';
        offset?: {
          left: number;
          top: number;
        };
        alignment: 'start' | 'center' | 'end';
        targetId: string;
      };
    }
  | {
      type: 'page';
      position: {
        left: number;
        top: number;
      };
    }
);

export const ONBOARDING_HUB_STEPS = [
  'hub-tooltip-getting-started',
  'boards-getting-started',
  'todo-getting-started',
] as const;

export const ONBOARDING_STUDIO_STEPS = [
  'creative-with-colour',
  'moodboards-that-pop',
  'vai-tidy-up',
] as const;

export const ONBOARDING_DOC_STEPS = [
  'doc-getting-started',
  'doc-file-library',
] as const;

export const DICT_OF_STEPS: Record<
  OptionKey,
  ReadonlyArray<OnboardingSteps>
> = {
  createHubs: ONBOARDING_HUB_STEPS,
  workCreatively: ONBOARDING_STUDIO_STEPS,
  manageBusinessAdmin: ONBOARDING_DOC_STEPS,
};

export type OnboardingSteps =
  | (typeof ONBOARDING_HUB_STEPS)[number]
  | (typeof ONBOARDING_STUDIO_STEPS)[number]
  | (typeof ONBOARDING_DOC_STEPS)[number];

export const hubPathFinalStep =
  ONBOARDING_HUB_STEPS[ONBOARDING_HUB_STEPS.length - 1];
export const studioColourPathFinalStep =
  ONBOARDING_STUDIO_STEPS[ONBOARDING_STUDIO_STEPS.length - 1];
export const docPathFinalStep =
  ONBOARDING_DOC_STEPS[ONBOARDING_DOC_STEPS.length - 1];

export const ONBOARDING_STEPS: Record<OnboardingSteps, Step> = {
  'hub-tooltip-getting-started': {
    type: 'target',
    onAction: 'first-hub',
    position: {
      targetId: 'onboarding-hub-demo',
      anchor: 'right',
      alignment: 'start',
      offset: {
        left: 20,
        top: 0,
      },
    },
    urlFilter: '/home',
    title: 'One space for your project',
    nextActionTitle: 'Next: Getting organized',
    content: [
      {
        type: 'text',
        content:
          'Bring in your clients and collaborators. Streamline the process with one place for discussions and give everyone peace of mind.',
      },
      {
        type: 'text',
        content: 'Go ahead and open the hub.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/hub-tooltip-share-hub.mp4',
      },
    ],
  },
  'boards-getting-started': {
    type: 'target',
    position: {
      targetId: 'onboarding-board-demo',
      anchor: 'right',
      alignment: 'center',
      offset: {
        left: 20,
        top: 0,
      },
    },
    urlFilter: '/h/',
    title: 'Organize with ease',
    nextActionTitle: 'Next: Action items',
    content: [
      {
        type: 'text',
        content: 'File your work and ideas into boards to keep things tidy.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/hub-rename-board-tooltip.mp4',
      },
    ],
  },
  'todo-getting-started': {
    type: 'target',
    position: {
      targetId: 'onboarding-hub-todos',
      anchor: 'right',
      alignment: 'center',
      offset: {
        left: 16,
        top: 0,
      },
    },
    urlFilter: '/h/',
    title: 'Turn your “to-do” into “ta-da”',
    nextActionTitle: 'Next: Color inspiration',
    content: [
      {
        type: 'text',
        content:
          'Stay on top of every single detail using sticky notes and action items.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/file-tooltip-add-sticky-note.mp4',
      },
      {
        type: 'text',
        hideOnEnd: true,
        content:
          'Have 3 more minutes? Keep going to learn about other features.',
      },
    ],
  },
  'creative-with-colour': {
    type: 'target',
    onAction: 'open-studio',
    position: {
      targetId: 'file-card-right-rail',
      anchor: 'right',
      alignment: 'start',
      offset: {
        left: -704,
        top: 12,
      },
    },
    urlFilter: '/block/',
    title: 'Get creative with color',
    nextActionTitle: 'Next: Moodboard with Vai',
    content: [
      {
        type: 'text',
        content:
          'Vai automatically extracts color palettes from every image you upload.',
      },
      {
        type: 'text',
        content: 'Save palettes and spark new ideas for your projects.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/file-save-palette.mp4',
      },
    ],
  },
  'moodboards-that-pop': {
    type: 'page',
    position: {
      left: 70,
      top: 50,
    },
    urlFilter: '/studio/',
    onAction: 'open-tidy-up',
    title: 'Make moodboards that pop',
    nextActionTitle: 'Next: Tidy up with Vai',
    content: [
      {
        type: 'text',
        content:
          'Find your groove in Studio. Remove background, make collages and share your ideas in just a few clicks.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/studio-remove-background.mp4',
      },
    ],
  },
  'vai-tidy-up': {
    type: 'target',
    position: {
      targetId: 'vai-tidy-up',
      anchor: 'left',
      alignment: 'end',
      offset: {
        left: -344,
        top: -64,
      },
    },
    urlFilter: '/studio/',
    title: 'Spend your time on what matters',
    nextActionTitle: 'Next: Client hubs',
    content: [
      {
        type: 'text',
        content:
          'Let Vai take care of the tedious work, starting with automatic tidy up.',
      },
      {
        type: 'text',
        content: 'Curious? Hit the spacebar.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/studio-tidy-up.mp4',
      },
      {
        type: 'text',
        hideOnEnd: true,
        content:
          'Have 3 more minutes? Keep going to learn about other features.',
      },
    ],
  },
  'doc-getting-started': {
    type: 'target',
    position: {
      alignment: 'start',
      anchor: 'right',
      targetId: 'left-hand-nav',
      offset: {
        left: 96,
        top: 188,
      },
    },
    urlFilter: '/d/',
    title: 'Docs, made for creatives',
    nextActionTitle: 'Next: Bring in your designs',
    onAction: 'doc-file-library',
    content: [
      {
        type: 'text',
        content:
          'We’ve got everything you need to create proposals and manage your business.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/doc-scroll.mp4',
      },
    ],
  },
  'doc-file-library': {
    type: 'target',
    position: {
      targetId: 'onboarding-doc-file-panel',
      anchor: 'right',
      alignment: 'start',
      offset: {
        left: 0,
        top: 0,
      },
    },
    title: 'Show and tell with docs',
    nextActionTitle: 'Next: Client hubs',
    urlFilter: '/d/',
    content: [
      {
        type: 'text',
        content:
          'Easily pull in creative assets from your library. Make an impression and craft docs that stand out.',
      },
      {
        type: 'video',
        href: '/onboarding/videos/doc-add-image.mp4',
      },
      {
        type: 'text',
        hideOnEnd: true,
        content:
          'Have 3 more minutes? Keep going to learn about other features.',
      },
    ],
  },
};
