import cn from 'classnames';
//@ts-ignore
import Highlighter from 'react-highlight-words';

import { PhotoAvatar } from '@visualist/design-system';

import { Button, CrossIcon } from '..';

import styles from './ClipItem.module.css';

type ChipType = 'board' | 'email' | 'boardClip' | 'hub' | 'tagClip';

export interface ClipItemProps {
  icon?: string;
  id?: string;
  image?: any;
  name: string;
  onClick?: (value?: string) => void;
  onDelete?: (value: string | null) => void;
  selected?: boolean;
  type?: ChipType;
  index?: number;
  removeChip?: any;
  isOwner?: boolean;
  query?: string;
  hasTag?: boolean;
}

const ClipItem = ({
  icon,
  id,
  image,
  name,
  onClick,
  onDelete,
  type,
  isOwner,
  query,
  hasTag,
}: ClipItemProps) => {
  const deleteHandle = (event: any) => {
    onDelete && onDelete(image);
    event.stopPropagation();
  };

  const onItemClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <div
      className={cn({
        [styles.clipItemWrapper]: type !== 'email',
        [styles.permissionDelete]: isOwner,
        [styles.email]: type === 'email',
        [styles.chip]: type === 'boardClip' || type === 'hub',
        [styles.tag]: hasTag,
      })}
    >
      <div
        className={cn(styles.clipItem, {
          [styles.emailChip]: type === 'email',
          [styles.tagChip]: type === 'tagClip',
        })}
        onClick={onItemClick}
      >
        <div style={{ display: 'flex' }}>
          {icon !== 'undefined' && icon !== undefined && (
            <PhotoAvatar photo={icon} size={16} />
          )}
          <span
            className={cn(styles.name, {
              [styles.tagName]: type === 'tagClip',
            })}
          >
            <Highlighter
              highlightClassName={styles.highlight}
              searchWords={[query]}
              autoEscape={true}
              textToHighlight={name}
            />
          </span>
        </div>
        {onDelete && (
          <Button className={styles.clipItemRemove} onClick={deleteHandle}>
            <CrossIcon size={12} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default ClipItem;
