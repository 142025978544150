import { Icon } from 'icons';

import { Dropdown, Item } from '@visualist/design-system/src/components/v2';

import { selectedThreadToDelete } from '../model';

import styles from './styles.module.css';

export const ReplyDropdown = (props: {
  replyId: string;
  showDropdown: boolean;
  setShowDropdown: (show: boolean) => void;
  isActionable: boolean;
  toggleActionable: () => void;
}) => {
  const closeDropdown = () => props.setShowDropdown(false);

  const deleteReply = () => {
    selectedThreadToDelete(props.replyId);
    closeDropdown();
  };

  const menuItemsTop: Item[] = [
    // {
    //   leadingIcon: <Icon name="sprite/pen" />,
    //   content: 'Edit',
    //   onClick: () => editedSticky(props.sticky.id),
    // },
    {
      leadingIcon: <Icon name="sprite/checkbox" />,
      content: props.isActionable ? "Don't mark for action" : 'Mark for action',
      onClick: props.toggleActionable,
    },
  ];

  const menuItemsBottom: Item[] = [
    {
      leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
      content: 'Delete',
      onClick: deleteReply,
    },
  ];

  if (!props.showDropdown) return null;

  return (
    <Dropdown open={props.showDropdown} onOpenChange={props.setShowDropdown}>
      <Dropdown.Menu side="bottom" sideOffset={8} align="end" alignOffset={-4}>
        {menuItemsTop.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
        {menuItemsBottom.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
