import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { MoveToBoard, moveToBoard } from '../../../../../api/boards';
import {
  ALL_BOARDS_QUERY,
  BOARD_QUERY,
  BOARDS_TREE_QUERY,
} from '../../../../../shared/constants/query-names';

export const useUndoConvertBoard = (
  options?: MutateOptions<MoveToBoard, any, MoveToBoard>,
) => {
  const queryClient = useQueryClient();

  const { mutate: undoConvertBoard } = useMutation({
    mutationFn: ({ parent, children }: MoveToBoard) =>
      moveToBoard({ parent, children }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: variables.parent }],
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
  });

  return { undoConvertBoard };
};
