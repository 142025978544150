import React from 'react';

import { Icon, IconProps } from './Icon';

export const CaretDownIcon = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M19 8.5L12.5 15.5L6 8.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </Icon>
  );
};
