import React, { ReactNode } from 'react';

import cn from 'classnames';
import { isDesktop } from 'react-device-detect';

import styles from './Header.module.css';

type HeaderProps = {
  mobile?: boolean;
  children: ReactNode;
  isShowLibraryBackdrop?: boolean;
};

//TODO: add onLight, onDark etc - need Acceptance Critera
const Navbar = ({
  children,
  mobile = false,
  isShowLibraryBackdrop,
}: HeaderProps) => (
  <header
    className={cn([
      styles.root,
      {
        [styles.mobile]: mobile,
        [styles.desktop]: isDesktop,
        [styles.backdropMobile]: isShowLibraryBackdrop,
      },
    ])}
  >
    {children}
  </header>
);

export default Navbar;
