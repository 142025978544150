import { Dispatch, SetStateAction } from 'react';

import { Icon } from 'icons';

import { Dropdown, Item } from '@visualist/design-system/src/components/v2';

import { Editor } from '@tiptap/react';

export type HeadingLevel = 1 | 2 | 3 | undefined;

export const StyleDropdown = (props: {
  showDropdown: boolean;
  setShowDropdown: Dispatch<SetStateAction<boolean>>;
  heading: HeadingLevel;
  setHeading: (heading: HeadingLevel) => void;
  editor: Editor;
}) => {
  const { showDropdown, setShowDropdown, setHeading, heading, editor } = props;

  const menuItems: Item[] = [
    {
      leadingIcon: <Icon name="sprite/tick" opacity={!heading ? 1 : 0} />,
      content: 'Normal',
      onClick: () => {
        editor
          .chain()
          .focus()
          //@ts-ignore
          .setParagraph()
          .setFontFamily('Poppins')
          .selectParentNode()
          //@ts-ignore
          .unsetBold()
          .unsetItalic()
          .unsetUnderline()
          .unsetStrike()
          .unsetFontSize()
          .run();
      },
    },
    {
      leadingIcon: <Icon name="sprite/tick" opacity={heading === 1 ? 1 : 0} />,
      content: 'Heading 1',
      onClick: () => {
        editor
          .chain()
          .focus()
          .selectParentNode()
          //@ts-ignore
          .unsetBold()
          .unsetItalic()
          .unsetUnderline()
          .unsetStrike()
          .unsetFontSize()
          .run();

        setHeading(1);
      },
    },
    {
      leadingIcon: <Icon name="sprite/tick" opacity={heading === 2 ? 1 : 0} />,
      content: 'Heading 2',
      onClick: () => {
        editor
          .chain()
          .focus()
          .selectParentNode()
          //@ts-ignore
          .unsetBold()
          .unsetItalic()
          .unsetUnderline()
          .unsetStrike()
          .unsetFontSize()
          .run();

        setHeading(2);
      },
    },
    {
      leadingIcon: <Icon name="sprite/tick" opacity={heading === 3 ? 1 : 0} />,
      content: 'Heading 3',
      onClick: () => {
        editor
          .chain()
          .focus()
          .selectParentNode()
          //@ts-ignore
          .unsetBold()
          .unsetItalic()
          .unsetUnderline()
          .unsetStrike()
          .unsetFontSize()
          .run();

        setHeading(3);
      },
    },
  ];

  return (
    <Dropdown open={showDropdown} onOpenChange={setShowDropdown}>
      <Dropdown.Menu
        side="bottom"
        sideOffset={24}
        align="center"
        // density="-2"
      >
        {menuItems.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
