import cn from 'classnames';
import { Icon } from 'icons';

import {
  Divider,
  IconButton,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { HubShare } from '@pages/HubPage';

import { settingsSheetOpened } from '../SettingsSheet/model';

import styles from './styles.module.css';

type SettingsProps = {
  hubShare: HubShare | null;
  getDateOfBilling: (isSettingsSheet?: boolean) => string | null;
  isOwner?: boolean;
};

export const Settings = ({
  hubShare,
  getDateOfBilling,
  isOwner,
}: SettingsProps) => {
  const copyPublicLink = async () => {
    try {
      if (hubShare) {
        await navigator.clipboard.writeText(
          `${import.meta.env.VITE_SHARE_URL}/${hubShare.id}/h/${hubShare.hub}`,
        );
        startedSnack({
          label: 'Copied link',
          close: true,
        });
      }
    } catch (error) {
      startedSnack({
        label: "Oops, could'nt copy this",
        action: {
          label: 'Try again',
          action: () => {
            copyPublicLink();
          },
        },
        close: true,
      });
    }
  };

  return (
    <li className={styles.item}>
      <div className={styles.card}>
        <div className={styles.header}>
          <TypographyPoppins
            className={styles.color}
            type="title"
            titleSize="M"
          >
            Settings
          </TypographyPoppins>
          <div className={styles.actions}>
            {hubShare?.link && (
              <Tooltip
                style={{
                  minWidth: 'max-content',
                }}
                parameter={{
                  type: 'plain',
                  description: 'Copy public link',
                  position: 'top',
                }}
              >
                <IconButton
                  type="unfilled"
                  icon={<Icon className={styles.public} name="sprite/public" />}
                  onClick={copyPublicLink}
                />
              </Tooltip>
            )}
            <IconButton
              type="unfilled"
              icon={
                <Icon className={styles.expand} name="sprite/arrow-expand" />
              }
              onClick={() => settingsSheetOpened()}
            />
          </div>
        </div>
        {isOwner && (
          <>
            <div className={styles.horizontal}>
              <Divider className={styles.divider} type="long-line" />
            </div>
            <DateOfBilling getDateOfBilling={getDateOfBilling} />
          </>
        )}
      </div>
    </li>
  );
};

type DateOfBillingProps = {
  getDateOfBilling: (isSettingsSheet?: boolean) => string | null;

  className?: string;
  isSettingsSheet?: boolean;
};

export const DateOfBilling = ({
  getDateOfBilling,
  className,
  isSettingsSheet,
}: DateOfBillingProps) => {
  return getDateOfBilling() ? (
    <div className={cn(styles.settings, className)}>
      <Icon
        className={cn(styles.billing, {
          [styles.settingsSheet]: isSettingsSheet,
        })}
        name="sprite/settings"
      />
      <TypographyPoppins
        className={cn(styles.billing, {
          [styles.settingsSheet]: isSettingsSheet,
        })}
        type="body"
        bodySize="M"
      >
        {getDateOfBilling()}
      </TypographyPoppins>
    </div>
  ) : null;
};
