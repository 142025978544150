import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useHistory, useParams } from 'react-router';

import {
  Button,
  Dialog,
  Modal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { LibraryActions } from '@components/LibraryActions';
import {
  $isOpenDocDeleteModal,
  deleteDocModalClosed,
} from '@pages/DocPage/model';
import { ErrorBoundary } from '@sentry/react';
import { useDeleteDoc } from '@src/entities/doc/queries/useDeleteDoc';
import { Editor } from '@src/entities/doc/ui/editor';
import { NavPanel } from '@src/entities/doc/ui/nav-panel';
import { PageSetupWrapper } from '@src/entities/doc/ui/page-setup';
import { DocTabs } from '@src/entities/doc/ui/tabs';
import { SearchDialog } from '@src/entities/search/ui/searchDialog';
import { clearAllTabData } from '@src/shared/utils/clear-all-local-data';

import styles from './styles.module.css';

import '@fontsource-variable/caveat';
import '@fontsource-variable/montserrat';
import '@fontsource-variable/noto-serif';
import '@fontsource-variable/nunito';
import '@fontsource-variable/open-sans';
import '@fontsource-variable/oswald';
import '@fontsource-variable/playfair-display';
import '@fontsource/alex-brush';
import '@fontsource/arvo';
import '@fontsource/cormorant-garamond';
import '@fontsource/lato';
import '@fontsource/merriweather';
import '@fontsource/monsieur-la-doulaise';
import '@fontsource/rouge-script';
import '@fontsource/zeyada';

export const DocPageV2 = () => {
  const { doc_id } = useParams<{ doc_id: string }>();
  const history = useHistory();

  return (
    <div className={styles.container}>
      <DocumentHead name="Docs" />
      <SearchDialog hideMobileSearch />
      <div className={cn(styles.docContainer)}>
        <ErrorBoundary
          fallback={
            <div className={styles.error}>
              <TypographyPoppins type="body" headlineSize="M">
                Oops, something went wrong.
              </TypographyPoppins>
              <Button
                type="filled"
                label="Go back"
                onClick={() => {
                  clearAllTabData();
                  history.push('/home');
                }}
              />
            </div>
          }
        >
          <DocTabs />
          <NavPanel />
          <Editor key={doc_id} docId={doc_id} />
        </ErrorBoundary>
      </div>
      <PageSetupWrapper docId={doc_id} />
      <DeleteModal docId={doc_id} />
      <LibraryActions page="docPage" />
    </div>
  );
};

const DeleteModal = ({ docId }: { docId: string }) => {
  const [isOpenDocDeleteModal] = useUnit([$isOpenDocDeleteModal]);

  const { removeDocMutation } = useDeleteDoc();

  return (
    <Modal handleClose={deleteDocModalClosed} showModal={isOpenDocDeleteModal}>
      <Dialog
        headline="Delete this Doc?"
        description="You will permanently delete this doc."
        action={async () => {
          await removeDocMutation.mutateAsync({ docId });
          deleteDocModalClosed();
        }}
        actionLabel="Delete"
        close={deleteDocModalClosed}
      />
    </Modal>
  );
};
