import { ChangeEvent, KeyboardEvent } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import { Board, BreadCrumbs, ClipItem, Input } from '@visualist/design-system';

import { $parentBoard } from '../../pages/BoardPage/model';
import { $boardSearchActive } from './model';

import styles from './styles.module.css';

interface BoardsSearchProps {
  isMobileOnly: boolean;
  boardsSuggestions: Board[];
  selectRow: number | null;
  selectBoard: (board: string, boardId?: string) => void;
  lastSuggestion: number;
  closeDropdown: () => void;
  onHandleChangeParentBoard: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (e: any) => void;
  onRemoveFocus: (e: KeyboardEvent<HTMLInputElement>) => void;
  creatingHub: boolean;
  creatingBookmark: string;
  editBookmark: string;
  currentHubId: string;
  onBlur: () => void;
  setSelectRow: (row: number | null) => void;
}

export const BoardsSearch = ({
  isMobileOnly,
  boardsSuggestions,
  selectRow,
  selectBoard,
  lastSuggestion,
  closeDropdown,
  onHandleChangeParentBoard,
  onKeyDown,
  onRemoveFocus,
  creatingHub,
  creatingBookmark,
  editBookmark,
  currentHubId,
  onBlur,
  setSelectRow,
}: BoardsSearchProps) => {
  const boardSearchActive = useUnit($boardSearchActive);
  const parentBoard = useUnit($parentBoard);

  return (
    <div>
      <Input
        className={cn(styles.parentBoardField, {
          [styles.focused]: boardSearchActive && parentBoard,
          [styles.parentBoardFieldMobile]: isMobileOnly,
          [styles.hideField]: creatingHub || creatingBookmark || editBookmark,
        })}
        customStyleRightIcon={cn({
          [styles.сhevronIconActiveSearch]: boardSearchActive,
        })}
        type="text"
        placeholder="Nest it in..."
        customLabel="Nest in"
        rightIcon={<Icon name="sprite/caret-down" />}
        value={parentBoard}
        onChange={onHandleChangeParentBoard}
        onKeyDown={onKeyDown}
        onKeyUp={onRemoveFocus}
        onBlur={onBlur}
      />
      {boardSearchActive && parentBoard && (
        <div
          className={cn(styles.dropdown, {
            [styles.dropdownMobile]: isMobileOnly,
          })}
        >
          <ul className={styles.ul}>
            {boardsSuggestions.length > 0 &&
              boardsSuggestions.slice(0, 5).map((board, index) => {
                if (board !== null) {
                  return (
                    <li
                      key={board.id}
                      className={cn(styles.li, {
                        [styles.highlight]: selectRow === index,
                      })}
                      onMouseEnter={() => setSelectRow(index)}
                      onClick={() => selectBoard(board.name, board.id)}
                    >
                      <span style={{ display: 'flex' }}>
                        <Icon
                          name="sprite/board"
                          size={16}
                          className={styles.boardIcon}
                        />
                        <ClipItem
                          type="boardClip"
                          name={board.name}
                          query={parentBoard}
                        />
                      </span>
                      <BreadCrumbs
                        hub={board.hub}
                        hubName={board.hub?.name}
                        level={board.level}
                        parentBoardName={board.parent?.name}
                        topBoardName={board.top_parent?.name}
                      />
                    </li>
                  );
                } else {
                  return null;
                }
              })}
            {!currentHubId && (
              <li
                className={cn(styles.noMatchesBoards, {
                  [styles.highlight]: selectRow === lastSuggestion,
                })}
                onClick={closeDropdown}
              >
                None
              </li>
            )}
            {currentHubId && boardsSuggestions.length === 0 && (
              <li
                className={cn(styles.noMatches, {
                  [styles.highlight]: selectRow === lastSuggestion,
                })}
                onClick={closeDropdown}
              >
                No matches
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
