import { getUserActions } from '@api/actions';
import { actions } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

export const useUserActions = () => {
  const items = useQuery({
    queryKey: [actions.all],
    queryFn: () => getUserActions(),
  });

  return {
    items,
  };
};
