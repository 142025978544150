import { ChangeEvent, KeyboardEvent, Ref } from 'react';

import cn from 'classnames';
import { FormikErrors } from 'formik';

import { Input } from '@visualist/design-system';

import styles from './styles.module.css';

interface BookmarkDialogProps {
  isMobileOnly: boolean;
  inputRef: Ref<HTMLInputElement>;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  name: string;
  errors: FormikErrors<{
    name: string;
  }>;
}

export const BookmarkDialog = ({
  handleOnKeyPress,
  inputRef,
  isMobileOnly,
  onHandleChange,
  name,
  errors,
}: BookmarkDialogProps) => {
  const handleFocus = (event: ChangeEvent<HTMLInputElement>) =>
    event.target.select();

  return (
    <div>
      <h1 className={styles.heading}>
        Bookmark pages and files to view and edit them here in Visualist
      </h1>
      <p className={styles.subHeading}>
        Check your file’s settings to make sure others in the hub can access it.
      </p>
      <div
        className={cn(styles.input, {
          [styles.inputMobile]: isMobileOnly,
          [styles.invalidLink]: errors.name,
        })}
      >
        <Input
          className={styles.input}
          customErrorMessage={cn(styles.error, {
            [styles.errorMobile]: isMobileOnly,
          })}
          type="text"
          ref={inputRef}
          name="name"
          placeholder="Paste YouTube, Google and other links here..."
          customLabel="URL"
          value={name}
          onChange={onHandleChange}
          onKeyPress={handleOnKeyPress}
          onFocus={handleFocus}
          errorMessage={errors.name}
        />
      </div>
    </div>
  );
};
