import { api } from '.';

/**
 * @description Sends a request to the API to never ask for the tagging modal WS ping again
 */

export const doNotAskAgainTaggingModal = async (
  type: 'tagging_prompt' | (string & {}),
) => {
  const { data } = await api.post('/connections/vai/do_not_ask_again/', {
    type,
  });
  return data;
};
