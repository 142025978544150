import { Dispatch, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import useOnclickOutsideMultiple from 'react-cool-onclickoutside';
import { isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

import {
  LeftHandLinkGroup,
  LeftHandNav,
  MiniLogo,
} from '@visualist/design-system';
import { useOnClickOutside, useWindowSize } from '@visualist/hooks';

import { $isScrolling, $isShowNavrailmenu } from '@components/header/model';
import { $docRenamingDialog } from '@pages/DocPage/model';
import {
  $isFileCardOpen,
  $isOpenFileCardDotMenu,
  fileCardDotMenuClosed,
} from '@pages/FileCard/model';
import { useAppData } from '@src/AppContext';
import { $hubDeleteModalState } from '@src/entities/delete-modals/modal';
import {
  $docsDotMenuNavrail,
  $isOpenDocDeleteModalNavrail,
  $isShowNavrailMenuBackdrop,
  docsDotMenuNavrailClosed,
  navrailMenuBackdropHidden,
  navrailMenuBackdropShown,
} from '@src/model';
import { SMALL } from '@src/shared/constants/breakpoints';

import { Avatar } from './avatar';
import { Doc } from './docs';
import { DrawerContainer } from './drawer-container';
import { Files } from './files';
import { HamburgerMenu } from './hamburger-menu';
import { Help } from './help';
import { Home } from './home';
import { NavRails } from './navrails';
import { Search } from './search';
import { Studio } from './studio';
import { VaiButton } from './vai';

import styles from './styles.module.css';

type NavRailMenuProps = {
  mobile?: boolean;
  setMobileNavrailMenu?: Dispatch<boolean>;
  showDrawer?: boolean;
  setShowDrawer?: Dispatch<boolean>;
};

export const NavRailMenu = ({
  mobile,
  setMobileNavrailMenu,
  showDrawer,
  setShowDrawer,
}: NavRailMenuProps) => {
  //@ts-ignore
  const isTablet = useMediaQuery({ minWidth: 760, maxWidth: 992 });
  const [showHelp, setShowHelp] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [menuDescriptor, setMenuDescriptor] = useState(true);
  const [activeTabProjects, setActiveTabProjects] = useState(false);
  const [avatarClicked, setAvatarClicked] = useState(false);
  const [accountClicked, setAccountClicked] = useState(false);

  const [
    isShowNavrailmenu,
    isShowNavrailMenuBackdrop,
    docsDotMenuNavrail,
    docRenamingDialog,
    isOpenDocDeleteModalNavrail,
  ] = useUnit([
    $isShowNavrailmenu,
    $isShowNavrailMenuBackdrop,
    $docsDotMenuNavrail,
    $docRenamingDialog,
    $isOpenDocDeleteModalNavrail,
  ]);

  const navRef = useRef<HTMLElement>(null);

  const { user } = useAppData();

  const { width } = useWindowSize();

  const firstLogin = user.meta?.firstLogin;
  const isSmallScreenSize = width <= SMALL;

  useEffect(() => {
    const signedApp = document.querySelector('.signedIn');
    showDrawer
      ? signedApp && signedApp.classList.add('drawerOpen')
      : signedApp && signedApp.classList.remove('drawerOpen');
  }, [showDrawer]);

  useEffect(() => {
    if (user && user.last_login !== localStorage.getItem('lastLogin')) {
      localStorage.setItem('lastLogin', user.last_login);
    }
  }, [user, firstLogin]);

  useEffect(() => {
    if (!isShowNavrailMenuBackdrop && setShowDrawer) {
      setShowDrawer(false);
      setActiveTabProjects(false);
    }
  }, [isShowNavrailMenuBackdrop, setShowDrawer]);

  const closeNavrailMenu = () => {
    if (
      mobile &&
      setMobileNavrailMenu &&
      !showDrawer &&
      !showHelp &&
      !showDropdown
    ) {
      setMobileNavrailMenu(false);
      setMenuDescriptor(true);
    }
  };

  useOnClickOutside(navRef, closeNavrailMenu);

  const drawerButtonCLickHandler = () => {
    if (setShowDrawer) {
      setShowDrawer(!showDrawer);
    }
    setMenuDescriptor(false);
    setActiveTabProjects(!activeTabProjects);

    if (showDrawer && setMobileNavrailMenu) {
      setMobileNavrailMenu(false);
    }

    if (showHelp) {
      setShowHelp(false);
    }

    if (showDropdown) {
      setShowDropdown(false);
    }

    if (!showDrawer && !isMobileOnly) {
      navrailMenuBackdropShown();
    } else {
      navrailMenuBackdropHidden();
    }
  };

  const navrailMenuRef = useOnclickOutsideMultiple(
    () => {
      if (
        setShowDrawer &&
        !docsDotMenuNavrail &&
        !docRenamingDialog &&
        !isOpenDocDeleteModalNavrail
      ) {
        setShowDrawer(false);
        navrailMenuBackdropHidden();
        setActiveTabProjects(false);
      } else {
        docsDotMenuNavrailClosed();
      }
    },
    {
      eventTypes: ['mousedown'],
    },
  );

  const resetAvatarBackdrop = () => {
    setShowDropdown(false);
    setAvatarClicked(false);
  };

  useEffect(() => {
    if (accountClicked) {
      setAvatarClicked(false);
      setShowDropdown(false);
      setAccountClicked(false);
    }
    if (isTablet && avatarClicked && showDropdown) {
      setShowDropdown(true);
      setAvatarClicked(true);
    }
    if (!avatarClicked && isTablet && !showDropdown) {
      setShowDropdown(false);
    }
    if (showHelp) {
      setShowDropdown(false);
      setAvatarClicked(false);
    }
  }, [isTablet, avatarClicked, showHelp, accountClicked, showDropdown]);

  return (
    <>
      {isShowNavrailmenu && !isSmallScreenSize ? (
        <LeftHandNav
          mobile={mobile}
          navRef={navRef}
          menuDescriptor={menuDescriptor}
          showHelp={showHelp}
        >
          <LeftHandLinkGroup mobile={mobile} menuDescriptor={menuDescriptor}>
            {!mobile && <MiniLogo />}
            <div className={styles.hamburgerMenu}>
              <HamburgerMenu
                drawerButtonCLickHandler={drawerButtonCLickHandler}
                activeTabProjects={activeTabProjects}
                navrailMenuRef={navrailMenuRef}
              />
              <Search />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Home />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Files />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <Studio />
            </div>
            <div style={{ marginBottom: '24px' }}>
              <Doc />
            </div>
            <div>
              <VaiButton />
            </div>
          </LeftHandLinkGroup>

          <LeftHandLinkGroup
            position="bottom"
            mobile={mobile}
            menuDescriptor={menuDescriptor}
          >
            <div style={{ marginBottom: '16px' }}>
              <Help isSmallScreenSize={isSmallScreenSize} />
            </div>
            <Avatar isSmallScreenSize={isSmallScreenSize} />
          </LeftHandLinkGroup>
        </LeftHandNav>
      ) : (
        <Navigation
          showDrawer={showDrawer}
          drawerButtonCLickHandler={drawerButtonCLickHandler}
          activeTabProjects={activeTabProjects}
          avatar={<Avatar isSmallScreenSize={isSmallScreenSize} />}
          navrailMenuRef={navrailMenuRef}
          isSmallScreenSize={isSmallScreenSize}
        />
      )}

      {showDrawer && setShowDrawer ? (
        <DrawerContainer
          drawerShow={showDrawer}
          setShowDrawer={setShowDrawer}
          closeButtonClickHandler={drawerButtonCLickHandler}
          navrailMenuRef={navrailMenuRef}
        />
      ) : null}

      {showDropdown && avatarClicked && (
        <div className="backdrop-avatar" onClick={resetAvatarBackdrop} />
      )}
    </>
  );
};

const Navigation = ({
  showDrawer,
  drawerButtonCLickHandler,
  activeTabProjects,
  avatar,
  navrailMenuRef,
}: any) => {
  const [isFileCardOpen, isOpenFileCardDotMenu, hubDeleteModalState] = useUnit([
    $isFileCardOpen,
    $isOpenFileCardDotMenu,
    $hubDeleteModalState,
  ]);

  const handleBackdropClick = () => {
    if (isOpenFileCardDotMenu) {
      fileCardDotMenuClosed();
    }
  };

  return (
    <div
      className={cn(styles.navigation, {
        [styles.confirmationDialogOpened]: !!hubDeleteModalState,
      })}
      onClick={handleBackdropClick}
    >
      {isFileCardOpen ? (
        <NavRails />
      ) : (
        <NavBars
          showDrawer={showDrawer}
          drawerButtonCLickHandler={drawerButtonCLickHandler}
          activeTabProjects={activeTabProjects}
          avatar={avatar}
          navrailMenuRef={navrailMenuRef}
        />
      )}
    </div>
  );
};

const NavBars = ({
  showDrawer,
  drawerButtonCLickHandler,
  activeTabProjects,
  avatar,
  navrailMenuRef,
}: any) => {
  const isScrolling = useUnit($isScrolling);

  return (
    <>
      <div
        style={{
          position: 'relative',
          top: isScrolling && !showDrawer ? '-400px' : '0',
          transition: 'top 0.4s',
        }}
        className={styles.topBar}
      >
        <div className={styles.leftSide}>
          <HamburgerMenu
            drawerButtonCLickHandler={drawerButtonCLickHandler}
            activeTabProjects={activeTabProjects}
            navrailMenuRef={navrailMenuRef}
          />
        </div>
        <div className={styles.centerSide}>
          <Search />
          <VaiButton isMobile />
        </div>
        <div className={styles.rightSide}>{avatar}</div>
      </div>

      <div
        style={{
          position: 'relative',
          top: isScrolling && !showDrawer ? '400px' : '0',
          transition: 'top 0.4s',
        }}
        className={styles.bottomBar}
      >
        <Home />
        <Files />
        <Studio />
        <Doc />
      </div>
    </>
  );
};
