import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import { boardsSearchDeactivated } from '@pages/DocPage/model';
import { deleteBoardModalClosed } from '@src/entities/delete-modals/modal';

import { parentBoardUnSetted } from '../../pages/BoardPage/model';

export type Variant = 'create-board' | 'create-hub' | 'create-bookmark';

export const boardIdPassed = createEvent<string>();
export const boardIdCleaned = createEvent();
export const boardRenamingDialogOpened = createEvent<string>();
export const boardRenamingDialogClosed = createEvent();
export const boardCreationDialogOpened = createEvent<Variant>();
export const boardCreationDialogClosed = createEvent();
export const boardSearchActived = createEvent<string>();
export const boardSearchNotActived = createEvent();
export const hubSearchActived = createEvent();
export const hubSearchNotActived = createEvent();
export const addingBoardToHubDialogOpened = createEvent();
export const addingBoardToHubDialogClosed = createEvent();
export const hubNameSetted = createEvent<string>();
export const hubNameUnSetted = createEvent();
export const addingDocToHubDialogOpened = createEvent();
export const setHubIdForDialog = createEvent<string>();

export const $boardRenamingDialog = createStore('');
export const $boardCreationDialog = createStore('');
export const $boardSearchActive = createStore('');
export const $hubSearchActive = createStore(false);
export const $hubSearchDropdownClose = createStore(true);
export const $addingBoardToHubDialog = createStore(false);
export const $addingDocToHubDialog = createStore(false);
export const $boardId = createStore('');
export const $hubName = createStore('');
export const $hubId = createStore('');

$hubName.on(hubNameSetted, (_, name) => name).reset(hubNameUnSetted);

$hubId
  .on(setHubIdForDialog, (_, p) => p)
  .reset([
    boardCreationDialogOpened,
    addingBoardToHubDialogOpened,
    addingDocToHubDialogOpened,
    boardIdPassed,
  ]);

$boardId
  .on(boardIdPassed, (_, id) => id)
  .reset([boardIdCleaned, boardRenamingDialogClosed, deleteBoardModalClosed]);

$boardRenamingDialog
  .on(boardRenamingDialogOpened, (_, boardName) => boardName)
  .reset(boardRenamingDialogClosed);

const $isBoardRenamingDialogOpen = $boardRenamingDialog.map(
  (boardName) => boardName.length > 0,
);

$boardCreationDialog
  .on(boardCreationDialogOpened, (_, payload) => payload)
  .reset(boardCreationDialogClosed);

export const $isBoardCreationDialogOpen = $boardCreationDialog.map(
  (name) => name.length > 0,
);

export const $isShowBoardDialog = combine(
  $isBoardCreationDialogOpen,
  $isBoardRenamingDialogOpen,
  (isBoardCreationDialogOpen, isBoardRenamingDialogOpen) =>
    isBoardCreationDialogOpen || isBoardRenamingDialogOpen,
);

$hubSearchActive.on(hubSearchActived, () => true).reset(hubSearchNotActived);
$hubSearchDropdownClose
  .on(hubSearchActived, () => false)
  .reset(hubSearchNotActived);

$addingBoardToHubDialog
  .on([addingBoardToHubDialogOpened, addingDocToHubDialogOpened], () => true)
  .reset(addingBoardToHubDialogClosed);

$addingDocToHubDialog
  .on(addingDocToHubDialogOpened, () => true)
  .reset(addingBoardToHubDialogClosed);

export const $isAddingAndCreatingBoardDialogOpen = combine(
  $addingBoardToHubDialog,
  $boardCreationDialog,
  (addingBoardToHubDialog, boardCreationDialog) =>
    addingBoardToHubDialog && Boolean(boardCreationDialog),
);

$boardSearchActive
  .on(boardSearchActived, (_, name) => name)
  .reset(boardSearchNotActived);

export const $isBoardSearchDropdownClose = $boardSearchActive.map(
  (name) => name.length === 0,
);

export const $isBoardSearchDropdownOpen = $boardSearchActive.map(
  (name) => name.length > 0,
);

export const $isDropdownsClosed = combine(
  $isBoardSearchDropdownClose,
  $hubSearchDropdownClose,
  (isBoardSearchDropdownClose, isBoardRenamingDialogOpen) =>
    isBoardSearchDropdownClose && isBoardRenamingDialogOpen,
);

hotkey({
  key: 'Escape',
  filter: $isBoardRenamingDialogOpen,
  target: boardRenamingDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isBoardCreationDialogOpen && $isDropdownsClosed,
  target: [
    boardCreationDialogClosed,
    addingBoardToHubDialogClosed,
    boardsSearchDeactivated,
  ],
});

hotkey({
  key: 'Escape',
  filter: $isBoardSearchDropdownOpen,
  target: [boardSearchNotActived, parentBoardUnSetted],
});

hotkey({
  key: 'Escape',
  filter:
    $addingBoardToHubDialog &&
    $hubSearchDropdownClose &&
    $isBoardSearchDropdownOpen,
  target: [addingBoardToHubDialogClosed, boardsSearchDeactivated],
});

hotkey({
  key: 'Escape',
  filter: $hubSearchActive,
  target: [hubSearchNotActived, hubNameUnSetted],
});
