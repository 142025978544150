import { createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

type HubOpenDeleteEvent = {
  hubId: string;
  name: string;
};

type BoardOpenDeleteEvent = {
  boardId: string;
  hubId?: string;
  parentBoardId?: string;
};

export const $hubDeleteModalState = createStore<HubOpenDeleteEvent | null>(
  null,
);
export const $boardDeleteModalState = createStore<BoardOpenDeleteEvent | null>(
  null,
);

export const deleteHubModalOpened = createEvent<HubOpenDeleteEvent>();
export const deleteHubModalClosed = createEvent();

export const deleteBoardModalOpened = createEvent<BoardOpenDeleteEvent>();
export const deleteBoardModalClosed = createEvent();

$hubDeleteModalState
  .on(deleteHubModalOpened, (_, payload) => payload)
  .reset(deleteHubModalClosed);

$boardDeleteModalState
  .on(deleteBoardModalOpened, (_, payload) => payload)
  .reset(deleteBoardModalClosed);

hotkey({
  key: 'Escape',
  filter: $hubDeleteModalState.map(Boolean),
  target: [deleteHubModalClosed],
});

hotkey({
  key: 'Escape',
  filter: $boardDeleteModalState.map(Boolean),
  target: [deleteBoardModalClosed],
});
