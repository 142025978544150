import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getBoards } from '../../../../../api/boards';

export function useAllBoards(
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof getBoards>>>,
    'queryKey' | 'queryFn'
  >,
) {
  return useQuery({
    ...options,
    queryKey: ['all_boards'],
    queryFn: getBoards,
  });
}
