import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  createBoard,
  restoreBoard,
  softDeleteBoard,
  UpdateBoard,
  updateBoard,
} from '@api/boards';
import { boardsKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  actions,
  ALL_BOARDS_QUERY,
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
  HUB_QUERY,
} from '../../../../../shared/constants/query-names';

export type CreateBoard = {
  name: string;
  parent?: string;
  hub?: string;
  description?: string;
};

type RemoveBoard = {
  id: string;
  hubId?: string;
  parentId?: string;
};

interface UseBoards {
  handleSuccessMakeBoard?: () => void;
  handleSuccessEditBoard?: () => void;
  handleSuccessRemoveBoard?: () => void;
  parentBoardId?: string;
  hub_id?: string;
}

export const useBoards = ({
  handleSuccessMakeBoard,
  handleSuccessEditBoard,
  handleSuccessRemoveBoard,
  parentBoardId,
  hub_id,
}: UseBoards) => {
  const { pathname } = useLocation();

  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: makeBoard } = useMutation({
    mutationFn: ({ name, parent, hub, description }: CreateBoard) =>
      createBoard({ name, parent, hub, description }),
    onSuccess: (data, { hub }) => {
      if (handleSuccessMakeBoard) handleSuccessMakeBoard();
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId: hub }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: data?.parent }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hubId: hub_id }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      startedSnack({
        label: 'Created board',
        action: {
          label: 'Go to board',
          action: () => {
            history.push(`/board/${data.id}`);
          },
        },
        close: true,
      });
    },
    onError: (error, { name, parent, hub, description }) => {
      startedSnack({
        label: "Couldn't create board",
        action: {
          label: 'Try again',
          action: () => makeBoard({ name, parent, hub, description }),
        },
        close: true,
      });
    },
  });

  const { mutate: editBoard } = useMutation({
    mutationFn: (payload: UpdateBoard) => updateBoard(payload),
    onSuccess: (data) => {
      if (handleSuccessEditBoard) handleSuccessEditBoard();
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: data?.parent }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: data?.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hubId: hub_id }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      startedSnack({
        label: 'Updated board',
        close: true,
      });
    },
    onError: (err, { name, boardId, description }) => {
      if (name) {
        startedSnack({
          label: "Couldn't update board",
          action: {
            label: 'Try again',
            action: () => {
              editBoard({ name, boardId, description });
            },
          },
          close: true,
        });
      }
    },
  });

  const { mutate: removeBoard } = useMutation({
    mutationFn: ({ id }: RemoveBoard) => softDeleteBoard(id),
    onSuccess: (data, variables) => {
      if (handleSuccessRemoveBoard) handleSuccessRemoveBoard();
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: parentBoardId }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: hub_id || variables.hubId }],
      });
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId: hub_id || variables.hubId }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      if (pathname.includes(variables.id)) {
        // On board page go home
        if (parentBoardId || variables.parentId) {
          history.push(`/board/${parentBoardId ?? variables.parentId}`);
        } else {
          // If no parent board check for parent hub, otherwise redirect home
          if (variables.hubId) {
            history.push(`/h/${variables.hubId}`);
          } else {
            history.push(`/home`);
          }
        }
      }

      startedSnack({
        label: 'Deleted board',
        action: {
          label: 'Undo',
          action: () => {
            putBoardBack(variables.id);
          },
        },
        close: true,
      });
    },
    onError: (err, { id }) => {
      startedSnack({
        label: "Couldn't delete board",
        action: {
          label: 'Try again',
          action: () => {
            removeBoard({ id });
          },
        },
        close: true,
      });
    },
  });

  const { mutate: putBoardBack } = useMutation({
    mutationFn: (boardId: string) => restoreBoard({ boards: [boardId] }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARDS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: hub_id }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });
    },
  });

  return { makeBoard, editBoard, removeBoard };
};
