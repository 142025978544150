import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence } from 'framer-motion';
import { Icon } from 'icons';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Board } from '@visualist/design-system';
import {
  Dropdown,
  Fab,
  Item,
  Palette,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { useOnClickOutside } from '@visualist/hooks';

import { boardDialogOpened } from '@src/entities/dialogs/board/model';
import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { paletteDialogOpened } from '@src/entities/dialogs/palette/model';
import { useBilling } from '@src/shared/queries/useBilling';
import { useMutation } from '@tanstack/react-query';

import { createNewSet } from '../../api';
import { useCreateDoc } from '../../entities/doc/queries/useCreateDoc';
import { Plan } from '../../pages/AccountPage/components/types';
import { useBoardContext } from '../../pages/BoardPage';
import { dropdownClosed } from '../../pages/BoardPage/model';
import {
  $docRenamingDialog,
  boardsSearchDeactivated,
  docRenamingDialogClosed,
} from '../../pages/DocPage/model';
import {
  $bookmarkCreationDialog,
  $bookmarkEditDialog,
  $hubCreationDialog,
  $hubRenamingDialog,
  bookmarkCreationDialogClosed,
  bookmarkEditDialogClosed,
  hubCreationDialogClosed,
  hubRenamingDialogClosed,
  titleDropdownClosed,
} from '../../pages/HubPage/model';
import { CreateDialogsBox } from '../CreateDialogsBox';
import {
  $addingBoardToHubDialog,
  $boardCreationDialog,
  $boardRenamingDialog,
  $hubSearchDropdownClose,
  $isBoardSearchDropdownClose,
  addingBoardToHubDialogClosed,
  boardCreationDialogClosed,
  boardRenamingDialogClosed,
} from '../CreateDialogsBox/model';
import { Page } from '../LibraryActions/LibraryActions';

import styles from './ActionButton.module.css';

export interface ActionButtonProps {
  className?: string;
  mobile?: boolean;
  uploadAction: any;
  showFAB?: boolean;
  handleOpen?: any;
  handleClose?: any;
  currentBoard?: Board;
  page?: Page;
  plan?: Plan;
}

enum ActionButtonValues {
  CREATE_HUB = 'create-hub',
  CREATE_BOARD = 'create-board',
  CREATE_DOC = 'create-doc',
  CREATE_COLOR_BLOCK = 'create-color-block',
  UPLOAD_IMAGES = 'upload-images',
  IMPORT_IMAGES = 'import-images',
}

const ActionButton = ({
  className = '',
  uploadAction,
  showFAB = false,
  handleOpen,
  handleClose,
  currentBoard,
  page,
  plan,
}: ActionButtonProps) => {
  const linkRef = useRef<any>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState<string>('');
  const [overlay, setOverlay] = useState(false);

  const boardRenamingDialog = useUnit($boardRenamingDialog);
  const boardCreationgDialog = useUnit($boardCreationDialog);
  const hubCreationgDialog = useUnit($hubCreationDialog);
  const hubRenamingDialog = useUnit($hubRenamingDialog);
  const bookmarkCreationDialog = useUnit($bookmarkCreationDialog);
  const bookmarkEditDialog = useUnit($bookmarkEditDialog);
  const addingBoardToHubDialog = useUnit($addingBoardToHubDialog);
  const isBoardSearchDropdownClose = useUnit($isBoardSearchDropdownClose);
  const hubSearchDropdownClose = useUnit($hubSearchDropdownClose);
  const docRenamingDialog = useUnit($docRenamingDialog);

  const dialogBoxRef = useRef(null);
  const { board_id } = useParams<{ board_id: string }>();

  useOnClickOutside(dialogBoxRef, (e) => {
    if (
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        boardCreationgDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        addingBoardToHubDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        boardRenamingDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        hubCreationgDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        bookmarkCreationDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        bookmarkEditDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        hubRenamingDialog) ||
      (isBoardSearchDropdownClose &&
        hubSearchDropdownClose &&
        docRenamingDialog)
    ) {
      handleClickBackdrop(e);
    }
  });

  useEffect(() => {
    if (
      boardRenamingDialog ||
      boardCreationgDialog ||
      hubCreationgDialog ||
      bookmarkCreationDialog ||
      addingBoardToHubDialog ||
      bookmarkEditDialog ||
      hubRenamingDialog ||
      docRenamingDialog
    ) {
      setOverlay(true);
    } else {
      setOverlay(false);
      dropdownClosed();
      titleDropdownClosed();
    }
  }, [
    boardRenamingDialog,
    boardCreationgDialog,
    hubCreationgDialog,
    bookmarkCreationDialog,
    addingBoardToHubDialog,
    bookmarkEditDialog,
    hubRenamingDialog,
    docRenamingDialog,
  ]);

  const history = useHistory();
  const { pathname } = useLocation();
  const { hasPermissionEditing } = useBoardContext();
  const { isWithinThreeDays } = useBilling();

  const createDoc = () => {
    board_id ? makeDoc({ board: board_id }) : makeDoc({});
  };

  const createDesign = () => {
    createNewSetMutation();
  };

  const uploadImages = () => {
    uploadAction();
  };

  const menuItems: Item[] = hasPermissionEditing
    ? [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          trailingIcon:
            plan === 'free' || plan === 'starter' ? (
              <TypographyPoppins
                className={styles.badge}
                type="label"
                labelSize="XS"
              >
                Pro users only
              </TypographyPoppins>
            ) : null,
          isDisabled:
            plan === 'free' || plan === 'starter' || isWithinThreeDays,
          onClick: hubDialogOpened,
        },
        {
          leadingIcon: <Icon name="sprite/board" />,
          content: 'Create board',
          onClick: boardDialogOpened,
        },
        {
          leadingIcon: <Icon name="sprite/doc" />,
          content: 'Create doc',
          onClick: createDoc,
        },
        {
          leadingIcon: <Icon name="sprite/studio" />,
          content: 'Create design',
          onClick: createDesign,
        },
        {
          leadingIcon: <Icon name="sprite/upload" />,
          content: 'Upload images',
          onClick: uploadImages,
        },
        {
          leadingIcon: <Palette fill="none" />,
          content: 'Create palette',
          onClick: paletteDialogOpened,
        },
      ]
    : [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          onClick: hubDialogOpened,
        },
        {
          leadingIcon: <Icon name="sprite/studio" />,
          content: 'Create design',
          onClick: createDesign,
        },
      ];

  if (isMobile) {
    menuItems.splice(2, 1);
  }

  const toggleOpenMenu = () => {
    setMenuIsOpen(!menuIsOpen);
    if (openDialog) {
      setOpenDialog('');
      setOverlay(false);
    }
    if (handleOpen && !menuIsOpen) {
      handleOpen();
    }
    if (handleClose && menuIsOpen) {
      handleClose();
    }
  };

  const handleClickBackdrop = (e: any) => {
    e.stopPropagation();
    setMenuIsOpen(false);
    if (handleLeave) {
      handleLeave();
    }
    if (handleClose) {
      handleClose();
    }
  };

  const handleLeave = () => {
    setOpenDialog('');
    setOverlay(false);
    boardRenamingDialogClosed();
    dropdownClosed();
    boardCreationDialogClosed();
    hubCreationDialogClosed();
    bookmarkCreationDialogClosed();
    bookmarkEditDialogClosed();
    addingBoardToHubDialogClosed();
    hubRenamingDialogClosed();
    docRenamingDialogClosed();
    boardsSearchDeactivated();
  };

  const { mutate: createNewSetMutation } = useMutation({
    mutationFn: () => {
      if (board_id) {
        return createNewSet({ name: 'Untitled', boardId: board_id });
      } else {
        return createNewSet({ name: 'Untitled' });
      }
    },
    onSuccess: async ({ data }) => {
      history.push(`/studio/${data.id}`);
    },
    onError: () => {
      startedSnack({
        label: "Couldn't create design",
        action: {
          label: 'Try again',
          action: () => {
            if (board_id) {
              return createNewSet({ name: 'Untitled', boardId: board_id });
            } else {
              return createNewSet({ name: 'Untitled' });
            }
          },
        },
        close: true,
      });
    },
  });

  const { makeDoc } = useCreateDoc();

  // fab control from mobile menu
  useEffect(() => {
    if (isMobile) {
      if (!showFAB) {
        setOpenDialog('');
        setOverlay(false);
      }
      setMenuIsOpen(showFAB);
    }
  }, [showFAB]);

  return (
    <>
      <div
        className={cn(styles.backdrop, {
          [styles.menuIsOpen]: menuIsOpen,
          [styles.overlay]: overlay,
        })}
        onClick={() => setMenuIsOpen(false)}
      />
      <div
        className={cn(styles.container, className, styles.desktop, {
          [styles.mobile]: isMobile,
          [styles.docPage]: page === 'docPage',
        })}
      >
        <AnimatePresence>
          <Dropdown>
            <Dropdown.Menu
              trigger={
                <Fab
                  style="small"
                  icon={<Icon name="sprite/plus" />}
                  onClick={toggleOpenMenu}
                />
              }
              side="top"
              sideOffset={15}
              collisionPadding={20}
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </AnimatePresence>
      </div>
      <div className={styles.boardBox} ref={dialogBoxRef}>
        <CreateDialogsBox
          creatingBoard={
            boardCreationgDialog === ActionButtonValues.CREATE_BOARD
          }
          renamingBoard={boardRenamingDialog}
          creatingHub={hubCreationgDialog === ActionButtonValues.CREATE_HUB}
          renamingHub={hubRenamingDialog}
          closeDialog={handleLeave}
          className={cn(styles.containerBoard, {
            [styles.containerBoardMobile]: isMobileOnly,
            [styles.containerBoardMobileLibrary]:
              isMobileOnly && pathname === '/library',
          })}
          onSuccess={() => {
            setMenuIsOpen(false);
          }}
          openDialog={boardCreationgDialog}
          currentBoard={currentBoard}
          creatingBookmark={bookmarkCreationDialog}
          editBookmark={bookmarkEditDialog}
          addingBoardToHubDialog={addingBoardToHubDialog}
          renamingDoc={docRenamingDialog}
        />
      </div>
      <a
        hidden
        ref={linkRef}
        href="https://chrome.google.com/webstore/detail/visualist-chrome-extensio/kfkkhoppmbfmfmacgfagijkmphpiibjd"
        rel="noopener noreferrer"
        target="_blank"
      >
        Visualist Chrome Extension
      </a>
    </>
  );
};

export default ActionButton;
