import React from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

type DividerProps = {
  mobile?: boolean;
  menuDescriptor?: boolean;
  className?: string;
};

export const Divider = ({
  mobile,
  menuDescriptor,
  className,
}: DividerProps) => (
  <div
    className={cn(styles.divider, className, {
      [styles.mobileDivider]: mobile && menuDescriptor,
    })}
  />
);
