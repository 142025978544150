import { ChangeEvent, KeyboardEvent, Ref } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';

import { Input } from '@visualist/design-system';

import styles from './styles.module.css';

interface DocDialogProps {
  isMobileOnly: boolean;
  inputRef: Ref<HTMLInputElement>;
  onHandleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleOnKeyPress: (e: KeyboardEvent<HTMLInputElement>) => void;
  name: string;
  renamingDoc: string;
}

export const DocDialog = ({
  handleOnKeyPress,
  inputRef,
  isMobileOnly,
  onHandleChange,
  name,
  renamingDoc,
}: DocDialogProps) => (
  <div
    className={cn(styles.input, {
      [styles.inputMobile]: isMobileOnly,
    })}
  >
    {!renamingDoc && <Icon name="sprite/hub" />}
    <Input
      className={cn(styles.input, {
        [styles.inputRenaming]: renamingDoc,
      })}
      type="text"
      ref={inputRef}
      name="name"
      customLabel="Doc name"
      value={name}
      onChange={onHandleChange}
      onKeyPress={handleOnKeyPress}
    />
  </div>
);
