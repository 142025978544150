import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { InvitationDoc, inviteToDoc } from '@api/docs';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../../../queryClient';
import {
  MEMBERS_INVITATION_DOC_QUERY,
  MEMBERS_SUGGESTIONS_DOC_QUERY,
} from '../../../../../shared/constants/query-names';

export const useInviteToDoc = (
  options?: MutateOptions<InvitationDoc, Error, InvitationDoc>,
) => {
  const { mutate: sendInvitation } = useMutation({
    mutationFn: ({ id, users, permissions }: InvitationDoc) =>
      inviteToDoc({ id, users, permissions }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_DOC_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_DOC_QUERY],
      });

      // startedSnack({
      //   label: `Invited them to ${variables.boardName}`,
      //   close: true,
      // });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (error.message.includes('406') && variables.setOn) {
        startedSnack({
          label: "You don't have guest seats left",
          close: true,
        });
        variables.setOn(false);
      }

      // else {
      //   startedSnack({
      //     label: `Couldn't invite them to ${variables.boardName}`,
      //     action: {
      //       label: 'Try again',
      //       action: () => {
      //         sendInvitation({
      //           boardId: variables.boardId,
      //           users: variables.users,
      //           boardName: variables.boardName,
      //           nested_board: variables.nested_board,
      //           permissions: variables.permissions,
      //         });
      //       },
      //     },
      //     close: true,
      //   });
      // }

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { sendInvitation };
};
