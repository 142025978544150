import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';
import { useHistory } from 'react-router-dom';

import { EmptyState } from '@visualist/design-system/src/components/v2';

import { boardDialogOpened } from '@src/entities/dialogs/board/model';
import { $isShowLibraryBackdrop, libraryBackdropHidden } from '@src/model';

import { Board, IBoard } from './Board';

import styles from './styles.module.css';

interface BoardStripeProps {
  boardsThumbnails: Array<IBoard>;
  parent: string;
  level: number;
}

export const BoardsStripe = ({
  boardsThumbnails,
  parent,
  level,
}: BoardStripeProps) => {
  const isShowLibraryBackdrop = useUnit($isShowLibraryBackdrop);

  const history = useHistory();

  const onClickHandler = (id: string) => {
    const location = `/board/${id}`;
    history.push(location);
  };

  return boardsThumbnails?.length === 0 && level !== 2 ? (
    <EmptyState
      icon={<Icon name="sprite/sticky-note-soft-colored" size={80} />}
      content="Want to stay organized?"
      label="Create a board"
      action={() => boardDialogOpened()}
    />
  ) : (
    <ul
      className={cn(styles.boardsList, {
        [styles.backdropBoardStripe]: isShowLibraryBackdrop,
      })}
      onClick={() => libraryBackdropHidden()}
    >
      {boardsThumbnails?.length > 0 &&
        boardsThumbnails.map(({ id, name, description }) => {
          return (
            <Board
              key={id}
              id={id}
              name={name}
              onClick={onClickHandler}
              parent={parent}
              description={description}
            />
          );
        })}
    </ul>
  );
};
