import { useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { motion, Variants } from 'framer-motion';
import { Icon } from 'icons';

import {
  Divider,
  SideSheet,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import {
  $isHubInfoSheetOpen,
  closedHubInfoSheet,
  openedHubCreditBundleFromInfo,
} from '@pages/AccountPage/models';

import styles from './styles.module.css';

export const HubInfoSheet = () => {
  const isHubInfoSheetOpen = useUnit($isHubInfoSheetOpen);

  const closeSheet = () => closedHubInfoSheet();

  const openCreditSheet = () =>
    openedHubCreditBundleFromInfo({
      bundleId: 'credit_pack_25',
    });

  return (
    <SideSheet
      className={styles.sideSheetContainer}
      handleBackdropClick={closeSheet}
      showSheet={isHubInfoSheetOpen}
      customWidth={388}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading closeSideSheet={closeSheet}>
        How we bill for hubs
      </SideSheet.Heading>
      <SideSheet.Body>
        <SideSheet.SubHeading>Pay for what you use</SideSheet.SubHeading>
        <SideSheet.ContentContainer>
          <SideSheet.Content>
            <span className={styles.content}>
              A hub is $12 per month.
              <br />
              <br />
              You can also pre-pay for hub credits and save up to 33%.
              <br />
              <br />
              Hub billing is simple.
              <br />
              <br />
              10 credits are valid for 1 hub for 1 month.
              <br />
              <br />
              When you create a hub, we'll deduct 10 credits from your balance.
              <br />
              <br />
              If you have no more credits, we'll charge you $12 for a hub.
              <br />
              <br />
              We'll then bill you again the following month if your hub is still
              open.
              <br />
              <br />
            </span>
          </SideSheet.Content>
          <FAQ />
        </SideSheet.ContentContainer>
      </SideSheet.Body>
      <SideSheet.BottomControls
        hideCancel
        primaryLabel="Top up my hub credits"
        closeSideSheet={closeSheet}
        mainClick={openCreditSheet}
      />
    </SideSheet>
  );
};

const FAQ_CONTENT = [
  {
    id: 1,
    question: 'When will I be charged for my hubs?',
    answer:
      'We bill you on the same date as your Pro plan. \n\nIn the first month you create your hub, we pro-rate the charge for the number of days remaining in your cycle. Subsequently, your hub will be charged on the same date as your Pro plan.\n\nRemember that you only pay for hubs that you use. Refer to your invoice to see when you’ve been charged.\n\n',
  },
  {
    id: 2,
    question: 'What happens if I have more than 1 hub?',
    answer:
      'Each hub is charged separately. In your monthly bill, you’ll see a list of all hubs that you’ve been charged for.\n\n',
  },
  // {
  //   id: 3,
  //   question:
  //     'I created a hub by mistake and immediately deleted it. Am I still charged?',
  //   answer:
  //     'We’ll refund your hub credit balance if you delete the hub within 7 days of your hub billing date.\n\n',
  // },
  {
    id: 4,
    question: 'Do hub credits expire?',
    answer:
      'Credits never expire, so it’s a good idea to stock up on them and save!\n\n',
  },
];

const FAQ = () => {
  const [selected, setSelected] = useState<number | null>(null);
  return (
    <div className={styles.accordionContainer}>
      {FAQ_CONTENT.map((faq) => {
        const expand = () => {
          if (selected === faq.id) {
            setSelected(null);
          } else {
            setSelected(faq.id);
          }
        };

        return (
          <Accordion
            faq={faq}
            key={faq.id}
            isExpanded={selected === faq.id}
            expand={expand}
          />
        );
      })}
    </div>
  );
};

const Accordion = ({
  isExpanded,
  expand,
  faq,
}: {
  isExpanded: boolean;
  expand: () => void;
  faq: (typeof FAQ_CONTENT)[number];
}) => {
  const variants: Variants = {
    open: {
      height: isExpanded ? 'auto' : 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0, 0, 1],
      },
    },
    hidden: {
      height: 0,
      transition: {
        duration: 0.5,
        ease: [0.2, 0, 0, 1],
      },
    },
  };

  return (
    <div className={styles.accordionRow}>
      <Divider type="long-line" />
      <div className={cn(styles.accordionQuestion)} onClick={expand}>
        <Icon
          name="sprite/chevron-down"
          className={cn(styles.downIcon, {
            [styles.rotate]: isExpanded,
          })}
        />
        <TypographyPoppins type="body" size="M">
          <span className={styles.questionLabel}>{faq.question}</span>
        </TypographyPoppins>
      </div>
      <motion.div
        variants={variants}
        className={styles.accordionAnswer}
        initial="hidden"
        animate="open"
      >
        <TypographyPoppins type="body" size="M">
          <span className={styles.accordionAnswer}>{faq.answer}</span>
        </TypographyPoppins>
      </motion.div>
    </div>
  );
};
