import React, { ReactNode } from 'react';

import cn from 'classnames';

import styles from './LeftHandNav.module.css';

type Props = {
  position?: 'top' | 'bottom';
  children: ReactNode;
  mobile?: boolean;
  menuDescriptor?: boolean;
};

const LeftHandLinkGroup = ({
  children,
  position = 'top',
  mobile,
  menuDescriptor,
}: Props) => {
  return (
    <div
      className={cn([
        styles.linkGroup,
        {
          [styles.bottom]: position === 'bottom',
          [styles.mobileMenu]: mobile && menuDescriptor,
        },
      ])}
    >
      {children}
    </div>
  );
};

export default LeftHandLinkGroup;
