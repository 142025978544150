import { Dispatch, SetStateAction } from 'react';

import { Icon } from 'icons';

import { TypographyItalic } from '@visualist/design-system/src/components/v2';

import { AllFileCopiesResponse } from '@api/blocks';
import { Board } from '@api/boards';

import { BoardIdsToCopy, BoardIdsToRemove } from '..';
import { EmptyListItem, ListItem } from '../ListItem';

import styles from './styles.module.css';

interface SearchBoardsListProps {
  boardsSuggestions: Array<Board>;
  allBoardsIds: string[];
  allFileCopies: AllFileCopiesResponse;
  idsSelectedBoards: string[];
  setIdsSelectedBoards: Dispatch<SetStateAction<Array<string>>>;
  boardIdsToRemove: BoardIdsToRemove;
  setBoardIdsToRemove: Dispatch<SetStateAction<BoardIdsToRemove>>;
  boardIdsToCopy: BoardIdsToCopy;
  setBoardIdsToCopy: Dispatch<SetStateAction<BoardIdsToCopy>>;
}

export const SearchBoardsList = ({
  boardsSuggestions,
  allBoardsIds,
  allFileCopies,
  idsSelectedBoards,
  setIdsSelectedBoards,
  boardIdsToRemove,
  boardIdsToCopy,
  setBoardIdsToRemove,
  setBoardIdsToCopy,
}: SearchBoardsListProps) => {
  if (boardsSuggestions.length === 0) {
    return (
      <EmptyListItem
        name={
          <TypographyItalic
            type="body"
            formattingBody="small italic"
            customStyle={styles.noItems}
          >
            Can't find any matches
          </TypographyItalic>
        }
      />
    );
  }

  return (
    <>
      {boardsSuggestions.map((board) => (
        <ListItem
          key={board.id}
          name={board.name}
          boardId={board.id}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          // hub={board.hub}
          level={board.level}
          allBoardsIds={allBoardsIds}
          allFileCopies={allFileCopies}
          boardIdsToRemove={boardIdsToRemove}
          setBoardIdsToRemove={setBoardIdsToRemove}
          boardIdsToCopy={boardIdsToCopy}
          setBoardIdsToCopy={setBoardIdsToCopy}
          idsSelectedBoards={idsSelectedBoards}
          setIdsSelectedBoards={setIdsSelectedBoards}
          board={board.parent}
          checkbox
        />
      ))}
    </>
  );
};
