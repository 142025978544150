import {
  ChangeEvent,
  KeyboardEvent,
  LegacyRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { FormikErrors, useFormik } from 'formik';
import useOnclickOutsideMultiple from 'react-cool-onclickoutside';
import { isMobileOnly } from 'react-device-detect';
import { useLocation, useParams } from 'react-router';

import { Board, ButtonPill } from '@visualist/design-system';

import { $description } from '@pages/HomePage/ShowPlaceholder/model';
import { $hubThumbnailID } from '@pages/HomePage/Title/model';
import { checkUrlPattern, KeyCodes } from '@src/shared/utils';
import { useQuery } from '@tanstack/react-query';

import { getBoards } from '../../api';
import { getHubs, HubResponse } from '../../api/hubs';
import { useUpdateDoc } from '../../entities/doc/queries/useUpdateDoc';
import {
  $parentBoard,
  parentBoardSetted,
  parentBoardUnSetted,
} from '../../pages/BoardPage/model';
import { useAddBoardToHub } from '../../pages/BoardPage/model/queries/useAddBoardToHub';
import { useBoards } from '../../pages/BoardPage/model/queries/useBoards';
import { $docId, $isActivateBoardsSearch } from '../../pages/DocPage/model';
import { $bookmarkdId } from '../../pages/HubPage/model';
import { useEditBookmark } from '../../pages/HubPage/model/queries/useEditBookmark';
import { useHubs } from '../../pages/HubPage/model/queries/useHubs';
import {
  BOARDS_SUGGESTIONS_QUERY,
  HUBS_SUGGESTIONS_QUERY,
} from '../../shared/constants';
import { CreateDialog } from './CreateDialog';
import {
  $addingDocToHubDialog,
  $boardId,
  $boardSearchActive,
  $hubId,
  $hubName,
  $hubSearchActive,
  $isAddingAndCreatingBoardDialogOpen,
  boardIdCleaned,
  boardSearchActived,
  boardSearchNotActived,
  hubNameSetted,
  hubNameUnSetted,
  hubSearchActived,
  hubSearchNotActived,
} from './model';

import styles from './styles.module.css';

enum DialogVariants {
  CREATE_HUB = 'CREATE_HUB',
  RENAME_HUB = 'RENAME_HUB',
  CREATE_BOARD = 'CREATE_BOARD',
  CREATE_SUB_BOARD = 'CREATE_SUB_BOARD',
  RENAME_BOARD = 'RENAME_BOARD',
  CREATE_BOOKMARK = 'CREATE_BOOKMARK',
  EDIT_BOOKMARK = 'EDIT_BOOKMARK',
  ADD_BOARD_TO_HUB = 'ADD_BOARD_TO_HUB',
  CREATE_BOARD_AND_MOVE_TO_HUB = 'CREATE_BOARD_AND_MOVE_TO_HUB',
  RENAME_DOC = 'RENAME_DOC',
  ADD_DOC_TO_HUB = 'ADD_DOC_TO_HUB',
}

enum ButtonActions {
  CREATE = 'Create',
  RENAME = 'Rename',
  ADD = 'Add',
}

interface ButtonsProps {
  onSubmit: () => void | Promise<any>;
  closeDialog: () => void;
  renamingBoard: string;
  renamingHub: string;
  emptyName?: string;
  creatingBookmark: string;
  editBookmark: string;
  name: string;
  hubName: string;
  addingBoardToHubDialog: boolean;
  hubsSuggestions?: HubResponse[];
  renamingDoc: string;
}

interface WrapperProps {
  children: ReactNode;
  creatingBoard: boolean;
  className: string;
  errorSnackbarRef: LegacyRef<HTMLDivElement>;
  renamingBoard: string;
  creatingHub: boolean;
  renamingHub: string;
  creatingBookmark: string;
  editBookmark: string;
  addingBoardToHubDialog: boolean;
  renamingDoc: string;
}

interface ColsProps {
  children: ReactNode;
  addingBoardToHubDialog: boolean;
}

interface CreateBoardBoxProps {
  closeDialog: () => void;
  creatingBoard: boolean;
  className: string;
  onSuccess: () => void;
  openDialog: string;
  currentBoard?: Board;
  renamingBoard: string;
  creatingHub: boolean;
  renamingHub: string;
  creatingBookmark: string;
  editBookmark: string;
  addingBoardToHubDialog: boolean;
  renamingDoc: string;
}

export const CreateDialogsBox = ({
  closeDialog,
  creatingBoard,
  className,
  onSuccess,
  openDialog,
  currentBoard,
  renamingBoard,
  creatingHub,
  renamingHub,
  creatingBookmark,
  editBookmark,
  addingBoardToHubDialog,
  renamingDoc,
}: CreateBoardBoxProps): JSX.Element => {
  const [parentBoardId, setParentBoardId] = useState<string | undefined>('');
  const [selectRow, setSelectRow] = useState<number | null>(null);
  const [variant, setVariant] = useState<DialogVariants | null>(null);
  const [currentHubId, setCurrentHubId] = useState('');
  const [initialValue, setInitialValue] = useState('');

  const boardId = useUnit($boardId);
  const hubIdState = useUnit($hubId);
  const bookmarkId = useUnit($bookmarkdId);
  const isAddingAndCreatingBoardDialogOpen = useUnit(
    $isAddingAndCreatingBoardDialogOpen,
  );
  const boardSearchActive = useUnit($boardSearchActive);
  const parentBoard = useUnit($parentBoard);
  const hubSearchActive = useUnit($hubSearchActive);
  const hubName = useUnit($hubName);
  const docId = useUnit($docId);
  const addingDocToHubDialog = useUnit($addingDocToHubDialog);
  const hubThumbnailID = useUnit($hubThumbnailID);
  const description = useUnit($description);

  const { board_id } = useParams<{ board_id: string }>();
  const { hub_id } = useParams<{ hub_id: string }>();
  const { doc_id } = useParams<{ doc_id: string }>();

  const inputRef = useRef<HTMLInputElement>(null);
  const errorSnackbarRef = useOnclickOutsideMultiple(() => {
    if (boardSearchActive || hubSearchActive) {
      closeDropdown();
    } else {
      resetForm();
      closeDropdown();
    }
  });

  const { data: boardsSuggestions = [] } = useQuery({
    queryKey: [
      BOARDS_SUGGESTIONS_QUERY,
      parentBoard,
      currentHubId,
      currentBoard,
    ],
    queryFn: () =>
      getBoards({
        name: parentBoard ? parentBoard : currentBoard?.name,
        level: ['0, 1'],
        hub: currentHubId,
      }),
    enabled: parentBoard.length > 0 || Boolean(currentBoard),
  });

  const { data: hubsSuggestions = [] } = useQuery({
    queryKey: [HUBS_SUGGESTIONS_QUERY, hubName],
    queryFn: () => getHubs({ name: hubName }),
  });

  const hubId = hubsSuggestions?.filter((hub) => hub.name === hubName)[0]?.id;

  const lastSuggestion = boardsSuggestions.slice(0, 5).length;

  const currentHubNamePage = hubsSuggestions?.filter(
    (hub) => hub.id === hub_id,
  )[0]?.name;

  useEffect(() => {
    if (
      creatingBoard ||
      renamingBoard ||
      creatingHub ||
      creatingBookmark ||
      editBookmark ||
      renamingHub ||
      renamingDoc
    ) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 70);
    }
  }, [
    creatingBoard,
    renamingBoard,
    creatingHub,
    creatingBookmark,
    editBookmark,
    renamingHub,
    renamingDoc,
  ]);

  useEffect(() => {
    if (!parentBoard) {
      boardSearchNotActived();
      setParentBoardId('');
    }

    if (!hubName) {
      hubSearchNotActived();
      setCurrentHubId('');
    }

    if (!creatingBoard && !addingBoardToHubDialog) {
      hubNameUnSetted();
    }
  }, [
    parentBoard,
    hubName,
    setParentBoardId,
    creatingBoard,
    addingBoardToHubDialog,
  ]);

  useEffect(() => {
    if (currentBoard) {
      const showOnlyOneOrSecondLevels = boardsSuggestions.filter(
        (board: Board) => board?.id === currentBoard.id,
      );

      if (showOnlyOneOrSecondLevels.length > 0) {
        selectBoard(
          showOnlyOneOrSecondLevels[0].name,
          showOnlyOneOrSecondLevels[0]?.id,
        );
      }
    }
    // eslint-disable-next-line
  }, [openDialog, currentBoard]);

  useEffect(() => {
    if (creatingHub) {
      setVariant(DialogVariants.CREATE_HUB);
    } else if (
      creatingBoard &&
      !parentBoard &&
      !currentHubId &&
      !hubSearchActive &&
      !boardSearchActive &&
      !currentHubNamePage
    ) {
      setVariant(DialogVariants.CREATE_BOARD);
    } else if (
      (creatingBoard && parentBoard && parentBoardId && !boardSearchActive) ||
      (isAddingAndCreatingBoardDialogOpen &&
        currentHubId &&
        parentBoard &&
        parentBoardId &&
        !boardSearchActive)
    ) {
      setVariant(DialogVariants.CREATE_SUB_BOARD);
    } else if (creatingBookmark) {
      setVariant(DialogVariants.CREATE_BOOKMARK);
    } else if (editBookmark) {
      setVariant(DialogVariants.EDIT_BOOKMARK);
    } else if (renamingBoard) {
      setVariant(DialogVariants.RENAME_BOARD);
    } else if (renamingHub) {
      setVariant(DialogVariants.RENAME_HUB);
    } else if (renamingDoc) {
      setVariant(DialogVariants.RENAME_DOC);
    } else if (
      addingBoardToHubDialog &&
      !addingDocToHubDialog &&
      !isAddingAndCreatingBoardDialogOpen
    ) {
      setVariant(DialogVariants.ADD_BOARD_TO_HUB);
    } else if (addingDocToHubDialog) {
      setVariant(DialogVariants.ADD_DOC_TO_HUB);
    } else if (
      (isAddingAndCreatingBoardDialogOpen || currentHubNamePage) &&
      !hubSearchActive
    ) {
      setVariant(DialogVariants.CREATE_BOARD_AND_MOVE_TO_HUB);
    } else {
      setVariant(null);
    }
  }, [
    creatingBoard,
    parentBoard,
    parentBoardId,
    creatingHub,
    creatingBookmark,
    editBookmark,
    renamingBoard,
    addingBoardToHubDialog,
    isAddingAndCreatingBoardDialogOpen,
    currentHubNamePage,
    currentHubId,
    hubSearchActive,
    boardSearchActive,
    renamingHub,
    renamingDoc,
    addingDocToHubDialog,
  ]);

  useEffect(() => {
    if (variant === DialogVariants.RENAME_BOARD) {
      setInitialValue(renamingBoard);
    } else if (variant === DialogVariants.EDIT_BOOKMARK) {
      setInitialValue(editBookmark);
    } else if (variant === DialogVariants.RENAME_HUB) {
      setInitialValue(renamingHub);
    } else if (variant === DialogVariants.RENAME_DOC) {
      setInitialValue(renamingDoc);
    }
  }, [variant, renamingBoard, editBookmark, renamingHub, renamingDoc]);

  const {
    values: { name },
    handleChange,
    handleSubmit,
    setErrors,
    resetForm,
    errors,
  } = useFormik({
    initialValues: { name: initialValue },

    validate: ({ name }) => {
      const errors: FormikErrors<{
        name: string;
      }> = {};

      if (
        (creatingBookmark || editBookmark) &&
        name.trim().length !== 0 &&
        !checkUrlPattern(name)
      ) {
        errors.name = 'Enter a valid URL';
      }

      return errors;
    },

    onSubmit: ({ name }) => {
      switch (variant) {
        case DialogVariants.CREATE_HUB:
          makeHub({ name, description });
          break;
        case DialogVariants.RENAME_HUB:
          if (hub_id || hubIdState) {
            editHub({ name, hubId: hub_id ?? hubIdState });
          } else {
            editHub({ name, hubId: hubThumbnailID ?? hubIdState });
          }
          break;
        case DialogVariants.CREATE_BOOKMARK:
          addBookmarks({
            url: name,
            hub: hub_id,
          });
          break;
        case DialogVariants.EDIT_BOOKMARK:
          updateBookmark({ url: name, id: bookmarkId });
          break;
        case DialogVariants.CREATE_BOARD:
          makeBoard({ name });
          break;
        case DialogVariants.CREATE_SUB_BOARD:
          if (boardsSuggestions[0].hub !== null) {
            makeBoard({
              name,
              parent: parentBoardId,
              hub: boardsSuggestions.find(
                (board: any) => board.id === parentBoardId,
              )?.hub?.id,
            });
          } else {
            makeBoard({
              name,
              parent: parentBoardId,
            });
          }
          break;
        case DialogVariants.RENAME_BOARD:
          editBoard({ name, boardId });
          break;
        case DialogVariants.ADD_BOARD_TO_HUB:
          addBoardToHub({ hub: hubId, boards: [board_id] });
          break;
        case DialogVariants.CREATE_BOARD_AND_MOVE_TO_HUB:
          makeBoard({ name, hub: currentHubId || hub_id });
          break;
        case DialogVariants.RENAME_DOC:
          editDoc({ docId, title: name });
          break;
        case DialogVariants.ADD_DOC_TO_HUB:
          editDoc({ docId: doc_id ? doc_id : docId, board: parentBoardId });
          break;
        default:
          return;
      }
      closeDialog();
    },
    enableReinitialize: true,
  });

  const handleSuccessMakeBoard = () => {
    onSuccess();
    resetForm();
  };

  const handleSuccessEditBoard = () => {
    boardIdCleaned();
  };

  const { editBoard, makeBoard } = useBoards({
    handleSuccessMakeBoard,
    handleSuccessEditBoard,
  });

  const onSuccessHub = () => {
    resetForm();
  };

  const handleSuccessAddBoardToHub = () => {
    resetForm();
  };

  const onSuccessEditBookmark = () => {
    resetForm();
  };

  const onErrorEditBookmark = () => {
    resetForm();
  };

  const handleSuccessEditDoc = () => {
    closeDialog();
  };

  const { makeHub, addBookmarks, editHub } = useHubs({
    onSuccess: onSuccessHub,
  });

  const { addBoardToHub } = useAddBoardToHub({
    handleSuccessAddBoardToHub,
  });

  const { updateBookmark } = useEditBookmark({
    onSuccess: onSuccessEditBookmark,
    onError: onErrorEditBookmark,
  });

  const { editDoc } = useUpdateDoc({ onSuccess: handleSuccessEditDoc });

  const handleOnKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === 'Enter' &&
      !e.shiftKey &&
      name.trim().length !== 0 &&
      name.trim() !== renamingBoard.trim() &&
      name.trim() !== renamingHub.trim() &&
      name.trim() !== renamingDoc.trim()
    ) {
      handleSubmit();
    }
  };

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setErrors({});
  };

  const handleCancel = () => {
    closeDialog();
    setErrors({});
    resetForm();
  };

  const onBlur = useCallback(() => {
    if (hubsSuggestions && hubsSuggestions.length === 0) {
      hubNameUnSetted();
    }

    if (boardsSuggestions.length === 0) {
      parentBoardUnSetted();
    }
  }, [hubsSuggestions, boardsSuggestions]);

  const closeDropdown = useCallback(() => {
    const isOneOrSecondLevel = boardsSuggestions.filter((board: Board) => {
      if (currentBoard) {
        return board?.id === currentBoard.id;
      } else {
        return false;
      }
    });

    if (isOneOrSecondLevel.length === 0) {
      parentBoardUnSetted();
    }

    if (hubsSuggestions && hubsSuggestions.length === 0) {
      onBlur();
    }

    boardSearchNotActived();
    hubSearchNotActived();
    setSelectRow(null);
  }, [currentBoard, boardsSuggestions, onBlur, hubsSuggestions]);

  const onHandleChangeParentBoard = (e: ChangeEvent<HTMLInputElement>) => {
    parentBoardSetted(e.target.value);
    if (!boardSearchActive) {
      boardSearchActived(e.target.value);
    }

    if (selectRow !== null) {
      setSelectRow(null);
    }
  };

  const onHandleChangeHubs = (e: ChangeEvent<HTMLInputElement>) => {
    hubNameSetted(e.target.value);

    if (!hubSearchActive) {
      hubSearchActived();
    }

    if (selectRow !== null) {
      setSelectRow(null);
    }
  };

  const onKeyDown = useCallback(
    (e: any) => {
      const lastOption = boardsSuggestions.slice(0, 5).length;

      if (e.keyCode === 40) {
        e.preventDefault();
        if (selectRow === null) {
          setSelectRow(0);
          return;
        } else if (
          !currentHubId &&
          selectRow <= boardsSuggestions.slice(0, 5).length - 1
        ) {
          setSelectRow(selectRow + 1);
        } else if (
          currentHubId &&
          selectRow < boardsSuggestions.slice(0, 5).length - 1
        ) {
          setSelectRow(selectRow + 1);
        } else if (selectRow === null || selectRow === lastOption) {
          setSelectRow(0);
        }
      }

      if (e.keyCode === 38) {
        e.preventDefault();
        if (selectRow !== null && selectRow > 0) {
          setSelectRow(selectRow - 1);
        } else if (selectRow === 0) {
          setSelectRow(lastOption);
        }
      }

      if (selectRow === lastSuggestion && e.keyCode === 13) {
        closeDropdown();
      }

      if (
        selectRow !== null &&
        selectRow !== 5 &&
        boardsSuggestions.length > 0 &&
        e.keyCode === 13
      ) {
        const parentBoardName = boardsSuggestions[selectRow]?.name || '';
        const parentBoardId = boardsSuggestions[selectRow]?.id;

        selectBoard(parentBoardName, parentBoardId);
      }
    },
    [selectRow, boardsSuggestions, lastSuggestion, closeDropdown, currentHubId],
  );

  const onKeyDownHubs = useCallback(
    (e: any) => {
      const firstRow = 0;
      let lastRow = 0;
      let currentHubName = '';
      let currentId = '';

      if (hubsSuggestions && selectRow !== null) {
        lastRow = hubsSuggestions.length - 1;
        currentHubName = hubsSuggestions[selectRow]?.name;
        currentId = hubsSuggestions[selectRow]?.id;
      }

      if (e.keyCode === KeyCodes.DOWN) {
        e.preventDefault();

        if (selectRow === null || selectRow === lastRow) {
          setSelectRow(firstRow);
        } else {
          setSelectRow(selectRow + 1);
        }
      }

      if (e.keyCode === KeyCodes.UP) {
        e.preventDefault();

        if (selectRow === firstRow) {
          setSelectRow(lastRow);
        } else if (selectRow !== null && selectRow > firstRow) {
          setSelectRow(selectRow - 1);
        }
      }

      if (
        e.key === KeyCodes.ENTER &&
        hubsSuggestions &&
        selectRow !== null &&
        hubsSuggestions.length > 0 &&
        hubSearchActive
      ) {
        selectHub(currentHubName, currentId);
      }
    },
    [selectRow, hubsSuggestions, hubSearchActive],
  );

  const selectBoard = (board: string, boardId?: string) => {
    parentBoardSetted(board);
    setParentBoardId(boardId);
    boardSearchNotActived();
  };

  const selectHub = (hub: string, id: string) => {
    hubNameSetted(hub);
    setCurrentHubId(id);
    hubSearchNotActived();
  };

  const onRemoveFocus = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLButtonElement;
    const parentBoardExist = boardsSuggestions.find(
      (board: Board) => parentBoard === board?.name,
    );

    if (
      e.keyCode === 13 &&
      parentBoardExist &&
      parentBoard.length !== 0 &&
      selectRow !== null
    ) {
      e.preventDefault();
      target.blur();
      setSelectRow(null);
    }
  };

  return (
    <Wrapper
      creatingBoard={creatingBoard}
      className={className}
      errorSnackbarRef={errorSnackbarRef}
      renamingBoard={renamingBoard}
      creatingHub={creatingHub}
      renamingHub={renamingHub}
      creatingBookmark={creatingBookmark}
      editBookmark={editBookmark}
      addingBoardToHubDialog={addingBoardToHubDialog}
      renamingDoc={renamingDoc}
    >
      <CreateDialog
        isMobileOnly={isMobileOnly}
        inputRef={inputRef}
        name={name}
        onHandleChange={onHandleChange}
        handleOnKeyPress={handleOnKeyPress}
        onHandleChangeParentBoard={onHandleChangeParentBoard}
        onKeyDown={onKeyDown}
        onRemoveFocus={onRemoveFocus}
        boardsSuggestions={boardsSuggestions}
        selectRow={selectRow}
        setSelectRow={setSelectRow}
        selectBoard={selectBoard}
        lastSuggestion={lastSuggestion}
        closeDropdown={closeDropdown}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        renamingBoard={renamingBoard}
        creatingHub={creatingHub}
        creatingBookmark={creatingBookmark}
        editBookmark={editBookmark}
        errors={errors}
        addingBoardToHubDialog={addingBoardToHubDialog}
        onHandleChangeHubs={onHandleChangeHubs}
        hubName={hubName}
        hubsSuggestions={hubsSuggestions}
        onKeyDownHubs={onKeyDownHubs}
        selectHub={selectHub}
        parentBoardId={parentBoardId}
        currentHubNamePage={currentHubNamePage}
        currentHubId={currentHubId}
        onBlur={onBlur}
        renamingHub={renamingHub}
        renamingDoc={renamingDoc}
      />
    </Wrapper>
  );
};

const Wrapper = ({
  children,
  creatingBoard,
  className,
  errorSnackbarRef,
  renamingBoard,
  creatingHub,
  renamingHub,
  creatingBookmark,
  editBookmark,
  addingBoardToHubDialog,
  renamingDoc,
}: WrapperProps) => {
  const { pathname } = useLocation();

  return (
    <div
      className={cn(styles.container, className, {
        [styles.openDialog]:
          creatingBoard ||
          renamingBoard ||
          creatingHub ||
          renamingHub ||
          creatingBookmark ||
          editBookmark ||
          addingBoardToHubDialog ||
          renamingDoc,
        [styles.openDialogMobileLibrary]:
          (creatingBoard ||
            renamingBoard ||
            creatingHub ||
            renamingHub ||
            creatingBookmark ||
            editBookmark ||
            addingBoardToHubDialog ||
            renamingDoc) &&
          pathname === '/library' &&
          isMobileOnly,
        [styles.boardRenamingDialog]:
          renamingBoard || renamingHub || renamingDoc,
        [styles.renamingDialogMobile]: isMobileOnly,
      })}
      ref={errorSnackbarRef}
    >
      {children}
    </div>
  );
};

export const Cols = ({ children, addingBoardToHubDialog }: ColsProps) => {
  return (
    <div
      className={cn(styles.cols, {
        [styles.colsAddBoardToHub]: addingBoardToHubDialog,
      })}
    >
      {children}
    </div>
  );
};

export const Buttons = ({
  onSubmit,
  closeDialog,
  renamingBoard,
  name,
  emptyName,
  creatingBookmark,
  editBookmark,
  hubName,
  addingBoardToHubDialog,
  hubsSuggestions,
  renamingHub,
  renamingDoc,
}: ButtonsProps) => {
  const [action, setAction] = useState('Create');
  const [disable, setDisable] = useState(true);

  const isAddingAndCreatingBoardDialogOpen = useUnit(
    $isAddingAndCreatingBoardDialogOpen,
  );
  const hubSearchActive = useUnit($hubSearchActive);
  const boardSearchActive = useUnit($boardSearchActive);
  const parentBoard = useUnit($parentBoard);
  const isActivateBoardsSearch = useUnit($isActivateBoardsSearch);

  useEffect(() => {
    if (renamingBoard || renamingHub || renamingDoc) {
      setAction(ButtonActions.RENAME);
    } else if (creatingBookmark || editBookmark) {
      setAction(ButtonActions.ADD);
    } else if (addingBoardToHubDialog && !isAddingAndCreatingBoardDialogOpen) {
      setAction(ButtonActions.ADD);
    } else {
      setAction(ButtonActions.CREATE);
    }
  }, [
    renamingBoard,
    creatingBookmark,
    addingBoardToHubDialog,
    editBookmark,
    isAddingAndCreatingBoardDialogOpen,
    renamingHub,
    renamingDoc,
  ]);

  useEffect(() => {
    if (creatingBookmark || editBookmark) {
      setDisable(!checkUrlPattern(name));
    } else if (name) {
      setDisable(
        name.trim() === renamingBoard.trim() ||
          name.trim() === renamingHub.trim() ||
          name.trim() === renamingDoc.trim() ||
          name.trim().length === 0 ||
          name.trim().length >= 100,
      );
    } else if (
      hubName &&
      !hubSearchActive &&
      !isAddingAndCreatingBoardDialogOpen
    ) {
      setDisable(
        !hubsSuggestions?.some(
          (hub) => hub.name.toLowerCase() === hubName.toLowerCase(),
        ),
      );
    } else if (parentBoard && !boardSearchActive && isActivateBoardsSearch) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [
    renamingBoard,
    name,
    creatingBookmark,
    editBookmark,
    hubName,
    hubSearchActive,
    hubsSuggestions,
    isAddingAndCreatingBoardDialogOpen,
    renamingHub,
    renamingDoc,
    isActivateBoardsSearch,
    parentBoard,
    boardSearchActive,
  ]);

  return (
    <div
      className={cn(styles.buttons, {
        [styles.emptyName]:
          renamingBoard && renamingHub && renamingDoc && emptyName,
      })}
    >
      <ButtonPill
        onClick={closeDialog}
        small
        label="Cancel"
        design="secondary"
      />
      <ButtonPill small onClick={onSubmit} label={action} disabled={disable} />
    </div>
  );
};
