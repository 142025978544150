import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { InvitationDoc } from '@api/docs';
import { PermissionsHub } from '@api/hubs';
import { ResponseSuggestionHub } from '@api/users';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type ExistingUserProps = {
  user: ResponseSuggestionHub;
  id: string | null;
  sendInvitation: UseMutateFunction<InvitationDoc, any, InvitationDoc, unknown>;
  permissions: PermissionsHub[];
};
export const ExistingUser = ({
  user,
  id,
  permissions,
  sendInvitation,
}: ExistingUserProps) => {
  const [on, setOn] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const invite = () => {
    if (!on && id) {
      sendInvitation({
        id,
        permissions: ['edit', 'invite'],
        users: [{ email: user.email }],
        setOn,
      });
    }
    setOn(true);
  };

  return (
    <User
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      invite={invite}
      on={on}
      permissions={permissions}
      permissionsRef={permissionsRef}
      changePermissions={() => {}}
    />
  );
};
