import { Children, PropsWithChildren, ReactNode, useState } from 'react';

import { Icon } from 'icons';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

const INDENT_SPACING = 16;

export const NavItemList = ({
  leadingIcon,
  trailingIcon,
  iconAfterText,
  name,
  indent,
  children,
  onClick,
  isSelected,
  isHoveredOverride = false,
}: PropsWithChildren<{
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  iconAfterText?: ReactNode;
  name: string;
  indent: number;
  onClick: () => void;
  isSelected: boolean;
  isHoveredOverride?: boolean;
}>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = !!Children.toArray(children).length;

  return (
    <>
      <button
        className={styles.container}
        onClick={onClick}
        data-active={isSelected}
        data-force-hover={isHoveredOverride}
        style={{
          paddingLeft: `${indent * INDENT_SPACING}px`,
        }}
      >
        <div className={styles.left}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (!hasChildren) return;

              setIsExpanded((e) => !e);
            }}
            style={{
              visibility: hasChildren ? 'visible' : 'hidden',
            }}
            className={styles.caretContainer}
          >
            <Icon
              name="sprite/caret-down"
              style={{
                rotate: isExpanded ? '0deg' : '-90deg',
              }}
              className={styles.caretIcon}
            />
          </button>
          {leadingIcon ? (
            <div className={styles.caretContainer}>{leadingIcon}</div>
          ) : null}
          <TypographyPoppins type="label" labelSize="M" className={styles.text}>
            {name}
          </TypographyPoppins>
          <span>{iconAfterText}</span>
        </div>
        {trailingIcon ? (
          <div className={styles.right}>{trailingIcon}</div>
        ) : null}
      </button>
      {isExpanded ? children : null}
    </>
  );
};
