import { useState } from 'react';

import { Icon } from 'icons';
import { useHistory } from 'react-router-dom';

import {
  Checkbox,
  MenuItem,
  SideSheet,
} from '@visualist/design-system/src/components/v2';

import { ResponseActions } from '@api/actions';
import { fileCardIdSet } from '@pages/FileCard/model';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { useStickyAction } from '@src/entities/Stickies/useStickyAction';

import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  close: () => void;
  items?: ResponseActions;
  isShowHub?: boolean;
};

export const ActionItemsSheet = ({
  isOpen,
  close,
  items,
  isShowHub,
}: Props) => {
  const [actionId, setActionId] = useState('');

  const history = useHistory();

  const { updateStickyAction } = useStickyAction({
    clearActionId: setActionId,
    imageId: '',
  });

  return (
    <SideSheet
      type="large"
      className={styles.sideSheetContainer}
      showSheet={isOpen}
      closeSideSheet={close}
      handleBackdropClick={close}
    >
      <SideSheet.Heading
        className={styles.headingContainer}
        closeSideSheet={close}
      >
        Action items
      </SideSheet.Heading>
      <SideSheet.Body className={styles.bodyContainer}>
        <SideSheet.ContentContainer>
          <ul className={styles.actionItems}>
            {items && items.count > 0
              ? items.sticky_actions
                  .filter((item) => item.action_sticky?.status === 'open')
                  .map((item) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                      key={item.action_sticky?.id}
                    >
                      {item.parent && (
                        <Breadcrumbs
                          board={item.parent[0]}
                          isShowHub={isShowHub}
                        />
                      )}
                      <MenuItem
                        type="body"
                        bodySize="L"
                        className={styles.actionItem}
                        classNameLeadingIcon={styles.icon}
                        classNameContent={styles.content}
                        leadingIcon={
                          <Checkbox
                            style={{
                              padding: '0px',
                              width: '40px',
                              height: '40px',
                            }}
                            className={styles.checkbox}
                            onClick={(e) => {
                              e.stopPropagation();

                              if (item.action_sticky) {
                                setActionId(item.action_sticky.id);
                                updateStickyAction.mutate({
                                  actionId: item.action_sticky.id,
                                  payload: {
                                    status:
                                      item.action_sticky.status !== 'open'
                                        ? 'open'
                                        : 'done',
                                  },
                                });
                              }
                            }}
                            icon={
                              <Icon
                                name="sprite/checkbox-filled"
                                color="#99461C"
                                size={30}
                              />
                            }
                            isSelected={
                              actionId === item.action_sticky?.id ||
                              item.action_sticky?.status === 'done'
                            }
                          />
                        }
                        onClickContent={() => {
                          fileCardIdSet(item.block);
                          history.push(`#/block/${item.block}`);
                          close();
                        }}
                      >
                        {item.text}
                      </MenuItem>
                    </div>
                  ))
              : null}
          </ul>
        </SideSheet.ContentContainer>
      </SideSheet.Body>
    </SideSheet>
  );
};
