import React from 'react';

import styles from './LeftHandNav.module.css';

const MiniLogo = () => (
  <div className={styles.miniLogo}>
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.356.046c-.893.039-1.195.126-1.754.508-1.057.72-1.5 2.067-1.09 3.318.243.743 1.315 2.722 2.458 4.538l.266.423-.837 2.373c-2.158 6.12-3.586 9.937-4.052 10.834l-.1.193-.287-.607c-.55-1.168-1.127-2.63-4.753-12.056L4.554.073 2.427.059.3.045.611.28c.76.572 1.47 1.453 2.097 2.606.63 1.157.55.955 5.086 12.757.637 1.656 1.202 3.176 1.256 3.377.077.283.098.541.095 1.156-.002.706-.018.842-.147 1.266-.262.86-.72 1.577-1.415 2.211L7.202 24h7.195l.034-.145c.626-2.69 2.55-8.54 5.376-16.343.368-1.015.894-2.472 1.17-3.238.589-1.637.829-2.207 1.106-2.626.305-.462.883-1.044 1.334-1.345.22-.147.41-.276.422-.288.034-.033-4.624-.007-5.483.03zm.706.756c.635.29 1.037.846 1.074 1.483.018.313-.018.53-.221 1.35-.219.876-1.05 3.477-1.213 3.79-.061.119-.083.093-.452-.527-.494-.834-1.414-2.625-1.656-3.227-.267-.665-.33-1.009-.255-1.41.146-.783.654-1.36 1.38-1.565.366-.104.992-.055 1.343.106z"
        fill="currentColor"
      />
    </svg>
  </div>
);

export default MiniLogo;
