import { ReactNode, RefObject } from 'react';

import cn from 'classnames';

import styles from './LeftHandNav.module.css';

type Props = {
  children: ReactNode;
  mobile?: boolean;
  navRef?: RefObject<HTMLElement>;
  menuDescriptor?: boolean;
  showHelp?: boolean;
};

const LeftHandNav = ({
  children,
  mobile,
  navRef,
  menuDescriptor,
  showHelp,
}: Props) => {
  return (
    <nav
      id="left-hand-nav"
      className={cn(styles.root, {
        [styles.mobileNavrailMenu]: mobile,
        [styles.menuWithoutDescriptor]: !menuDescriptor || showHelp,
      })}
      ref={navRef}
    >
      {children}
    </nav>
  );
};

export default LeftHandNav;
