import { Dispatch, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';
import { isDesktop, isTablet } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useLongPress } from 'use-long-press';

import { ButtonPill } from '@visualist/design-system';
import { useOnClickOutside } from '@visualist/hooks';

import {
  $isBoardCreationDialogOpen,
  addingBoardToHubDialogOpened,
  addingDocToHubDialogOpened,
} from '../../../../components/CreateDialogsBox/model';
import { useRemoveBoardFromHub } from '../../model/queries/useRemoveBoardFromHub';

import styles from './styles.module.css';

type Type = 'doc';
interface AddToHubProps {
  hubName?: string;
  hubId?: string;
  boardId?: string;
  isBoardNotPartOfHub?: boolean;
  isLevelOneBoard?: boolean;
  docId?: string;
  type?: Type;
  numberMembers?: number;
  setOpenDialog: Dispatch<boolean>;
}

export const AddToHub = ({
  hubName,
  boardId,
  hubId,
  isBoardNotPartOfHub,
  isLevelOneBoard,
  type,
  numberMembers,
  setOpenDialog,
}: AddToHubProps) => {
  const [isShowRemoveOption, setShowRemoveOption] = useState(false);
  const isBoardCreationDialogOpen = useUnit($isBoardCreationDialogOpen);

  const containerRef = useRef<HTMLDivElement>(null);

  const history = useHistory();

  const { deleteBoardFromHub } = useRemoveBoardFromHub();

  useOnClickOutside(containerRef, () => {
    setShowRemoveOption(false);
  });

  const longPressBind = useLongPress(
    () => {
      if (!isDesktop && hubName && isLevelOneBoard) {
        setShowRemoveOption(true);
      } else if (!isDesktop && hubName && type === 'doc') {
        setShowRemoveOption(true);
      }
    },
    {
      cancelOnMovement: true,
    },
  );

  const addBoardToHub = () => {
    if (!hubName) {
      addingBoardToHubDialogOpened();
    }
  };

  const addDocToHub = () => {
    if (!hubName) {
      addingDocToHubDialogOpened();
    }
  };

  const deleteBoard = () => {
    if (hubName && boardId && numberMembers && numberMembers <= 1) {
      deleteBoardFromHub({ hub: hubId, boards: [boardId] });
    } else {
      setOpenDialog(true);
    }
  };

  const goToHub = () => {
    if (!isBoardCreationDialogOpen) {
      history.push(`/h/${hubId}`);
    }
  };

  return (
    <div
      {...longPressBind()}
      className={cn(styles.container, {
        [styles.docPage]: type === 'doc',
        [styles.tabletDocPage]: type === 'doc' && isTablet,
      })}
      ref={containerRef}
    >
      <ButtonPill
        className={cn(styles.add, {
          [styles.disable]: isBoardNotPartOfHub,
        })}
        design="secondary"
        leftIcon={
          <Icon
            name="sprite/hub"
            className={cn(styles.briefcasePlaceholder, {
              [styles.briefcaseHubName]: hubName && !isBoardNotPartOfHub,
            })}
            size={30}
          />
        }
        onClick={type === 'doc' ? () => addDocToHub() : () => addBoardToHub()}
        disabled={isBoardNotPartOfHub}
      >
        <h3
          className={cn(styles.addToHub, {
            [styles.hubName]: hubName,
          })}
        >
          {hubName && !isBoardNotPartOfHub ? (
            <span onClick={goToHub}>{hubName}</span>
          ) : (
            'Add to a hub'
          )}
        </h3>
      </ButtonPill>

      {hubName && !isBoardNotPartOfHub && (
        <ButtonPill
          className={cn(styles.remove, {
            [styles.mobileShowRemoveOption]: isShowRemoveOption,
            [styles.hideRemoveOption]: !isLevelOneBoard && type !== 'doc',
          })}
          design="secondary"
          onClick={deleteBoard}
        >
          <span className={styles.removeFromHub}>Remove from hub</span>
        </ButtonPill>
      )}
    </div>
  );
};
