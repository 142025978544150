import { ChangeEvent, useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { Icon } from 'icons';
import { ColorResult, TwitterPicker as ColorPanel } from 'react-color';
import { isTablet } from 'react-device-detect';
import { useParams } from 'react-router';

import { ThumbnailBrightness } from '@api/hubs';
import { BLUE_Y, GREEN_Y, RED_Y } from '@src/shared/constants/colours';

import { useAddCover } from '../../../../pages/HubPage/model/queries/useAddCover';
import { colors, style } from './colorPickerConfig';
import { colorPicked, uploadPopupClosed } from './model';

import styles from './styles.module.css';

export const UploadPopup = () => {
  const [coverColor, setCoverColor] = useState<ThumbnailBrightness | null>(
    null,
  );
  const [file, setFile] = useState<FileList | null>(null);

  const { hub_id } = useParams<{ hub_id: string }>();

  const uploadRef = useRef<HTMLInputElement>(null);

  const uploadAction = () => {
    uploadRef.current?.click();
  };

  const onSuccess = () => uploadPopupClosed();

  const { addCover } = useAddCover({ onSuccess });

  useEffect(() => {
    if (coverColor && file) {
      addCover({
        hubId: hub_id,
        thumbnail: file,
        thumbnailBrightness: coverColor,
      });
    }
  }, [coverColor, file]);

  const handleChangeComplete = (color: ColorResult) => {
    colorPicked(color.hex);

    const brightness =
      RED_Y * color.rgb.r + GREEN_Y * color.rgb.g + BLUE_Y * color.rgb.b;
    const coverColor = brightness < 128 ? 'dark' : 'light';

    addCover({
      hubId: hub_id,
      thumbnail_hex: color.hex,
      thumbnailBrightness: coverColor,
    });
  };

  const handleUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = uploadRef.current as HTMLInputElement;
    colorPicked(files);

    if (files) {
      setFile(files);
    }

    if (event.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          analyzePixelsImage(e.target.result);
        }
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const analyzePixelsImage = (src: string | ArrayBuffer) => {
    if (!src) return;

    const img = new Image();
    img.src = src as string;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      if (!ctx) return;

      ctx.drawImage(img, 0, 0);
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const data = imageData.data;

      let totalBrightness = 0;

      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const brightness = RED_Y * r + GREEN_Y * g + BLUE_Y * b;

        totalBrightness += brightness;
      }

      const avgBrightness = totalBrightness / (img.width * img.height);
      const coverColor = avgBrightness < 128 ? 'dark' : 'light';

      setCoverColor(coverColor);
    };
  };

  return (
    <div
      className={cn(styles.uploadPopup, {
        [styles.tabletUploadPopup]: isTablet,
      })}
    >
      <h3 className={styles.text}>Choose a colour</h3>
      <div className={styles.colorPicker}>
        <ColorPanel
          styles={style}
          colors={colors}
          onChangeComplete={handleChangeComplete}
          width="312px"
          triangle="hide"
        />
      </div>

      <h3 className={styles.text}>Or upload an image</h3>
      <form
        className={styles.uploadOption}
        method="post"
        encType="multipart/form-data"
        onClick={() => uploadAction()}
      >
        <div>
          <label htmlFor="file">
            <Icon name="sprite/plus" size={48} className={styles.addIcon} />
          </label>
          <input
            style={{ visibility: 'hidden', position: 'absolute' }}
            type="file"
            accept=".png, .jpg, .jpeg"
            ref={uploadRef}
            onChange={handleUpload}
          />
        </div>
      </form>

      <div className={styles.tips}>
        <span className={styles.firstTip}>
          Use images wider than 1500 pixels.
        </span>
        <span>The maximum size per file is 10 MB.</span>
      </div>
    </div>
  );
};
