import { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { Return } from 'react-cool-onclickoutside';
import { isMobileOnly } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';

import { Divider, Section } from '@visualist/design-system';
import {
  Button,
  CaretDownIcon,
} from '@visualist/design-system/src/components/v2';

import { ResponseDocs } from './DocsSection';
import { Hub, HubsSection } from './HubsSection';
import { ParentBoardsSection } from './ParentBoardsSection';

import styles from './styles.module.css';

export type File = {
  full_size: string;
  small_square_crop: string;
  thumbnail_100: string;
  thumbnail_130: string;
  thumbnail_160: string;
  thumbnail_330: string;
  thumbnail_400: string;
  thumbnail_640: string;
};

export type ImageThumbnail = {
  id: string;
  file: File;
};

type ParentBoard = {
  id: string;
  name: string;
};

export type Permissions = 'edit' | 'comment' | 'invite';

type LevelOfBoard = 0 | 1 | 2;

export type Board = {
  block_count: number;
  children: Array<Board> | null;
  description: string;
  has_nested_boards: boolean;
  hub: { id: string; name: string } | null;
  id: string;
  is_archive: boolean;
  is_removed: boolean;
  is_shared: string;
  level: LevelOfBoard;
  name: string;
  parent: ParentBoard | null;
  permissions: Permissions[];
  recent_images: Array<ImageThumbnail>;
  share: string;
  top_parent: ParentBoard;
};

interface DrawerPropsTypes {
  drawerShow: boolean;
  closeButtonClickHandler: any;
  handleClose?: any;
  boards: Array<Board>;
  name?: string;
  openCreateBoardDialog: () => void;
  hubs: Hub[];
  openCreateHubDialog: () => void;
  navrailMenuRef?: Return;
  makeDoc: any;
  docs?: ResponseDocs;
  docsDotMenuNavrail: string;
  docIdPassed: (id: string) => void;
  docsDotMenuNavrailClosed: () => void;
  docRenamingDialogOpened: (docName: string) => void;
  deleteDocModalNavrailOpened: () => void;
  docNamePassed: (docName: string) => void;
}

export const Drawer = ({
  drawerShow,
  closeButtonClickHandler,
  boards,
  hubs,
  // openCreateHubDialog,
  navrailMenuRef,
  // makeDoc,
  docs,
  docsDotMenuNavrail,
  docIdPassed,
  docsDotMenuNavrailClosed,
  docRenamingDialogOpened,
  deleteDocModalNavrailOpened,
  docNamePassed,
}: DrawerPropsTypes): JSX.Element => {
  const [tablet, setTablet] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [isExpandHubs, setExpandHubs] = useState(false);
  const [isExpandOtherItems, setExpandOtherItems] = useState(false);

  // const [isExpandDocsSection, setExpandDocsSection] = useState(false);

  const isMobileSize = useMediaQuery({ query: `(max-width: 767px)` });
  const isTabletSize = useMediaQuery({
    query: `(min-width: 767px) and (max-width: 1439px)`,
  });

  useEffect(() => {
    if (!drawerShow) {
      setExpandHubs(false);
      setExpandOtherItems(false);
    }
  }, [drawerShow]);

  const actualIds = hubs.map((hub) => hub.id);

  const sortedBoards = useMemo(() => {
    return (
      boards &&
      boards.sort(function (a, b) {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
    );
  }, [boards]);

  const boardsLevel1 = sortedBoards?.filter((board) => board.parent === null);
  const boardsNotInHub = boardsLevel1.filter(
    (board) =>
      board.hub === null || actualIds.every((id) => id !== board.hub?.id),
  );

  const handleTouchEnd = () => {
    setTablet(true);
  };

  const handleTouchStart = () => {
    setTablet(false);
    setMobile(true);
  };

  const handleMouseMove = () => {
    setTablet(false);
    setMobile(false);
  };

  const visibleHubs = isExpandHubs ? hubs : hubs?.slice(0, 5);
  const visibleOtherItems = isExpandOtherItems
    ? boardsNotInHub
    : boardsNotInHub?.slice(0, 5);

  return (
    <div
      ref={navrailMenuRef}
      className={cn(styles.drawerContainer, {
        [styles.drawerContainerShow]: drawerShow,
        'drawer-container': true,
        'drawer-container-show': drawerShow,
      })}
      onTouchEnd={handleTouchEnd}
      onTouchStart={handleTouchStart}
      onMouseMove={handleMouseMove}
    >
      <div
        className={cn(styles.drawerWrapper, {
          [styles.drawerShow]: drawerShow,
          'drawer-wrapper': true,
          'drawer-show': drawerShow,
        })}
      >
        <div
          className={cn(styles.drawerMainList, {
            [styles.drawerMainListForTablet]: tablet && isTabletSize,
            [styles.drawerMainListForMobileSize]: mobile && isMobileSize,
            [styles.drawerMainListMobile]: isMobileOnly,
          })}
        >
          <div className={styles.hubsSection}>
            <Section title="Hubs" />
          </div>
          {visibleHubs.length > 0 &&
            visibleHubs.map((hub) => (
              <HubsSection
                key={hub.id}
                id={hub.id}
                name={hub.name}
                drawerShow={drawerShow}
                listItemClickHandler={() =>
                  isMobileOnly && closeButtonClickHandler()
                }
                boardsLevel1={boardsLevel1}
                closeButtonClickHandler={closeButtonClickHandler}
                docs={docs}
                docsDotMenuNavrail={docsDotMenuNavrail}
                docIdPassed={docIdPassed}
                docsDotMenuNavrailClosed={docsDotMenuNavrailClosed}
                docRenamingDialogOpened={docRenamingDialogOpened}
                deleteDocModalNavrailOpened={deleteDocModalNavrailOpened}
                docNamePassed={docNamePassed}
                isExpandHubsSection={true}
              />
            ))}
          {hubs.length > 5 && (
            <Button
              type="text"
              label={isExpandHubs ? 'See less' : 'See all hubs'}
              icon={
                <CaretDownIcon
                  fill="none"
                  className={cn({ [styles.caretDownIcon]: isExpandHubs })}
                />
              }
              onClick={() => setExpandHubs(!isExpandHubs)}
            />
          )}
          <Divider className={styles.divider} />
          <Section title="Other items" />
          {visibleOtherItems.length > 0 &&
            visibleOtherItems.map((board) => (
              <ParentBoardsSection
                key={board.id}
                boardIdLevel1={board.id}
                name={board.name}
                drawerShow={drawerShow}
                listItemClickHandler={() =>
                  isMobileOnly && closeButtonClickHandler()
                }
                boardsLevel1={boardsNotInHub}
                isExpandBoardsSection={true}
                otherItems
              />
            ))}
          {boardsNotInHub.length > 5 && (
            <Button
              type="text"
              label={
                isExpandOtherItems ? 'See less' : 'See all items not in hubs'
              }
              icon={
                <CaretDownIcon
                  fill="none"
                  className={cn({ [styles.caretDownIcon]: isExpandOtherItems })}
                />
              }
              onClick={() => setExpandOtherItems(!isExpandOtherItems)}
            />
          )}
          {/* <Section
            title="Other Docs"
            action={isMobile ? null : () => makeDoc({})}
            isExpand={isExpandDocsSection}
            setExpand={setExpandDocsSection}
          /> */}
          {/* {docs &&
            docs.results.length > 0 &&
            docs.results
              .filter((doc) => doc.hub === null)
              .map(({ id, title }) => (
                <DocsSection
                  key={id}
                  title={title}
                  docId={id}
                  listItemClickHandler={() =>
                    isMobileOnly && closeButtonClickHandler()
                  }
                  docsDotMenuNavrail={docsDotMenuNavrail}
                  docIdPassed={docIdPassed}
                  docsDotMenuNavrailClosed={docsDotMenuNavrailClosed}
                  docRenamingDialogOpened={docRenamingDialogOpened}
                  deleteDocModalNavrailOpened={deleteDocModalNavrailOpened}
                  docNamePassed={docNamePassed}
                  isExpandDocsSection={isExpandDocsSection}
                />
              ))} */}
        </div>
      </div>
    </div>
  );
};
