import cn from 'classnames';
import { Icon } from 'icons';

import { MenuItem, Tooltip } from '@visualist/design-system/src/components/v2';

import { PermissionsHub } from '@api/hubs';

import styles from './styles.module.css';

type PermissionsMenuProps = {
  isShowMenu: boolean;
  setShowMenu: React.Dispatch<boolean>;
  permissions: PermissionsHub[];
  changePermissions: (permissions: PermissionsHub[]) => void;
  position?: 'bottom';
};

export const PermissionsMenu = ({
  isShowMenu,
  setShowMenu,
  permissions,
  changePermissions,
  position,
}: PermissionsMenuProps) => {
  return (
    <ul
      className={cn(styles.menu, {
        [styles.open]: isShowMenu,
        [styles.position]: position === 'bottom',
      })}
    >
      <MenuItem
        classNameLabel={cn({
          [styles.nonselected]: !permissions.includes('edit'),
        })}
        type="body"
        bodySize="L"
        supportingText="Can edit, delete and comment."
        trailingIcon={
          permissions.includes('edit') && (
            <Icon name="sprite/checkbox" className={styles.tickmarkIcon} />
          )
        }
        onClickContent={() => {
          if (!permissions.includes('edit')) {
            changePermissions(['edit', 'invite']);
            setShowMenu(false);
          }
        }}
      >
        Editor
      </MenuItem>
      <Tooltip
        style={{
          minWidth: 'max-content',
        }}
        parameter={{
          type: 'plain',
          description: 'Coming soon',
          position: 'top',
        }}
      >
        <MenuItem
          classNameLabel={cn({
            [styles.nonselected]: !permissions.includes('comment'),
          })}
          type="body"
          bodySize="L"
          supportingText="Can comment but can't edit or delete."
          trailingIcon={
            permissions.includes('comment') && (
              <Icon name="sprite/checkbox" className={styles.tickmarkIcon} />
            )
          }
          onClickContent={() => {
            if (!permissions.includes('comment')) {
              changePermissions(['comment', 'invite']);
              setShowMenu(false);
            }
          }}
          isDisabled
        >
          Commenter
        </MenuItem>
      </Tooltip>
      <Tooltip
        style={{
          minWidth: 'max-content',
        }}
        parameter={{
          type: 'plain',
          description: 'Coming soon',
          position: 'top',
        }}
      >
        <MenuItem
          classNameLabel={cn({
            [styles.nonselected]: permissions.length > 1,
          })}
          type="body"
          bodySize="L"
          supportingText="Read-only. Can't comment or edit."
          trailingIcon={
            permissions.length === 1 && (
              <Icon name="sprite/checkbox" className={styles.tickmarkIcon} />
            )
          }
          onClickContent={() => {
            if (permissions.length > 1) {
              changePermissions(['invite']);
              setShowMenu(false);
            }
          }}
          isDisabled
        >
          Viewer
        </MenuItem>
      </Tooltip>
    </ul>
  );
};
