import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Title =
  | 'Boards'
  | 'Docs'
  | 'Bookmarks'
  | 'Other Boards'
  | 'Hubs'
  | 'Other Docs'
  | 'Other items';

interface SectionProps {
  title: Title;
}

export const Section = ({ title }: SectionProps) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionName}>
        <div className={styles.header}>
          <TypographyPoppins type="title" size="M" className={styles.title}>
            {title}
          </TypographyPoppins>
        </div>
        <div className={styles.stub} />
      </div>
    </div>
  );
};
