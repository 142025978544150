import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import { Variant } from '@components/CreateDialogsBox/model';

export const hubCreationDialogOpened = createEvent<Variant>();
export const hubCreationDialogClosed = createEvent();
export const hubRenamingDialogOpened = createEvent<string>();
export const hubRenamingDialogClosed = createEvent();
export const bookmarkCreationDialogOpened = createEvent<Variant>();
export const bookmarkCreationDialogClosed = createEvent();
export const bookmarkEditDialogOpened = createEvent<string>();
export const bookmarkEditDialogClosed = createEvent();
export const srcPassed = createEvent<string>();
export const bookmarkModalWindowClosed = createEvent();
export const bookmarkIdPassed = createEvent<string>();
export const bookmarkIdCleaned = createEvent();
export const titleDropdownOpened = createEvent();
export const titleDropdownClosed = createEvent();
export const hubPermissionsDropdownOpened = createEvent<string>();
export const hubPermissionsDropdownClosed = createEvent();
export const deleteHubPersmissionsModalOpened = createEvent();
export const deleteHubPersmissionsModalClosed = createEvent();
export const nameOrEmailPassed = createEvent<string>();
export const shareSideSheetOpened = createEvent();
export const shareSideSheetClosed = createEvent();
export const actionItemsSheetOpened = createEvent();
export const actionItemsSheetClosed = createEvent();

export const $hubCreationDialog = createStore('');
export const $hubRenamingDialog = createStore('');
export const $bookmarkCreationDialog = createStore('');
export const $bookmarkEditDialog = createStore('');
export const $src = createStore('');
export const $bookmarkdId = createStore('');
export const $isOpenTitleDropdown = createStore(false);
export const $hubPermissionsDropdown = createStore<string>('');
export const $isOpenHubPersmissionsDeleteModal = createStore(false);
export const $inviteeNameOrEmailToRemove = createStore('');
export const $inviteeId = createStore('');
export const $isShareSheetOpen = createStore(false);
export const $isActionItemsSheetOpen = createStore(false);

$isShareSheetOpen
  .on(shareSideSheetOpened, (state) => !state)
  .reset(shareSideSheetClosed);

$hubCreationDialog
  .on(hubCreationDialogOpened, (_, payload) => payload)
  .reset(hubCreationDialogClosed);

$hubRenamingDialog
  .on(hubRenamingDialogOpened, (_, hubName) => hubName)
  .reset(hubRenamingDialogClosed);

const $isHubCreationDialogOpen = $hubCreationDialog.map(
  (name) => name.length > 0,
);

const $isHubRenamingDialogOpen = $hubRenamingDialog.map(
  (hubName) => hubName.length > 0,
);

export const $isShowHubDialog = combine(
  $isHubCreationDialogOpen,
  $isHubRenamingDialogOpen,
  (isHubCreationDialogOpen, isHubRenamingDialogOpen) =>
    isHubCreationDialogOpen || isHubRenamingDialogOpen,
);

$bookmarkCreationDialog
  .on(bookmarkCreationDialogOpened, (_, payload) => payload)
  .reset(bookmarkCreationDialogClosed);

const $isBookmarkCreationDialogOpen = $bookmarkCreationDialog.map(
  (url) => url.length > 0,
);

$src.on(srcPassed, (_, payload) => payload).reset(bookmarkModalWindowClosed);

export const $isBookmarModalOpen = $src.map((url) => url.length > 0);

$bookmarkEditDialog
  .on(bookmarkEditDialogOpened, (_, payload) => payload)
  .reset(bookmarkEditDialogClosed);

const $isBookmarkEditDialogOpen = $bookmarkEditDialog.map(
  (url) => url.length > 0,
);

$bookmarkdId
  .on(bookmarkIdPassed, (_, id) => id)
  .reset([bookmarkIdCleaned, bookmarkEditDialogClosed]);

$isOpenTitleDropdown
  .on(titleDropdownOpened, () => true)
  .reset(titleDropdownClosed);

$hubPermissionsDropdown
  .on(hubPermissionsDropdownOpened, (prev, id) => {
    if (prev === id) {
      return '';
    } else {
      return id;
    }
  })
  .reset(hubPermissionsDropdownClosed);

$isOpenHubPersmissionsDeleteModal
  .on(deleteHubPersmissionsModalOpened, () => true)
  .reset(deleteHubPersmissionsModalClosed);

$inviteeNameOrEmailToRemove
  .on(nameOrEmailPassed, (_, nameOrEmail) => nameOrEmail)
  .reset(deleteHubPersmissionsModalClosed);

$inviteeId.on(hubPermissionsDropdownOpened, (_, id) => id);

$isActionItemsSheetOpen
  .on(actionItemsSheetOpened, (open) => !open)
  .reset(actionItemsSheetClosed);

hotkey({
  key: 'Escape',
  filter: $isHubCreationDialogOpen,
  target: hubCreationDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isBookmarkCreationDialogOpen,
  target: bookmarkCreationDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isBookmarkEditDialogOpen,
  target: bookmarkEditDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isBookmarModalOpen,
  target: bookmarkModalWindowClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenTitleDropdown,
  target: titleDropdownClosed,
});

hotkey({
  key: 'Escape',
  filter: $isHubRenamingDialogOpen,
  target: hubRenamingDialogClosed,
});
