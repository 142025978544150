import { Icon } from 'icons';

import { Board } from '@visualist/design-system';
import {
  Divider,
  EmptyState,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { boardDialogOpened } from '@src/entities/dialogs/board/model';

import { BoardOfHub } from './BoardOfHub';

import styles from './styles.module.css';

interface BoardsProps {
  boards?: Board[];
  hubId?: string;
}

export const Boards = ({ boards, hubId }: BoardsProps) => {
  return (
    <div className={styles.boardCard}>
      <div className={styles.header}>
        <TypographyPoppins className={styles.color} type="title" titleSize="M">
          Boards
        </TypographyPoppins>
      </div>
      <div className={styles.horizontal}>
        <Divider className={styles.divider} type="long-line" />
      </div>

      {boards && boards.length > 0 ? (
        <ul className={styles.boardsList}>
          {boards
            .filter((board) => board.level === 0)
            .map(({ id, name, recent_images, parent, description }, idx) => {
              const thumbnail =
                recent_images.length > 0
                  ? recent_images[0].file.full_size
                  : null;

              return (
                <li key={id} style={{ display: 'flex' }}>
                  <BoardOfHub
                    id={id}
                    name={name}
                    thumbnail={thumbnail}
                    hubId={hubId}
                    parentBoardId={parent?.id}
                    onboardingId={
                      idx === 1 ? 'onboarding-board-demo' : undefined
                    }
                    description={description}
                  />
                </li>
              );
            })}
        </ul>
      ) : (
        <EmptyState
          icon={<Icon name="sprite/sticky-note-soft-colored" size={80} />}
          content="Want to stay organized?"
          label="Create a board"
          action={() => boardDialogOpened()}
        />
      )}
    </div>
  );
};
