import { RefObject, useEffect, useState } from 'react';

import { throttle } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router';

import { ResponseDocs } from '@api/docs';
import { getHubs } from '@api/hubs';
import { api, BlocksResponse } from '@api/index';
import { filesPanelToggled } from '@pages/DocPage/model/sidebar-opening';
import { openedShuffler } from '@pages/StudioPage/model';
import {
  DICT_OF_STEPS,
  ONBOARDING_STEPS,
  OnboardingAction,
  OnboardingSteps,
  OptionKey,
  useAppData,
} from '@src/AppContext';
import { disabledLoadingDemoMode } from '@src/model';
import { ALL_HUBS_QUERY } from '@src/shared/constants';
import { clearAllTabData } from '@src/shared/utils/clear-all-local-data';
import { useQuery } from '@tanstack/react-query';

import {
  getNextPath,
  positionTooltip,
  setupMutationObserver,
  setupResizeAndIntersectionObserver,
  setupWindowResizeObserver,
} from './helpers';
import {
  hidingTooltip,
  resetHiddenTooltipForSession,
  showingDemoCompletion,
  showingTooltip,
} from './model';
import { InitalOnboardingState, useUserOnboarding } from './useUserOnboarding';

const docPageRegex = /\/d\//;
const hubPageRegex = /\/h\//;
const homePageRegex = /\/home/;
const libraryPageRegex = /\/library/;
const studioPageRegex = /\/studio/;

/**
 * @description This hook is used to handle the onboarding state. It is used to show and hide the tooltip, handle page scroll, and handle the next step.
 * @description IMPORTANT: Only call at root of onboarding component
 *
 * @param param0 {tooltipRef}
 * @returns
 */

export const useOnboardingState = ({
  tooltipRef,
}: {
  tooltipRef: RefObject<HTMLDivElement>;
}) => {
  const { onboardingState, mutateOnboarding, startDemoMode, endDemoMode } =
    useUserOnboarding();
  const { user } = useAppData();
  const history = useHistory();

  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState('');
  // Cache demo mode data in state
  const [demoDesignIdForRedirect, setDemoDesignIdForRedirect] = useState('');
  const [demoFileIdForRedirect, setDemoFileIdForRedirect] = useState('');
  const [demoDocIdForRedirect, setDemoDocIdForRedirect] = useState('');

  /**
   * @description Switches onboarding path to each path
   */

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      if (user.meta?.onboarding === undefined || !user.meta.onboarding.demoMode)
        return;

      resetHiddenTooltipForSession();

      if (
        docPageRegex.test(history.location.pathname) &&
        !user.meta?.onboarding?.completedPaths.manageBusinessAdmin
      ) {
        updateOnboardingPath({
          path: 'manageBusinessAdmin',
        });
      } else if (
        (hubPageRegex.test(history.location.pathname) ||
          homePageRegex.test(history.location.pathname)) &&
        !user.meta?.onboarding?.completedPaths.createHubs
      ) {
        updateOnboardingPath({
          path: 'createHubs',
        });
      } else if (
        libraryPageRegex.test(history.location.pathname) &&
        !user.meta?.onboarding?.completedPaths.workCreatively
      ) {
        updateOnboardingPath({
          path: 'workCreatively',
        });
      } else if (
        studioPageRegex.test(history.location.pathname) &&
        user.meta?.onboarding?.workCreatively === 'creative-with-colour'
      ) {
        updateSelectedPath({
          selectedPath: 'workCreatively',
          path: 'moodboards-that-pop',
        });
      }
      setPrevLocation(location.pathname);
    }
  }, [location, prevLocation, mutateOnboarding, history, user]);

  // Data for hub movement
  // Queries
  // HACK could not get the useQuery hooks to work without constantly crashing because of some cache store key issue... so this is meant to be temporary
  useEffect(() => {
    // Request demo mode redirect data
    const promises = [
      // Fetch file blocks
      fetchBlocks({ typeOfBlock: 'image' }),
      fetchBlocks({ typeOfBlock: 'set' }),
      fetchDocs(),
    ];

    try {
      Promise.allSettled(promises).then((results) => {
        const fileBlocks = results[0];
        const designBlocks = results[1];
        const docs = results[2];

        if (fileBlocks.status === 'fulfilled' && fileBlocks.value.results[0]) {
          setDemoFileIdForRedirect(fileBlocks.value.results[0].id);
        }

        if (
          designBlocks.status === 'fulfilled' &&
          designBlocks.value.results[0]
        ) {
          setDemoDesignIdForRedirect(designBlocks.value.results[0].id);
        }

        if (docs.status === 'fulfilled' && docs.value.results[0]) {
          setDemoDocIdForRedirect(docs.value.results[0].id);
        }
      });
    } catch {
      // TODO handle error somehow...
      console.error(
        'Error fetching demo mode data. This is a temporary fix as useAllBlocks is crashing on reloads. If encountered best to fix this properly',
      );
    }
  }, [user.meta?.onboarding?.demoMode]);

  const { data: hubs = [] } = useQuery({
    queryKey: [
      ALL_HUBS_QUERY,
      {
        demo: user.meta?.onboarding?.demoMode,
      },
    ],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });

  const selectedPath = onboardingState.selectedPath;
  const currentStepKey = onboardingState[selectedPath] as OnboardingSteps;
  const currentStep = ONBOARDING_STEPS[currentStepKey];

  /**
   * @description If user meta object has no onboarding object set it to initial state
   */

  useEffect(() => {
    if (!user.meta?.onboarding || !user.meta?.onboarding.selectedPath) {
      mutateOnboarding.mutate({
        meta: {
          ...user.meta,
          onboarding: InitalOnboardingState,
        },
      });
    }
  }, []);

  /**
   * @description This function is used to update the currently selected path the user is on
   * @param path {OptionKey}
   * @param selectedPath {OnboardingSteps}
   * @returns
   */

  const updateSelectedPath = async ({
    path,
    selectedPath,
  }: {
    path: OnboardingSteps;
    selectedPath: OptionKey;
  }) => {
    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        onboarding: {
          ...user.meta?.onboarding,
          [selectedPath]: path,
        },
      },
    });
  };

  /**
   * @description This function is used to update the onboarding path, if demo is provuded as a param the demo mode is started. isInitialPath determines if a redirect is needed
   * @param param0 {path, demo, isInitialPath}
   * @returns
   */

  const updateOnboardingPath = async ({
    path,
    startDemo,
    isInitialPath,
    completedPath,
  }: {
    path: OptionKey;
    startDemo?: boolean;
    isInitialPath?: boolean;
    completedPath?: OptionKey;
  }) => {
    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        onboarding: {
          ...user.meta?.onboarding,
          selectedPath: path,
          ...(startDemo !== undefined && { demoMode: startDemo }),
          completedPaths: {
            ...user.meta?.onboarding?.completedPaths,
            ...(completedPath !== undefined ? { [completedPath]: true } : {}),
          },
        },
      },
    });

    // If its the initial path redirect from welcome screens call
    if (!isMobileOnly && isInitialPath) {
      clearAllTabData();
      if (path === 'createHubs') {
        history.push('/home');
      }

      if (path === 'manageBusinessAdmin') {
        const doc = await fetchDocs();
        if (doc.results[0].id) {
          history.push(`/d/${doc.results[0].id}`);
        } else {
          history.push('/library');
        }
      }

      if (path === 'workCreatively') {
        const block = await fetchBlocks({ typeOfBlock: 'image' });
        if (block.results[0].id) {
          history.push(`/library#/block/${block.results[0].id}`);
        } else {
          history.push('/library');
        }
      }
    }
    disabledLoadingDemoMode();
  };

  /**
   * @description Reset meta onboarding object to initial state
   */

  const resetMetaOnboarding = async () => {
    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        onboarding: InitalOnboardingState,
      },
    });
  };

  /**
   * @description Page scroll handler
   */

  useEffect(() => {
    const options = { capture: true };
    const scrollHandler = throttle(
      () => positionTooltip({ currentStep, tooltipRef }),
      10,
    );

    document.addEventListener('scroll', scrollHandler, options);

    return () => {
      document.removeEventListener('scroll', scrollHandler, options);
    };
  }, [currentStep, positionTooltip]);

  const isLastStep =
    onboardingState[selectedPath] ===
    DICT_OF_STEPS[selectedPath][DICT_OF_STEPS[selectedPath].length - 1];

  /**
   * @description Gets label for tooltip buttons when on last step
   */

  const getNextStepLabel = () => {
    if (!isLastStep) {
      return false;
    }

    const nextPath = getNextPath(user);

    if (!nextPath) return "I'm all set";

    switch (nextPath) {
      case 'createHubs':
        return 'Next: Client hubs';
      case 'workCreatively':
        return 'Next: Color inspiration';
      case 'manageBusinessAdmin':
        return 'Next: Business-in-a-box';
      default:
        return false;
    }
  };

  useEffect(() => {
    if (currentStep.type === 'page') {
      showingTooltip();
      return;
    }

    let mutationObserver: MutationObserver | null = null;
    let observers: readonly [IntersectionObserver, ResizeObserver];
    let windowObserver: ResizeObserver;

    const positionTooltipInitial = async () => {
      let element: HTMLElement | null = null;

      while (element === null) {
        element = positionTooltip({
          currentStep,
          tooltipRef,
          from: 'position from while loop',
        });
        await new Promise((resolve) => setTimeout(resolve, 400));
        element = positionTooltip({
          currentStep,
          tooltipRef,
        });
      }

      showingTooltip();

      mutationObserver = setupMutationObserver({
        currentStep,
        tooltipRef,
        targetElement: element,
      });

      observers = setupResizeAndIntersectionObserver({
        currentStep,
        tooltipRef,
        targetElement: element,
      });

      windowObserver = setupWindowResizeObserver({
        currentStep,
        tooltipRef,
      });
    };

    if (user.meta?.onboarding?.demoMode) {
      positionTooltipInitial();
    }

    return () => {
      if (observers) {
        observers[0].disconnect();
        observers[1].disconnect();
      }
      if (mutationObserver) {
        mutationObserver.disconnect();
      }
      if (windowObserver) {
        windowObserver.disconnect();
      }
    };
  }, [currentStep, tooltipRef, user]);

  const goToNextStep = async () => {
    const selectedPath = onboardingState.selectedPath;
    const currentStepKey = onboardingState[selectedPath] as OnboardingSteps;
    const currentStepPath = DICT_OF_STEPS[onboardingState.selectedPath];

    const indexInSteps = currentStepPath.indexOf(currentStepKey);

    hidingTooltip();

    // Check if the current step is the last step
    if (indexInSteps === currentStepPath.length - 1) {
      // Check if other paths are complete
      const path = getNextPath(user);
      if (path) {
        // Go to the next onboarding path
        await updateOnboardingPath({ path, completedPath: selectedPath });

        // HARD CODED PATH REDIRECTS
        if (path === 'createHubs') {
          history.push('/home');
        } else if (path === 'workCreatively') {
          if (demoFileIdForRedirect) {
            history.push(`/library#/block/${demoFileIdForRedirect}`);
            return;
          }

          history.push('/library');
        } else if (path === 'manageBusinessAdmin') {
          if (demoDocIdForRedirect) {
            history.push(`/d/${demoDocIdForRedirect}`);
            return;
          }

          history.push('/library');
        }

        return;
      }

      // Update final completed path as complete
      await updateOnboardingPath({
        path: selectedPath,
        completedPath: selectedPath,
      });

      showingDemoCompletion();
    } else {
      // If it is not the last step, go to the next step
      const nextStep = currentStepPath[indexInSteps + 1];

      if (ONBOARDING_STEPS[nextStep].type === 'page') {
        showingTooltip();
      }

      await updateSelectedPath({ selectedPath, path: nextStep });

      if (currentStep.onAction) {
        performStepAction(currentStep.onAction);
      }
    }
  };

  const performStepAction = async (step: OnboardingAction) => {
    switch (step) {
      case 'first-hub': {
        const firstHub = hubs[0];
        if (!firstHub) return;
        history.push(`/h/${firstHub.id}`);
        break;
      }
      case 'open-studio':
        if (demoDesignIdForRedirect) {
          history.push(`/studio/${demoDesignIdForRedirect}`);
          return;
        }
        break;
      case 'open-tidy-up':
        openedShuffler();
        break;
      case 'doc-file-library': {
        filesPanelToggled();
        break;
      }
    }
  };

  return {
    getNextPath: () => getNextPath(user),
    positionTooltip,
    getNextStepLabel,
    goToNextStep,
    onboardingState,
    currentStep,
    endDemoMode,
    startDemoMode,
    updateOnboardingPath,
    resetMetaOnboarding,
  };
};

// These two endpoints already exsist in the api already but here it is hardcoded to use the demo endpoint
const fetchBlocks = async ({
  typeOfBlock,
}: {
  typeOfBlock: 'image' | 'set';
}): Promise<BlocksResponse> => {
  const { data } = await api.get(`/blocks/`, {
    baseURL: `${import.meta.env.VITE_API_BASE_URL}/demo`,
    params: {
      // Images are 0 and sets are 1
      // If adding a third block type this will need to be changed
      block_type: typeOfBlock === 'image' ? 0 : 1,
      offset: 0,
      ordering: '-created_at',
    },
  });
  return data;
};

export const fetchDocs = async (): Promise<ResponseDocs> => {
  const { data } = await api.get('/docs/?limit=1', {
    baseURL: `${import.meta.env.VITE_API_BASE_URL}/demo`,
  });
  return data;
};
