import { ChangeEvent, Dispatch, KeyboardEvent, useEffect, useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';
import { isMobileOnly } from 'react-device-detect';

import { ClipItem, Input } from '@visualist/design-system';

import { HubResponse } from '@api/hubs';

import { $hubSearchActive, $isAddingAndCreatingBoardDialogOpen } from './model';

import styles from './styles.module.css';

interface HubsSearchProps {
  selectRow: number | null;
  onHandleChangeHubs: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHubs: (e: any) => void;
  onRemoveFocus: (e: KeyboardEvent<HTMLInputElement>) => void;
  addingBoardToHubDialog: boolean;
  hubName: string;
  hubsSuggestions?: HubResponse[];
  selectHub: (hubName: string, id: string) => void;
  onBlur: () => void;
  setSelectRow: Dispatch<number | null>;
}

export const HubsSearch = ({
  selectRow,
  onHandleChangeHubs,
  onKeyDownHubs,
  onRemoveFocus,
  addingBoardToHubDialog,
  hubName,
  hubsSuggestions,
  selectHub,
  onBlur,
  setSelectRow,
}: HubsSearchProps) => {
  const isAddingAndCreatingBoardDialogOpen = useUnit(
    $isAddingAndCreatingBoardDialogOpen,
  );
  const hubSearchActive = useUnit($hubSearchActive);

  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (addingBoardToHubDialog) {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 70);
    }
  }, [addingBoardToHubDialog]);

  return (
    <div
      className={cn({
        [styles.wrapper]: isAddingAndCreatingBoardDialogOpen,
      })}
    >
      <Input
        ref={searchRef}
        className={cn(styles.hubsSearchField, {
          [styles.focused]: hubSearchActive,
          [styles.hubsField]: addingBoardToHubDialog,
          [styles.addBoardToHubField]: isAddingAndCreatingBoardDialogOpen,
        })}
        customStyleRightIcon={cn({
          [styles.сhevronIconActiveSearch]: hubSearchActive,
        })}
        type="text"
        name="name"
        placeholder="Add it to..."
        customLabel="Add to"
        rightIcon={<Icon name="sprite/caret-down" />}
        value={hubName}
        onChange={onHandleChangeHubs}
        onKeyDown={onKeyDownHubs}
        onKeyUp={onRemoveFocus}
        onBlur={onBlur}
      />
      {hubSearchActive && hubName && (
        <div
          className={cn(styles.dropdown, {
            [styles.dropdownMobile]: isMobileOnly,
            [styles.dropdownHubs]: hubName,
            [styles.dropdownAddBoardToHub]: isAddingAndCreatingBoardDialogOpen,
          })}
        >
          <ul
            style={{
              maxHeight: isMobileOnly ? '139px' : '239px',
            }}
            className={styles.hubsList}
          >
            {hubsSuggestions && hubsSuggestions.length > 0 ? (
              hubsSuggestions.map((hub, index) => (
                <li
                  key={hub.id}
                  className={cn(styles.li, {
                    [styles.highlight]: selectRow === index,
                  })}
                  onMouseEnter={() => setSelectRow(index)}
                  onClick={() => selectHub(hub.name, hub.id)}
                >
                  <span style={{ display: 'flex' }}>
                    <Icon name="sprite/hub" />
                    <ClipItem type="hub" name={hub.name} query={hubName} />
                  </span>
                </li>
              ))
            ) : (
              <li className={styles.noMatches}>No matches</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};
