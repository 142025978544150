import React, { useMemo } from 'react';

import { useUnit } from 'effector-react';
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { VaiPopup } from '@src/entities/vai/ui/popup';

import { $showVaiSearchHelpPopup, closedVaiSearchHelpPopup } from '../../model';

import styles from './styles.module.css';

export const VaiPopupSeachHelp = () => {
  const [showVaiSearchHelpPopup] = useUnit([$showVaiSearchHelpPopup]);

  const text = useMemo(() => {
    switch (showVaiSearchHelpPopup) {
      case 'search_results_large':
        return 'Looks like we found many matches.\nRefine your results with filters.';
      case 'search_results_small':
        return 'We didn’t find many results.\nTry adjusting your filters.';
      default:
        return '';
    }
  }, [showVaiSearchHelpPopup]);

  const textLength = useMotionValue(0);

  React.useEffect(() => {
    let controls: AnimationPlaybackControls;
    if (showVaiSearchHelpPopup) {
      const timeout = setTimeout(() => {
        controls = animate(textLength, text.length, {
          duration: 4,
        });
      }, 500);
      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
  }, [showVaiSearchHelpPopup]);

  const roundedTextLength = useTransform(textLength, (latest) =>
    Math.round(latest),
  );
  const displayedText = useTransform(roundedTextLength, (latest) =>
    text.slice(0, latest),
  );

  return (
    <VaiPopup
      closePopup={() => {
        textLength.set(0);
        closedVaiSearchHelpPopup();
      }}
      showPopup={showVaiSearchHelpPopup.length > 0}
      top={76}
      right={20}
    >
      <div className={styles.main}>
        <TypographyPoppins type="body" bodySize="M" className={styles.text}>
          <motion.span>{displayedText}</motion.span>
        </TypographyPoppins>
      </div>
    </VaiPopup>
  );
};
