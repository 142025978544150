import { ComponentProps, forwardRef, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import TypographyPoppins from '../Styles/Typography/TypographyPoppins';
import { Tooltip } from '../Tooltip';

import styles from './styles.module.css';

type Props = ComponentProps<typeof Link> & {
  to: string;
  name: string;
  isSelected: boolean;
  isBulletPoint?: boolean;
  isDisabled?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  minimal?: boolean;
  onClick?: () => void;
  isNested?: boolean;
};

export const NavItem = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    name,
    to,
    isSelected,
    isBulletPoint = false,
    isDisabled = false,
    leftIcon,
    rightIcon,
    minimal = false,
    onClick,
    isNested = false,
    ...rest
  } = props;
  return isDisabled ? (
    <Tooltip
      parameter={{
        type: 'plain',
        description: 'Coming soon',
        position: 'right',
      }}
    >
      <Link
        {...rest}
        to={to}
        style={{
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
        className={styles.link}
        data-is-selected={isSelected}
        data-is-bullet={isBulletPoint}
        ref={ref}
        aria-disabled={isDisabled}
        data-is-nested={isNested}
      >
        <div className={styles.left}>
          <TypographyPoppins
            type="label"
            labelSize={isNested ? 'M' : 'L'}
            className={styles.label}
          >
            {name}
          </TypographyPoppins>
        </div>
        <div className={styles.right}>{rightIcon}</div>
      </Link>
    </Tooltip>
  ) : (
    <Link
      {...rest}
      // @ts-expect-error
      to={to ? to : undefined}
      onClick={onClick}
      className={styles.link}
      data-is-selected={isSelected}
      data-is-bullet={isBulletPoint}
      data-is-nested={isNested}
      ref={ref}
      aria-disabled={isDisabled}
    >
      <div className={styles.left}>
        <div className={styles.iconLeft}>{leftIcon}</div>
        {minimal ? null : (
          <div>
            <TypographyPoppins
              type="label"
              labelSize={isNested ? 'M' : 'L'}
              className={styles.label}
            >
              {name}
            </TypographyPoppins>
          </div>
        )}
      </div>
      {minimal ? null : <div className={styles.right}>{rightIcon}</div>}
    </Link>
  );
});
