import { AxiosResponse } from 'axios';

import { ImageThumbnail } from '@visualist/design-system';

import { api } from './services';

type Photo = {
  full_size: string;
  medium_square_crop: string;
  small_square_crop: string;
  thumbnail: string;
};

type ParentBoard = {
  id: string;
  name: string;
};

type LevelOfBoard = 0 | 1 | 2;

export type BoardLvl3 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 2;
  parent: BoardLvl2;
};

export type BoardLvl2 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 1;
  parent: BoardLvl1;
};

export type BoardLvl1 = {
  created_at: string;
  description: string | null;
  id: string;
  name: string;
  level: 0;
  parent: Hub | null;
};

export type Hub = {
  created_by: {
    email: string;
    first_name: string;
    id: number;
    last_name: string;
    photo: Photo;
  };
  id: string;
  name: string;
  type: 'hub';
};

export type Board = {
  block_count: number;
  children: Array<Board> | null;
  description: string;
  has_nested_boards: boolean;
  hub: { id: string; name: string } | null;
  id: string;
  is_archive: boolean;
  is_removed: boolean;
  is_shared: string;
  level: LevelOfBoard;
  name: string;
  parent: BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
  permissions: Permissions[];
  recent_images: Array<ImageThumbnail>;
  share: string;
  top_parent: ParentBoard;
};

// Design
type DesignResponse = {
  id: string;
  name: string;
  file: string;
  is_removed: boolean;
  tags: any[];
  board: BoardLvl1[] | BoardLvl2[] | BoardLvl3[];
};

export const getDesign = (
  designId: string,
): Promise<AxiosResponse<DesignResponse>> => {
  return api.get(`/blocks/set/${designId}/`);
};

export const createDesign = (): Promise<AxiosResponse<DesignResponse>> => {
  return api.post('/blocks/set/');
};

export const updateDesignName = ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => {
  return api.patch(`/blocks/set/${id}/`, { name });
};

export const updateDesignThumbnail = (id: string, file: string) =>
  api.put(`/blocks/set/${id}/`, { file });
