import React from 'react';

import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import {
  IconButton,
  Switch,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  $isStickyModeOn,
  $openPanel,
  changedStickyMode,
  enabledStickyMode,
  toggledPanel,
} from '@pages/FileCard/model';
import { useAppData } from '@src/AppContext';
import useStickies from '@src/shared/queries/useStickies';

import {
  chooseColour,
  isStickyOutOfBounds,
  sortByCreatedAt,
} from '../../../../entities/Stickies/utils';
import { StickyCard } from '../StickyCard';

import styles from './styles.module.css';

type Props = {
  blockId: string;
  imageRef: React.RefObject<React.ElementRef<'img'>>;
};

export const StickiesPanel = (props: Props) => {
  const [openPanel] = useUnit([$openPanel]);

  const { stickiesQuery } = useStickies(props.blockId, null);

  const isExtended = openPanel === 'sticky';

  const toggleStickyPanel = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // Do not run when switch is pressed
    if (e.currentTarget !== e.target) return;
    toggledPanel('sticky');
  };

  if (stickiesQuery.isLoading) {
    return <Skeleton />;
  }

  return (
    <div className={styles.panel} data-expanded={openPanel === 'sticky'}>
      <div className={styles.header} onClick={toggleStickyPanel}>
        <TypographyPoppins type="title" titleSize="M">
          <span className={styles.heading}>Sticky notes</span>
        </TypographyPoppins>

        <PanelHeader blockId={props.blockId} />
      </div>

      <StickiesContainer
        blockId={props.blockId}
        expandStickiesPanel={isExtended}
        imageRef={props.imageRef}
      />
    </div>
  );
};

const PanelHeader = ({ blockId }: { blockId: string }) => {
  const [isStickyMode, openPanel] = useUnit([$isStickyModeOn, $openPanel]);
  const isExtended = openPanel === 'sticky';

  const { stickiesQuery, removeNewStickies } = useStickies(blockId, null);

  React.useEffect(() => {
    // On unmount fetch fresh stickies
    return () => removeNewStickies();
  }, []);

  const toggleStickies = (value: boolean) => {
    if (!stickiesQuery.data) return;

    if (
      !isStickyMode &&
      stickiesQuery.data &&
      stickiesQuery.data?.stickies.length === 0
    )
      startedSnack({
        label: 'Add a sticky note anywhere on your file',
        close: true,
      });
    changedStickyMode(value);
    if (!value) {
      removeNewStickies();
    }
  };

  return (
    <div className={styles.switchContainer}>
      <Tooltip
        parameter={{
          type: 'plain',
          description: isStickyMode
            ? 'Hide sticky notes'
            : 'Display sticky notes',
          position: 'top',
          hasVisualBoundary: true,
        }}
      >
        <Switch value={isStickyMode} onValueChange={toggleStickies} />
      </Tooltip>
      <IconButton
        className={styles.iconButton}
        type="unfilled"
        icon={
          <Icon
            name="sprite/chevron-down"
            className={styles.expandStickiesPanelCaretButton}
            style={{
              transform: `rotate(${isExtended ? '180deg' : '0deg'})`,
            }}
          />
        }
        onClick={() => toggledPanel('sticky')}
      />
    </div>
  );
};

type StickContainerProps = {
  blockId: string;
  expandStickiesPanel: boolean;
  imageRef: React.RefObject<React.ElementRef<'img'>>;
};

const StickiesContainer = (props: StickContainerProps) => {
  const { user } = useAppData();
  const { stickiesQuery, addNewStickyOptimistic } = useStickies(
    props.blockId,
    null,
  );

  const addPlaceholderSticky = () => {
    if (!props.imageRef.current) return;

    const clientXRatio =
      (props.imageRef.current.clientWidth / 2 - 48) /
      props.imageRef.current.clientWidth;

    const clientYRatio =
      (props.imageRef.current.clientHeight / 2 - 48) /
      props.imageRef.current.clientHeight;

    addNewStickyOptimistic({
      id: 'new-sticky',
      created_by: user,
      is_private: true,
      left_pixel: clientXRatio,
      top_pixel: clientYRatio,
      text: '',
      created_at: new Date().toISOString(),
      background_hex: chooseColour(
        stickiesQuery.data ? stickiesQuery.data.stickies.length : 0,
      ),
      updated_at: new Date().toISOString(),
      attached_to_id: undefined,
      attached_to_type: undefined,
      action_sticky: null,
    });
    enabledStickyMode();
  };

  if (!stickiesQuery.data || stickiesQuery.data.stickies.length === 0) {
    return (
      <div className={styles.stickiesContainer}>
        <StickyCard
          type="placeholder"
          blockId={props.blockId}
          addPlaceholderSticky={addPlaceholderSticky}
        />
      </div>
    );
  }

  return (
    <div
      className={styles.stickiesContainer}
      onClick={() => enabledStickyMode()}
    >
      {stickiesQuery.data.stickies
        .filter((sticky, _, stickies) => {
          // If we have more than 1 sticky filter out all stickies with the new-sticky id
          // Only show the new-sticky if it's the only sticky (Design shows we keep it and its basically invisible) We just dont want an empty space when there are more stickies
          if (stickies.length === 1 && stickies[0].id === 'new-sticky')
            return true;
          return sticky.id !== 'new-sticky';
        })
        .sort(sortByCreatedAt)
        .reverse()
        .slice(
          0,
          props.expandStickiesPanel ? stickiesQuery.data.stickies.length : 2,
        )
        .map((sticky, i) => {
          const showWithTooltip = !isStickyOutOfBounds(sticky);

          if (showWithTooltip) {
            return (
              <Tooltip
                key={sticky.id}
                parameter={{
                  type: 'plain',
                  description:
                    'This sticky note is placed outside this area. Open the file in Studio instead to see it.',
                  position: 'top',
                  hasVisualBoundary: true,
                }}
              >
                <StickyCard
                  sticky={sticky}
                  type="thumbnail"
                  key={sticky.id}
                  index={i}
                  blockId={props.blockId}
                />
              </Tooltip>
            );
          }

          return (
            <StickyCard
              sticky={sticky}
              type="thumbnail"
              key={sticky.id}
              index={i}
              blockId={props.blockId}
            />
          );
        })}
    </div>
  );
};

const Skeleton = () => {
  return <div className={styles.skeleton} />;
};
