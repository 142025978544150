import { useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';
import { isDesktop } from 'react-device-detect';
import { useParams } from 'react-router';

import { AssistChip } from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';

import { useRemoveCover } from '../../../../pages/HubPage/model/queries/useRemoveCover';
import {
  $defaultColor,
  $isUploadPopupOpen,
  uploadPopupClosed,
  uploadPopupOpened,
} from './model';
import { UploadPopup } from './UploadPopup';

import styles from './styles.module.css';

interface SelectCoverProps {
  thumbnail: string;
  thumbnailHex: string;
}

export const SelectCover = ({ thumbnail, thumbnailHex }: SelectCoverProps) => {
  const [isUploadPopupOpen, defaultColor] = useUnit([
    $isUploadPopupOpen,
    $defaultColor,
  ]);

  const { hub_id } = useParams<{ hub_id: string }>();
  const uploadRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(uploadRef, () => {
    if (isUploadPopupOpen) {
      uploadPopupClosed();
    }
  });

  const { removeCover } = useRemoveCover();

  return (
    <div ref={uploadRef} className={styles.cover}>
      {(!thumbnailHex && !thumbnail) || thumbnailHex === defaultColor ? (
        <AssistChip
          style="elevated"
          className={styles.addCover}
          leadingIcon
          icon={<Icon name="sprite/image" />}
          onClick={() => uploadPopupOpened()}
        >
          Add cover
        </AssistChip>
      ) : (
        <div
          className={cn(styles.updateCover, {
            [styles.show]: isUploadPopupOpen,
            [styles.touchscreenCover]: !isDesktop,
          })}
        >
          <AssistChip
            style="elevated"
            className={styles.deleteCover}
            leadingIcon
            icon={<Icon name="sprite/bin" />}
            onClick={() =>
              removeCover({
                hubId: hub_id,
                thumbnail_hex: defaultColor,
              })
            }
          >
            Remove cover
          </AssistChip>

          <AssistChip
            style="elevated"
            className={styles.changeCover}
            leadingIcon
            icon={<Icon name="sprite/image" />}
            onClick={() => uploadPopupOpened()}
          >
            Change cover
          </AssistChip>
        </div>
      )}
      {isUploadPopupOpen && <UploadPopup />}
    </div>
  );
};
