import { Icon } from 'icons';
import { Return } from 'react-cool-onclickoutside';

import { IconButton } from '@visualist/design-system/src/components/v2';

type ItemsListProps = {
  drawerButtonCLickHandler: () => void;
  activeTabProjects?: boolean;
  navrailMenuRef: Return;
};

export const HamburgerMenu = ({
  drawerButtonCLickHandler,
  activeTabProjects,
  navrailMenuRef,
}: ItemsListProps) => {
  return (
    <IconButton
      ref={navrailMenuRef}
      type="unfilled"
      icon={<Icon name="sprite/menu-hamburger-left" />}
      isSelected={activeTabProjects}
      onClick={drawerButtonCLickHandler}
    />
  );
};
