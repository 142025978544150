import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import {
  Button,
  Dialog,
  Fab,
  Modal,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  $showHubEmail,
  hideHubEmailDialog,
  revealedHubEmailDialog,
} from '@pages/messages/model';

import styles from './styles.module.css';

export const EmailHubButton = ({
  className,
  hubId,
}: {
  className?: string;
  hubId: string;
}) => {
  const [showHubEmail] = useUnit([$showHubEmail]);

  return (
    <>
      <Fab
        label="Email to hub"
        style="extended"
        icon={<Icon name="sprite/paper-plane" />}
        className={className}
        onClick={() => revealedHubEmailDialog()}
      />
      <Modal showModal={showHubEmail} handleClose={() => hideHubEmailDialog()}>
        <Dialog.Container>
          <Dialog.Content>
            <Dialog.Headline className={styles.dialogHeading}>
              Send emails to this hub
            </Dialog.Headline>
            <Dialog.Description>
              Emails sent to this address will be found in the
              <br />
              hub’s message center.
            </Dialog.Description>
            <input
              className={styles.input}
              type="text"
              onChange={() => {}}
              value={`hub_${hubId}@visualist.email`}
            />
            <Dialog.Centered>
              <Button
                style={{
                  marginTop: 24,
                  marginBottom: 24,
                }}
                type="filled"
                label="Copy email address"
                icon={<Icon name="sprite/copy" />}
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(
                      `hub_${hubId}@visualist.email`,
                    );
                    startedSnack({
                      label: 'Copied email address',
                    });
                  } catch (err) {
                    startedSnack({
                      label: 'Couldn’t copy email address',
                      action: {
                        action: () => {},
                        label: 'Try again',
                      },
                    });
                  }
                }}
              />
            </Dialog.Centered>
          </Dialog.Content>
        </Dialog.Container>
      </Modal>
    </>
  );
};
