import { Dispatch } from 'react';

import { useUnit } from 'effector-react';
import { Return } from 'react-cool-onclickoutside';

import { Drawer } from '@visualist/design-system';

import { getBoards } from '@api/boards';
import { getDocs } from '@api/docs';
import { getHubs } from '@api/hubs';
import { boardCreationDialogOpened } from '@components/CreateDialogsBox/model';
import { docNamePassed, docRenamingDialogOpened } from '@pages/DocPage/model';
import { hubCreationDialogOpened } from '@pages/HubPage/model';
import { useCreateDoc } from '@src/entities/doc/queries/useCreateDoc';
import {
  ALL_DOCS_QUERY,
  ALL_HUBS_QUERY,
} from '@src/shared/constants/query-names';
import { useQuery } from '@tanstack/react-query';

import {
  $docsDotMenuNavrail,
  deleteDocModalNavrailOpened,
  docIdPassed,
  docsDotMenuNavrailClosed,
  navrailMenuBackdropHidden,
} from '../../../../model';

interface Props {
  drawerShow: boolean;
  setShowDrawer: Dispatch<boolean>;
  closeButtonClickHandler?: () => void;
  navrailMenuRef?: Return;
}

export const DrawerContainer = ({
  drawerShow,
  setShowDrawer,
  closeButtonClickHandler,
  navrailMenuRef,
}: Props) => {
  const docsDotMenuNavrail = useUnit($docsDotMenuNavrail);

  const { data: boards } = useQuery({
    queryKey: ['boards_tree'],
    queryFn: () => getBoards({ view_type: 'tree' }),
  });

  const boardsWithRightAccess = boards?.filter(
    (board) => board.permissions.length > 0,
  );

  const { data: hubs } = useQuery({
    queryKey: [ALL_HUBS_QUERY],
    queryFn: () => getHubs({ ordering: '-created_at' }),
  });
  const { data: docs } = useQuery({
    queryKey: [ALL_DOCS_QUERY],
    queryFn: () => getDocs(),
  });

  const onSuccessCreateDoc = () => {
    setShowDrawer(false);
    navrailMenuBackdropHidden();
  };

  const { makeDoc } = useCreateDoc({ onSuccess: onSuccessCreateDoc });

  if (!boards || !hubs) return null;

  return (
    <>
      <Drawer
        drawerShow={drawerShow}
        closeButtonClickHandler={closeButtonClickHandler}
        boards={boardsWithRightAccess}
        openCreateBoardDialog={() => boardCreationDialogOpened('create-board')}
        openCreateHubDialog={() => hubCreationDialogOpened('create-hub')}
        hubs={hubs}
        navrailMenuRef={navrailMenuRef}
        makeDoc={makeDoc}
        docs={docs}
        docsDotMenuNavrail={docsDotMenuNavrail}
        docIdPassed={docIdPassed}
        docsDotMenuNavrailClosed={docsDotMenuNavrailClosed}
        docRenamingDialogOpened={docRenamingDialogOpened}
        deleteDocModalNavrailOpened={deleteDocModalNavrailOpened}
        docNamePassed={docNamePassed}
      />
    </>
  );
};
