import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import {
  Checkbox,
  SegmentedButton,
} from '@visualist/design-system/src/components/v2';

import {
  $filteredElements,
  $isPatternsSelected,
  $isShapesSelected,
  getElements,
  patternsSelected,
  shapesSelected,
} from '../../pages/DocPage/model/elements-filter';
import {
  $selectedImages,
  imageSelected,
  SelectedImage,
} from '../../pages/DocPage/model/files-selection';

import styles from './styles.module.css';

export const Elements = () => {
  // const isAllElementsSelected = useUnit($isAllElementsSelected);
  const isShapesSelected = useUnit($isShapesSelected);
  const isPatternsSelected = useUnit($isPatternsSelected);
  const filteredElements = useUnit($filteredElements);

  useEffect(() => {
    getElements();
  }, []);

  return (
    <>
      <div className={styles.sheetHeader}>
        {/* <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => allElementsSelected()}
          start
          label="All"
          isSelected={isAllElementsSelected}
        /> */}
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => shapesSelected()}
          label="Shapes"
          isSelected={isShapesSelected}
          start
        />
        <SegmentedButton
          buttonStyle={styles.segmentedButton}
          onClick={() => patternsSelected()}
          label="Patterns"
          isSelected={isPatternsSelected}
          end
        />
      </div>
      <div className={styles.elements}>
        {filteredElements.map((shape) => (
          <ElementItem key={shape.id} shape={shape} />
        ))}
      </div>
    </>
  );
};

const ElementItem = ({ shape }: { shape: SelectedImage }) => {
  const [isShowCheckbox, setShowCheckbox] = useState(false);

  const selectedImages = useUnit($selectedImages);

  // TODO move this drag to effector state
  const imageRef = useRef<React.ElementRef<'img'>>(null);

  // TODO move this to effector state?
  const isSelectedCheckbox = selectedImages
    ? selectedImages.some((image) => image.id === shape.id)
    : false;

  useEffect(() => {
    if (!selectedImages) {
      setShowCheckbox(false);
    }

    if (isSelectedCheckbox) {
      setShowCheckbox(true);
    } else {
      setShowCheckbox(false);
    }
  }, [selectedImages, isSelectedCheckbox]);

  const storeDataForDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (!imageRef.current) return;

    e.dataTransfer.dropEffect = 'move';

    e.dataTransfer.setData(
      'imageData',
      JSON.stringify({
        url: shape.url,
        height: 60,
        width: 60,
        fileId: shape.id,
      }),
    );
  };

  return (
    <div
      className={cn(styles.element, {
        [styles.selectedElement]: isSelectedCheckbox,
      })}
      draggable
      onDragStart={(e) => {
        storeDataForDrag(e);
        setShowCheckbox(false);
      }}
      onDragEnd={() => {
        if (isSelectedCheckbox) {
          setShowCheckbox(true);
        }
      }}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => {
        if (!isSelectedCheckbox) {
          setShowCheckbox(false);
        }
      }}
    >
      {isShowCheckbox && (
        <Checkbox
          style={{
            position: 'absolute',
            width: '16px',
            height: '16px',
            top: '0px',
            left: '0px',
          }}
          classNameOuterRadio={styles.checkboxOuterRadio}
          icon={
            <Icon
              name="sprite/checkbox-filled"
              color="#99461C"
              className={styles.checkbox}
            />
          }
          onClick={() => {
            if (imageRef.current) {
              imageSelected({
                id: shape.id,
                url: shape.url,
                height: 60,
                width: 60,
                thumbnailUrl: shape.thumbnailUrl,
              });
            }
          }}
          isSelected={isSelectedCheckbox}
        />
      )}
      <img
        loading="lazy"
        style={{ width: '60px', height: '60px' }}
        ref={imageRef}
        alt="library item"
        src={shape.thumbnailUrl}
      />
    </div>
  );
};
