import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import {
  AssistChip,
  TeamIcon,
  Tooltip,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { $id } from '../model/open-share-sheet';

import styles from './styles.module.css';

interface PublicLinkProps {
  // handlePublishChange: (
  //   published: boolean,
  //   allow_comments?: boolean,
  // ) => Promise<void>;
  // publicLink: string | null;
  isDocInHub: boolean;
}

export const Links = ({
  // handlePublishChange,
  // publicLink,
  isDocInHub,
}: PublicLinkProps) => {
  const id = useUnit($id);
  // const copyPublicLink = async () => {
  //   try {
  //     if (publicLink) {
  //       await navigator.clipboard.writeText(publicLink);
  //       startedSnack({
  //         label: 'Copied link',
  //         close: true,
  //       });
  //     }
  //   } catch (error) {
  //     startedSnack({
  //       label: "Oops, could'nt copy this",
  //       action: {
  //         label: 'Try again',
  //         action: () => {
  //           copyPublicLink();
  //         },
  //       },
  //       close: true,
  //     });
  //   }
  // };

  const copyPrivateLink = async () => {
    // const url = new URL(document.location.href);

    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/d/${id}`,
      );

      // await navigator.clipboard.writeText(url.href);
      startedSnack({
        label: 'Copied link',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link",
        action: {
          label: 'Try again',
          action: () => {
            copyPrivateLink();
          },
        },
        close: true,
      });
    }
  };

  return (
    <div className={styles.links}>
      <div className={styles.row}>
        <div className={styles.title}>
          <TeamIcon className={styles.teamIcon} />
          <TypographyPoppins type="body" size="L">
            <span className={styles.publicLabel}>
              Share link with invited guests
            </span>
          </TypographyPoppins>
          <Tooltip
            style={{ minWidth: '246px', left: '-102px' }}
            parameter={{
              type: 'plain',
              description:
                'Only those invited can see this page when they log in',
              hasVisualBoundary: false,
              position: 'bottom',
            }}
          >
            <Icon
              name="sprite/information"
              size={16}
              className={styles.infoIcon}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.row}>
        {isDocInHub ? (
          <AssistChip style="outlined" onClick={copyPrivateLink}>
            Copy private link
          </AssistChip>
        ) : (
          <Tooltip
            style={{ minWidth: 'max-content', left: '49px' }}
            parameter={{
              type: 'plain',
              description: 'Add the doc to a hub to invite others',
              hasVisualBoundary: false,
              position: 'top',
            }}
          >
            <AssistChip style="outlined" disabled={!isDocInHub}>
              Copy private link
            </AssistChip>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
