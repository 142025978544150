import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { getCredits } from '@api/hubs';
import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import { settingsSheetClosed } from '@pages/HubPage/components/SettingsSheet/model';
import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { REFUNDED_HUB_CREDITS_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import {
  $boardDeleteModalState,
  $hubDeleteModalState,
  deleteBoardModalClosed,
  deleteHubModalClosed,
} from './modal';

export const DeleteModals = () => {
  const [hubDeleteModalState, boardDeleteModalState] = useUnit([
    $hubDeleteModalState,
    $boardDeleteModalState,
  ]);

  return (
    <>
      {/* Hub Delete */}
      <Modal
        handleClose={deleteHubModalClosed}
        showModal={hubDeleteModalState !== null}
      >
        {hubDeleteModalState !== null ? (
          <HubDeleteModal hubToDelete={hubDeleteModalState} />
        ) : null}
      </Modal>
      {/* Board Delete */}
      <Modal
        handleClose={deleteBoardModalClosed}
        showModal={boardDeleteModalState !== null}
      >
        {boardDeleteModalState !== null ? (
          <BoardDeleteModal boardToDelete={boardDeleteModalState} />
        ) : null}
      </Modal>
    </>
  );
};

const BoardDeleteModal = ({
  boardToDelete,
}: {
  boardToDelete: {
    hubId?: string;
    boardId: string;
    parentBoardId?: string;
  };
}) => {
  const { removeBoard } = useBoards({
    hub_id: boardToDelete.hubId,
    handleSuccessRemoveBoard: () => {
      deleteBoardModalClosed();
    },
  });

  const handleRemoveBoard = () =>
    removeBoard({
      id: boardToDelete.boardId,
      parentId: boardToDelete.parentBoardId,
      hubId: boardToDelete.hubId,
    });

  return (
    <Dialog
      headline="Delete this board?"
      description="You will permanently delete this board."
      action={handleRemoveBoard}
      actionLabel="Confirm"
      close={deleteBoardModalClosed}
    />
  );
};

const HubDeleteModal = ({
  hubToDelete,
}: {
  hubToDelete: {
    hubId: string;
    name: string;
  };
}) => {
  const { hubId, name } = hubToDelete;

  const { data: creditsToRefund } = useQuery({
    queryKey: [REFUNDED_HUB_CREDITS_QUERY, { hubId }],
    queryFn: () => getCredits({ hubId }),
  });

  const getSupportingText = () => {
    if (creditsToRefund && Math.abs(creditsToRefund.credits_refunded) > 0) {
      return `You’ll be refunded ${Math.abs(
        creditsToRefund.credits_refunded,
      )} ${
        Math.abs(creditsToRefund.credits_refunded) === 1 ? 'credit' : 'credits'
      }.`;
    }
  };

  const { removeHub } = useHubs({
    onSuccess: () => {
      // Closes setting sheet if its open
      settingsSheetClosed();
      deleteHubModalClosed();
    },
  });

  const handleRemove = () => removeHub({ hubId, name });

  return (
    <Dialog
      headline="Delete this hub?"
      description="You will permanently delete this hub."
      supportingText={getSupportingText()}
      action={handleRemove}
      actionLabel="Confirm"
      close={deleteHubModalClosed}
    />
  );
};
