import { useEffect } from 'react';

import { Icon } from 'icons';
import { useHistory, useParams } from 'react-router';

import { Item, TeamIcon } from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { addingDocToHubDialogOpened } from '@components/CreateDialogsBox/model';
import {
  boardsSearchActivated,
  deleteDocModalOpened,
} from '@pages/DocPage/model';
import {
  docShareSheetOpened,
  hubVerified,
  idSelected,
} from '@src/entities/share-sheet/doc/model/open-share-sheet';
import { Tabs } from '@src/entities/tabs/ui/tabs';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import { openedPageSetupModal } from '../../model';
import { useCreateDoc } from '../../queries/useCreateDoc';
import { useDownloadDoc } from '../../queries/useDownloadDoc';
import { useGetDoc } from '../../queries/useGetDoc';
import { useUpdateDoc } from '../../queries/useUpdateDoc';

export type Tab = {
  id: string;
  label: string;
};

export type TabWithHandlers = Tab & {
  onClick: () => void;
  menuItems?: Item[];
};

export const DocTabs = () => {
  const history = useHistory();
  const { doc_id: selectedId } = useParams<{ doc_id: string }>();

  const [tabs, setTabs, reset] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const { data: doc, isFetched } = useGetDoc({ doc_id: selectedId });

  const { makeDocMutation } = useCreateDoc();
  const { editDoc } = useUpdateDoc();
  const { downloadMutation } = useDownloadDoc();

  const createNewDoc = async () => {
    const newDoc = await makeDocMutation.mutateAsync({});
    setTabs((tabs) => [
      ...tabs,
      {
        id: newDoc.id,
        label: newDoc.title,
        onClick: () => {},
      },
    ]);
    history.push(`/d/${newDoc.id}`);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);

      startedSnack({
        label: 'Copied link to doc',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to doc",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const addToBoard = () => {
    addingDocToHubDialogOpened();
    boardsSearchActivated();
  };

  const removeFromBoard = () => {
    editDoc({ docId: selectedId, board: '', isDocRemovedFromBoard: true });
  };

  const downloadAsPDF = (id: string, label: string) => {
    downloadMutation.mutate({ id, name: label });
  };

  const closeTab = (id: string) => {
    setTabs((tabs) => tabs.filter((t) => t.id !== id));
  };

  const postCloseAction = (passedTabs?: Tab[]) => {
    const tabsToUse = passedTabs || tabs;
    if (tabsToUse.length !== 0) {
      history.push(`/d/${tabsToUse[tabsToUse.length - 1].id}`);
      return;
    }

    history.push('/library');
  };

  const deleteDoc = async () => {
    deleteDocModalOpened();
  };

  const saveDocName = (id: string, name: string) =>
    editDoc({ docId: id, title: name });

  const reorderDocTabs = (newIdOrder: Array<string>) => {
    if (!tabs) return;

    const currentTabs = tabs.reduce((acc, tab) => {
      acc[tab.id] = tab;
      return acc;
    }, {} as Record<string, Tab>);
    const newTabs = newIdOrder.map((t) => currentTabs[t]);
    setTabs(newTabs);
  };

  useEffect(() => {
    if (doc && !tabs.find((t) => t.id === doc.id)) {
      setTabs((tabs) => [...tabs, { id: doc.id, label: doc.title }]);
    }
  }, [doc]);

  // If doc is fetched and no data close tab
  if (!doc && isFetched) {
    reset();
    history.push('/library');
  }

  const renderTabs = tabs.map((t) => ({
    ...t,
    onClick: () => history.push(`/d/${t.id}`),
    menuItems: [
      {
        leadingIcon: <Icon name="sprite/board" />,
        content: doc?.board ? 'Remove doc from board' : 'Add doc to board',
        onClick: doc?.board ? removeFromBoard : addToBoard,
      },
      {
        leadingIcon: <TeamIcon />,
        content: 'Invite to doc',
        onClick: () => {
          docShareSheetOpened();
          idSelected(selectedId);
          hubVerified(Boolean(doc.hub));
        },
      },
      {
        leadingIcon: <Icon name="sprite/download" />,
        content: 'Download as PDF',
        onClick: () => downloadAsPDF(t.id, t.label),
      },
      {
        leadingIcon: <Icon name="sprite/link" size={21} />,
        content: 'Copy link',
        onClick: () => copyLink(),
      },
      {
        leadingIcon: <Icon name="sprite/settings" size={21} />,
        content: 'Page setup',
        onClick: () => openedPageSetupModal(),
      },
    ],
  }));

  return (
    <>
      <Tabs
        tabs={renderTabs}
        selectedId={selectedId}
        isLoadingTabs={false}
        reorderTabs={reorderDocTabs}
        closeTab={closeTab}
        postCloseAction={postCloseAction}
        newTabAction={createNewDoc}
        canDeleteTab
        deleteTab={deleteDoc}
        saveTabName={saveDocName}
        board={doc?.board}
      />
    </>
  );
};
