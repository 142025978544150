import { Icon } from 'icons';
import { useHistory, useLocation } from 'react-router-dom';

import {
  DocFilled,
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { ResponseCreateDoc } from '@api/docs';
import { useCreateDoc } from '@src/entities/doc/queries/useCreateDoc';
import { Tab } from '@src/entities/doc/ui/tabs';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import styles from './styles.module.css';

export const Doc = () => {
  const history = useHistory();
  const location = useLocation();

  const [tabs, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);
  const { makeDocMutation } = useCreateDoc();

  const storeNewDoc = (doc: ResponseCreateDoc) => {
    setTabs((tabs) => [
      ...tabs,
      {
        id: doc.id,
        label: doc.title,
        onClick: () => {},
      },
    ]);
  };

  const goToDoc = async () => {
    if (/^\/d\//.test(location.pathname)) return;

    try {
      if (tabs.length > 0) {
        const docTab = tabs[0];
        history.push(`/d/${docTab.id}`);
      } else {
        const newDoc = await makeDocMutation.mutateAsync({});
        storeNewDoc(newDoc);
        history.push(`/d/${newDoc.id}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={styles.docs}
        type="unfilled"
        icon={
          location.pathname.includes('/d/') ? (
            <DocFilled />
          ) : (
            <Icon name="sprite/doc" />
          )
        }
        isSelected={location.pathname.includes('/d/')}
        onClick={goToDoc}
      />
      <TypographyPoppins className={styles.text} type="label" labelSize="M">
        Docs
      </TypographyPoppins>
    </div>
  );
};
