import { Icon } from 'icons';

import { IconButton } from '@visualist/design-system/src/components/v2';

import { toggleSearch } from '@src/entities/search/model';

import styles from './styles.module.css';

export const Search = () => {
  const openSearchDialog = () => toggleSearch();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={styles.search}
        type="unfilled"
        icon={<Icon name="sprite/magnifier" />}
        isSelected={false}
        onClick={openSearchDialog}
      />
    </div>
  );
};
