import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { openedTrialSheet } from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

import { $showProTrialUpgrade, hidingProTrial } from '../../model';
import { useUserOnboarding } from '../../useUserOnboarding';

import styles from './styles.module.css';

export const TrialProOnboarding = () => {
  const [showProTrialUpgrade] = useUnit([$showProTrialUpgrade]);
  const { query } = useBilling();
  const { markProTrialAsCompleted, user } = useUserOnboarding();

  if (
    !(
      showProTrialUpgrade &&
      query &&
      query.data &&
      query.data.membershipTierInfo.plan === 'free' &&
      user.meta &&
      user.meta.onboarding &&
      user.meta.onboarding.completedPaths.createHubs &&
      !user.meta.proTrialDialogCompleted
    )
  )
    return null;

  return (
    <Modal
      handleClose={() => {
        markProTrialAsCompleted();
        hidingProTrial();
      }}
      showModal={showProTrialUpgrade}
      className={styles.container}
    >
      <Dialog
        action={() => {
          openedTrialSheet();
          hidingProTrial();
        }}
        headline="Time to go Pro"
        description="Hubs are the best way to enhance client wellness."
        supportingText="This feature is available on the Pro plan, and each hub costs $12 per month."
        thirdLine="Get 15 days of Pro and $120 worth of hubs by starting your free trial."
        actionLabel="Trial Pro today"
        close={() => {
          markProTrialAsCompleted();
          hidingProTrial();
        }}
      />
    </Modal>
  );
};
