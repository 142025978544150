import React, { useState } from 'react';

import queryString from 'query-string';
import { Helmet } from 'react-helmet-async';
import { useHistory, useLocation } from 'react-router-dom';

import { ButtonPill, Carousel } from '@visualist/design-system';

import { useMutation } from '@tanstack/react-query';

import { updatePassword } from '../../api';
import { ResetPasswordForm } from '../../components/ResetPasswordForm';

import styles from './ResetYourPasswordPage.module.css';

export const ResetYourPasswordPage = () => {
  const [firstScreen, setFirstScreen] = useState(false);
  const history = useHistory();
  const { search } = useLocation();

  const backToLogin = () => {
    history.push('/login');
  };

  const { uid = '', token = '' } = queryString.parse(search);

  const { mutate } = useMutation({
    mutationFn: (payload) => {
      updatePassword(payload);
    },
    onSuccess: () => {
      setFirstScreen(true);
    },
  });

  return (
    <>
      <section className="auth-page forgot-password-page">
        <Helmet>
          <title>Reset password | Visualist</title>
        </Helmet>
        <div className={styles.blocks}>
          {firstScreen ? (
            <div className={styles.totalWraper}>
              <div className={styles.textWrapReset}>
                <p>Password reset</p>
              </div>
              <div className={styles.submitButtonReset}>
                <ButtonPill label={'Log in'} onClick={backToLogin} />
              </div>
            </div>
          ) : (
            <ResetPasswordForm
              onSubmitForm={(values) =>
                mutate({
                  uid,
                  token,
                  new_password1: values.password1,
                  new_password2: values.password2,
                })
              }
              greeting={'Reset your password'}
            />
          )}
          <div className={styles.carouselBox}>
            <Carousel />
          </div>
        </div>
      </section>
    </>
  );
};
