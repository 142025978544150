import { ActionFilter } from '@pages/action-items/modal';
import { PriorityType } from '@pages/action-items/ui/priority';
import { User } from '@src/AppContext';
import { StickyType } from '@src/entities/Stickies/types';

import { api } from './services';

type Nullable<T> = {
  [P in keyof T]: T[P] | null;
};

export type SimpleAction = {
  id: string;
  assigned_to: Array<User> | null;
  marked_as_done_by: User | null;
  created_by: User;
  is_removed: boolean;
  status: 'open' | 'done';
  created_at: string;
  updated_at: string;
  completed_at: string;
  name: string;
  description: string;
  priority: PriorityType;
  due_date: string | null;
};

export type NullableSimpleAction = Nullable<SimpleAction>;

export const defaultSimpleAction: Nullable<SimpleAction> = {
  id: null,
  assigned_to: null,
  marked_as_done_by: null,
  created_by: null,
  is_removed: false,
  status: 'open',
  created_at: null,
  updated_at: null,
  completed_at: null,
  name: null,
  description: null,
  priority: 0,
  due_date: null,
};

export type Action = SimpleAction & {
  sticky?:
    | (StickyType & {
        block_file: string;
      })
    | null;
};

export type InfiniteActionResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<SimpleAction>;
};

export type ResponseActions = {
  count: number;
  sticky_actions: StickyType[];
};

export const getUserActions = async (): Promise<ResponseActions> => {
  const { data } = await api.get(`/actions/user?status=open`);
  return data;
};

export const getActions = async ({
  offset,
  limit = 40,
  filters,
}: {
  offset?: number;
  limit?: number;
  filters: ActionFilter;
}): Promise<InfiniteActionResponse> => {
  const { data } = await api.get(`/actions/`, {
    params: {
      offset,
      limit,
      due_date: filters.due,
      status: filters.status,
      ordering:
        filters.sortOrder === 'descending'
          ? `-${filters.ordering}`
          : filters.ordering,
      hub: filters.hub?.id,
    },
  });
  return data;
};

export type ResponseHubActions = {
  count: number;
  results: Array<SimpleAction>;
};

export const getHubActions = async ({
  id,
}: {
  id: string;
}): Promise<ResponseHubActions> => {
  const { data } = await api.get(`/actions/`, {
    params: {
      offset: 0,
      limit: 3,
      status: 'open',
      ordering: '-created_at',
      hub: id,
    },
  });
  return data;
};

export const getAction = async (actionId: string): Promise<Action> => {
  const { data } = await api.get(`/actions/${actionId}/`, {});

  return data;
};

export const createAction = async (
  actionData: Partial<
    Omit<SimpleAction, 'assigned_to'> & {
      assigned_to?: Array<number>;
    }
  > & {
    hub?: string;
  },
): Promise<SimpleAction> => {
  const { data } = await api.post(
    '/actions/',
    {
      ...actionData,
    },
    {},
  );

  return data;
};

export const patchAction = async (
  actionId: string,
  actionData: Partial<
    Omit<SimpleAction, 'assigned_to'> & {
      assigned_to?: Array<number>;
    }
  >,
): Promise<any> => {
  const { data } = await api.patch(`/actions/${actionId}/`, {
    ...actionData,
  });

  return data;
};

export const getActionAssignees = async (
  actionId: string,
): Promise<Array<User>> => {
  const { data } = await api.get(`/actions/${actionId}/assignees/`);

  return data;
};

export const deleteAction = async (id: string) => {
  const { data } = await api.delete(`/actions/${id}/`);
  return data;
};
