import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { Icon } from 'icons';

import { Checkbox } from '@visualist/design-system/src/components/v2';

import {
  $selectedImages,
  imageSelected,
} from '../../../pages/DocPage/model/files-selection';

import styles from './styles.module.css';

export const File = ({
  thumbnailURL,
  url,
  fileId,
  fileType,
}: {
  thumbnailURL: string;
  url: string;
  fileId: string;
  fileType: 'Image' | 'Set' | 'Colour palette';
}) => {
  const [isShowCheckbox, setShowCheckbox] = useState(false);

  const selectedImages = useUnit($selectedImages);

  // TODO move this drag to effector state
  const imageRef = useRef<React.ElementRef<'img'>>(null);

  // TODO move this to effector state?
  const isSelectedCheckbox = selectedImages
    ? selectedImages.some((image) => image.id === fileId)
    : false;

  useEffect(() => {
    if (!selectedImages) {
      setShowCheckbox(false);
    }

    if (isSelectedCheckbox) {
      setShowCheckbox(true);
    }
  }, [selectedImages, isSelectedCheckbox]);

  const storeDataForDrag = (e: React.DragEvent<HTMLDivElement>) => {
    if (!imageRef.current) return;

    e.dataTransfer.dropEffect = 'move';

    const img = imageRef.current;

    e.dataTransfer.setData(
      'imageData',
      JSON.stringify({
        url,
        height: img.naturalHeight,
        width: img.naturalWidth,
        fileId,
      }),
    );
  };

  return (
    <div
      className={cn(styles.imageContainer, {
        [styles.selectedImage]: isSelectedCheckbox,
      })}
      draggable
      onDragStart={(e) => {
        storeDataForDrag(e);
        setShowCheckbox(false);
      }}
      onDragEnd={() => {
        if (isSelectedCheckbox) {
          setShowCheckbox(true);
        }
      }}
      onMouseEnter={() => setShowCheckbox(true)}
      onMouseLeave={() => {
        if (!isSelectedCheckbox) {
          setShowCheckbox(false);
        }
      }}
    >
      {isShowCheckbox && (
        <Checkbox
          style={{
            position: 'absolute',
            width: '16px',
            height: '16px',
          }}
          classNameOuterRadio={styles.checkboxOuterRadio}
          icon={
            <Icon
              name="sprite/checkbox-filled"
              color="#99461C"
              className={styles.checkbox}
            />
          }
          onClick={() => {
            if (imageRef.current) {
              imageSelected({
                id: fileId,
                url,
                height: imageRef.current.naturalHeight,
                width: imageRef.current.naturalWidth,
              });
            }
          }}
          isSelected={isSelectedCheckbox}
        />
      )}
      <img
        ref={imageRef}
        alt="library item"
        className={cn(styles.image, {
          [styles.palette]: fileType === 'Colour palette',
        })}
        src={thumbnailURL}
      />
    </div>
  );
};
